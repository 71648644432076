import { FilterUtils } from 'primeng/utils';
import { Component, ViewChild, ViewEncapsulation, OnInit, DoCheck, OnDestroy, AfterViewInit } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { RoleManagementService } from 'src/app/core/security/role-management.service';
import { SuiteService } from 'src/app/core/services/suite.service';
import { ApiService } from 'src/app/core/services/api.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from "@angular/platform-browser";
import { ReportScheduleService } from 'src/app/core/services/reportSchedule.service';

/**
  * Report Grid Component.
  */
@Component({
    selector: 'app-report-grid',
    templateUrl: './report-grid.component.html',
    styleUrls: ['./report-grid.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class ReportGridComponent implements OnInit, DoCheck, OnDestroy, AfterViewInit {
    @ViewChild('taskBoardRole', { static: false }) content;

    /**
    * Initial Declarations.
    */
    fullFormName: string;
    suite: string;
    report: string;
    canEdit: boolean;
    hasReport = true;
    subReports: string;
    state: any = null;
    localJSON: any = [];
    tab: string;
    tabArray: Object[] = [];
    dataAdapter: any;
    source: any = {};
    columns: any = [];
    columngroups: any = [];
    latestChangeAudit: any = [];
    input: any;
    BT: any = [];
    currentPage: any;
    columnGroupInfo: any;
    columnInfo: any;
    dataInfo: any;
    loading = false;
    reportPageList: any;
    reportPackIdSub: Subscription;
    selectedRp: any;
    isAssigned: boolean = false;
    headerCols: any;
    yearRange = "2000:" + (new Date()).getFullYear().toString();
    approverComments = '';
    approverCommentHistory: any;
    postedDateFilters: Date[];
    visibleAssignTo = false;
    visibleAdhoc = false;
    subReportAlias: string;
    auditExceptionPages: any;
    selectedReportingType: any = '';
    reportAudit: any = [];
    reportChanges: any = [];
    boxCodeAudit: any = [];
    blockedCells: any = [];
    blankCells: any = [];
    cellRegArtReferenceUrlList: any = [];
    cellDataTypes: any = [];
    cellDecimalPrecision: any = [];
    uncomputedCells: any = [];
    cellEditPropertyList: any = [];
    cellDataDictionary: any = [];
    user: any;
    dependentReports: string;
    downloadPopupHeader: string;
    auditExceptionData: any;
    auditExceptionColumns: any;
    auditExceptionViewHeader: string;
    selectedAuditExceptionPage: any;
    auditExceptionGridData: any;
    reportList: any;
    reportingTypeDetails: any;
    /**
     * Recalculate subscription of grid component
     */
    private recalculateSubs: Subscription;
    private userSubscription: Subscription;

    /**
    * Creates an instance of the Report Grid Component.
    */
    constructor(
        private modalService: NgbModal,
        private dashboardService: DashboardService,
        private roleService: RoleManagementService,
        private suiteService: SuiteService,
        private apiService: ApiService,
        private router: Router,
        private commonService: CommonService,
        private alertService: AlertService,
        private authService: AuthenticationService,
        private titleService: Title,
        private reportScheduleService: ReportScheduleService
    ) {
        this.fullFormName = this.dashboardService.fullFormName;
        this.hasReport = this.dashboardService.hasReport;
        this.report = this.dashboardService.formName;
        this.suite = this.suiteService.getCurrentSuite();
        this.subReportAlias = this.dashboardService.reportAliasName;

        const currentUrl = this.router.url.split('/');
        this.subReports = '';
        if (currentUrl && currentUrl.length === 6 && currentUrl[2] === 'suite' && currentUrl[5] != 'subreport') {
            this.subReports = currentUrl[4].toString();
        }
    }

    /**
    * on Init.
    */
    ngOnInit() {
        if (!this.authService.isPrivacyNoticeAcknowledged) {
            this.router.navigate(['/privacy-notice']);
        }

        this.canEdit = this.roleService.checkPermissions(
            'E',
            this.suiteService.getCurrentSuite(),
            this.dashboardService.formName ? this.dashboardService.formName.toLowerCase().trim() : ''
        );

        this.user = this.authService.getLoggedInUser();
        if (!this.user) {
            this.userSubscription = this.authService.watchUser().subscribe(user => {
                this.user = user;
            });
        }

        this.initializeData();

        this.recalculateSubs = this.commonService.watchShowPanel()
            .subscribe(() => {
                this.getColumns(this.dashboardService.reportData.columnGroups, this.dashboardService.reportData.columnHeaders, this.localJSON);
            });

        this.selectedRp = this.dashboardService.selectedReportPack;

        this.reportPackIdSub = this.dashboardService.watchReportPackId().subscribe(rpId => {
            this.loading = true;
            this.selectedRp = this.dashboardService.selectedReportPack;
            if (this.dashboardService.reportId != 0) {
                this.dashboardService.getReportsByReportPack().then((res: any) => {
                    let reportList = res;
                    let reportId = this.dashboardService.reportId;
                    let reportName = this.dashboardService.reportAliasName;
                    this.dashboardService.hasReport = false;
                    if (reportList.filter(x => x.reportAliasName === reportName && x.reportId === reportId)) {
                        this.dashboardService.hasReport = true;
                        this.dashboardService.getReportData()
                            .then(() => {
                                this.loading = false;
                                this.initializeData();
                            }, () => { this.loading = false; });
                    } else this.loading = false;
                })
            }
        });
        this.checkReportIsAssigned();

        this.titleService.setTitle('IRR: ' + this.subReportAlias);

        this.headerCols = [
            { field: 'ApproverLevel', header: 'Approver Level' },
            { field: 'UserName', header: 'Reporter' },
            { field: 'Email', header: 'Email' },
            { field: 'PostDate', header: 'Approval Date' },
            { field: 'Comments', header: 'Comments' }
        ];

        FilterUtils['dateRangeFilter'] = (value, filter: [Date, Date]): boolean => {
            if (!filter || (!filter[0] && !filter[1]))
                return true;
            var val = new Date(value);
            var convertDate = new Date(val.getFullYear() + '/' + (val.getMonth() + 1) + '/' + val.getDate());
            var s = filter[0].getTime();
            var e;
            if (filter[1])
                e = filter[1].getTime() + (24 * 60 * 60 * 1000) - 1000;
            else e = s + (24 * 60 * 60 * 1000) - 1000;
            return convertDate.getTime() >= s && convertDate.getTime() <= e;
        }
    }

    ngAfterViewInit() {
        //document.getElementsByClassName('custom-bread-crumb')[0].lastChild.textContent = this.subReportAlias;
    }

    initializeData() {
        var pageHeaders = this.dashboardService.reportData.reportPageHeaders;

        if (!pageHeaders || !pageHeaders.length) {
            this.alertService.warn('Cannot access the report!', true);
            if (this.subReports) {
                this.router.navigate(['/app/suite', this.suite, this.subReports, 'subreports']);
            }
            else {
                this.router.navigate(['/app/suite', this.suite]);
            }
        }

        this.currentPage = (this.dashboardService.reportPageList && this.dashboardService.reportPageList.length > 0) ?
            this.dashboardService.reportPageList[0].PageName.trim() : '';
        this.columnGroupInfo = this.dashboardService.reportData.columnGroups;
        this.columnInfo = this.dashboardService.reportData.columnHeaders;
        this.latestChangeAudit = this.dashboardService.reportData.reportChangeAudit;
        this.BT = this.dashboardService.reportData.columnData;
        this.localJSON = this.dashboardService.reportData.columnData;
        this.blockedCells = this.dashboardService.reportData.BlockedCells != null ? this.dashboardService.reportData.BlockedCells : [];
        this.blankCells = this.dashboardService.reportData.BlankCells != null ? this.dashboardService.reportData.BlankCells : [];
        this.cellRegArtReferenceUrlList = this.dashboardService.reportData.RegArtReferenceUrlCells != null ? this.dashboardService.reportData.RegArtReferenceUrlCells : [];
        this.cellDataTypes = this.dashboardService.reportData.BoxCodeDataTypes != null ? this.dashboardService.reportData.BoxCodeDataTypes : [];
        this.cellEditPropertyList = this.dashboardService.reportData.EditableCells != null ? this.dashboardService.reportData.EditableCells : [];
        this.cellDataDictionary = this.dashboardService.reportData.BoxCodeDataDictionary != null ? this.dashboardService.reportData.BoxCodeDataDictionary : [];
        this.dependentReports = this.dashboardService.reportData.DependentReports;
        this.reportingTypeDetails = this.dashboardService.reportData.ReportingTypeDetails;
        this.cellDecimalPrecision = this.dashboardService.reportData.DecimalPrecision != null ? this.dashboardService.reportData.DecimalPrecision : [];
        this.uncomputedCells = this.dashboardService.reportData.UncomputedCells != null ? this.dashboardService.reportData.UncomputedCells : [];

        this.loadState();
        if (this.columnGroupInfo) {
            this.getColumns(this.dashboardService.reportData.columnGroups, this.dashboardService.reportData.columnHeaders, this.localJSON);
        }
        if (this.dashboardService.reportPageList) {
            this.currentPage = this.dashboardService.reportPageList[0].PageName.trim();
        }
        this.reportPageList = this.dashboardService.reportPageList;

        this.reportAudit = [];
        this.reportChanges = [];
    }

    /**
    * Checks for report data.
    */
    ngDoCheck() {
        if (this.dashboardService.reportData === null) {
            this.dashboardService.getReportData();
        }
    }

    loadState(): void {
        if (this.state) {
            this.localJSON = this.state;
        } else {
            this.localJSON = this.dashboardService.reportData.columnData;
        }
    }

    getColumns(columnGroups: any, columnHeaders: any, columnData: any): void {
        this.dataAdapter = null;
        const page = this.currentPage;

        this.columngroups = columnGroups.filter(x => x.PageName.trim() === page);
        this.columns = columnHeaders.filter(x => x.PageName.trim() === page);
        if (columnData != null && columnData[0] != null && columnData[0].hasOwnProperty('pagename')) {
            this.dataAdapter = columnData.filter(x => x.pagename.trim() === page);
        }
    }

    validateChangedCell = (changedCell: any) => {
        const newValue = changedCell.editor == "numeric" && changedCell.decimals == 0 ? parseInt(changedCell.newValue) : changedCell.newValue;
        const changedDate = new Date();

        //Adding to audit
        this.reportAudit.push({
            id: this.reportAudit.length + 1, boxCode: changedCell.tabCode.replace('%', changedCell.rowCode), oldValue: changedCell.oldValue, newValue: changedCell.newValue,
            comment: changedCell.comment, changedDate: changedDate
        });

        //Updating latest audit data
        var latestCellAudit = this.latestChangeAudit.filter(x => x.boxcode == changedCell.boxCode)[0];
        if (latestCellAudit) {
            latestCellAudit.oldvalue = changedCell.oldValue;
            latestCellAudit.newvalue = changedCell.newValue;
            latestCellAudit.comment = changedCell.comment;
            latestCellAudit.changedby = this.user.username;
            latestCellAudit.changeddate = changedDate;
        }
        else {
            this.latestChangeAudit.push({
                RowCode: changedCell.rowCode, boxcode: changedCell.boxCode, rowdescription: changedCell.headTitle, column: changedCell.columnTitle,
                oldvalue: changedCell.oldValue, newvalue: changedCell.newValue, comment: changedCell.comment, changedby: this.user.username, changeddate: changedDate
            });
        }

        // Call logic only edited value in numeric
        if ((changedCell.editor == "numeric" && !isNaN(newValue)) || changedCell.editor != "numeric") {
            this.AdjustmentCalculation(changedCell.rowCode, changedCell.oldValue, newValue, changedCell.column, changedCell.columnIdentifier, changedCell.tabCode, changedCell.editor, changedCell.pageName, true);
        }

        this.saveReportData();
    }

    /**
    * Saves adjustments and current state of the report.
    */

    saveState() {
        var param = {
            reportPackID: +this.dashboardService.reportPackID,
            userId: this.authService.getLoggedInUser().userid,
            reportID: this.dashboardService.reportId,
            suitename: this.dashboardService.suitename,
            countryCode: this.dashboardService.formName === this.dashboardService.reportAliasName ? null : this.dashboardService.reportAliasName,
            reportAbbr: this.report
        }
        this.loading = true;
        this.dashboardService.hasPermission(param)
            .then((data: any) => {
                this.loading = false;
                if (data === "true") {
                    this.loading = true;
                    this.dashboardService.isReportAssigned(param)
                        .then((data: any) => {
                            this.loading = false;

                            if (data.IsReportSignedOff === true) {
                                this.isAssigned = true;
                                this.alertService.success(data.Message);
                            }
                            else {
                                if (data.IsAssignedFlag === false) {
                                    this.isAssigned = false;
                                    this.openTaskboardRoleModal();
                                }
                                else if (data.IsAssignedFlag === true) {
                                    this.isAssigned = true;
                                    this.saveReportData();
                                }

                                this.visibleAdhoc = data.adhocScheduleFlag;
                                this.visibleAssignTo = data.assignToMeFlag;
                            }
                        }).catch(() => { this.loading = false; });
                }
                else {
                    this.isAssigned = true;
                    this.alertService.warn(data);
                }
            });
    }

    async saveReportData() {
        if (this.reportChanges.length > 0) {
            // Calling save adjustment
            this.loading = true;
            await this.dashboardService.saveReportData(this.reportChanges, this.reportAudit)
                .then(() => {
                    this.reportChanges = [];
                    this.reportAudit = [];
                    this.dashboardService.getReportGridData()
                        .then(() => {
                            this.BT = this.dashboardService.reportData.columnData;
                            this.localJSON = this.dashboardService.reportData.columnData;
                            this.dataAdapter = this.localJSON.filter(x => x.pagename.trim() === this.currentPage);
                            this.loading = false;
                        }).catch(() => { this.loading = false; });
                }).catch(() => { this.loading = false; });
        }
        else {
            this.alertService.info('No changes to save!');
        }
    }

    setPageReportId(value: any) {
        this.currentPage = value;
        // Calling grid
        this.getColumns(this.dashboardService.reportData.columnGroups, this.dashboardService.reportData.columnHeaders,
            this.dashboardService.reportData.columnData);
        return;
    }

    /**
    * Calculates Adjustments.
    */
    AdjustmentCalculation(rowCode: string, oldValue: number, newvalue: any, curColumn: string, columnIdentifier: string, tabCode: string, colDataType: string, pageName: string, gridBind: boolean) {
        var row = this.localJSON.findIndex(x => x.boxcode == rowCode && x.pagename == pageName);
        this.localJSON[row][curColumn] = newvalue;

        //Updating reportChanges
        var curBoxCode = tabCode.replace('%', this.localJSON[row].boxcode);
        if (this.reportChanges.length > 0 && this.reportChanges.filter(x => x.boxCode == curBoxCode).length > 0) {
            var boxCodeRow = this.reportChanges.filter(x => x.boxCode == curBoxCode)[0];
            boxCodeRow.boxCodeValue = this.localJSON[row][curColumn];
        }
        else {
            this.reportChanges.push({ boxCode: curBoxCode, boxCodeValue: this.localJSON[row][curColumn], columnIdentifier: columnIdentifier, dataType: colDataType, pageName: pageName });
        }
        // Check Sum Parent & Rollup
        if (!isNaN(newvalue)) {
            this.SumParentAndRollup(row, oldValue, newvalue, curColumn, columnIdentifier, tabCode, colDataType);
        }
        // call get columns only on rebind grid needed
        if (gridBind) {
            this.dataAdapter = this.localJSON.filter(x => x.pagename.trim() === this.currentPage);
        }
        return;
    }

    SumParentAndRollup(row: number, oldValue: number, newvalue: any, curColumn: string, columnIdentifier: string, tabCode: string, colDataType: string,) {
        // Check Sum Parent
        if (this.BT[row].sumparent !== null && this.BT[row].sumparent.toString() !== '') {
            // Check Sum Parent Row
            const val = newvalue - oldValue;
            const sumParentRow = this.localJSON
                ? (this.localJSON.filter(x => x.boxcode.toString().trim() === this.BT[row].sumparent.toString()).length > 1
                    ? this.localJSON.findIndex(x => x.boxcode.toString().trim() === this.BT[row].sumparent.toString()
                        && x.pagename.toString().trim() === this.BT[row].pagename.toString())
                    : this.localJSON.findIndex(x => x.boxcode.toString().trim() === this.BT[row].sumparent.toString()))
                : 0;

            this.localJSON[sumParentRow][curColumn] = val + (this.localJSON[sumParentRow][curColumn] == null ? 0 : parseFloat(this.localJSON[sumParentRow][curColumn]));
            //Updating reportChanges for parent
            var sumParentBoxCode = tabCode.replace('%', this.localJSON[sumParentRow].boxcode);
            if (this.reportChanges.length > 0 && this.reportChanges.filter(x => x.boxCode == sumParentBoxCode).length > 0) {
                var boxCodeRow = this.reportChanges.filter(x => x.boxCode == sumParentBoxCode)[0];
                boxCodeRow.boxCodeValue = this.localJSON[sumParentRow][curColumn];
            }
            else {
                this.reportChanges.push({ boxCode: sumParentBoxCode, boxCodeValue: this.localJSON[sumParentRow][curColumn], columnIdentifier: columnIdentifier, dataType: colDataType, pageName: this.localJSON[sumParentRow].pagename });
            }

            // Looping Recursive
            this.SumParentAndRollup(sumParentRow, oldValue, newvalue, curColumn, columnIdentifier, tabCode, colDataType);
        } else if (this.BT[row].sumparent === null) {
            return;
        }
    }

    validateSubmitAndApprove(submitApprove) {

        var param = {
            reportPackID: +this.dashboardService.reportPackID,
            userId: this.authService.getLoggedInUser().userid,
            reportID: this.dashboardService.reportId,
            suitename: this.dashboardService.suitename,
            countryCode: this.dashboardService.formName === this.dashboardService.reportAliasName ? null : this.dashboardService.reportAliasName,
            comment: this.approverComments,
            reportAbbr: this.report
        }
        this.loading = true;
        this.dashboardService.hasPermission(param)
            .then((data: any) => {
                this.loading = false;
                if (data === "true") {
                    this.loading = true;
                    this.dashboardService.isReportAssigned(param)
                        .then((data: any) => {
                            this.loading = false;

                            if (data.IsReportSignedOff === true) {
                                this.isAssigned = true;
                                this.alertService.success(data.Message);
                            }
                            else {
                                if (data.IsAssignedFlag === false) {
                                    this.isAssigned = false;
                                    this.openTaskboardRoleModal();
                                }
                                else if (data.IsAssignedFlag === true) {
                                    this.isAssigned = true;
                                    this.open(submitApprove);
                                }

                                this.visibleAdhoc = data.adhocScheduleFlag;
                                this.visibleAssignTo = data.assignToMeFlag;
                            }
                        }).catch(() => { this.loading = false; });
                }
                else {
                    this.isAssigned = true;
                    this.alertService.warn(data);
                }
            }).catch(() => { this.loading = false; });
    }

    /**
     * Submit for Approval.
     */
    async onSubmitForApproval() {
        if (this.approverComments.trim() === '') {
            this.alertService.warn('Please enter the comments');
            return;
        }
        else {
            this.modalService.dismissAll();
        }

        if (this.reportChanges.length > 0) {
            await this.dashboardService.saveReportData(this.reportChanges, this.reportAudit)
                .then(() => {
                    this.reportChanges = [];
                    this.reportAudit = [];
                    this.submitApproval();
                }, () => { this.loading = false; }).catch(() => { this.loading = false; });
        }
        else {
            this.submitApproval();
        }
    }

    submitApproval() {
        const body = {
            reportpackid: +this.dashboardService.reportPackID,
            reportid: this.dashboardService.reportId,
            countrycode: this.dashboardService.formName === this.dashboardService.reportAliasName ? null : this.dashboardService.reportAliasName,
            reportName: this.dashboardService.formName,
            isApproval: 'true',
            comment: this.approverComments
        };
        this.loading = true;
        this.apiService.post('approvals/', body, 'Report submitted and approved')
            .subscribe(
                () => { this.loading = false; },
                () => { this.loading = false; }
            );
    }


    async ViewValidations(validationGrid) {
        if (this.dashboardService.isReviewValidation == false) { this.alertService.info("Review validation is not applicable for this report"); return; }
        this.loading = true;
        await this.dashboardService.getValidationData()
            .then((res: any) => {
                if (!this.dashboardService.validationData) {
                    this.alertService.warn('No validation data found!', true);
                } else {
                    this.modalService.open(validationGrid, { size: 'xl', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
                }
                this.loading = false;
            }, () => { this.loading = false; });
    }

    onReportDownLoadRequest(reportListPopUp, reportingTypeId: string) {
        this.selectedReportingType = this.reportingTypeDetails.filter(x => x.ReportingTypeId == reportingTypeId)[0];
        var tempReportList = this.selectedReportingType.ReportTemplates;
        this.downloadPopupHeader = 'Download ' + this.selectedReportingType.ReportingTypeName + ' Report';
        var reportNotAvailableMessage = this.selectedReportingType.ReportingTypeName + ' Report is not available for this report.'
        if (tempReportList && tempReportList.length > 0) {
            if (tempReportList.length > 1) {
                this.reportList = [];
                tempReportList.forEach(el => {
                    this.reportList.push({ id: el.ReportTemplateId, displayName: el.DisplayName });
                });
                this.modalService.open(reportListPopUp, { size: 'xl', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
            }
            else {
                this.downloadReport(tempReportList[0].ReportTemplateId);
            }
        }
        else {
            this.alertService.info(reportNotAvailableMessage); return;
        }
    }

    downloadReport(reportTemplateId: any) {
        this.alertService.info('The ' + this.selectedReportingType.ReportingTypeName + ' report will be downloaded in a while!');
        this.dashboardService.downloadReport(reportTemplateId);
    }

    ngOnDestroy() {
        if (this.recalculateSubs) {
            this.recalculateSubs.unsubscribe();
        }
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        this.modalService.dismissAll();
    };

    openTaskboardRoleModal() {
        this.modalService.dismissAll();
        this.modalService.open(this.content, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
    }

    assignReportTaskboard() {
        this.dashboardService.fromReport = true;
        this.modalService.dismissAll();
        this.router.navigate(['/app/task-board']);
    }

    async checkReportIsAssigned() {
        var param = {
            reportPackID: +this.dashboardService.reportPackID,
            userId: this.authService.getLoggedInUser().userid,
            reportID: this.dashboardService.reportId,
            suitename: this.dashboardService.suitename,
            countryCode: this.dashboardService.formName === this.dashboardService.reportAliasName ? null : this.dashboardService.reportAliasName,
            reportAbbr: this.report
        }
        this.loading = true;

        this.dashboardService.hasPermission(param)
            .then((data: any) => {
                this.loading = false;
                if (data === "true") {
                    this.loading = true;
                    this.dashboardService.isReportAssigned(param)
                        .then((data: any) => {
                            this.loading = false;
                            if (data.IsReportSignedOff === true) {
                                this.isAssigned = true;
                                this.alertService.success(data.Message);
                            }
                            else {
                                if (data.IsAssignedFlag === false) {
                                    this.isAssigned = false;

                                    this.openTaskboardRoleModal();
                                }
                                else if (data.IsAssignedFlag === true) {
                                    this.isAssigned = true;
                                }

                                this.visibleAdhoc = data.adhocScheduleFlag;
                                this.visibleAssignTo = data.assignToMeFlag;
                            }
                        });
                }
                else {
                    this.isAssigned = true;
                    this.alertService.warn(data);
                }
            });
    }

    open(submitApprove) {
        this.approverComments = '';
        var param = {
            reportPackID: +this.dashboardService.reportPackID,
            reportID: this.dashboardService.reportId,
            countryCode: this.dashboardService.formName === this.dashboardService.reportAliasName ? null : this.dashboardService.reportAliasName
        }
        this.loading = true;
        this.dashboardService.getApproverComments(param)
            .then((data: any) => {
                this.loading = false;
                this.approverCommentHistory = data;
                this.modalService.open(submitApprove, { size: 'xl', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
            }).catch(() => { this.loading = false; });
    }

    openModal(commentsHistory) {
        var param = {
            reportPackID: +this.dashboardService.reportPackID,
            reportID: this.dashboardService.reportId,
            countryCode: this.dashboardService.formName === this.dashboardService.reportAliasName ? null : this.dashboardService.reportAliasName
        }
        this.loading = true;
        this.dashboardService.getApproverComments(param)
            .then((data: any) => {
                this.loading = false;
                this.approverCommentHistory = data;
                this.modalService.open(commentsHistory, { size: 'xl', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
            }).catch(() => { this.loading = false; });
    }
    clearFilters() {
        this.postedDateFilters = null;
    }

    closeDateRangeFilter(dateComponent: any, filters: Date[]) {
        if (filters[1]) {
            dateComponent.hideOverlay();
        }
    }

    createAdhocSchedule() {
        this.modalService.dismissAll();
        this.checkReportPackMappedWithReport(this.report, this.dashboardService.reportPackID);
    }

    checkReportPackMappedWithReport(report, reportPackId) {
        const param = {
            reportName: report,
            reportPackId: reportPackId
        }
        this.loading = true

        this.reportScheduleService.checkReportPackMappedWithReport(param)
            .then((data: any) => {
                if (data === true) {
                    this.loading = false;
                    this.alertService.error("Report pack is already mapped with other due date.");
                    return;
                }
                else {
                    const param = {
                        reports: this.report,
                        Frequency: 'Daily',
                        Day: 0,
                        IsActive: false,
                        StartDate: new Date(),
                        EndDate: new Date(),
                        ReportPackId: this.dashboardService.reportPackID,
                        UserId: this.authService.getLoggedInUser().userid
                    }
                    this.loading = true;
                    this.reportScheduleService.creatAdhocReportSchedule(param, 'Ad hoc Schedule created successfully').then((reportSchedule: any) => {
                        this.checkReportIsAssigned();
                    }).catch(() => { this.loading = false; });

                }
            }).catch(() => { this.loading = false; });
    }

    downloadChangeAuditReport() {
        this.alertService.info('The change audit report will be downloaded in a while. Please note that only saved changes will be downloaded!');
        this.dashboardService.downloadChangeAuditReport();
    }

    getBoxCodeAudit(cell: any) {
        this.loading = true;
        this.dashboardService.getBoxCodeAudit(cell.boxCode)
            .then((data: any) => {
                var auditList = [];
                data.forEach(el => {
                    auditList.push({
                        oldValue: el.OldValue, newValue: el.NewValue, comment: el.Comment, changedBy: el.ChangedBy, changedDate: el.ChangedDate, isSaved: true
                    });
                });
                this.reportAudit.filter(x => x.boxCode == cell.boxCode).forEach(el => {
                    auditList.push({
                        oldValue: el.oldValue, newValue: el.newValue, comment: el.comment, changedBy: this.user.username, changedDate: el.changedDate, isSaved: false
                    });
                });
                this.boxCodeAudit = auditList.sort((a, b) => a.changedDate < b.changedDate ? 1 : -1);
                this.loading = false;
            }).catch(() => { this.loading = false; });
    }

    openAuditExceptionView(auditExceptionView, reportingType: string) {
        this.auditExceptionPages = this.reportingTypeDetails.filter(x => x.ReportingTypeName == reportingType)[0].ReportTemplates;
        if (!this.auditExceptionPages || this.auditExceptionPages.length == 0) {
            this.alertService.info(reportingType + " is not available for this report!");
        }
        else {
            this.auditExceptionViewHeader = "View " + reportingType;
            this.selectedAuditExceptionPage = this.auditExceptionPages[0];
            this.loading = true;
            this.dashboardService.getAuditExceptionData(this.selectedAuditExceptionPage.ReportTemplateId)
                .then((data: any) => {
                    this.auditExceptionGridData = data;
                    if (data.Data && data.Data.length > 0) {
                        this.modalService.open(auditExceptionView, { size: 'xl', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
                    }
                    else this.alertService.info(reportingType + " data is not available for this report pack!");
                    this.loading = false;
                }).catch(() => { this.loading = false; });
        }
    }

    closeAuditExceptionView() {
        this.auditExceptionPages = null;
        this.selectedAuditExceptionPage = null;
    }

    onAuditExceptionPageChange(selectedPage: any) {
        this.selectedAuditExceptionPage = this.auditExceptionPages.filter(x => x.ProcName == selectedPage)[0];
        this.loading = true;
        this.dashboardService.getAuditExceptionData(this.selectedAuditExceptionPage.ProcName)
            .then((data: any) => {
                this.auditExceptionGridData = data;
                this.loading = false;
            }).catch(() => { this.loading = false; });
    }
}