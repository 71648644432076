import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-prompt-modal',
  templateUrl: './prompt-modal.component.html',
  styleUrls: ['./prompt-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PromptModalComponent implements OnInit {

  @Input() promptMessage;
  @Input() promptCallFor;
  @Input() promptCallFrom;

  returnResponse: any = {};
  callFor: string = null;
  callFrom: string = null;

  constructor(public activeModal: NgbActiveModal, private commonService: CommonService) { }

  ngOnInit() {
    this.callFor = this.promptCallFor;
    this.callFrom = this.promptCallFrom;
  }


  async onNoClick() {
    this.returnResponse.response = 'No';
    this.returnResponse.callFor = this.callFor;
    this.commonService.setPrompt(this.returnResponse);
    this.activeModal.close();
  }

  onYesClick() {
    this.returnResponse.response = 'Yes';
    this.returnResponse.callFor = this.callFor;
    this.commonService.setPrompt(this.returnResponse);
    this.activeModal.close();
  }
}
