import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Title } from "@angular/platform-browser";
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { SuiteService } from 'src/app/core/services/suite.service';
import { RoleManagementService } from 'src/app/core/security/role-management.service';
import { AlertService } from 'src/app/core/services/alert.service';


@Component({
    selector: 'app-sub-report',
    templateUrl: './sub-report.component.html',
    styleUrls: ['./sub-report.component.css']
})
export class SubReportComponent implements OnInit, OnDestroy {
    loading: any;
    url: string[];
    suite: string;
    report: string;
    reportDisplayName: string;
    reportPackId: number = 0;
    reportPackIdSub: Subscription;
    suitesPermissions: any;
    suitesPermissionsSub: Subscription;
    subreports: any;
    filteredReports: any;
    nameFilter: string = '';
    statusFilter: string = '';
    sortOrder: string = '';
    selectedRp: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private dashboardService: DashboardService,
        private suiteService: SuiteService,
        private roleService: RoleManagementService,
        private authService: AuthenticationService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        if (!this.authService.isPrivacyNoticeAcknowledged) {
            this.router.navigate(['/privacy-notice']);
        }

        this.suite = this.suiteService.getCurrentSuite();
        this.report = this.dashboardService.parentFormName;
        this.reportDisplayName = this.dashboardService.parentReportDisplayName;
        this.reportPackId = this.dashboardService.reportPackID;
        this.selectedRp = this.dashboardService.selectedReportPack;

        if (!this.reportPackId) {
            this.alertService.warn('Cannot access the sub-reports as report pack is not selected!', true);
            this.router.navigate(['/app/suite', this.suite]);
        }

        this.getSubReports();
        this.suitesPermissions = this.roleService.getSuitePermissions();
        if (!this.suitesPermissions) {
            this.suitesPermissionsSub = this.roleService.watchSuitePermissions()
                .subscribe(permissions => {
                    this.suitesPermissions = permissions;
                });
        }

        this.reportPackIdSub = this.dashboardService.watchReportPackId().subscribe(rpId => {
            this.reportPackId = rpId;
            this.getSubReports();
            this.selectedRp = this.dashboardService.selectedReportPack;
        });

        this.titleService.setTitle('IRR: ' + this.reportDisplayName.toUpperCase());
    }

    getSubReports() {
        this.loading = true;
        this.dashboardService.getSubReports(this.suite, this.report).then((data: any) => {
            const filterSubReports = data.filter((subReport) => this.roleService.checkPermissions('V', this.suite, subReport.reportAbbr.toLowerCase()));
            this.subreports = filterSubReports;
            this.onFilterChange();
            this.loading = false;
        });
    }

    onFilterChange() {
        if (this.nameFilter.trim() != '' && this.statusFilter.trim() != '')
            this.filteredReports = this.subreports.filter(x => (x.reportName.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1
                || x.ReportDisplayName.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1)
                && x.Status === this.statusFilter);
        else if (this.nameFilter.trim() != '')
            this.filteredReports = this.subreports.filter(x => (x.reportName.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1
                || x.ReportDisplayName.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1));
        else if (this.statusFilter.trim() != '')
            this.filteredReports = this.subreports.filter(x => x.Status === this.statusFilter);
        else this.filteredReports = this.subreports;
        this.sortList();
    }

    toggleSortOrder() {
        if (this.sortOrder == '' || this.sortOrder == 'desc')
            this.sortOrder = 'asc';
        else if (this.sortOrder == 'asc')
            this.sortOrder = 'desc';
        else this.sortOrder = '';
        this.sortList();
    }

    sortList() {
        if (this.sortOrder != '') {
            if (this.sortOrder == 'asc')
                this.filteredReports.sort((a, b) => a.ReportDisplayName > b.ReportDisplayName ? 1 : -1);
            else if (this.sortOrder == 'desc')
                this.filteredReports.sort((a, b) => a.ReportDisplayName < b.ReportDisplayName ? 1 : -1);
        }
    }

    clearFilter() {
        this.nameFilter = '';
        this.statusFilter = '';
        this.onFilterChange();
    }

    getClass(status) {
        switch (status) {
            case 'Not Started':
                return 'mb-1 txt-ns';
            case 'In Progress':
                return 'mb-1 txt-ip';
            case 'Completed':
                return 'mb-1 txt-c';
            default:
                return 'mb-1 txt-ns';
        }
    }

    ngOnDestroy() {
        if (this.suitesPermissionsSub) {
            this.suitesPermissionsSub.unsubscribe();
        }
        if (this.reportPackIdSub) {
            this.reportPackIdSub.unsubscribe();
        }
    }

    async navigateRoute(formName: string, reportId: number, fullFormName: string, reportAliasName: string, subReportDisplayName:string) {

        this.dashboardService.formName = formName;
        this.dashboardService.reportId = reportId;
        this.dashboardService.fullFormName = fullFormName;
        this.dashboardService.reportAliasName = reportAliasName;
        this.dashboardService.suitename = this.suite;
        this.dashboardService.reportDisplayName = subReportDisplayName;

        var report = this.filteredReports.find(x => x.reportAbbr === formName && x.reportAliasName === reportAliasName);

        this.dashboardService.isReviewValidation = report.isReviewValidation;
        this.dashboardService.auditProcName = report.auditProcName;
        this.dashboardService.exceptionsProcName = report.exceptionsProcName;

        if (this.reportPackId === 0) {
            this.reportPackId = !this.dashboardService.reportPackID ? 0 : this.dashboardService.reportPackID;
        }
        if (!this.reportPackId) {
            this.alertService.warn('Please select a report pack to continue!', true);
        } else
            if (this.reportPackId !== 0) {
                this.loading = true;
                await this.dashboardService.getReportData().then(() => {
                    var pageHeaders = this.dashboardService.reportData.reportPageHeaders;
                    this.loading = false;
                    if (!pageHeaders || !pageHeaders.length) {
                        this.alertService.warn('Cannot access the report!', true);
                    }
                    else {
                        this.router.navigate(['/app/suite', this.suite, this.reportDisplayName, subReportDisplayName.toLowerCase().trim()]);
                    }
                }).catch(() => { this.loading = false; });
            }
    }
}
