<app-loader *ngIf="loading"></app-loader>
<div class="row">
    <div class="col-12 col-lg-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'" class="last-cap">
        </app-ng7-dynamic-breadcrumb>
    </div>
</div>
<div class="row" *ngIf="queryList">
    <div class="col-12">
        <div class="row header">Query Selection Details</div>
        <form #queryEngineForm="ngForm">
            <div class="row form-row">
                <div class="col-1"><label>Template Type </label></div>
                <div class="col-2">
                    <select id="queryList" name="queryList" [(ngModel)]="queryModel.queryId"
                        class="query-engine-form-control w-100 select-arrow">
                        <option *ngFor="let query of queryList" [selected]="query.queryId == queryModel.queryId"
                            value={{query.queryId}}>
                            {{query.displayName}}
                        </option>
                    </select>
                </div>
                <div class="col-1"></div>
                <div class="col-1"><label>Report Pack</label></div>
                <div class="col-4">
                    <app-report-pack-filter [(selectedReportPacks)]="selectedReportPack" [showOnlyValidReportPacks]="true"
                        [selectedRpIds]="queryModel.reportPackId" [updateServiceOnRpSelection]="false"
                        (selectedReportPacksChange)="onReportPackSelection()">
                    </app-report-pack-filter>
                </div>
                <div class="col-1"></div>
                <div class="col-2 align-items-center">
                    <button class="btn btn-modal-pos small-font hvr-shrink" type="button"
                        (click)="getQueryResult(true, false)" [disabled]="(queryModel.reportPackId === null || queryModel.reportPackId == 0)">
                        <span>Get Query Result</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<app-dynamic-grid
    [gridData]="gridData" 
    [isCRUDEnabled]="false" 
    (modelChange)="updateQueryModel($event)"
    (downloadResults)="downloadQueryResult()">
</app-dynamic-grid>
