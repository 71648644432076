import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { ReportDownloaderService } from 'src/app/core/services/reportDownloader.service';

@Component({
  selector: 'app-report-downloader',
  templateUrl: './report-downloader.component.html',
  styleUrls: ['./report-downloader.component.css']
})
export class ReportDownloaderComponent implements OnInit {
  loading = false;
  selectedRp: any;
  reportPackId: number;
  treeList: any = [];
  reportTreeListData: any = [];
  headerCols: any[];
  reportingTypeOptions: any = [];
  selectedReportingTypes: any = [];

  constructor(
    private authService: AuthenticationService,
    private alertService: AlertService,
    private router: Router,
    private downloaderService: ReportDownloaderService
  ) { }

  ngOnInit() {
    if (!this.authService.isPrivacyNoticeAcknowledged) {
      this.router.navigate(['/privacy-notice']);
    }

    this.headerCols = [
      { field: 'DisplayName', header: 'Name' },
      { field: 'Description', header: 'Description' },
      { field: 'ReportingType', header: 'Reporting Type' },
      { field: 'TemplateName', header: 'Template Name' },
      { field: '', header: 'Download' }
    ];
  }

  onReportPackSelectionChange() {
    this.reportPackId = this.selectedRp.ReportPackID;
    if (this.reportPackId) {
      this.getReportDownloaderTreeList();
    }
  }

  private getReportDownloaderTreeList() {
    this.loading = true;
    this.downloaderService.getReportTreeList(this.reportPackId)
      .then((reportList: any) => {
        this.treeList = JSON.parse(JSON.stringify(reportList));
        this.reportTreeListData = JSON.parse(JSON.stringify(reportList));
        if (this.reportTreeListData.length > 0) {
          if(this.downloaderService.reportingTypes && this.downloaderService.reportingTypes.length >0){
            this.prepareReportingTypeOptions(this.downloaderService.reportingTypes);
          }
          else{
          this.downloaderService.getReportingTypes().then((reportingTypesList: any) => {
            this.prepareReportingTypeOptions(reportingTypesList);
          });
        }
        }
        this.loading = false
      }).catch(() => { this.loading = false; });
  }

  private prepareReportingTypeOptions(reportingTypes: any) {
    this.reportingTypeOptions = [];
    reportingTypes.forEach(element => {
      var option = {
        label: element.ReportingTypeName,
        value: element.ReportingTypeName
      }
      this.reportingTypeOptions.push(option);
    });
  }

  clearFilters() {
    this.reportTreeListData = JSON.parse(JSON.stringify(this.treeList));
    this.selectedReportingTypes = [];
  }

  reportDownload(rowNode: any) {
    var parentData = rowNode.parent.data;
    this.downloaderService.downloadReport(this.reportPackId, parentData.Id, parentData.Name, parentData.Alias, rowNode.node.data.TemplateId);
    this.alertService.info('The requested report will be downloaded in a while!');
  }
}
