import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { Router } from '@angular/router';
import { SuiteService } from 'src/app/core/services/suite.service';
import { Subscription } from 'rxjs';
/**
 * Component
 */
@Component({
    selector: 'app-reporting',
    templateUrl: './reporting.component.html',
    styleUrls: ['./reporting.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ReportingComponent implements OnInit {
    selectedRp: any;
    reportPackIdSub: Subscription;

    constructor(private dashboardService: DashboardService) {
    }

    ngOnInit() {
        this.selectedRp = this.dashboardService.selectedReportPack;
        this.reportPackIdSub = this.dashboardService.watchReportPackId().subscribe(rpId => {
            this.selectedRp = this.dashboardService.selectedReportPack;
        });
    }
}
