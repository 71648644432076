import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * Manage authetication and access
 */
@Injectable()
export class AuthenticationService {
    /**
     * User loggeed in flag
     */
    isloggedIn = false;
    /**
     * User logged in
     */
    loggedInUser = null;
    /**
     * User's permissions
     */
    private permissions = null;
    /**
     * Token
     */
    protected msalToken = '';
    /**
     * Subect to track user
     */
    private userSub = new Subject<any>();

    /**
     * Privacy notice flag
     */
    isPrivacyNoticeAcknowledged = false;
    /**
     * Service constructor
     */
    lastActionTime: Date = new Date();

    constructor(
        private router: Router,
        private msalService: MsalService,
        private http: HttpClient
    ) { }

    /**
     * Set user as logged in
     */
    setUserLoggedIn(user): boolean {
        this.isloggedIn = true;
        // user['createddate'] = moment(user['createddate']).format('LL');
        this.loggedInUser = user;
        this.loggedInUser['expiration'] = 0;
        this.userSub.next(this.loggedInUser);
        this.isPrivacyNoticeAcknowledged = user.isPrivacyNoticeAcknowledged;
        const permissionList: any[] = [];
        user.userRoleMappingDtos.forEach(element => {
            if (user && element.role && element.role.permissions) {
                element.role.permissions.forEach(permission => {
                    permissionList.push(permission);
                });
            }

            if (permissionList.length > 0) {
                this.setPermissions(permissionList);
            }
            else {
                this.setPermissions([]);
            }

        });
        return true;
    }

    /**
     * Watch the user value
     */
    watchUser(): Observable<any> {
        return this.userSub.asObservable();
    }

    setPrivacyInfo(privacyAcknowledged: boolean) {
        this.loggedInUser.isPrivacyNoticeAcknowledged = privacyAcknowledged;
        this.isPrivacyNoticeAcknowledged = privacyAcknowledged;
    }

    /**
     * Set token header
     */
    setTokenHeader(token: string) {
        this.msalToken = token;
    }

    /**
     * Get token header
     */
    getTokenHeader() {
        return this.msalToken ? this.msalToken : '';
    }

    /**
     * Add expiration date to the user token
     */
    addExpirationDate(date): boolean {
        if (this.loggedInUser)
            this.loggedInUser['expiration'] = new Date(date * 1000);
        return true;
    }

    /**
   * Update client name
   */
    updateClientName(clientName) {
        this.loggedInUser.clientName = clientName;
        this.userSub.next(this.loggedInUser)
    }

    /**
     * Get the user logged in
     */
    getLoggedInUser() {
        return this.loggedInUser;
    }

    /**
     * Log out user
     */
    logoutUser(): void {
        this.isloggedIn = false;
        this.loggedInUser = null;
        this.logOut();
        this.router.navigate(['/logout']);
    }

    logOut() {
        let finalUrl = environment.apiUrl + 'logout';
        let headers = {
            'X-CSRFToken': this.getCookie('csrftoken'),
            [environment.tokenHeader]: this.getTokenHeader()
        };
        let requestHeaders = new HttpHeaders(headers);
        this.msalToken = '';
        this.http.put(finalUrl, null, { observe: 'response', headers: requestHeaders })
            .subscribe(response => {
                this.msalService.logoutRedirect();
        });
    }

    msalLogOut() {
        this.msalService.logoutPopup({ mainWindowRedirectUri: window.location.origin });
    }

    /**
   * Gets cookie
   */
    private getCookie(name) {
        const value = '; ' + document.cookie;
        const parts = value.split('; ' + name + '=');
        if (parts.length === 2) {
            return parts
                .pop()
                .split(';')
                .shift();
        }
        return '';
    }

    /**
     * Deny access
     */
    accessNotAllowed() {
        this.userSub.next('access-denied');
        this.router.navigate(['/access-denied']);
        return false;
    }

    /**
     * Restrict access on server error
     */
    serverError() {
        this.userSub.next('server-unavailable');
        this.router.navigate(['/server-error']);
        return false;
    }

    /**
     * Check if user is logged in and with a valid token
     * Task - 958173 
     * Every 30 min we going to refresh the token 
     * 
     */


    isValidToken(updateLastActionDate: boolean) {
        if (updateLastActionDate) { this.lastActionTime = new Date(); }
        if (!this.checkTokenValidity()) {
            // Task - 958173
            this.refreshToken();
            //this.logoutUser();
            //this.msalLogOut();
        }
        return true;
    }

    checkTokenValidity() {
        if (!this.loggedInUser || (this.loggedInUser && new Date() > new Date(this.loggedInUser['expiration'].getTime() - 30 * 60000))) {
            return false;
        } else return true;
    }

    /**
     * Sets permissions of the user
     */
    setPermissions(permissions) {
        this.permissions = permissions.map(perm => perm.permissionid);
    }

    /**
     * Gets permissions of the user
     */
    getPermissions() {
        return this.permissions;
    }
    // Task - 958173 - refreshToken
    refreshToken() {
        const accessTokenRequest = {
            scopes: ["user.read"],
            account: this.msalService.instance.getAllAccounts()[0]
        };
        this.msalService.acquireTokenSilent(accessTokenRequest)
            .subscribe(
                token => {
                    this.setTokenHeader(token.idToken);
                },
                err => { }
            );
    }
    /**
     * Task - 958173 - checkIdleTime
     * Idle time we set it as 30 min 
     */

    checkIdleTime() {
        if (this.lastActionTime >= new Date(new Date().getTime() - 30 * 60000)) {
            return true;
        }
        else {
            this.logoutUser();
            return false;

        }
    }
    /**
     * Redirect if in Maintenance Window
     */
    redirectToUnderMaintenance() {
        this.router.navigate(['/under-maintenance']);
        return false;
    }
}
