<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
    <div class="col-12 col-sm-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
    </div>
    <div class="col-12 col-sm-6 text-left text-sm-right">
        <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button"
            (click)="open(addRole,null,'Add')">
            <div class="icon-container"><i class="fas fa-user-plus fa-lg"></i></div><span>Add Role</span>
        </button>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="role-mgmt-scroll">
            <p-table #roleListVal [value]="roleList" [paginator]="true" [rows]="12" class="table-irr data-ingestion"
                [columns]="cols" [scrollable]="true" [scrollHeight]="'calc(100vh - 292px)'" sortField="rolename">
                <ng-template pTemplate="paginatorleft">
                    <button class="btn btn-clear-filter hvr-shrink" type="button" (click)="roleListVal.reset()">
                        <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                    </button>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                        </th>
                        <th>Is Active ?</th>
                        <th>Actions</th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns">
                            <input *ngIf="col.field !='createddate'" type="text" class="w-100" placeholder="Filter Text"
                                [value]="roleListVal.filters && roleListVal.filters[col.field] ? roleListVal.filters[col.field].value: ''"
                                (input)="roleListVal.filter($event.target.value, col.field, 'contains')">
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.rolename}}</td>
                        <td>{{rowData.roledesc}}</td>
                        <td>{{rowData.isActive=== true ?'Yes':'No'}}</td>
                        <td *ngIf="rowData.rolename === 'Admin' || rowData.rolename === 'Ingestion' || rowData.rolename === 'Report Bundling'
                                                || rowData.rolename === 'Original Exposure Composition'|| rowData.rolename === 'Exposure and Risk Weighted Assets'
                                                || rowData.rolename === 'Counterparty and Credit Ratings'|| rowData.rolename === 'Distribution of Exposure per Country'
                                                || rowData.rolename === 'Overview of Short Term Exposure'|| rowData.rolename === 'Off Balance Sheet Exposure'
                                                || rowData.rolename === 'Overview of Mortgage Exposure'|| rowData.rolename === 'Overview of PD over all loan book'
                                                || rowData.rolename === 'Trend Analysis'|| rowData.rolename === 'Period-on-Period Analysis'
                                                || rowData.rolename === 'COREP Analytics Dashboard'|| rowData.rolename === 'Capital Visualizations'
                                                || rowData.rolename === 'Investment Firm Dashboard'|| rowData.rolename === 'Investment Firm Trend Analysis'
                                                || rowData.rolename === 'Capital Dashboard' || rowData.rolename === 'Own Funds and Capital Dashboard'
                                                || rowData.rolename === 'Risk Exposure and Capital Dashboard' || rowData.rolename === 'Liquidity Coverage and Assets Dashboard'
                                                || rowData.rolename === 'Leverage, Liquidity and Capital Dashboard'|| rowData.rolename === 'Leverage Exposure and Capital Dashboard'
                                                || rowData.rolename === 'Outflows Dashboard' || rowData.rolename === 'Liquid Assets Dashboard'
                                                || rowData.rolename === 'Net Stable Funding Analysis' || rowData.rolename === 'Leverage and Liquidity Dashboard'
                                                || rowData.rolename === 'Support'"></td>
                        <td *ngIf="rowData.rolename !== 'Admin' && rowData.rolename !== 'Ingestion' && rowData.rolename !== 'Report Bundling'
                                                && rowData.rolename !== 'Original Exposure Composition'&& rowData.rolename !== 'Exposure and Risk Weighted Assets'
                                                && rowData.rolename !== 'Counterparty and Credit Ratings'&& rowData.rolename !== 'Distribution of Exposure per Country'
                                                && rowData.rolename !== 'Overview of Short Term Exposure'&& rowData.rolename !== 'Off Balance Sheet Exposure'
                                                && rowData.rolename !== 'Overview of Mortgage Exposure'&& rowData.rolename !== 'Overview of PD over all loan book'
                                                && rowData.rolename !== 'Trend Analysis'&& rowData.rolename !== 'Period-on-Period Analysis'
                                                && rowData.rolename !== 'COREP Analytics Dashboard'&& rowData.rolename !== 'Capital Visualizations'
                                                && rowData.rolename !== 'Investment Firm Dashboard'&& rowData.rolename !== 'Investment Firm Trend Analysis'
                                                && rowData.rolename !== 'Capital Dashboard' && rowData.rolename !== 'Own Funds and Capital Dashboard'
                                                && rowData.rolename !== 'Risk Exposure and Capital Dashboard' && rowData.rolename !== 'Liquidity Coverage and Assets Dashboard'
                                                && rowData.rolename !== 'Leverage, Liquidity and Capital Dashboard'&& rowData.rolename !== 'Leverage Exposure and Capital Dashboard'
                                                && rowData.rolename !== 'Outflows Dashboard' && rowData.rolename !== 'Liquid Assets Dashboard'
                                                && rowData.rolename !== 'Net Stable Funding Analysis' && rowData.rolename !== 'Leverage and Liquidity Dashboard'
                                                && rowData.rolename !== 'Support' && rowData.rolename !== 'RefData Admin'">
                            <button type="button" class="btn-table-icon hvr-shrink" container="body"
                                ngbTooltip="Edit Role" triggers="hover" (click)="open(addRole,rowData,'Edit')">
                                <i class="fas fa-edit fa-lg"></i>
                            </button>
                            <!-- <button type="button" class="btn-table-icon hvr-shrink" container="body"
                                ngbTooltip="Delete Role" (click)='deleteRole(rowData)'>
                                <i class="fas fa-trash-alt fa-lg"></i>
                            </button> -->
                            <button type="button" class="btn-table-icon hvr-shrink" container="body"
                                ngbTooltip="View Role Permissions" triggers="hover"
                                (click)="openPermList(viewRolePerm,rowData)">
                                <i class="fas fa-file-alt fa-lg"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<ng-template #addRole let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">
            {{action +' Role'}}<i class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="modal.dismiss('Cross click')"></i>
        </h5>
    </div>
    <div class="modal-body">
        <ngb-tabset class="custom-tabs">
            <ngb-tab title="Step 1  : Add Role">
                <ng-template ngbTabContent>
                    <div class="row animated fadeIn fast mt-4">
                        <div class="col-12 d-flex align-items-center justify-content-center">
                            <form class="material-styling w-100 mt-3" #roleForm="ngForm">
                                <div class="group">
                                    <input type="text" id="role" required name="role" [(ngModel)]='rolename'
                                        #rlname="ngModel" id="rolename" name="rolename" minlength="4" maxlength="50"
                                        [disabled]="action === 'Edit' || roleid !== 0 ? true : false"
                                        pattern="^[A-Za-z0-9 _\-.]*$" trim-text />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label *ngIf="action === 'Add'">Role</label>
                                    <label class="non-input-label" *ngIf="action === 'Edit'">Role</label>

                                    <div *ngIf="rlname.invalid && (rlname.dirty || rlname.touched)"
                                        class="alert alert-danger">
                                        <div *ngIf="rlname.errors.required">
                                            Role name is required.
                                        </div>
                                        <div *ngIf="rlname.errors.minlength">
                                            Role name must be at least 4 characters long.
                                        </div>
                                        <div *ngIf="rlname.errors.maxlength">
                                            Role name should not should not exceed 50 characters.
                                        </div>
                                        <div *ngIf="rlname.errors.pattern">
                                            Role name contains invalid characters.
                                        </div>
                                    </div>
                                </div>
                                <div class="group">
                                    <textarea maxlength="150" class="w-100" name="desc" id="desc" #rldesc="ngModel"
                                        [(ngModel)]='roledesc' pattern="[^^/:*|'<>\\{}\[\]]*" trim-text></textarea>
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label>Description</label>

                                    <div *ngIf="rldesc.invalid && (rldesc.dirty || rldesc.touched)"
                                        class="alert alert-danger">
                                        <div *ngIf="rldesc.errors.maxlength">
                                            Role description should not exceed 150 characters.
                                        </div>
                                        <div *ngIf="rldesc.errors.pattern">
                                            Role description contains invalid characters.
                                        </div>
                                    </div>
                                </div>
                                <div class="group">
                                    <p-checkbox name="active" value="val1" class="common-checkbox"
                                        [(ngModel)]='isActive' [binary]="true" [disabled]="action==='Add'?true:false">
                                    </p-checkbox>
                                    <label class="non-input-label">Is Active ?</label>
                                </div>
                                <div class="text-right">
                                    <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button"
                                        (click)='clear(action)'>
                                        <span>Clear</span>
                                    </button>
                                    <button class="btn btn-modal-pos small-font hvr-shrink" type="button"
                                        (click)='submitRole(action)'
                                        [disabled]="roleForm && roleForm.form ? !roleForm.form.valid : false">
                                        <span>Save</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </ngb-tab>
            <ngb-tab title="Step 2 : Role Permission Mapping">
                <ng-template ngbTabContent>
                    <div class="row animated fadeIn fast mt-4">
                        <div class="col-12">
                            <div class="role-popup-scroll">
                                <p-treeTable #rolePermLevel [value]="rolePermissionList"
                                    class="table-irr rolePermissionLevel" selectionMode="checkbox"
                                    [(selection)]="selectedNodes" (onNodeSelect)="reportSelect($event)"
                                    (onNodeUnselect)="reportUnselect($event)" [columns]="headerCols" [scrollable]="true"
                                    [scrollHeight]="'calc(100vh - 440px)'" [paginator]="true" [rows]="8"
                                    sortField="reportDisplayName" sortOrder="1">
                                    <ng-template pTemplate="paginatorleft">
                                        <button class="btn btn-clear-filter hvr-shrink" type="button"
                                            (click)="rolePermLevel.reset();clearFiltersPopup()">
                                            <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                                        </button>
                                    </ng-template>
                                    <ng-template pTemplate="header" let-columns>
                                        <tr>
                                            <th *ngFor="let col of columns" [ttSortableColumn]="col.field">
                                                {{col.header}}
                                                <p-treeTableSortIcon [field]="col.field"></p-treeTableSortIcon>
                                            </th>
                                            <th>Permission Level</th>
                                        </tr>
                                        <tr>
                                            <th *ngFor="let col of columns">
                                                <input type="text" class="w-100" placeholder="Filter Text"
                                                    [value]="rolePermLevel.filters && rolePermLevel.filters[col.field] ? rolePermLevel.filters[col.field].value: ''"
                                                    (input)="rolePermLevel.filter($event.target.value, col.field, 'contains')">
                                            </th>
                                            <th></th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                                        <tr [ttRow]="rowNode">
                                            <td>
                                                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                                <p-treeTableCheckbox [value]="rowNode" ngDefaultControl
                                                    *ngIf="rowData.parent != ''">
                                                </p-treeTableCheckbox>
                                                {{rowData.reportDisplayName}}
                                            </td>
                                            <td>
                                                <p-dropdown [options]="rowData.permissionLevel" appendTo="body"
                                                    name='rowData.selectedPermission' placeholder="Select a Permission"
                                                    [(ngModel)]="rowData.selectedPermission"
                                                    *ngIf="rowData.parent != ''" [disabled]="rowData.isDisable">
                                                </p-dropdown>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-treeTable>
                            </div>
                        </div>
                        <div class="col-12 mt-3 text-right">
                            <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button"
                                (click)='clearPermission(action)'>
                                <span>Clear</span>
                            </button>
                            <button class="btn btn-modal-pos small-font hvr-shrink" type="button"
                                [disabled]="roleId === 0 ? true : false" (click)="submitPermission()">
                                <span>Submit</span>
                            </button>
                        </div>
                    </div>
                </ng-template>
            </ngb-tab>
        </ngb-tabset>
    </div>
</ng-template>

<ng-template #viewRolePerm let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">
            Role Permissions<i class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="modal.dismiss('Cross click')"></i>
        </h5>
    </div>
    <div class="modal-body">
        <form class="material-styling w-100 mt-4">
            <div class="group mb-4">
                <input type="text" value="{{rolename}}" required disabled />
                <span class="highlight"></span>
                <span class="bar"></span>
                <label class="non-input-label">Role Name</label>
            </div>
        </form>
        <P class="color-teal">Permissions</P>
        <ul>
            <li *ngFor='let permission of permissions'>{{permission}}</li>
        </ul>
    </div>
</ng-template>