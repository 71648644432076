import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AccessDeniedComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }

}
