import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

/**
 * Injectable
 */
@Injectable()
export class BundleTypeManagementService {
  editMode: boolean;
  selectedBundleType: any;
  reportList: any;

  constructor(private apiService: ApiService) { }

  getBundleTypesList() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getBundleTypes/', '', '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getActiveBundleTypesList() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getActiveBundleTypes/', '', '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  saveBundleTypeActivationStatus(bundleTypeId: number, isActive: boolean) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.put('activateOrDeactivateBundleType/', bundleTypeId, isActive, '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  deleteBundleType(bundleTypeId: number) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.put('deleteBundleType/', bundleTypeId, null, 'Bundle Type has been deleted successfully!')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getBundleTypeReportTreeData() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('bundleTypeManagement/getBundleTypeReportTreeData', null)
        .toPromise()
        .then(
          res => {
            this.reportList = res;
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  addBundleType(bundleType: any) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.post('bundleTypeManagement/addBundleType/', bundleType, 'Bundle type has been created successfully!')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  updateBundleType(bundleType: any) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.put('bundleTypeManagement/updateBundleType/', bundleType.bundleTypeId, bundleType, 'Bundle type has been updated successfully!')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}
