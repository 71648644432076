<app-loader *ngIf="loading"></app-loader>
<div class="row">
  <div class="col-12 col-lg-6">
    <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
      [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
  </div>
  <div class="col-12 col-lg-6 text-left text-lg-right mb-3 mb-lg-0" *ngIf="cardOverlay == false">
    <div class="legend-placeholder">
      <span class="mr-3"><i class="fas fa-circle mr-2"></i>Not Started</span>
      <span class="mr-3"><i class="fas fa-circle mr-2"></i>In Progress</span>
      <span class="mr-3"><i class="fas fa-circle mr-2"></i>Completed</span>
    </div>
  </div>
</div>
<app-report-pack-filter></app-report-pack-filter>
<div [ngClass]="cardOverlay == true ? 'scrollable-report1 mt-3' : 'scrollable-report-pack1 mt-3'">
  <div class="row mr-0" *ngIf="suites && suitesPermissions">
    <div class="col-6 col-sm-3 col-xl-2" *ngFor="let suite of suites">
      <div class="card card-suites">
        <div class="card-header">
          <div class="suites-name d-flex align-items-center w-100">
            <div class="suites-first-half w-100">
              <div container="body" ngbTooltip="{{suite.subHeader}}">{{suite.subHeader}}</div>
              <div class="font-lighter">{{suite.reportSuiteAbbr}}</div>
            </div>
          </div>
        </div>
        <div [ngClass]="cardOverlay == true ? 'overlay-active card-body' : 'card-body'">
          <p-chart type="doughnut" [data]="suite.statusData" [options]="options" width="100%" height="16vh"></p-chart>
          <div [ngClass]="suite.overlayTextValue == true ? 'overlay hover-overlay' : 'overlay'">
            <i class="fas fa-times-circle fa-lg" (click)="suite.overlayTextValue = false; "></i>
            <div class="text w-100" data-simplebar data-simplebar-auto-hide="false">{{suite.description}}
            </div>
          </div>
        </div>
        <div class="card-footer d-flex align-items-center justify-content-center">
          <button class="btn btn-icon-round mr-2 hvr-shrink" type="button"
            (click)="navigateRoute(suite.reportSuiteAbbr)" ngbTooltip="View Reports" container="body"
            tooltipClass="reporting-tooltip">
            <i class="fas fa-eye"></i>
          </button>
          <button class="btn btn-icon-round hvr-shrink overlay-btn" (click)="suite.overlayTextValue = true"
            type="button" ngbTooltip="Overview" container="body" tooltipClass="reporting-tooltip">
            <i class="fas fa-book-open"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

