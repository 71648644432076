import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private apiService: ApiService) { }

  getClientTreeList() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getClientTreeList/', null)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  switchClientEntityEnvironment(clientEntityEnvironmentId: number) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.put('switchClientEntityEnvironment/', clientEntityEnvironmentId, '', '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
            window.location.reload();
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  updateUserClient(clientId) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.put('updateUserClient/', clientId, '', '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  updateClientDetails(clientDetail: any) {
    var param = { Id: clientDetail.Id, Name: clientDetail.ChangedName, RowType: clientDetail.RowType };
    const promise = new Promise((resolve, reject) => {
      this.apiService.put('updateClientDetails/', null, param, clientDetail.RowType + ' updated successfully!')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}
