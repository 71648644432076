import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { RoleManagementService } from 'src/app/core/security/role-management.service';


@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent {
    /**
     * User  of header component
     */
    user: any;
    /**
     * Subscription  of user in header component
     */
    subscription: Subscription;

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private commonService: CommonService,
        private roleManagementService: RoleManagementService
    ) {
    }

    ngOnInit() {
        this.user = this.authService.getLoggedInUser();
        if (!this.user) {
            this.subscription = this.authService.watchUser().subscribe(user => {
                this.user = user;
            });
        }
    }

    /**
 * on destroy
 */
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    getStarted() {
        this.authService.isloggedIn = true;        
        if (!this.authService.isPrivacyNoticeAcknowledged) {
            this.router.navigate(['/privacy-notice']);
        }
        else {
            if(this.roleManagementService.checkPermissionsForMenu('V',null,null,'reporting')){
                this.router.navigate(['/app']);
            }
            else if (this.roleManagementService.checkPermissionsForMenu('V',null,null,'ingestion')){
                this.router.navigate(['/app/data-ingestion']);
            }
            else if (this.roleManagementService.checkPermissionsForMenu('V',null,null,'report-bundling')){
                this.router.navigate(['/app/report-bundling']);
            }
            else if (this.roleManagementService.checkPermissionsForMenu('V',null,null,'administration')){
                this.router.navigate(['/app/user-management']);
            }
            else if (this.roleManagementService.checkPermissionsForMenu('S',null,null,'support')){
                this.router.navigate(['/app/client-management']);
            }
        }
    }
}