/**
  * Assigns type of the params.
  */
export class Parameters {
  reportpackid: number;
  formname: string;
  reportId: number;
  reportType: number;
  reportData: any;
  suitename: string;
}

export class PnpFilterParameters {
  reportId: number;
  period1Id: number;
  period2Id: number;
}

export class SaveAdjusmentParameters {
  reportpackid: number;
  reportid: number;
  formname: string;
}

export class PnpReportParameters {
  reportId: number;
  preportId: number;
  period1Id: number;
  period2Id: number;
  rowFilter: string;
  columnFilter: any;
}
