import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PermissionManagementService } from 'src/app/core/services/permissionManagement.service';
import { AlertService } from 'src/app/core/services/alert.service';

/**
 * Component
 */
@Component({
    selector: 'app-permission-management',
    templateUrl: './permission-management.component.html',
    styleUrls: ['./permission-management.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PermissionManagementComponent implements OnInit {
    reportTreeList: any = [];
    gridReportTreeList: any = [];
    headerCols: any;

    loading = false;

    changedNodes: any = [];

    constructor(
        private permissionManagementService: PermissionManagementService, private alertService: AlertService
    ) { }

    ngOnInit() {
        this.headerCols = [
            { field: 'DisplayName', header: 'Name' },
            { field: 'Description', header: 'Description' },
            { field: 'MaxApprovalLevelForReport', header: 'Permission Level' }
        ];
        this.getReportPermissionLevelList();
    }

    private getReportPermissionLevelList() {
        this.loading = true;
        this.permissionManagementService.getReportPermissionLevelList()
            .then((treeList: any) => {
                this.reportTreeList = this.prepareGridTreeList(treeList);
                this.gridReportTreeList = JSON.parse(JSON.stringify(this.reportTreeList));
                this.loading = false;
            });
    }

    prepareGridTreeList(treeList) {
        treeList.forEach(suiteNode => {
            suiteNode.children.forEach(reportNode => {
                reportNode.data.currentMaxApprovalLevel = reportNode.data.MaxApprovalLevelForReport;
                reportNode.data.approvalLevelList = Array.from(Array(reportNode.data.ApprovalLevelLimit), (x, i) => ({ label: i + 1, value: i + 1 }));
            });
        });
        return treeList;
    }

    onApprovalLevelChange(rowNode: any) {
        if (rowNode.node.data.currentMaxApprovalLevel != rowNode.node.data.MaxApprovalLevelForReport) {
            var nodeIndex = this.changedNodes.indexOf(this.changedNodes.filter(x => x.data.Id == rowNode.node.data.Id)[0]);
            if (nodeIndex > -1) this.changedNodes.splice(nodeIndex);
            this.changedNodes.push(rowNode.node);
        }
    }

    updatePermissionLevels() {

        var changedReports = this.changedNodes.map(x => ({ ReportId: x.data.Id, MaxApprovalLevelForReport: x.data.MaxApprovalLevelForReport }));

        this.loading = true;
        this.permissionManagementService.updatePermissionLevels(changedReports)
            .then(() => {
                this.getReportPermissionLevelList();
                this.loading = false;
            }).catch(() => this.loading = false);
    }

    clearChanges() {
        this.gridReportTreeList = JSON.parse(JSON.stringify(this.reportTreeList));
        this.changedNodes = [];
    }
}   
