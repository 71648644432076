import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationService } from './security/authentication.service';
import { CanActivateChildAuthGuard } from './security/canActivateChildAuthGuard';
import { DashboardService } from './services/dashboard.service';
import { SuiteService } from './services/suite.service';
import { EventEmitterService } from './services/event-emitter.service';
import { ApiService } from './services/api.service';
import { RoleManagementService } from './security/role-management.service';
import { CanActivateChildRoleGuard } from './security/canActivateChildRoleGuard';
import { AlertService } from './services/alert.service';
import { GlobalErrorHandler } from './services/globalErrorHandler.service';
import { ServerErrorInterceptor } from './services/serverErrorInterceptor.service';
import { CommonService } from './services/common.service';
import { ReportPackService } from './services/reportPack.service';
import { DataIngestionService } from './services/dataIngestion.service';
import { UserManagementService } from './services/user.service';
import { AnalyticService } from './services/analytics.service';
import { ToastrModule } from 'ngx-toastr';
import { PermissionManagementService } from './services/permissionManagement.service';
import { RolePermissionManagementService } from './services/rolePermissionManagement.service';
import { ReportScheduleService } from './services/reportSchedule.service';
import { PrivacyNoticeService } from './services/privacyNotice.service';
import { BundleTypeManagementService } from './services/bundleTypeManagementService';
import { ReportBundlingService } from './services/reportBundling.service';
import { ReportDownloaderService } from './services/reportDownloader.service';
import { MsalModule, MsalGuard } from "@azure/msal-angular";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { msalConfig } from './security/msal.config';
import { ReportTreeService } from './services/reportTree.service';
import { QueryEngineService } from './services/query-engine.service';
import { ReportPackSequenceService } from './services/reportPackSequence.service';
import { ApplicationConfigurationService } from './services/applicationConfigurationService';
import { RefDataService } from './services/ref-data.service';

const protectedResourceMap = new Map<string, Array<string>>();
protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read"]);


@NgModule({
    declarations: [
    ],
    imports: [
        HttpClientModule,
        MsalModule.forRoot(new PublicClientApplication(msalConfig()), {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: ['user.read']
            }
        }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap: protectedResourceMap
        }),
        ToastrModule
    ],
    exports: [
    ],
    providers: [
        HttpClientModule,
        CanActivateChildAuthGuard,
        CanActivateChildRoleGuard,
        AuthenticationService,
        DashboardService,
        SuiteService,
        EventEmitterService,
        ApiService,
        RoleManagementService,
        AlertService,
        CommonService,
        ReportPackService,
        DataIngestionService,
        UserManagementService,
        AnalyticService,
        PermissionManagementService,
        RolePermissionManagementService,
        ReportScheduleService,
        BundleTypeManagementService,
        ReportBundlingService,
        PrivacyNoticeService,
        ReportDownloaderService,
        ReportTreeService,
        QueryEngineService,
        ReportPackSequenceService,
        ApplicationConfigurationService,
        RefDataService,
        {
            provide: ErrorHandler, useClass: GlobalErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true
        },
        MsalGuard
    ]

})
export class CoreModule { }
