import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class PermissionManagementService {

    permissionLevelList: any = [];
    constructor(private apiService: ApiService) { }


    getReportPermissionLevelList() {
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('permissionManagement/getPermissionLevelList', '')
                .toPromise()
                .then(
                    res => {
                        this.permissionLevelList = res;
                        resolve(res);
                    },
                    msg => {
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    updatePermissionLevels(permissionLevelsList) {
        var params = { permissionLevels: permissionLevelsList };
        const promise = new Promise((resolve, reject) => {
            this.apiService.post('permissionManagement/updatePermissionLevels', params, 'Permission levels saved successfully.', false, true)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }
}