<app-loader *ngIf="loading"></app-loader>
<div *ngIf="selectionMode=='single'" class="d-flex align-items-center filter-wrapper">
    <i class="fas fa-filter filter-wrapper-icon hvr-shrink mr-5" (click)="open(reportPackFilter)"
        ngbTooltip="View report packs" container="body"></i>
    <div *ngIf="!rpForBannerDisplay" class="filter-wrapper-content">No Report Pack Selected</div>
    <div class="filter-wrapper-content d-flex align-items-center justify-content-center" *ngIf="rpForBannerDisplay">
        <div class="col-12 specify-overflow" id="overflowCheck">
            <span class="value mr-3">
                <span class="title">Report Pack ID | </span>{{rpForBannerDisplay.ReportPackID}}
            </span>
            <span class="value mr-3">
                <span class="title">Report Pack Title | </span>{{rpForBannerDisplay.Title}}
            </span>
            <span class="value mr-3">
                <span class="title">Report Date | </span>{{rpForBannerDisplay.ReportDate | date: 'dd/MM/yyyy'}}
            </span>
            <span class="value">
                <span class="title">Description | </span><span
                    *ngIf="rpForBannerDisplay.RP_Description != null">{{rpForBannerDisplay.RP_Description}}</span><span
                    *ngIf="rpForBannerDisplay.RP_Description == null">No description provided</span>
            </span>
        </div>
    </div>
    <i class="fas fa-align-center filter-wrapper-icon2 hvr-shrink ml-5"
        *ngIf="(rpForBannerDisplay) && (rpForBannerDisplay.RP_Description != null)" ngbTooltip="View description"
        container="body" (click)="openModal(descriptionContent); overflowToolCheck()"></i>
</div>
<div *ngIf="selectionMode=='multiple'">
    <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button" (click)="open(reportPackFilter)">
        <div class="icon-container"><i class="fas fa-play"></i></div><span>Select Report Packs</span>
    </button>
</div>

<ng-template #reportPackFilter>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">
            Apply Filter<i class="fas fa-times ml-auto cursor-pointer" aria-label="Close" (click)="closeModal();"></i>
        </h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="report-filter-scroll">
                    <p-table #rpdt [value]="reportPackList" [paginator]="true" [rows]="8" class="table-irr filter-table"
                        [selectionMode]="selectionMode" [(selection)]="selectedRpRows" dataKey="ReportPackID"
                        [columns]="headerCols" [scrollable]="true" [scrollHeight]="'calc(100vh - 371px)'" [tableStyle]="{'min-width': '60rem'}">
                        <ng-template pTemplate="paginatorleft">
                            <button class="btn btn-clear-filter hvr-shrink" type="button"
                                (click)="rpdt.reset();clearFilters();">
                                <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngIf="selectionMode == 'multiple'" style="width:40px">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                        ariaLabelDesc="Activate to sort in descending order"
                                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                                </th>
                            </tr>
                            <tr>
                                <th *ngIf="selectionMode == 'multiple'" style="width:40px">
                                </th>
                                <th *ngFor="let col of columns">
                                    <p-calendar *ngIf="col.field =='ReportDate'" [(ngModel)]="reportDateFilters"
                                        #rdFilter selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy"
                                        [readonlyInput]="true" placeholder="Select a range" appendTo="body"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="{{yearRange}}"
                                        (ngModelChange)="rpdt.filter(reportDateFilters, col.field, 'dateRangeFilter')"
                                        (onSelect)="closeDateRangeFilter(rdFilter, reportDateFilters)" [showButtonBar]="true"></p-calendar>

                                    <p-calendar *ngIf="col.field =='IngestedDate'" [(ngModel)]="ingestedDateFilters"
                                        #idFilter selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy"
                                        [readonlyInput]="true" placeholder="Select a range" appendTo="body"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="{{yearRange}}"
                                        (ngModelChange)="rpdt.filter(ingestedDateFilters, 'IngestedDate', 'dateRangeFilter')"
                                        (onSelect)="closeDateRangeFilter(idFilter, ingestedDateFilters)" [showButtonBar]="true">
                                    </p-calendar>

                                    <input *ngIf="col.field !='IngestedDate' && col.field !='ReportDate'" type="text"
                                        placeholder="Filter Text"
                                        [value]="rpdt.filters && rpdt.filters[col.field] ? rpdt.filters[col.field].value: ''"
                                        (input)="rpdt.filter($event.target.value, col.field, 'contains')">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr [pSelectableRow]="rowData">
                                <td *ngIf="selectionMode == 'multiple'" style="width:40px">
                                    <p-tableCheckbox [pSelectableRow]="rowData" [value]="rowData"></p-tableCheckbox>
                                </td>
                                <td>{{rowData['ReportPackID']}}</td>
                                <td>{{rowData['Title']}}</td>
                                <td>{{rowData['FileName']}}</td>
                                <td>{{rowData['ReportDate'] | date: 'dd/MM/yyyy'}}</td>
                                <td>{{rowData['DefaultRPSequenceName']}}</td>
                                <td>{{rowData['IngestedBy']}}</td>
                                <td>{{rowData['IngestedDate'] | date: 'dd/MM/yyyy'}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="float-right">
            <button class="btn btn-modal-pos small-font hvr-shrink" (click)="setReportPackId();" type="button">
                <span>Submit</span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #descriptionContent>
    <div class="modal-body description-content text-center">
        <div class="row mb-3">
            <div class="col-12 text-right">
                <i class="fas fa-times-circle text-yellow cursor-pointer fa-lg" aria-label="Close"
                    (click)="closeModal();"></i>
            </div>
        </div>
        <div *ngIf="showDescription">
            <div class="row">
                <div class="col-6">
                    <p class="text-yellow mb-0">Report Pack Title</p>
                    <p>{{rpForBannerDisplay.Title}}</p>
                </div>
                <div class="col-6">
                    <p class="text-yellow mb-0">Report Date</p>
                    <p>{{rpForBannerDisplay.ReportDate | date: 'dd/MM/yyyy'}}</p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <p class="text-yellow mb-0">Description</p>
                    <p>
                        {{rpForBannerDisplay.RP_Description}}
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="!showDescription">
            <p>No detailed description for this report pack</p>
        </div>
    </div>
</ng-template>