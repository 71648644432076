<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
    <div class="col-12 col-lg-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="user-mgmt-scroll">
            <p-table #appConfigList [value]="appConfigs" [paginator]="true" [rows]="12" class="table-irr"
                selectionMode="none" [columns]="headerCols" [scrollable]="true" sortField="DisplayName"
                [scrollHeight]="'calc(100vh - 292px)'">
                <ng-template pTemplate="paginatorleft">
                    <button class="btn btn-clear-filter hvr-shrink" type="button"
                        (click)="appConfigList.reset();clearFilters();">
                        <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                    </button>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                        </th>
                        <th>Actions</th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns">
                            <input *ngIf="col.field !='LastChangedDate'" type="text" class="w-100"
                                placeholder="Filter Text"
                                [value]="appConfigList.filters && appConfigList.filters[col.field] ? appConfigList.filters[col.field].value: ''"
                                (input)="appConfigList.filter($event.target.value, col.field, 'contains')">

                            <p-calendar *ngIf="col.field =='LastChangedDate'" [(ngModel)]="lastChangedDateFilters"
                                #rdFilter selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy"
                                [readonlyInput]="true" placeholder="Select a range" appendTo="body"
                                [monthNavigator]="true" [yearNavigator]="true" yearRange="{{yearRange}}"
                                (ngModelChange)="appConfigList.filter(lastChangedDateFilters, col.field, 'dateRangeFilter')"
                                (onSelect)="closeDateRangeFilter(rdFilter, lastChangedDateFilters)">
                            </p-calendar>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.DisplayName}}</td>
                        <td>{{rowData.Description}}</td>
                        <td>{{rowData.ConfigValue}}</td>
                        <td>{{rowData.ConfigDefaultValue}}</td>
                        <td>{{rowData.LastChangedBy}}</td>
                        <td>{{rowData.LastChangedDate | date:'dd/MM/yyyy'}}</td>
                        <td>
                            <button type="button" class="btn-table-icon hvr-shrink" container="body"
                                [disabled]="!rowData.UserEditable" ngbTooltip="Edit Configuration" triggers="hover"
                                (click)="openModal(editAppConfigModal,rowData)">
                                <i class="fas fa-edit fa-lg"></i>
                            </button>
                            <button type="button" class="btn-table-icon hvr-shrink" container="body"
                                *ngIf="rowData.AppDefaultId != null"
                                [disabled]="!rowData.UserEditable || rowData.ConfigValue == rowData.ConfigDefaultValue"
                                ngbTooltip="Reset to Default" triggers="hover" (click)="resetToDefault(rowData)">
                                <i class="fas fa-undo fa-lg"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<ng-template #editAppConfigModal let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">Edit Application Configuration<i
                class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="modal.dismiss('Cross click')"></i></h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <form #appConfigForm="ngForm">
                    <div class="row form-row">
                        <div class="col-4"><label
                                class="color-teal font-14 mb-2">Old Value</label></div>
                        <div class="col-4">
                            <input type="text" value="{{selectedRowForEdit.oldValue}}" required disabled class="w-100 cellPopOutInput" />
                        </div>
                        <br/>
                    </div>
                    <br/>
                    <div class="row form-row">
                        <div class="col-4"><label
                                class="color-teal font-14 mb-2">{{selectedRowForEdit.DisplayName}}</label></div>
                        <div class="col-4">
                            <kendo-numerictextbox
                                *ngIf="selectedRowForEdit.cellOptions.length == 0 && selectedRowForEdit.editor == 'numeric'"
                                required id="newConfigValue" name="newConfigValue"
                                [(ngModel)]="selectedRowForEdit.newConfigValue" [decimals]="selectedRowForEdit.decimals"
                                [autoCorrect]="true" [min]="-1000000000000000" [max]="1000000000000000"
                                [format]="'#.#####'" class="w-100">
                            </kendo-numerictextbox>
                            <!-- <kendo-switch
                                *ngIf="selectedRowForEdit.cellOptions.length == 0 && selectedRowForEdit.editor == 'boolean'"
                                required id="newConfigValue" name="newConfigValue" [(ngModel)]="selectedRowForEdit.newConfigValue"
                                onLabel="Yes" offLabel="No">
                            </kendo-switch> -->
                            <input type="text"
                                *ngIf="selectedRowForEdit.cellOptions.length == 0 && selectedRowForEdit.editor == 'text'"
                                id="newConfigValue" name="cellPopOutNewValue" required
                                [(ngModel)]="selectedRowForEdit.newConfigValue" maxlength="50"
                                pattern="^[A-Za-z0-9 _\-.]*$" trim-text class="w-100 cellPopOutInput" />
                            <input
                                *ngIf="selectedRowForEdit.cellOptions.length == 0 && selectedRowForEdit.editor == 'date'"
                                [style.display]="'none'" />
                            <p-calendar
                                *ngIf="selectedRowForEdit.cellOptions.length == 0 && selectedRowForEdit.editor == 'date'"
                                id="cellPopOutNewValue" name="cellPopOutNewValue" required
                                [(ngModel)]="selectedRowForEdit.newConfigValue" [showIcon]="true" [autoZIndex]="true"
                                dateFormat="yy-mm-dd" [readonlyInput]="true" [monthNavigator]="true"
                                yearRange="{{yearRange}}" [yearNavigator]="true" appendTo="body">
                            </p-calendar>
                            <select *ngIf="selectedRowForEdit.cellOptions.length > 0" class="w-100 cellPopOutInput"
                                required [(ngModel)]="selectedRowForEdit.newConfigValue" id="cellPopOutNewValue"
                                name="cellPopOutNewValue">
                                <option *ngFor="let op of selectedRowForEdit.cellOptions" [ngValue]="op.Value">
                                    {{op.Title}}</option>
                            </select>
                            <div *ngIf="selectedRowForEdit.oldValue == selectedRowForEdit.newConfigValue"
                                class="alert alert-danger">
                                New value cannot be same as old value. Please enter a different value
                            </div>
                            <div *ngIf="!appConfigForm.valid && appConfigForm.controls.cellPopOutNewValue.errors"
                                class="alert alert-danger">
                                <div *ngIf="appConfigForm.controls.cellPopOutNewValue.errors.required">
                                    Please enter a value to save changes.
                                </div>
                                <div
                                    *ngIf="appConfigForm.controls.cellPopOutNewValue.errors.maxError || appConfigForm.controls.cellPopOutNewValue.errors.minError">
                                    The value should be between -1,000,000,000,000,000 and
                                    1,000,000,000,000,000.
                                </div>
                                <div *ngIf="appConfigForm.controls.cellPopOutNewValue.errors.pattern">
                                    The value contains invalid characters.
                                </div>
                                <div *ngIf="appConfigForm.controls.cellPopOutNewValue.errors.maxlength">
                                    The value cannot be greater than 50 characters.
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div class="row form-row">
                        <div class="col-4"><label class="color-teal font-14 mb-2">Comments</label></div>
                        <div class="col-8">
                            <textarea id="cellPopOutComment" name="cellPopOutComment" required maxlength="500"
                                #cellPopOutComment="ngModel" rows="4" [(ngModel)]="selectedRowForEdit.comments"
                                pattern="^[A-Za-z0-9 _\-.]*$" trim-text placeholder="Enter Comments"
                                class="w-100 cellPopOutInput"></textarea>
                            <div *ngIf="!appConfigForm.valid && appConfigForm.controls.cellPopOutComment.errors"
                                class="alert alert-danger">
                                <div
                                    *ngIf="appConfigForm.controls.cellPopOutComment.errors.required && appConfigForm.controls.cellPopOutComment.touched">
                                    Please enter a comment to save changes.
                                </div>
                                <div *ngIf="appConfigForm.controls.cellPopOutComment.errors.pattern">
                                    The comment contains invalid characters.
                                </div>
                                <div *ngIf="appConfigForm.controls.cellPopOutComment.errors.maxlength">
                                    The comment cannot be greater than 500 characters.
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer text-right">
        <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button" (click)='clearChanges()'>
            <span>Clear</span>
        </button>
        <button class="btn btn-modal-pos small-font hvr-shrink" type="button" (click)='submitChanges()'
            [disabled]="appConfigForm && appConfigForm.form ? !appConfigForm.form.valid : false">
            <span>Save</span>
        </button>
    </div>
</ng-template>