<app-loader *ngIf="loading"></app-loader>
<div class="privacy-notice">
    <img src="assets/images/EY-Logo-White.png" class="ey-logo">
    <img src="assets/images/IRR-Logo3.svg" class="irr-logo">
    <div class="row d-flex align-items-center mx-0 justify-content-center mt-3">
        <div class="col-12 col-xl-8">
            <div class="card">
                <div class="card-header text-center">
                    <h2>
                        <b>I</b>ntegrated <b>R</b>egulatory <b>R</b>eporting
                    </h2>
                    <h2 class="mb-0">
                        <b>P</b>rivacy <b>N</b>otice
                    </h2>
                </div>
                <div class="card-body" data-simplebar data-simplebar-auto-hide="false">
                    <div [innerHTML]="htmlData">
                    </div>
                </div>
                <div class="card-footer d-flex flex-column align-items-center justify-content-center d-md-block">
                    <div class="float-left mb-3 mb-mb-0" *ngIf="user && user.isPrivacyNoticeAcknowledged">
                        <span class="mr-4">Signed By : {{ user.username }}</span> <span>Signed On :
                            {{currentDate}}</span>
                    </div>
                    <div class="float-right">
                        <button class="btn btn-secondary-ey btn-icon hvr-shrink small-font"
                            *ngIf="user && !user.isPrivacyNoticeAcknowledged" type="button" (click)='onPrivacyNoticeSigning()'>
                            <div class="icon-container"><i class="fas fa-thumbs-up fa-lg"></i></div><span>Agree</span>
                        </button>

                        <button class="btn btn-secondary-ey btn-icon hvr-shrink small-font" type="button"
                            *ngIf="user && user.isPrivacyNoticeAcknowledged" (click)="getStarted()">
                            <div class="icon-container"><i class="fas fa-arrow-right fa-lg"></i></div><span>Get
                                Started</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>