import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

/**
 * Injectable
 */
@Injectable()
export class ReportPackSequenceService {
  editMode: boolean;
  selectedRPSequence: any;

  constructor(private apiService: ApiService) { }

  getActiveReportPackSequenceList() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getActiveReportPackSequences/', '', '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getReportPackSequenceList() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getReportPackSequences/', '', '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  changeReportPackSequenceStatus(reportPackSequenceId: number, activeStatus: boolean) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.put('changeReportPackSequenceStatus/', reportPackSequenceId, activeStatus, '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  addReportPackSequence(reportPackSequence: any) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.post('addReportPackSequence/', reportPackSequence, 'Report Pack Sequence has been created successfully!')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  updateReportPackSequence(reportPackSequence: any) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.put('updateReportPackSequence/', reportPackSequence.RPSId, reportPackSequence, 'Report Pack Sequence has been updated successfully!')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}
