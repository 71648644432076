import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../security/authentication.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ReportBundlingService {
  baseURL: string = environment.apiUrl;
  bundlingActionList: any = [];
  selectedBundle: any;
  viewMode: boolean;

  constructor(private apiService: ApiService, private authService: AuthenticationService) { }

  getBundleList() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getReportBundles/', '')
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  downloadFile(fileName: string) {
    window.location.href = this.baseURL + 'downloadFile?fileName=' + fileName + '&token=' + this.authService.getTokenHeader();
  }

  getBundlingActions() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getBundlingActions/', '')
        .toPromise()
        .then(
          res => {
            this.bundlingActionList = res;
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  getBundleTypeReports(reportPackId: number, bundleTypeId: number) {
    var params = { reportPackId: reportPackId, bundleTypeId: bundleTypeId };
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getBundleTypeReports/', params)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  startReportBundling(bundle: any) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.post('startReportBundling/', bundle, 'Report Bundling has been initiated successfully!')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getBundleReferenceReports(bundleId: number) {
    var params = { bundleId: bundleId };
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getBundleReferenceReports/', params)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }
}