import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { Router } from '@angular/router';
import { DataIngestionService } from 'src/app/core/services/dataIngestion.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromptModalComponent } from 'src/app/shared/prompt-modal/prompt-modal.component';
import { CommonService } from 'src/app/core/services/common.service';
import { TreeNode } from 'primeng';

/**
 * IngestionPanelComponent
 */
@Component({
    selector: 'app-ingestion-form',
    templateUrl: './ingestion-form.component.html',
    styleUrls: ['./ingestion-form.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class IngestionFormComponent implements OnInit, OnDestroy {
    public selectedNodes: TreeNode[];
    public loading = false;
    ingestionLoadData: any;
    formData: any;
    formPageDropDownTitle: string;
    formPageDropDownOptions: any = [];
    selectedFormPage: any;
    editType: string;
    latestAudit: any = [];
    blockedCells: any = [];
    blankCells: any = [];
    cellDataTypes: any = [];
    cellDecimalPrecision: any = [];
    cellEditPropertyList: any = [];
    cellDataDictionary: any = [];
    formLayout: any;
    formChanges: any = [];
    formAudit: any = [];
    hasError: boolean = false;
    cellPopOutModel: any = null;
    cellAudit: any;
    modifiedDateFilters: Date[];
    user: any;
    private userSubscription: Subscription;
    promptResponse: Subscription;
    dataPrepInProgress: boolean = false;
    selectedReports: string = '';

    constructor(
        private alertService: AlertService,
        private modalService: NgbModal,
        private dataIngestionService: DataIngestionService,
        private commonService: CommonService,
        private router: Router,
        private authService: AuthenticationService,
    ) { }

    ngOnInit() {
        if (!this.authService.isPrivacyNoticeAcknowledged) {
            this.router.navigate(['/privacy-notice']);
        }

        this.ingestionLoadData = this.dataIngestionService.ingestionLoadData;

        if (!this.ingestionLoadData) {
            this.router.navigate(['/app/data-ingestion']);
        }
        else {
            if (this.ingestionLoadData.reportpackid && this.ingestionLoadData.reportpackid != 0) {
                this.checkForActiveDataPreps(this.ingestionLoadData.reportpackid);
            }

            this.loading = true;

            if (!this.formData) {
                this.dataIngestionService.getFormPageDetails(this.ingestionLoadData.templateId)
                    .then((data: any) => {
                        this.formPageDropDownTitle = data.FormPageDropDownTitle;
                        this.formPageDropDownOptions = data.FormPageOptions;
                        if (this.formPageDropDownOptions && this.formPageDropDownOptions.length > 0) {
                            this.selectedFormPage = this.formPageDropDownOptions[0].OptionValue;
                        }
                        this.getFormData();
                    }).catch(() => { this.loading = false; });
            }
            else {
                this.prepareFormLayout();
            }

            this.user = this.authService.getLoggedInUser();
            if (!this.user) {
                this.userSubscription = this.authService.watchUser().subscribe(user => {
                    this.user = user;
                });
            }
        }

        this.promptResponse = this.commonService.watchPrompt().subscribe(response => {
            this.modalService.dismissAll();
            if (response.callFor === 'saveFormChanges') {
                if (response.response === 'Yes') {
                    this.saveIngestionForm(true, true);
                }
                else {
                    this.formChanges = [];
                    this.getFormData();
                }
            }
        });
    }

    getFormData() {
        this.loading = true;
        var formPage = this.formPageDropDownOptions && this.formPageDropDownOptions.length > 0 ? this.formPageDropDownOptions.filter(x => x.OptionValue == this.selectedFormPage)[0] : null;
        this.dataIngestionService.getIngestionFormData(this.ingestionLoadData.reportpackid, this.ingestionLoadData.tableId,
            (formPage ? formPage.ReportId : this.ingestionLoadData.correspondingReportId),
            (formPage ? formPage.OptionValue : this.ingestionLoadData.correspondingReport), this.ingestionLoadData.correspondingReport)
            .then((data: any) => {
                this.editType = this.ingestionLoadData.loadId == 0 || !this.ingestionLoadData.isFormSubmittedBefore ? 'inline' : 'cellPopOut';
                this.formData = JSON.parse(JSON.stringify(data));;
                this.prepareFormLayout();
                this.loading = false;
            }).catch(() => { this.loading = false; });
    }

    checkForActiveDataPreps(reportPackId: number) {
        this.dataIngestionService.checkForActiveDataPreparations(reportPackId).then((hasActiveDataPreps: any) => {
            this.dataPrepInProgress = hasActiveDataPreps;
        });
    }

    onFormPageChange(selectedPage: string) {
        this.selectedFormPage = selectedPage
        if (this.formChanges.length == 0) {
            this.getFormData();
        }
        else {
            var openModal = this.modalService.open(PromptModalComponent, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
            openModal.componentInstance.promptCallFor = 'saveFormChanges';
            openModal.componentInstance.promptMessage = 'The page cannot be changed unless there are no pending changes on the current page. Would you like to save the changes ?';
        }
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        this.modalService.dismissAll();
    };

    openPopUp(reportRunPopUp) {
        this.modalService.open(reportRunPopUp, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
        this.selectedReports = this.dataIngestionService.ingestionLoadData.reportsToPrepare
    }

    submitReportSelection() {
        this.dataIngestionService.ingestionLoadData.reportsToPrepare = this.selectedReports;
    }

    clearReportSelection() {
        this.selectedReports = '';
    }

    prepareFormLayout() {
        this.blockedCells = this.formData.BlockedCells != null ? this.formData.BlockedCells : [];
        this.blankCells = this.formData.BlankCells != null ? this.formData.BlankCells : [];
        this.cellDecimalPrecision = this.formData.DecimalPrecision != null ? this.formData.DecimalPrecision : [];
        this.cellDataTypes = this.formData.BoxCodeDataTypes != null ? this.formData.BoxCodeDataTypes : [];
        this.cellEditPropertyList = this.formData.EditableCells != null ? this.formData.EditableCells : [];
        this.cellDataDictionary = this.formData.BoxCodeDataDictionary != null ? this.formData.BoxCodeDataDictionary : [];
        this.latestAudit = this.formData.reportChangeAudit;

        this.formLayout = [];
        var pages = this.formData.reportPageHeaders;
        pages.forEach(g => {
            var grid = {
                id: g.PageName,
                title: g.PageTitle,
                gridError: null,
                dynamicRows: g.HasDynamicRows,
                clientRefIdField: 'rowId', //Needs to be modified dynamically
                columnGroups: this.formData.columnGroups.filter(x => x.PageName == g.PageName),
                columns: this.formData.columnHeaders.filter(x => x.PageName == g.PageName),
                gridData: this.formData.columnData.filter(x => x.pagename == g.PageName)
            };

            this.formLayout.push(grid);
        });
    }

    backToIngestion() {
        this.dataIngestionService.ingestionLoadData = null;
        this.router.navigate(['/app/data-ingestion']);
    }

    validateGridError() {
        if (this.formChanges.filter(x => x.changeType != 'D' && (x.clientRefId == null || x.clientRefId == '' || x.clientRefId == undefined)).length > 0) {
            this.formChanges.filter(x => x.changeType != 'D' && (x.clientRefId == null || x.clientRefId == '' || x.clientRefId == undefined)).map(x => x.gridId).filter((value, index, self) => self.indexOf(value) === index).forEach(el => {
                var grid = this.formLayout.filter(x => x.id == el)[0];
                grid.gridError = grid.columns.filter(x => x.field == grid.clientRefIdField)[0].title + ' is a mandatory field. Please make sure all rows have this column filled.'
                this.hasError = true;
            });
        }
        else {
            this.formLayout.forEach(el => {
                el.gridError = null;
            });
            this.hasError = false;
        }
    }

    async saveIngestionForm(draftVersion: boolean, onFormPageChange: boolean = false) {
        if (this.formChanges.filter(x => x.changeType != 'D' && (x.clientRefId == null || x.clientRefId == '' || x.clientRefId == undefined)).length > 0) {
            this.validateGridError();
            return;
        }

        if (this.formChanges.length > 0 || (this.ingestionLoadData.currentStatus == 'Draft' && !draftVersion)) {
            this.loading = true;
            await this.dataIngestionService.saveIngestionForm(this.formChanges, draftVersion)
                .then((model: any) => {
                    this.loading = false;
                    this.ingestionLoadData.reportpackid = model.reportpackid;
                    this.ingestionLoadData.loadId = model.loadId;
                    this.ingestionLoadData.currentStatus = 'Draft';
                    if (!draftVersion) {
                        this.backToIngestion();
                    }
                    else {
                        this.formChanges = [];
                        this.getFormData();
                    }
                }).catch(() => { this.loading = false; });
        }
        else {
            this.alertService.info('No changes to save!');
        }
    }

    submitCellChange(changedCell) {
        var grid = this.formLayout.filter(x => x.id == changedCell.gridId)[0];
        //Updating changed data in form data and grid
        this.formData.columnData.filter(x => x.rowid == changedCell.rowRefId && x.pagename == grid.id)[0][changedCell.column] = changedCell.newValue;
        grid.gridData = this.formData.columnData.filter(x => x.pagename == grid.id);

        //Updating form changes
        var prevCellChange = this.formChanges.filter(x => x.columnId == changedCell.columnId && x.rowRefId == changedCell.rowRefId && x.gridId == changedCell.gridId)[0];
        if (prevCellChange) {
            prevCellChange.value = changedCell.newValue;
            prevCellChange.changed = true;
            prevCellChange.comment = changedCell.comment;
            prevCellChange.changeType = 'U';
        }
        else this.formChanges.push({ columnId: changedCell.columnId, rowRefId: changedCell.rowRefId, clientRefId: changedCell.clientRefId, value: changedCell.newValue, gridId: changedCell.gridId, comment: changedCell.comment, changeType: 'U' });

        if (grid.clientRefIdField == changedCell.column) {
            this.formChanges.filter(x => x.rowRefId == changedCell.rowRefId && x.gridId == changedCell.gridId).forEach(el => {
                el.clientRefId = changedCell.newValue;
                el.changeType = 'U';
            });
        }

        if (this.ingestionLoadData.loadId != 0 && !changedCell.isNewRow)
            this.addAudit(changedCell, false);
    }

    addAudit(changedCell: any, isDelete: boolean) {
        const changedDate = new Date();
        //Adding to audit
        var audit = this.formAudit.filter(x => x.rowRefId == changedCell.rowRefId && x.clientRefId == changedCell.clientRefId && x.columnId == changedCell.columnId)[0];
        if (audit) {
            audit.newValue = changedCell.newValue;
            audit.comment = changedCell.comment;
            audit.changedDate = changedDate;
        } else {
            audit = {
                id: this.formAudit.length + 1, rowRefId: changedCell.rowRefId, clientRefId: changedCell.clientRefId, columnId: changedCell.columnId, rowCode: changedCell.rowCode,
                rowDescription: changedCell.headTitle, columnTitle: changedCell.columnTitle, oldValue: changedCell.oldValue, newValue: changedCell.newValue,
                comment: changedCell.comment, changedDate: changedDate
            }
            this.formAudit.push(audit);
        }

        if (!isDelete) {
            //Updating latest audit data
            var latestCellAudit = this.latestAudit.filter(x => x.rowrefid == changedCell.rowRefId && x.clientrefid == changedCell.clientRefId && x.columnid == changedCell.columnId)[0];
            if (latestCellAudit) {
                latestCellAudit.oldvalue = this.ingestionLoadData.currentStatus != 'Draft' ? changedCell.oldValue : latestCellAudit.oldvalue;
                latestCellAudit.newvalue = changedCell.newValue;
                latestCellAudit.comment = changedCell.comment;
                latestCellAudit.changedby = this.user.username;
                latestCellAudit.changeddate = changedDate;
            }
            else {
                this.latestAudit.push({
                    rowcode: changedCell.rowCode, rowrefid: changedCell.rowRefId, clientrefid: changedCell.clientRefId, columnid: changedCell.columnId,
                    rowdescription: changedCell.description, columntitle: changedCell.columnTitle, oldvalue: changedCell.oldValue, newvalue: changedCell.newValue,
                    comment: changedCell.comment, changedby: this.user.username, changeddate: changedDate
                });
            }
        }
    }

    addNewRow(row: any) {
        this.formData.columnData.push(row);
        var grid = this.formLayout.filter(x => x.id == row.pagename)[0];
        grid.gridData = this.formData.columnData.filter(x => x.pagename == grid.id);
    }

    removeRow(row: any) {
        this.formData.columnData = this.formData.columnData.filter(x => !(x.pagename == row.pagename && x.rowid == row.rowid));
        var grid = this.formLayout.filter(x => x.id == row.pagename)[0];
        grid.gridData = this.formData.columnData.filter(x => x.pagename == grid.id);

        this.formChanges = this.formChanges.filter(x => !(x.gridId == row.pagename && x.rowRefId == row.rowid));

        if (!row.IsNewRow) {
            grid.columns.filter(x => x.editable == true).forEach(col => {
                if (row[col.dataField] != null && row[col.dataField] != '' && row[col.dataField] != 0) {
                    this.formChanges.push({ columnId: col.IFColumnId, rowRefId: row.rowid, clientRefId: row.clientrefid, value: null, gridId: grid.id, comment: 'Deleted.', changeType: 'D' });
                }
            });
        }
    }

    getFormCellAudit(cell: any) {
        this.loading = true;
        this.dataIngestionService.getFormCellAudit(this.ingestionLoadData.reportpackid, this.ingestionLoadData.tableId, cell.rowRefId, cell.clientRefId, cell.columnId)
            .then((data: any) => {
                var auditList = [];
                data.forEach(el => {
                    if (!el.IsDraft || this.formAudit.filter(x => x.rowRefId == cell.rowRefId && x.clientRefId == cell.clientRefId && x.columnId == cell.columnId).length == 0) {
                        auditList.push({
                            oldValue: el.OldValue, newValue: el.NewValue, comment: el.Comment, changedBy: el.ChangedBy, changedDate: el.ChangedDate, isSaved: !el.IsDraft
                        });
                    }
                });
                this.formAudit.filter(x => x.rowRefId == cell.rowRefId && x.clientRefId == cell.clientRefId && x.columnId == cell.columnId).forEach(el => {
                    auditList.push({
                        oldValue: el.oldValue, newValue: el.newValue, comment: el.comment, changedBy: this.user.username, changedDate: el.changedDate, isSaved: false
                    });
                });
                this.cellAudit = auditList.sort((a, b) => a.changedDate < b.changedDate ? 1 : -1);
                this.loading = false;
            }).catch(() => { this.loading = false; });
    }

    downloadAuditReport() {
        this.alertService.info('The form audit report will be downloaded in a while. Please note that only saved changes will be downloaded!');
        this.dataIngestionService.downloadFormAuditReport();
    }
}
