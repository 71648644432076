<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
  <div class="col-12 col-lg-6">
    <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
      [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
  </div>
</div>
<app-report-pack-filter [(selectedReportPacks)]="selectedRp" [selectedRpIds]="reportPackId" class="mb-0 ml-2"
  [updateServiceOnRpSelection]="false" (selectedReportPacksChange)="onReportPackSelectionChange()">
</app-report-pack-filter>
<app-no-report-pack *ngIf="!selectedRp"></app-no-report-pack>
<div class="row mt-3" *ngIf="selectedRp">
  <div class="col-12">
    <div>
      <p-treeTable #dt [value]="reportTreeListData" selectionMode="checkbox" class="table-irr"
        [(selection)]="selectedNodes" [columns]="headerCols" [paginator]="true" [rows]="8" [scrollable]="true"
        [scrollHeight]="'calc(100vh - 400px)'" sortField="ReportDisplayName" sortOrder="1">
        <ng-template pTemplate="paginatorleft">
          <button class="btn btn-clear-filter hvr-shrink" type="button" (click)="dt.reset();clearFilters();">
            <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
          </button>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [ttSortableColumn]="col.field"> {{col.header}}
              <p-treeTableSortIcon [field]="col.field"></p-treeTableSortIcon>
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns">
              <input *ngIf="col.field !='' && col.field != 'ReportingType'" type="text" class="w-100" placeholder="Filter Text"
                [value]="dt.filters && dt.filters[col.field] ? dt.filters[col.field].value: ''"
                (input)="dt.filter($event.target.value, col.field, 'contains')">
              <p-multiSelect *ngIf="col.field =='ReportingType'" [options]="reportingTypeOptions" (onChange)="dt.filter($event.value, 'ReportingType', 'in')"
                appendTo="body" defaultLabel="Select Reporting Type" [(ngModel)]='selectedReportingTypes'>
              </p-multiSelect>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
          <tr>
            <td>
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
              <!-- <p-treeTableCheckbox [value]="rowNode"  ngDefaultControl [disabled]="false">
                </p-treeTableCheckbox> -->
              {{rowData.DisplayName}}
            </td>
            <td>{{rowData.Description}}</td>
            <td>{{rowData.ReportingType}}</td>
            <td>{{rowData.TemplateName}}</td>
            <td><a (click)="reportDownload(rowNode)" href="javascript:void(0)" *ngIf="rowData.TemplateId">Download</a>
            </td>
          </tr>
        </ng-template>
      </p-treeTable>
    </div>
  </div>
</div>