import { Directive, TemplateRef, ViewContainerRef, Input, OnInit } from '@angular/core';

import { RoleManagementService } from 'src/app/core/security/role-management.service';
import { PermissionType } from '../models/permission.model';

/**
 * Directive to set permissions
 */
@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements OnInit {
  /**
   * Type  of permission
   */
  type: PermissionType;
  /**
   * Suite  of permission
   */
  suite: string;
  /**
   * Report  of permission
   */
  report: string;
  /**
   * Resource  of permission
   */
  resource: string;

  /**
   * Sets input for directive
   */
  @Input() set appPermission(permission: string[]) {
    this.type = <PermissionType>permission[0];
    this.suite = permission[1] ? permission[1].toLowerCase().trim() : null;
    this.report = permission[2] ? permission[2].toLowerCase().trim() : null;
    this.resource = permission[3] ? permission[3].toLowerCase().trim() : null;
  }

  /**
   * Creates an instance of permission directive.
   */
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private roleService: RoleManagementService
  ) { }

  /**
   * on init
   */
  ngOnInit() {
      if (this.roleService.checkPermissionsForMenu(this.type, this.suite, this.report, this.resource)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
  }

}
