import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { Subscription, interval } from 'rxjs';
import { DashboardService } from '../../../core/services/dashboard.service';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { Router } from '@angular/router';
import { DataIngestionService } from 'src/app/core/services/dataIngestion.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-datapreparation-panel',
  templateUrl: './datapreparation-panel.component.html',
  styleUrls: ['./datapreparation-panel.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DataPreparationComponent implements OnInit, OnDestroy {

  private autoRef: Subscription;

  loading = false;
  reportPackId: number;
  reportPackIdSub: Subscription;
  selectedRp: any;
  selectedNodes: any = [];
  reportTreeList: any = [];
  public gridReportTreeList: any = [];
  headerCols: any[];
  public hasNewLoad = false;
  ingestionsInProgress: boolean = false;
  
  private dateTime = new Date();
  private yearRange = "2000:" + this.dateTime.getFullYear().toString();
  preparedDateFilters: Date[];

  selectedDependentNodes: any = [];
  reportSelectedForDependencyView: any;
  selectedReportSourceDependencies: any;
  selectedReportDependents: any;
  dependencyDirection: any;

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private dashboardService: DashboardService,
    private authService: AuthenticationService,
    private dataIngestionService: DataIngestionService,
    private modalService: NgbModal,
    private router: Router
  ) {
  }

  ngOnInit() {
    if (!this.authService.isPrivacyNoticeAcknowledged) {
      this.router.navigate(['/privacy-notice']);
    }

    this.selectedRp = this.dashboardService.selectedReportPack;
    this.reportPackId = (this.dashboardService.reportPackID != null && this.dashboardService.reportPackID !== undefined) ? this.dashboardService.reportPackID : 0;

    if (this.reportPackId != null || this.reportPackId != 0) {
      this.loading = true;
      this.getDataPreparationReportList();
    }

    this.reportPackIdSub = this.dashboardService.watchReportPackId().subscribe(rpId => {
      this.loading = true;
      this.reportPackId = rpId;
      this.selectedRp = this.dashboardService.selectedReportPack;
      this.checkForActiveIngestions();
      this.getDataPreparationReportList();
    });

    this.headerCols = [
      { field: 'DisplayName', header: 'Name' },
      { field: 'Description', header: 'Description' },
      { field: 'LastPreparedBy', header: 'Last Prepared By' },
      { field: 'LastPreparedDate', header: 'Last Prepared Date' },
      { header: 'Status' }
    ];
  }


  checkForActiveIngestions() {
    this.dataIngestionService.checkForActiveIngestions(this.reportPackId).then((hasActiveIngestions: any) => {
      this.ingestionsInProgress = hasActiveIngestions;
    });
  }

  private getDataPreparationReportList() {
    var reportPackId = (this.dashboardService.reportPackID != null && this.dashboardService.reportPackID !== undefined) ? this.dashboardService.reportPackID : 0;
    this.dataIngestionService.getDataPreparationReportList(reportPackId)
      .then((treeList: any) => {
        this.reportTreeList = JSON.parse(JSON.stringify(treeList));
        this.gridReportTreeList = JSON.parse(JSON.stringify(treeList));
        this.hasNewLoad = treeList.filter(parentNode => parentNode.children && parentNode.children.filter(child => child.data.PrepLoadId != 0 && child.data.PrepLoadId < child.data.MaxLoadId).length > 0).length > 0 ? true : false;
        this.selectedNodes = [];

        var inProgressDataPrepCount = treeList.filter(parentNode => parentNode.children && parentNode.children.filter(child => child.data.Status == 'Not Started' || child.data.Status == 'In Progress').length > 0).length;

        if (inProgressDataPrepCount == 0) {
          if (this.autoRef && !this.autoRef.closed) {
            this.autoRef.unsubscribe();
          }
        }
        else if (inProgressDataPrepCount > 0) {
          this.bindAutoRefresh();
        }

        this.loading = false;
      }).catch(() => { this.loading = false; });
  }

  countSelectedReports() {
    this.gridReportTreeList.forEach(rowNode => {
      if (rowNode.children.length > 0) {
        let selReport = this.selectedNodes.filter(sNode => sNode.parent && sNode.parent.data.Name == rowNode.data.Name);
        rowNode.data.selectedReportsCount = selReport.length ? '(Selected Reports: ' + selReport.length + ')' : '';
      }
    });
  }

  bindAutoRefresh() {
    this.autoRef = interval(60000).subscribe((val) => { this.getDataPreparationReportList() });
  }
  x
  clearFilters() {
    this.preparedDateFilters = null;
    this.gridReportTreeList = JSON.parse(JSON.stringify(this.reportTreeList));
    this.selectedNodes=[];
    this.resetNodesOnFilterChange(this.gridReportTreeList);
    if (this.gridReportTreeList.filter(parentNode => parentNode.children && parentNode.children.filter(child => child.data.Status == 'Not Started' || child.data.Status == 'In Progress').length > 0).length == 0) {
      this.autoRef.unsubscribe();
    }
    
  }

  onDataPrepFilterChange(event: any) {
    var filteredList = event.filteredValue;
    this.resetNodesOnFilterChange(filteredList);
  }

  closeDateRangeFilter(dateComponent: any, filters: Date[]) {
    if (filters[1]) {
      dateComponent.hideOverlay();
    }
  }

  resetNodesOnFilterChange(filteredList: any) {
    let selectedReportsArray = this.selectedNodes.map(node => node.data.Name);
    this.selectedNodes = [];
      filteredList.forEach(parentNode => {
        var selectedChildrenNodes = parentNode.children.filter(childNode => selectedReportsArray.map(x => x).includes(childNode.data.Name));
        if (selectedChildrenNodes.length == parentNode.children.length) this.selectedNodes.push(parentNode);
        this.selectedNodes.push(...selectedChildrenNodes);
      });
      this.countSelectedReports();
  }

  prepareReportData(isSupport) {
    if (this.dashboardService.reportPackID === null) {
      this.alertService.warn('Please select the report pack to continue.', true);
      return false;
    }

    var selectedReports = '';
    var reportsNotPreparedForLatestLoad = '';
    if (this.selectedNodes == null || this.selectedNodes == undefined || this.selectedNodes.length == 0) {
      this.alertService.warn('Please select one or more report(s) to continue.', true);
      return false
    }
    else if (isSupport) {
      var selectedReportsArray = this.selectedNodes.filter(node => node.parent && ((node.data.Status == 'Success' || node.data.Status == 'Failed') && node.data.PrepLoadId >= node.data.MaxLoadId)).map(x => x.data.Name);
      selectedReports = selectedReportsArray.length > 0 ? selectedReportsArray.join('|') : '';

      var unPreparedReports = this.selectedNodes.filter(node => node.parent && selectedReportsArray.filter(x => x == node.data.Name).length == 0).map(x => x.data.Name);
      reportsNotPreparedForLatestLoad = unPreparedReports.length > 0 ? unPreparedReports.join(',') : '';
    }
    else {
      var selectedReportsArray = this.selectedNodes.filter(node => node.parent && (node.data.Status == '' || node.data.PrepLoadId < node.data.MaxLoadId)).map(x => x.data.Name);
      selectedReports = selectedReportsArray.length > 0 ? selectedReportsArray.join('|') : '';
    }

    if (selectedReports === '') {
      var dataPrepType = isSupport === true ? 'Re-prepare Data' : 'Prepare Data';
      var dataPrepWarningMessage = isSupport === true ? 'completed or failed' : 'not prepared';
      var warning = this.selectedNodes.length == 0 ? "Please select one or more reports, before clicking '" + dataPrepType + "' button!" : "Please select one or more reports that are " + dataPrepWarningMessage + " for the latest load, before clicking the '" + dataPrepType + "' button!";
      this.alertService.warn(warning, true);
      return false;
    }

    if (reportsNotPreparedForLatestLoad !== '') {
      var warning = 'Re-preparation of data will be not done for the following reports, as they have not been prepared earlier for the latest load : ' + reportsNotPreparedForLatestLoad;
      this.alertService.warn(warning, true);

    }

    this.loading = true;
    const params = {
      reportpackid: this.dashboardService.reportPackID,
      reportsToPrepare: selectedReports,
      isSupportRequest: isSupport
    };

    this.apiService.post('startpreparedata', params, 'Data preparation has been initiated for the selected report(s)!', false, true)
      .subscribe(
        (data) => {
          this.autoRef = interval(60000).subscribe((val) => { this.getDataPreparationReportList() });
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  onRefresh() {
    this.loading = true;
    this.checkForActiveIngestions();
    this.getDataPreparationReportList();
  }

  SelectAllReports(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.resetNodesOnFilterChange(this.gridReportTreeList);
    
  }

  nodeSelectChange(event: any) {
    if (event.node.data.isSourceReport == true) {
      event.preventDefault();
    }
    else {
      var reportDependencies = [];
      this.selectedNodes.filter(node => node.parent).map(x => x.data.SourceDependencies).forEach(dList => {
        if (dList && dList.length > 0) {
          reportDependencies = reportDependencies.concat(dList.filter((item) => reportDependencies.indexOf(item) < 0));
        }
      });

      this.gridReportTreeList.forEach(suite => {
        suite.children.forEach(report => {
          if (reportDependencies.filter(x => x.ParentReportId == report.data.Id).length > 0) report.data.isSourceReport = true;
          else report.data.isSourceReport = false;
          if (report.data.isSourceReport == true && this.selectedNodes.filter(x => x.data.Id == report.data.Id).length == 0) {
            this.selectedNodes.push(report);
          }
        });
      });

      this.resetNodesOnFilterChange(this.gridReportTreeList);
    }
  }

  viewDependencies(dependencyListModal, rowData) {
    this.dependencyDirection = "ViewSource"
    this.reportSelectedForDependencyView = rowData.DisplayName;
    this.selectedReportSourceDependencies = rowData.SourceDependencies ? rowData.SourceDependencies : [];
    this.selectedReportDependents = rowData.Dependents ? rowData.Dependents : [];
    this.modalService.open(dependencyListModal, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
  }

  closeViewDependenciesModal() {
    this.modalService.dismissAll();
    this.reportSelectedForDependencyView = '';
    this.selectedReportSourceDependencies = [];
    this.selectedReportDependents = [];
  }

  ngOnDestroy() {
    if (this.autoRef && !this.autoRef.closed) {
      this.autoRef.unsubscribe();
    }
  }
}