/**
 * Environment variables
 */
export const environment = {
  env: window['env'] || 'develop',
  apiUrl: window['apiUrl'] || 'http://##Missing_URL##',
  azure_tenant_id: window['a_t_id'] || '##A_T_ID##',
  azure_application_id: window['a_a_id'] || '##A_A_ID##',
  api_manager_token: window['apim_token'] || '##API_TOKEN##',
  tokenHeader: window['tokenHeader'] || '##MISSING_TOKEN##',
  mock: window['mock'] || 'false',
  azsso: window['azsso'] || 'true'
};
