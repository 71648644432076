import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { SuiteService } from 'src/app/core/services/suite.service';
import { FilterUtils } from 'primeng/utils';
import { DataIngestionService } from 'src/app/core/services/dataIngestion.service';


@Component({
    selector: 'app-report-pack-filter',
    templateUrl: './report-pack-filter.component.html',
    styleUrls: ['./report-pack-filter.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class ReportPackFilterComponent implements OnInit, OnChanges, OnDestroy {

    @Input() selectedReportPacks: any;
    @Input() selectedRpIds: any;
    @Input() ingestionDataTypes: any = [];
    @Input() rpIdsToIgnore: any = [];
    @Input() updateServiceOnRpSelection: boolean = true;
    @Input() showOnlyValidReportPacks: boolean = true;
    @Input() reportPackList: any;
    @Input() selectionMode: 'single' | 'multiple' = 'single'
    @Output() selectedReportPacksChange = new EventEmitter();

    reportPacks: any;
    suite: string;
    selectedRpRows: any;
    loading = false;
    isReport = false;
    headerCols: any[];
    reportDateFilters: Date[];
    ingestedDateFilters: Date[];
    private dateTime = new Date();
    private yearRange = "2000:" + this.dateTime.getFullYear().toString();
    showDescription: boolean;
    rpForBannerDisplay: any;

    constructor(private modalService: NgbModal, private dashboardService: DashboardService,
        private dataIngestionService: DataIngestionService, private suiteService: SuiteService) {
    }

    ngOnInit() {
        this.headerCols = [
            { field: 'ReportPackID', header: 'Report Pack ID' },
            { field: 'Title', header: 'Report Pack Title' },
            { field: 'RP_Description', header: 'Description' },
            { field: 'ReportDate', header: 'Report Date' },
            { field: 'DefaultRPSequenceName', header: 'Default Report Pack Sequence' },
            { field: 'IngestedBy', header: 'Ingested By' },
            { field: 'IngestedDate', header: 'Ingested Date' }
        ];

        if (this.reportPackList) {
            this.prepareDisplayList();
        }
        else if (this.showOnlyValidReportPacks) {
            this.loading = true;
            this.dashboardService.getReportPacks()
                .then(() => {
                    this.reportPacks = JSON.parse(JSON.stringify(this.dashboardService.reportPackIdList));
                    this.reportPackList = this.dashboardService.reportPackIdList;
                    this.prepareDisplayList();
                    this.loading = false;
                }, () => {
                    this.loading = false;
                });
        }
        else {
            this.loading = true;
            this.dataIngestionService.getReportPacks()
                .then((data: any) => {
                    this.reportPacks = JSON.parse(JSON.stringify(data));
                    this.reportPackList = data
                    this.prepareDisplayList();
                    this.loading = false;
                }, () => {
                    this.loading = false;
                });
        }

        this.suite = this.suiteService.getCurrentSuite();
        if (this.suite != null) {
            this.dashboardService.getSuiteReports(this.suite);
        }

        FilterUtils['dateRangeFilter'] = (value, filter: [Date, Date]): boolean => {
            if (!filter || (!filter[0] && !filter[1]))
                return true;

            var val = new Date(value);
            var s = filter[0].getTime();
            var e;
            if (filter[1])
                e = filter[1].getTime() + (24 * 60 * 60 * 1000) - 1000;
            else e = s + (24 * 60 * 60 * 1000) - 1000;
            return val.getTime() >= s && val.getTime() <= e;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.hasOwnProperty('reportPackList') && this.reportPackList && this.reportPackList.length > 0)
            || (changes.hasOwnProperty('selectedRpIds') && this.selectedRpIds)
            || (changes.hasOwnProperty('rpIdsToIgnore') && this.rpIdsToIgnore)
            || (changes.hasOwnProperty('selectedReportPacks') && this.selectedReportPacks)
            || (changes.hasOwnProperty('ingestionDataTypes') && this.ingestionDataTypes && this.ingestionDataTypes.length > 0)) {
            this.reportPackList = JSON.parse(JSON.stringify(this.reportPacks));
            this.prepareDisplayList();
        }
    }

    ngOnDestroy(): void {
        this.modalService.dismissAll();
    }

    prepareDisplayList() {
        if (this.ingestionDataTypes && this.ingestionDataTypes.length > 0) {
            this.reportPackList = this.reportPackList.filter(x => x.DataTypes.filter(y => this.ingestionDataTypes.indexOf(y) > -1).length > 0);
        }
        if (this.rpIdsToIgnore && this.rpIdsToIgnore.length > 0) {
            this.reportPackList = this.reportPackList.filter(x => this.rpIdsToIgnore.indexOf(x.ReportPackID) === -1);
        }
        if (this.selectedReportPacks) {
            this.selectedRpRows = this.selectedReportPacks;
        }
        else if (this.updateServiceOnRpSelection && this.reportPackList.length > 0
            && this.dashboardService.reportPackID
            && this.reportPackList.filter(x => x.ReportPackID == this.dashboardService.reportPackID).length > 0) {
            this.selectedReportPacks = this.reportPackList.filter(x => x.ReportPackID == this.dashboardService.reportPackID);
            this.selectedRpRows = this.selectedReportPacks;
        }
        else if (this.selectedRpIds
            && this.reportPackList.length > 0 && this.reportPackList.filter(x => x.ReportPackID == this.selectedRpIds).length > 0) {
            this.selectedReportPacks = this.reportPackList.filter(x => this.selectedRpIds.indexOf(x.ReportPackID));
            this.selectedRpRows = this.selectedReportPacks;
        }
        if (this.selectionMode == 'single' && this.selectedReportPacks)
            this.rpForBannerDisplay = Array.isArray(this.selectedReportPacks) ? this.selectedReportPacks[0] : this.selectedReportPacks;
    }

    closeDateRangeFilter(dateComponent: any, filters: Date[]) {
        if (filters[1]) {
            dateComponent.hideOverlay();
        }
    }

    clearFilters() {
        this.ingestedDateFilters = null;
        this.reportDateFilters = null;
    }

    /**
      * Sets report pack id
      */
    async setReportPackId() {
        this.closeModal();
        this.selectedReportPacks = this.selectedRpRows;
        if (this.selectionMode == 'single') this.rpForBannerDisplay = this.selectedReportPacks;
        if (this.updateServiceOnRpSelection && this.selectionMode == 'single') {
            this.dashboardService.setReportPack(this.selectedRpRows);
        }
        this.selectedReportPacksChange.emit(this.selectedReportPacks);
    }

    open(reportPackFilter) {
        this.modalService.open(reportPackFilter, { size: 'xl', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
    }

    openModal(descriptionContent) {
        this.modalService.open(descriptionContent, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
    }

    closeModal() {
        this.clearFilters();
        this.modalService.dismissAll();
    }

    overflowToolCheck() {
        let ele = document.getElementById("overflowCheck");
        let osw = ele.offsetWidth;
        let sw = ele.scrollWidth;

        if (osw < sw) {
            this.showDescription = true;
        } else {
            this.showDescription = false;
        }
    }
}
