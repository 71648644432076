import { Injectable, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable()
export class EventEmitterService {

  // invokeReportGridDropwDownFunction = new EventEmitter();
  // subsVar: Subscription;

  currentPage = "P3";

  @Output() change: EventEmitter<any> = new EventEmitter();
/**
 * To access different pages.
 */
  onReportGridDropwDownSet(pageName: any) {
    this.change.emit(pageName);
  }
}
