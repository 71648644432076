<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
    <div class="col-12 col-lg-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
    </div>
</div>
<div class="row">
    <div class="col-4">
        <div class="row header" *ngIf="!viewMode">New Bundle </div>
        <div class="row header" *ngIf="viewMode">View Bundle </div>
    </div>
</div>
<div *ngIf="selectedBundle">
    <div class="row">
        <div class="col-4">
            <form #bundleForm="ngForm">
                <div class="row form-row">
                    <div class="col-4"><label>Bundle Name </label></div>
                    <div class="col-8" *ngIf="!viewMode">
                        <input type="text" required minlength="4" maxlength="50" [(ngModel)]="selectedBundle.bundleName"
                            class="bundle-form-control " #bundleName="ngModel" id="bundleName" name="bundleName"
                            pattern="^[A-Za-z0-9 _\-.]*$" trim-text />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <div *ngIf="bundleName.invalid && (bundleName.dirty || bundleName.touched)"
                            class="alert alert-danger">
                            <div *ngIf="bundleName.errors.required">
                                Bundle Name is required.
                            </div>
                            <div *ngIf="bundleName.errors.minlength">
                                Bundle Name must be at least 4 characters long.
                            </div>
                            <div *ngIf="bundleName.errors.maxlength">
                                Bundle Name should not exceed 50 characters.
                            </div>
                            <div *ngIf="bundleName.errors.pattern">
                                Bundle Name is not valid, as it contains special characters.
                            </div>
                        </div>
                    </div>
                    <div class="col-8 bundling-form-label" *ngIf="viewMode">
                        <label>{{selectedBundle.bundleName}}</label>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col-4"><label>Report Pack </label></div>
                    <div class="col-8" *ngIf="!viewMode">
                        <app-report-pack-filter [(selectedReportPacks)]="selectedReportPack" [showOnlyValidReportPacks]="true"
                            [selectedRpIds]="selectedBundle.reportPackId" [updateServiceOnRpSelection]="false"
                            (selectedReportPacksChange)="onReportPackSelection()">
                        </app-report-pack-filter>
                    </div>
                    <div class="col-8 bundling-form-label" *ngIf="viewMode">
                        <label>{{selectedBundle.reportPackName}}</label>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col-4"><label>Bundle Type </label></div>
                    <div class="col-8" *ngIf="!viewMode">
                        <app-bundle-type-filter [(selectedBundleType)]="selectedBundleType"
                            [selectedBundleTypeId]="selectedBundle.bundleTypeId"
                            (selectedBundleTypeChange)="onBundleTypeSelection()">
                        </app-bundle-type-filter>
                    </div>
                    <div class="col-8 bundling-form-label" *ngIf="viewMode">
                        <label>{{selectedBundle.bundleTypeName}}</label>
                    </div>
                </div>
                <div class="row form-row" *ngIf="!viewMode && actionList && actionList.length > 0">
                    <div class="col-4"><label>Actions </label></div>
                    <div class="col-8">
                        <ng-multiselect-dropdown [placeholder]="'Select Actions'" [settings]="dropDownSettings"
                            [data]="actionList" [(ngModel)]="selectedBundle.actions" name="selectedActions"
                            class="report-bundle-form-control-drop-down">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="row form-row" *ngIf="viewMode">
                    <div class="col-4"><label>Created By </label></div>
                    <div class="col-8 bundling-form-label">
                        <label>{{selectedBundle.createdBy}}</label>
                    </div>
                </div>
                <div class="row form-row" *ngIf="viewMode">
                    <div class="col-4"><label>Created Date </label></div>
                    <div class="col-8 bundling-form-label">
                        <label>{{selectedBundle.createdDate | date: 'dd/MM/yyyy'}}</label>
                    </div>
                </div>
                <div class="row form-row" *ngIf="viewMode && selectedBundle.generatedFileName">
                    <div class="col-4"><label>Generated Bundle </label></div>
                    <div class="col-8 bundling-form-label">
                        <div class="report-link" (click)="downloadGeneratedFile(selectedBundle.generatedFileName)">
                            Download</div>
                    </div>
                </div>
            </form>
        </div>

        <div class="col-8" *ngIf="viewMode && bundleTypeReports && bundleTypeReports.length > 0">
            <h6 *ngIf="showWarning" class="text-white-50  font-italic small-font mt-3">
                <i class="fas fa-exclamation-triangle text-yellow mr-3"></i> {{warningMessage}}
            </h6>
            <p-table #btReports class="table-irr report-bundling" [value]="bundleTypeReports" [columns]="headerCols"
                [scrollable]="true" [scrollHeight]="'calc(100vh - 400px)'">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}}
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns">
                            <input *ngIf="col.field != 'generatedFileName'" type="text" class="w-100"
                                placeholder="Filter Text"
                                [value]="btReports.filters && btReports.filters[col.field] ? btReports.filters[col.field].value: ''"
                                (input)="btReports.filter($event.target.value, col.field, 'contains')">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.suiteName}}</td>
                        <td>
                            {{rowData.reportName}}
                            <i *ngIf="!rowData.isLatestLoadData"
                                class="fas fa-exclamation-triangle text-yellow mr-3 float-right"
                                ngbTooltip="The report does not contain data of the latest data load for the report pack."
                                triggers="hover" tooltipClass="reporting-tooltip" container="body"
                                placement="right"></i>
                            <i *ngIf="rowData.isLatestLoadData && !rowData.hasLatestData"
                                class="fas fa-exclamation-triangle text-yellow mr-3 float-right"
                                ngbTooltip="The report does not contain latest data." triggers="hover"
                                tooltipClass="reporting-tooltip" container="body" placement="right"></i>
                        </td>
                        <td>{{rowData.reportDescription}}</td>
                        <td>{{rowData.reportingTypeName}} </td>
                        <td>
                            <div *ngIf="rowData.generatedFileName">
                                <div class="report-link" (click)="downloadGeneratedFile(rowData.generatedFileName)">
                                    Download</div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="col-8" *ngIf="!viewMode && bundleTypeReports && bundleTypeReports.length > 0">
            <h6 *ngIf="showWarning" class="text-white-50  font-italic small-font mt-3">
                <i class="fas fa-exclamation-triangle text-yellow mr-3"></i> {{warningMessage}}
            </h6>
            <p-table #btReports class="table-irr report-bundling" [value]="bundleTypeReports"
                [columns]="headerCols" [scrollable]="true" [scrollHeight]="'calc(100vh - 400px)'">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}}
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns">
                            <input type="text" class="w-100" placeholder="Filter Text"
                                [value]="btReports.filters && btReports.filters[col.field] ? btReports.filters[col.field].value: ''"
                                (input)="btReports.filter($event.target.value, col.field, 'contains')">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.suiteName}}</td>
                        <td>
                            {{rowData.reportName}}
                            <i *ngIf="selectedBundle.reportPackId && selectedBundle.reportPackId !=0 && !rowData.isDataPrepared"
                                class="fas fa-exclamation-triangle text-yellow mr-3 float-right"
                                ngbTooltip="Data has not been prepared for the latest load." triggers="hover"
                                tooltipClass="reporting-tooltip" container="body" placement="right"></i>
                        </td>
                        <td>{{rowData.reportDescription}}</td>
                        <td>{{rowData.reportingTypeName}} </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="row float-right m-0 d-flex flex-column justify-content-center d-md-block mt-3">
        <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button" (click)='goToReportBundlingPage()'>
            <span>Back to Report Bundling</span>
        </button>
        <button *ngIf="!viewMode" class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button"
            (click)='clearChanges()'>
            <span>Clear</span>
        </button>
        <button *ngIf="!viewMode" class="btn btn-modal-pos small-font hvr-shrink" type="button"
            (click)="submitReportBundle()" [disabled]="bundleForm && bundleForm.form ? !bundleForm.form.valid : false">
            <span>Start Bundling</span>
        </button>
    </div>

</div>