import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
 name: 'searchReportFilter'
})

@Injectable()
export class SearchReportFilterPipe implements PipeTransform {
 transform(items: any[], field: string, value: string): any[] {
   if (!items) return [];
   else if (value && value === '') return items;
   else if (!value) return items;
   return items.filter(it => it[field].toString().toLowerCase().indexOf(value.toLowerCase())>-1 )
 }
}