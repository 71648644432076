import { CommonService } from 'src/app/core/services/common.service';
import { Component, OnInit, ViewEncapsulation, Injectable } from '@angular/core';
import { Router } from "@angular/router";
/**
 * Component
 */

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.css'],
    encapsulation: ViewEncapsulation.None
})
@Injectable()
export class PagesComponent implements OnInit {
    sidebarToggleValue = false;
    /**
     * Creates an instance of pages component.
     */
    constructor(private sidebarShared: CommonService, public router: Router) {
    }

    /**
     * on init
     */
    ngOnInit() { }

    sidebarSizeToggle() {
        this.sidebarShared.setShowPanel(this.sidebarToggleValue = !this.sidebarToggleValue);
    }

    hasRoute(route: string) {
        return this.router.url.includes(route);
    }

}