<app-header></app-header>
<div class="row mx-0 h-100vh" *ngIf="!hasRoute('all-analytics-dashboard')">
    <div
        [ngClass]="sidebarToggleValue == true ? 'col-0 col-sm-0 col-md-0 col-lg-0 col-xl-2 p-0 sidebar-toggle' : 'col-0 col-sm-0 col-md-0 col-lg-0 col-xl-2 p-0 '">
        <app-sidebar></app-sidebar>
        <footer class="text-center d-none d-sm-none d-md-done d-lg-none d-xl-block">
            <img src="assets/images/IRR-Logo3.svg" class="irr-logo-footer" alt="IRR" (click)="sidebarSizeToggle()">
        </footer>
    </div>
    <div
        [ngClass]="sidebarToggleValue == true ? 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 main-content-toggle content-padding pb-3' : 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 content-padding pb-3'">
        <router-outlet></router-outlet>
    </div>
</div>

<div class="row mx-0 h-100vh" *ngIf="hasRoute('all-analytics-dashboard')">
    <div class="col-12 content-padding">
        <router-outlet></router-outlet>
    </div>
</div>
