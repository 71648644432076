import {NgModule} from '@angular/core';
import { PagesComponent } from './component/pages.component';
import { SharedModule } from '../shared/shared.module';
import { ReportingModule } from './reporting/reporting.module';
import { AnalyticsModule } from './analytics/analytics.module';
import { DataProcessingModule } from './data-processing/data-processing.module';
import { DashBoardModule } from './dashboard/dashboard.module';
import { AdminModule } from './admin/admin.module';
import { SupportModule } from './support/support.module';

@NgModule({
  imports: [
    SharedModule,
    ReportingModule,
    AnalyticsModule,
    DataProcessingModule,
    DashBoardModule,
    AdminModule,
    SupportModule
  ],
  declarations: [ PagesComponent ]
})
export class PagesModule {
}
