import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { ReportPackService } from 'src/app/core/services/reportPack.service';

@Component({
  selector: 'app-report-pack-form',
  templateUrl: './report-pack-form.component.html',
  styleUrls: ['./report-pack-form.component.css']
})
export class ReportPackFormComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  reportPack: any;
  editMode: boolean = false;

  dataLoadsList: any;
  dataLoadHeaders: any[] = [];
  createdDateFilters: Date[];
  private dateTime = new Date();
  private yearRange = "2000:" + this.dateTime.getFullYear().toString();

  reportPackList: any = [];

  accountingFrameworks: any = [];
  reportingLevels: any = [];

  reportPackSequenceHeaders: any;
  selectedReportPackSequences: any = [];
  rpsIdsToIgnore: any;

  constructor(private reportPackService: ReportPackService,
    private alertService: AlertService,
    private router: Router,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    if (!this.authService.isPrivacyNoticeAcknowledged) {
      this.router.navigate(['/privacy-notice']);
    }

    if (!this.reportPackService.selectedReportPack) {
      this.backToReportPackManagement();
    }
    else {
      this.editMode = this.reportPackService.editMode;
      this.prepareRPModel();

      if (this.editMode) {
        this.getReportPacks();
      }
    }

    this.dataLoadHeaders = [
      { field: 'filename', header: 'File Name' },
      { field: 'username', header: 'Ingested By' },
      { field: 'createddate', header: 'Ingested Date' },
      { header: 'Status' }
    ];

    this.accountingFrameworks = this.reportPackService.accountingFrameworks;
    this.reportingLevels = this.reportPackService.reportingLevels;


    this.reportPackSequenceHeaders = [
      { field: 'RPSequenceName', header: 'Report Pack Sequence Name' },
      { field: 'RPSequenceDescription', header: 'Report Pack Sequence Description' },
      { field: 'Order', header: 'Order' },
      { field: 'IsDefault', header: 'Is Default Sequence' },
      { field: 'Comments', header: 'Comments' },
    ];
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  prepareRPModel() {
    var rp = JSON.parse(JSON.stringify(this.reportPackService.selectedReportPack));
    this.reportPack = {
      reportPackId: rp.ReportPackID, title: rp.Title, description: rp.RP_Description, reportDate: rp.ReportDate, isActive: rp.isVisible,
      createdBy: rp.CreatedBy, createdDate: rp.CreatedDate, templateGroups: [], defaultRPSequenceId: rp.DefaultRPSequenceId,
      accountingFrameworkId: rp.AccountingFrameworkId, accountingFrameworkName: rp.AccountingFrameworkName, reportingLevelId: rp.ReportingLevelId,
      reportingLevelName: rp.ReportingLevelName, ReportPackSequenceMappings: rp.ReportPackSequenceMappings
    };

    this.markAsDefaultSequence(this.reportPack.ReportPackSequenceMappings.filter(x => x.RPSId == this.reportPack.defaultRPSequenceId)[0], true);

    if (this.reportPackService.templateGroups) {
      this.reportPackService.templateGroups.forEach(tg => {
        var group = {
          templateGroupId: tg.TemplateGroupId, templateGroupName: tg.TemplateGroup, dataLoads: rp.DataLoads.filter(x => x.templateGroupId == tg.TemplateGroupId)
        };
        this.reportPack.templateGroups.push(group);
      });
    }

    this.prepareRpSequencesToIgnore();
  }

  prepareRpSequencesToIgnore() {
    if (this.reportPack.ReportPackSequenceMappings && this.reportPack.ReportPackSequenceMappings.length > 0) {
      this.rpsIdsToIgnore = this.reportPack.ReportPackSequenceMappings.filter(x => !x.IsDeleted).map(x => x.RPSId);
    }
  }

  backToReportPackManagement() {
    this.router.navigate(['app/reportpack-management']);
  }

  clear() {
    this.prepareRPModel();
  }

  viewDataLoads(dataLoads: any, dataLoadModal: any) {
    this.dataLoadsList = dataLoads;
    this.modalService.open(dataLoadModal, { size: 'xl', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
  }

  closeDataLoadsModal() {
    this.modalService.dismissAll();
    this.dataLoadsList = [];
  }

  clearFilters() {
    this.createdDateFilters = null;
  }

  closeDateRangeFilter(dateComponent: any, filters: Date[]) {
    if (filters[1]) {
      dateComponent.hideOverlay();
    }
  }

  getReportPacks() {
    this.loading = true;
    this.reportPackService.getActiveReportPackList().then((reportPacks: any[]) => {
      this.reportPackList = JSON.parse(JSON.stringify(reportPacks));
      this.loading = false;
    }).catch(() => { this.loading = false; });
  }

  updateReportPack() {
    var updatedReportPack = {
      ReportPackID: this.reportPack.reportPackId, Title: this.reportPack.title, ReportDate: this.reportPack.reportDate,
      RP_Description: this.reportPack.description, isVisible: this.reportPack.isActive, accountingFrameworkId: this.reportPack.accountingFrameworkId,
      reportingLevelId: this.reportPack.reportingLevelId, DefaultRPSequenceId: this.reportPack.defaultRPSequenceId,
      ReportPackSequenceMappings: this.reportPack.ReportPackSequenceMappings
    }
    this.loading = true;
    this.reportPackService.updateReportPack(updatedReportPack).then(() => {
      this.backToReportPackManagement();
      this.alertService.success('Report pack updated successfully!');
    }).catch(() => { this.loading = false; });
  }

  onNewRPSequenceMappings() {
    var rpId = this.reportPack.reportPackId;
    if (this.selectedReportPackSequences && this.selectedReportPackSequences.length > 0) {
      this.selectedReportPackSequences.forEach(rps => {
        var mapping = {
          RPSId: rps.RPSId, ReportPackId: rpId, RPSequenceName: rps.RPSequenceName, RPSequenceDescription: rps.Description,
          IsNewMapping: true, Order: 0, Comment: null
        }
        this.reportPack.ReportPackSequenceMappings = [...this.reportPack.ReportPackSequenceMappings, mapping];
      });
      this.prepareRpSequencesToIgnore();
      this.selectedReportPackSequences = [];
    }
  }

  onMappingDelete(rowData) {
    if (this.editMode && !rowData.IsNewMapping) {
      rowData.IsDeleted = true;
      if (this.reportPack.defaultRPSequenceId == rowData.RPSId)
        this.markAsDefaultSequence(null, false);
    }
    else {
      this.reportPack.ReportPackSequenceMappings = this.reportPack.ReportPackSequenceMappings.filter(x => x.RPSId != rowData.RPSId);
    }
    this.prepareRpSequencesToIgnore();
  }

  onUndoMappingDelete(rowData) {
    rowData.IsDeleted = null;
    this.prepareRpSequencesToIgnore();
  }

  markAsDefaultSequence(rowData, isDefaut: boolean) {
    if (rowData && isDefaut) {
      this.reportPack.defaultRPSequenceId = rowData.RPSId;
    }
    else this.reportPack.defaultRPSequenceId = null;
    this.reportPack.ReportPackSequenceMappings.forEach(mapping => {
      mapping.IsDefault = mapping.RPSId == this.reportPack.defaultRPSequenceId;
    });
  }
}
