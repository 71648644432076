import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';
import { Router } from '@angular/router';
import { Observable, Subscription, forkJoin, interval } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../../environments/environment';
import { ApiService } from '../services/api.service';
import { map } from 'rxjs/operators';
import { RoleModel } from 'src/app/shared/models/role.model';
import { PermissionModel } from 'src/app/shared/models/permission.model';
import { RoleManagementService } from './role-management.service';
import { MsalService } from '@azure/msal-angular';
/**
 * Guard to authenticate with azure
 */
@Injectable()
export class CanActivateChildAuthGuard implements CanActivateChild {

    /**
     * Boolean observable to check access
     */
    private allow: Observable<boolean>;
    /**
     * Subscription to keep user from api call
     */
    private _userSub: Subscription;

    private result;

    private tokenExpiration: any;

    /**
     * Guard constructor
     */
    constructor(private router: Router,
        private apiService: ApiService,
        private authService: AuthenticationService,
        private msalService: MsalService,
        private roleService: RoleManagementService
    ) { }

    /**
     * Start authentication
     */
    canActivateChild(): Observable<boolean> {
        return environment.azsso === 'true' ? this.getUser() : this.mockGetUser();
    }

    /**
     * Mock method. TODO remove when CT provide credentials
     */
    mockGetUser() {
        if (this.authService.getLoggedInUser()) {
            this.allow = new Observable(observer => {
                observer.next(true);
                observer.complete();
            });
            return this.allow;
        }
        this.allow = new Observable(observer => {
            this.userLoginAPI(observer, false);

            // Retreive a mock fixture user
            // this._userSub = this.http.get(Mock['users/admin/']).subscribe(mockUser => {
            //   this.authService.setUserLoggedIn(mockUser);
            //   this.authService.addExpirationDate(new Date());
            //   if (!this.roleService.getRoles().length && !this.roleService.getSuitePermissions()) {
            //     this.setUpRolesPermissions(observer);
            //   } else {
            //     observer.next(true);
            //     observer.complete();
            //   }
            //   // observer.next(true);
            //   // observer.complete();
            // });
        });
        return this.allow;
    }

    setUpRolesPermissions(observer) {
        forkJoin([this.apiService.get('roles/', ''),
        this.apiService.get('permissions/', '')])
            .pipe(
                map(([roles, permissions]) => {
                    return { roles, permissions };
                })
            ).subscribe(res => {
                this.roleService.setRoles(<RoleModel[]>res.roles);
                this.roleService.setPermissions(<PermissionModel[]>res.permissions);
                observer.next(true);
                observer.complete();
            });
    }

    /**
     * Get user if authenticated
     */
    getUser() {
        const token = this.authService.getTokenHeader();
        if (token === undefined || token === '' || !token) {
            this.allow = null;
        }
        //&& this.authService.getLoggedInUser() - // Task - 958173 - remvoed from the condition
        if (this.allow) {
            this.allow = new Observable(observer => {
                // Task - 958173
                this.fetchLoginToken(observer,false);
                observer.next(true);
                observer.complete();
            });
            return this.allow;
        }

        this.allow = new Observable(observer => {
            if (this._userSub) {
                this._userSub.unsubscribe();
            }
            if (this.msalService.instance.getAllAccounts().length > 0) {
                this.fetchLoginToken(observer, true);
            } else {
                this.startLoginAzure(observer);
            }

        });
        return this.allow;
    }

    /**
     * Acquire token from azure single sign on
     */
     public startLoginAzure(observer) {
         if (this.msalService.instance != null && this.msalService.instance["browserStorage"] != null)  
            this.msalService.instance["browserStorage"].cleanRequestByInteractionType()
        this.msalService.loginPopup()
            .subscribe(
                result => {
                    this.authService.setTokenHeader(result.idToken);
                    this.tokenExpiration = result.expiresOn;
                    this.userLoginAPI(observer, true);
                },
                err => {
                    //Task - 958173 - removed (usually refresh token takes 6 sec to refresh, 
                    //so what happen when call "acquireToken" it automaticaly move to error call and it redirect to server error page)
                    //this.deniedAccess(observer, err);
                }
            );
    }

    /**
     * API login
     */
     private userLoginAPI(observer, saveMsal) {
        this._userSub = this.apiService.get('login/', '')
            .subscribe(user => {                
                interval(15000).subscribe(() =>{
                    if(this.authService.checkTokenValidity() === true){
                        this.getMaintenanceWindow()
                    }
                });             
                this.authService.setUserLoggedIn(user);
                if (saveMsal) {
                    this.authService.addExpirationDate(this.tokenExpiration);
                } else {
                    this.authService.addExpirationDate(new Date());
                }
                if (!this.roleService.getRoles().length && !this.roleService.getSuitePermissions()) {
                    this.setUpRolesPermissions(observer);
                } else {
                    observer.next(true);
                    observer.complete();
                }
            }, err => {
                this.authService.loggedInUser = err.error.userEmailId;
                //Task - 958173 - removed (usually refresh token takes 6 sec to refresh, 
                //so what happen when call "acquireToken" it automaticaly move to error call and it redirect to server error page)
                //console.log('Access denied ==> ', err);
                //this.deniedAccess(observer, err);
            });
    }

    /**
    * Get Maintenance Window
    */
    private getMaintenanceWindow() {
        this.apiService.get('IsClientUnderMaintenance/', '')
            .subscribe(response => {
                this.result = response;
                if (this.result) {
                    if ((this.router.url.endsWith('/')) || (this.router.url.endsWith('under-maintenance'))) {
                        this.authService.redirectToUnderMaintenance();
                    } else {
                        alert("The application is going under maintenance.");
                        this.authService.redirectToUnderMaintenance();
                    }
                } else {
                    if (this.router.url.endsWith('under-maintenance')) {
                        this.router.navigate(['/home']);
                    }
                }
            });
    }
    /**
     * Authentication denied
     */
    private deniedAccess(observer: any, error: any) {
        this.allow = null;
        const errorMessage = error && error.error && error.error.error ? error.error.error : 'Something went wrong';
        if (errorMessage === 'Authentication Token Expired') {
            this.router.navigate(['/access-denied']);
        } else if (error.status && error.status === 401) {
            this.authService.accessNotAllowed();
        } else {
            this.authService.serverError();
        }
        observer.next(false);
        observer.complete();
    }
    /**
     * Acquire token from azure single sign on
     */
     public fetchLoginToken(observer: any, isInitialLogin: boolean) {
        const accessTokenRequest = {
            scopes: ["user.read"],
            account: this.msalService.instance.getAllAccounts()[0]
        };
        this.msalService.acquireTokenSilent(accessTokenRequest)
            .subscribe(
                result => {
                    this.authService.setTokenHeader(result.idToken);
                    this.tokenExpiration = result.expiresOn;
                    if (isInitialLogin) this.userLoginAPI(observer, true);
                    else this.authService.addExpirationDate(this.tokenExpiration);
                },
                err => {
                }
            );
    }
}
