import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { AnalyticService } from 'src/app/core/services/analytics.service';
import * as pbi from 'powerbi-client';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'all-analytics-dashboard',
  templateUrl: './all-analytics-dashboard.component.html',
  styleUrls: ['./all-analytics-dashboard.component.css']
})
export class PowerBiReportPanelComponent implements OnInit {
  @ViewChild('reportContainer', { static: false }) reportContainer: ElementRef;
    
  public loading = true;
  loginUser: any;
  analyticsPowerBiReports:any[] = [];
  screenHeight:any;
  analyticReportName:string;
  isAccessDenied: any;


  constructor(private analyticService: AnalyticService, private titleService: Title,
    private authService: AuthenticationService,
    private router:ActivatedRoute) { }

   ngOnInit() {
    this.screenHeight = (window.screen.height);
    this.router.queryParamMap.subscribe(queryParams => {
      this.analyticReportName = queryParams.get("reportname");
    });
    this.LoadPowerBiReports(this.analyticReportName); 
  }

  LoadPowerBiReports(analyticRepName){   
    this.loginUser = this.authService.getLoggedInUser();
    this.loading = true;
    this.analyticService.getPowerBIToken(analyticRepName).then((result: any) => {
        if (result.embedToken) {
          this.isAccessDenied = false;
          this.titleService.setTitle('IRR: ' + result.reportDisplayName);
          this.showReport(result.embedToken, result.reportUrl, result.reportId);
          this.loading = false;
        }
        else {
          this.loading = false
          this.isAccessDenied = true;
        }
      },() => {
        this.loading = false;
    });    
  }

  showReport(embedToken, reportUrl, reportId) {
    var userId = this.loginUser.userid;
    var models = pbi.models;
    const basicFilter: pbi.models.IBasicFilter = {
      $schema: "http://powerbi.com/product/schema",
      target: {
        table: "SecurityMgmt",
        column: "userId"
      },
      operator: "In",
      values: [userId],
      filterType: 1 
    };

    // Embed URL
    let config: pbi.IEmbedConfiguration = {
      type: 'report',
      tokenType: models.TokenType.Embed,
      accessToken: embedToken,
      embedUrl: reportUrl,
      id: reportId,
      settings:{
        filterPaneEnabled: false,navContentPaneEnabled: false
      },
    filters: [basicFilter]
    };
    let reportContainer = this.reportContainer.nativeElement;
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    let report = powerbi.embed(reportContainer, config);
    report.off("loaded");
    report.on("loaded", () => {
      console.log("Loaded");
    });
    report.on("error", (event) => {
      console.log(event.detail);
    });
  }

}
