import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { AnalyticService } from 'src/app/core/services/analytics.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-pnp-report',
    templateUrl: './pnp-report.component.html',
    styleUrls: ['./pnp-report.component.css']
})
export class PnpReportComponent implements OnInit, OnDestroy {
    @Input() reportId: number;
    @Input() period1Id: number;
    @Input() period2Id: number;
    @Input() rowFilter: any;
    @Input() columnFilter: any;
    @Output() previousTab = new EventEmitter();
    gridColumnGroups: any = [];
    gridColumns: any = [];
    gridData: any;
    blockedCells: any;
    summary: any;
    dataSource: any;
    localizationobj: any = {};
    loading: boolean = false;
    excelReportFileName: string = '';
  
    constructor(private analyticsService: AnalyticService, private modalService: NgbModal) { }

    ngOnInit() {
        this.getPnpReport();
    }

    getPnpReport() {
        this.loading = true;
        var rowFilterStr = this.rowFilter.join();
        this.analyticsService.getPnpReport(this.reportId, this.period1Id, this.period2Id, rowFilterStr, this.columnFilter).then((data: any) => {
            this.summary = {
                suiteName: data.Suite, suiteDesc: data.SuiteDesc, reportName: data.Report, reportDesc: data.ReportDesc,
                period1Title: data.Period1, period1Date: data.Period1ReportDate, period2Title: data.Period2, period2Date: data.Period2ReportDate
            };
            this.excelReportFileName =  data.Report + "_Period-On-Period-Report.xlsx"
            this.gridColumnGroups = data.GridColumnGroups;
            this.gridColumns = data.GridColumns;
            this.gridData = data.GridData;
            this.blockedCells = data.BlockedCells;
            this.loading = false;
        }).catch(() => { this.loading = false; });
    }

    goToPreviousTab() {
        this.previousTab.emit(true);
    }

    open(summaryPNP) {
        this.modalService.open(summaryPNP, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
    }

    ngOnDestroy(): void {
        this.modalService.dismissAll();
    }
}
