import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterUtils } from 'primeng/utils';
import { BundleTypeManagementService } from 'src/app/core/services/bundleTypeManagementService';


@Component({
  selector: 'app-bundle-type-filter',
  templateUrl: './bundle-type-filter.component.html',
  styleUrls: ['./bundle-type-filter.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class BundleTypeFilterComponent implements OnInit, OnChanges, OnDestroy {

  @Input() selectedBundleType: any;
  @Input() selectedBundleTypeId: any = 0;
  @Output() selectedBundleTypeChange = new EventEmitter();

  loading = false;
  bundleTypeList: any;
  headerCols: any[];
  selectedRow: any;
  createdDateFilters: Date[];
  private dateTime = new Date();
  private yearRange = "2000:" + this.dateTime.getFullYear().toString();
  showDescription: boolean;

  constructor(private modalService: NgbModal, private bundleTypeService: BundleTypeManagementService) {
  }

  ngOnInit() {
    this.headerCols = [
      { field: 'bundleTypeName', header: 'Bundle Type' },
      { field: 'bundleTypeDescription', header: 'Description' },
      { field: 'createdBy', header: 'Created By' },
      { field: 'createdDate', header: 'Created Date' },
    ];

    if (this.bundleTypeList) {
      this.prepareDisplayList();
    }
    else {
      this.loading = true;
      this.bundleTypeService.getActiveBundleTypesList()
        .then((data: any) => {
          this.bundleTypeList = data
          this.prepareDisplayList();
          this.loading = false;
        }, () => {
          this.loading = false;
        });
    }

    FilterUtils['dateRangeFilter'] = (value, filter: [Date, Date]): boolean => {
      if (!filter || (!filter[0] && !filter[1]))
        return true;

      var val = new Date(value);
      var s = filter[0].getTime();
      var e;
      if (filter[1])
        e = filter[1].getTime() + (24 * 60 * 60 * 1000) - 1000;
      else e = s + (24 * 60 * 60 * 1000) - 1000;
      return val.getTime() >= s && val.getTime() <= e;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.hasOwnProperty('bundleTypeList') && this.bundleTypeList && this.bundleTypeList.length > 0) || (changes.hasOwnProperty('selectedBundleTypeId') && this.selectedBundleTypeId)) {
      this.prepareDisplayList();
    }
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  prepareDisplayList() {
    if (!this.selectedBundleType && this.selectedBundleTypeId && this.bundleTypeList.length > 0 && this.bundleTypeList.filter(x => x.bundleTypeId == this.selectedBundleTypeId).length > 0) {
      this.selectedBundleType = this.bundleTypeList.filter(x => x.bundleTypeId == this.selectedBundleTypeId)[0];
      this.selectedRow = this.selectedBundleType;
    }
  }

  closeDateRangeFilter(dateComponent: any, filters: Date[]) {
    if (filters[1]) {
      dateComponent.hideOverlay();
    }
  }

  clearFilters() {
    this.createdDateFilters = null;
  }

  async setBundleTypeId() {
    this.closeModal();
    this.selectedBundleType = this.selectedRow;
    this.selectedBundleTypeChange.emit(this.selectedBundleType);
  }

  open(bundleTypeFilter) {
    this.modalService.open(bundleTypeFilter, { size: 'xl', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
  }

  openModal(descriptionContent) {
    this.modalService.open(descriptionContent, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
  }

  closeModal() {
    this.clearFilters();
    this.modalService.dismissAll();
  }

  overflowToolCheck() {
    let ele = document.getElementById("overflowCheck");
    let osw = ele.offsetWidth;
    let sw = ele.scrollWidth;

    if (osw < sw) {
      this.showDescription = true;
    } else {
      this.showDescription = false;
    }
  }
}
