import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyNoticeComponent } from './privacy-notice/component/privacy-notice.component';
import { LandingComponent } from './landing/component/landing.component';
import { CanActivateChildAuthGuard } from './core/security/canActivateChildAuthGuard';
import { LandingPageComponent } from './landing/landing-page/landing-page.component';
import { PagesComponent } from './pages/component/pages.component';
import { TaskBoardComponent } from './pages/dashboard/taskboard/taskboard.component';
import { ReportingComponent } from './pages/reporting/component/reporting.component';
import { SuiteDashboardComponent } from './pages/reporting/suite-dashboard/suite-dashboard.component';
import { CanActivateChildRoleGuard } from './core/security/canActivateChildRoleGuard';
import { ReportDashboardComponent } from './pages/reporting/report-dashboard/report-dashboard.component';
import { SubReportComponent } from './pages/reporting/sub-report/sub-report.component';
import { ReportGridComponent } from './pages/reporting/report-grid/report-grid.component';
import { ReportPackDashboardComponent } from './pages/reporting/report-pack-dashboard/reportpack-dashboard.component';
import { PNPAnalysisComponent } from './pages/analytics/pnp-analysis/component/pnp-analysis.component';
import { IngestionPanelComponent } from './pages/data-processing/ingestion-panel/ingestion-panel.component';
import { DataPreparationComponent } from './pages/data-processing/data-preparation-panel/datapreparation-panel.component';
import { RoleManagementComponent } from './pages/admin/role-management/role-management.component';
import { UserManagementComponent } from './pages/admin/user-management/user-management.component';
import { PermissionManagementComponent } from './pages/admin/permission-management/permission-management.component';
import { ReportPackComponent } from './pages/admin/report-pack-panel/reportpack-panel.component';
import { ReportScheduleManagementComponent } from './pages/admin/report-schedule-management/report-schedule-management.component';
import { AccessDeniedComponent } from './placeholder/access-denied/access-denied.component';
import { LogoutComponent } from './placeholder/logout/logout.component';
import { ServerErrorComponent } from './placeholder/server-error/server-error.component';
import { COREPAnalyticsDashboardComponent } from './pages/analytics/corep_analytics_dashboard/corep_analytics_dashboard.component';
import { IngestionFormComponent } from './pages/data-processing/ingestion-form/ingestion-form.component';
import { IngestionFileEditComponent } from './pages/data-processing/ingestion-file-edit/ingestion-file-edit.component';
import { PowerBiReportsComponent } from './pages/analytics/all-analytics/all-analytics.component';
import { PowerBiReportPanelComponent } from './pages/analytics/all-analytics-dashboard/all-analytics-dashboard.component';
import { NewIngestionFormComponent } from './pages/data-processing/new-ingestion-form/new-ingestion-form.component';
import { ClientManagementComponent } from './pages/support/client-management/client-management.component';
import { ReportPackFormComponent } from './pages/admin/report-pack-form/report-pack-form.component';
import { UnderMaintenanceComponent } from './placeholder/under-maintenance/under-maintenance.component';
import { BundleTypeManagementComponent } from './pages/admin/bundle-type-management/bundle-type-management.component';
import { ReportBundlingComponent } from './pages/data-processing/report-bundling/report-bundling.component';
import { BundleTypeFormComponent } from './pages/admin/bundle-type-form/bundle-type-form.component';
import { ReportBundlingFormComponent } from './pages/data-processing/report-bundling-form/report-bundling-form.component';
import { ReportDownloaderComponent } from './pages/support/report-downloader/report-downloader.component';
import { QueryEngineComponent } from './pages/support/query-engine/query-engine.component';
import { ReportPackSequenceManagementComponent } from './pages/admin/report-pack-sequence-management/report-pack-sequence-management.component';
import { ReportPackSequenceFormComponent } from './pages/admin/report-pack-sequence-form/report-pack-sequence-form.component';
import { ApplicationConfigurationComponent } from './pages/admin/application-configuration/application-configuration.component';
import { RefDataComponent } from './pages/support/ref-data/ref-data.component';

const routes: Routes = [
    {
        path: '',
        component: LandingComponent,
        canActivateChild: [CanActivateChildAuthGuard],
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', component: LandingPageComponent, data: { title: 'IRR: Home' } },
            { path: 'privacy-notice', component: PrivacyNoticeComponent, data: { title: 'IRR: Privacy Notice' } },
            {
                path: 'app',
                component: PagesComponent,
                canActivateChild: [CanActivateChildAuthGuard],
                children: [
                    { path: '', redirectTo: 'task-board', pathMatch: 'full' },
                    {
                        path: 'task-board', component: TaskBoardComponent, data: {
                            title: 'IRR: Task Board',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Task Board',
                                    url: ''
                                }
                            ]
                        },
                    },
                    {
                        path: 'suite',
                        component: ReportingComponent,
                        data: {
                            title: 'IRR: Suites',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Suites',
                                    url: ''
                                }
                            ]
                        },
                        children: [
                            { path: '', component: SuiteDashboardComponent },
                            {
                                path: ':suitename',
                                canActivateChild: [CanActivateChildRoleGuard],
                                data: {
                                    breadcrumb: [
                                        {
                                            label: 'Home',
                                            url: '/home'
                                        },
                                        {
                                            label: 'Suites',
                                            url: '/app/suite'
                                        },
                                        {
                                            label: '{{suitename}}',
                                            url: ''
                                        }
                                    ]
                                },
                                children: [
                                    { path: '', component: ReportDashboardComponent },
                                    {
                                        path: ':reportdisplayname',
                                        canActivateChild: [CanActivateChildRoleGuard],
                                        data: {
                                            breadcrumb: [
                                                {
                                                    label: 'Home',
                                                    url: '/home'
                                                },
                                                {
                                                    label: 'Suites',
                                                    url: '/app/suite'
                                                },
                                                {
                                                    label: '{{suitename}}',
                                                    url: '/app/suite/:suitename'
                                                },
                                                {
                                                    label: '{{reportdisplayname}}',
                                                    url: ''
                                                }
                                            ]
                                        },
                                        children: [
                                            { path: 'subreports', component: SubReportComponent },
                                            { path: '', component: ReportGridComponent },
                                            {
                                                path: ':subreportdisplayname', component: ReportGridComponent, data: {
                                                    breadcrumb: [
                                                        {
                                                            label: 'Home',
                                                            url: '/home'
                                                        },
                                                        {
                                                            label: 'Suites',
                                                            url: '/app/suite'
                                                        },
                                                        {
                                                            label: '{{suitename}}',
                                                            url: '/app/suite/:suitename'
                                                        },
                                                        {
                                                            label: '{{reportdisplayname}}',
                                                            url: '/app/suite/:suitename/:reportdisplayname/subreports'
                                                        },
                                                        {
                                                            label: '{{subreportdisplayname}}',
                                                            url: ''
                                                        }
                                                    ]
                                                },
                                            },
                                        ]
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        path: 'reportpack-dashboard',
                        canActivateChild: [CanActivateChildRoleGuard],
                        data: {
                            title: 'IRR: Report Pack Dashboard',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Report Pack Dashboard',
                                    url: ''
                                }
                            ]
                        },
                        children: [
                            { path: '', component: ReportPackDashboardComponent }

                        ]
                    },
                    {
                        path: 'all-analytics',
                        canActivateChild: [CanActivateChildRoleGuard],
                        data: {
                            title: 'IRR: All Analytics',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'All Analytics',
                                    url: ''
                                }
                            ]
                        },
                        children: [
                            { path: '', component: PowerBiReportsComponent },
                            { path: 'all-analytics-dashboard', component: PowerBiReportPanelComponent },
                            {
                                path: 'period-on-period-analysis',
                                canActivateChild: [CanActivateChildRoleGuard], data: {
                                    title: 'IRR: Period-on-Period Analysis',
                                    breadcrumb: [
                                        {
                                            label: 'Home',
                                            url: '/home'
                                        },
                                        {
                                            label: 'All Analytics',
                                            url: '/app/all-analytics'
                                        },
                                        {
                                            label: 'Period-on-Period Analysis',
                                            url: ''
                                        }
                                    ]
                                },
                                children: [
                                    { path: '', component: PNPAnalysisComponent }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'analytics-report-dashboard/:reportname',
                        canActivateChild: [CanActivateChildRoleGuard],
                        data: {
                            title: 'IRR: Analytics Report Dashboard',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'COREP Analytics Dashboard',
                                    url: ''
                                }
                            ]
                        },
                        children: [
                            { path: '', component: COREPAnalyticsDashboardComponent }
                        ]
                    },
                    {
                        path: 'data-ingestion',
                        canActivateChild: [CanActivateChildRoleGuard],
                        data: {
                            title: 'IRR: Data Load',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Data Load',
                                    url: ''
                                }
                            ]
                        },
                        children: [
                            { path: '', component: IngestionPanelComponent },
                            {
                                path: 'new-ingestion',
                                component: NewIngestionFormComponent,
                                data: {
                                    title: 'New Ingestion',
                                    breadcrumb: [
                                        {
                                            label: 'Home',
                                            url: '/home'
                                        },
                                        {
                                            label: 'Data Load',
                                            url: '/app/data-ingestion'
                                        },
                                        {
                                            label: 'New Ingestion',
                                            url: ''
                                        },
                                    ]
                                }
                            },
                            {
                                path: 'ingestion-form',
                                component: IngestionFormComponent,
                                data: {
                                    title: 'Ingestion Form',
                                    breadcrumb: [
                                        {
                                            label: 'Home',
                                            url: '/home'
                                        },
                                        {
                                            label: 'Data Load',
                                            url: '/app/data-ingestion'
                                        },
                                        {
                                            label: 'Ingestion Form',
                                            url: ''
                                        },
                                    ]
                                }
                            },
                            {
                                path: 'ingestion-file',
                                component: IngestionFileEditComponent,
                                data: {
                                    title: 'Ingestion File Edit',
                                    breadcrumb: [
                                        {
                                            label: 'Home',
                                            url: '/home'
                                        },
                                        {
                                            label: 'Data Load',
                                            url: '/app/data-ingestion'
                                        },
                                        {
                                            label: 'Ingestion File Edit',
                                            url: ''
                                        },
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        path: 'bulk-report-run',
                        data: {
                            title: 'IRR: Bulk Report Run',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Bulk Report Run',
                                    url: ''
                                }
                            ]
                        },
                        canActivateChild: [CanActivateChildRoleGuard],
                        children: [
                            { path: '', component: DataPreparationComponent }
                        ]
                    },
                    {
                        path: 'role-management',
                        data: {
                            title: 'IRR: Role Management',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Role Management',
                                    url: ''
                                }
                            ]
                        },
                        canActivateChild: [CanActivateChildRoleGuard],
                        children: [
                            { path: '', component: RoleManagementComponent }
                        ]
                    },
                    {
                        path: 'user-management',
                        data: {
                            title: 'IRR: User Management',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'User Management',
                                    url: ''
                                }
                            ]
                        },
                        canActivateChild: [CanActivateChildRoleGuard],
                        children: [
                            { path: '', component: UserManagementComponent }
                        ]
                    },
                    {
                        path: 'application-configuration',
                        data: {
                            title: 'IRR: Application Configuration',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Application Configuration',
                                    url: ''
                                }
                            ]
                        },
                        canActivateChild: [CanActivateChildRoleGuard],
                        children: [
                            { path: '', component: ApplicationConfigurationComponent }
                        ]
                    },
                    {
                        path: 'permission-management',
                        data: {
                            title: 'IRR: Approval Level Management',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Approval Level Management',
                                    url: ''
                                }
                            ]
                        },
                        canActivateChild: [CanActivateChildRoleGuard],
                        children: [
                            { path: '', component: PermissionManagementComponent }
                        ]
                    },
                    {
                        path: 'reportpack-management',
                        data: {
                            title: 'IRR: Report Pack Administration',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Report Pack Administration',
                                    url: ''
                                }
                            ]
                        },
                        canActivateChild: [CanActivateChildRoleGuard],
                        children: [
                            { path: '', component: ReportPackComponent },
                            {
                                path: 'report-pack-form', component: ReportPackFormComponent, data: {
                                    breadcrumb: [
                                        {
                                            label: 'Home',
                                            url: '/home'
                                        },
                                        {
                                            label: 'Report Pack Administration',
                                            url: '/app/reportpack-management'
                                        },
                                        {
                                            label: 'Report Pack Form',
                                            url: ''
                                        }
                                    ]
                                },
                            }
                        ]
                    },
                    {
                        path: 'report-schedule-management',
                        data: {
                            title: 'IRR: Report Schedule Management',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Report Schedule Management',
                                    url: ''
                                }
                            ]
                        },
                        canActivateChild: [CanActivateChildRoleGuard],
                        children: [
                            { path: '', component: ReportScheduleManagementComponent }
                        ]
                    },
                    {
                        path: 'client-management',
                        data: {
                            title: 'IRR: Client Management',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Client Management',
                                    url: ''
                                }
                            ]
                        },
                        canActivateChild: [CanActivateChildRoleGuard],
                        children: [
                            { path: '', component: ClientManagementComponent }
                        ]
                    },
                    {
                        path: 'report-downloader',
                        data: {
                            title: 'IRR: Report Downloader',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Report Downloader',
                                    url: ''
                                }
                            ]
                        },
                        canActivateChild: [CanActivateChildRoleGuard],
                        children: [
                            { path: '', component: ReportDownloaderComponent }
                        ]
                    },
                    {
                        path: 'ref-data',
                        data: {
                            title: 'IRR: Ref Data',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Ref Data',
                                    url: ''
                                }
                            ]
                        },
                        canActivateChild: [CanActivateChildRoleGuard],
                        children: [
                            { path: '', component: RefDataComponent }
                        ]
                    },
                    {
                        path: 'query-engine',
                        data: {
                            title: 'IRR: Query Engine',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Query Engine',
                                    url: ''
                                }
                            ]
                        },
                        canActivateChild: [CanActivateChildRoleGuard],
                        children: [
                            { path: '', component: QueryEngineComponent }
                        ]
                    },
                    {
                        path: 'bundle-type-management',
                        data: {
                            title: 'IRR: Bundle Type Management',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Bundle Type Management',
                                    url: ''
                                }
                            ]
                        },
                        canActivateChild: [CanActivateChildRoleGuard],
                        children: [
                            { path: '', component: BundleTypeManagementComponent },
                            {
                                path: 'add-bundle-type',
                                component: BundleTypeFormComponent,
                                data: {
                                    title: 'New Bundle Type',
                                    breadcrumb: [
                                        {
                                            label: 'Home',
                                            url: '/home'
                                        },
                                        {
                                            label: 'Bundle Type Management',
                                            url: '/app/bundle-type-management'
                                        },
                                        {
                                            label: 'Add Bundle Type',
                                            url: ''
                                        }
                                    ]
                                }
                            },
                            {
                                path: 'edit-bundle-type',
                                component: BundleTypeFormComponent,
                                data: {
                                    title: 'Edit Bundle Type',
                                    breadcrumb: [
                                        {
                                            label: 'Home',
                                            url: '/home'
                                        },
                                        {
                                            label: 'Bundle Type Management',
                                            url: '/app/bundle-type-management'
                                        },
                                        {
                                            label: 'Edit Bundle Type',
                                            url: ''
                                        }
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        path: 'report-pack-sequence-management',
                        data: {
                            title: 'IRR: Report Pack Sequence Management',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Report Pack Sequence Management',
                                    url: ''
                                }
                            ]
                        },
                        canActivateChild: [CanActivateChildRoleGuard],
                        children: [
                            { path: '', component: ReportPackSequenceManagementComponent },
                            {
                                path: 'add-report-pack-sequence',
                                component: ReportPackSequenceFormComponent,
                                data: {
                                    title: 'New Report Pack Sequence',
                                    breadcrumb: [
                                        {
                                            label: 'Home',
                                            url: '/home'
                                        },
                                        {
                                            label: 'Report Pack Sequence Management',
                                            url: '/app/report-pack-sequence-management'
                                        },
                                        {
                                            label: 'Add Report Pack Sequence',
                                            url: ''
                                        }
                                    ]
                                }
                            },
                            {
                                path: 'edit-report-pack-sequence',
                                component: ReportPackSequenceFormComponent,
                                data: {
                                    title: 'Edit Report Pack Sequence',
                                    breadcrumb: [
                                        {
                                            label: 'Home',
                                            url: '/home'
                                        },
                                        {
                                            label: 'Report Pack Sequence Management',
                                            url: '/app/report-pack-sequence-management'
                                        },
                                        {
                                            label: 'Report Pack Sequence',
                                            url: ''
                                        }
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        path: 'report-bundling',
                        data: {
                            title: 'IRR: Report Bundling',
                            breadcrumb: [
                                {
                                    label: 'Home',
                                    url: '/home'
                                },
                                {
                                    label: 'Report Bundling',
                                    url: ''
                                }
                            ]
                        },
                        canActivateChild: [CanActivateChildRoleGuard],
                        children: [
                            { path: '', component: ReportBundlingComponent },
                            {
                                path: 'new-report-bundle',
                                component: ReportBundlingFormComponent,
                                data: {
                                    title: 'New Report Bundle',
                                    breadcrumb: [
                                        {
                                            label: 'Home',
                                            url: '/home'
                                        },
                                        {
                                            label: 'Report Bundling',
                                            url: '/app/report-bundling'
                                        },
                                        {
                                            label: 'New Report Bundle',
                                            url: ''
                                        }
                                    ]
                                }
                            },
                            {
                                path: 'view-report-bundle',
                                component: ReportBundlingFormComponent,
                                data: {
                                    title: 'View Report Bundle',
                                    breadcrumb: [
                                        {
                                            label: 'Home',
                                            url: '/home'
                                        },
                                        {
                                            label: 'Report Bundling',
                                            url: '/app/report-bundling'
                                        },
                                        {
                                            label: 'View Report Bundle',
                                            url: ''
                                        }
                                    ]
                                }
                            }
                        ]
                    },
                ]
            },
        ]
    },
    { path: 'access-denied', component: AccessDeniedComponent, data: { title: 'IRR: Access Denied' } },
    { path: 'server-error', component: ServerErrorComponent, data: { title: 'IRR: Server Error' } },
    { path: 'logout', component: LogoutComponent, data: { title: 'IRR: Logout' } },
    { path: 'under-maintenance', component: UnderMaintenanceComponent, data: { title: 'IRR: Under Maintenance' } },
    { path: '**', redirectTo: '' }

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }