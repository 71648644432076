import { Component, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PrivacyNoticeService } from 'src/app/core/services/privacyNotice.service';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RoleManagementService } from 'src/app/core/security/role-management.service';

@Component({
    selector: 'app-privacy-notice',
    templateUrl: './privacy-notice.component.html',
    styleUrls: ['./privacy-notice.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PrivacyNoticeComponent {
    privacyNoticeId: number;
    htmlData: any;
    loading = false;
    user: any;
    subscription: Subscription;
    currentDate = new Date().getDate().toString() + '/' + (new Date().getMonth() + 1).toString() + '/' + new Date().getFullYear().toString();

    constructor(private privacyNoticeService: PrivacyNoticeService,
        private sanitizer: DomSanitizer,
        private authService: AuthenticationService,
        private router: Router,
        private roleManagementService: RoleManagementService
    ) {
        Window["myComponent"] = this;
    }

    ngOnInit() {
        this.user = this.authService.getLoggedInUser();
        if (!this.user) {
            this.subscription = this.authService.watchUser().subscribe(user => {
                this.user = user;
            });
        }
        this.getPrivacyNotice();
    }

    getPrivacyNotice() {
        this.loading = true;
        this.privacyNoticeService.getPrivacyNotice()
            .then((result: any) => {
                this.privacyNoticeId = result.PrivacyNoticeId;
                this.htmlData = this.sanitizer.bypassSecurityTrustHtml(result.Description);
                this.loading = false;
            }).catch(() => { this.loading = false; });
    }

    downloadClientData() {
        this.privacyNoticeService.getPrivacyNoticeExcel();
    }

    onPrivacyNoticeSigning() {
        this.loading = true;
        this.privacyNoticeService.savePrivacyNoticeSigning(this.authService.loggedInUser.userid, this.privacyNoticeId)
            .then((result: any) => {
                this.authService.setPrivacyInfo(true);
                this.currentDate = new Date().getDate().toString() + '/' + (new Date().getMonth() + 1).toString() + '/' + new Date().getFullYear().toString();
                this.loading = false;
            }).catch(() => { this.loading = false; });
    }

    getStarted() {
        if (this.roleManagementService.checkPermissionsForMenu('V', null, null, 'reporting')) {
            this.router.navigate(['/app']);
        }
        else if (this.roleManagementService.checkPermissionsForMenu('V', null, null, 'ingestion')) {
            this.router.navigate(['/app/data-ingestion']);
        }
        else if (this.roleManagementService.checkPermissionsForMenu('V', null, null, 'report-bundling')) {
            this.router.navigate(['/app/report-bundling']);
        }
        else if (this.roleManagementService.checkPermissionsForMenu('V', null, null, 'administration')) {
            this.router.navigate(['/app/user-management']);
        }
        else if (this.roleManagementService.checkPermissionsForMenu('S', null, null, 'support')) {
            this.router.navigate(['/app/client-management']);
        }
    }
}