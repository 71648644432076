<app-loader *ngIf="loading"></app-loader>
<div class="taskboard-1">
    <div class="row">
        <div class="col-12 col-sm-6">
            <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
                [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
        </div>

        <div class="col-12 col-sm-6 mb-3 mb-xl-0">
            <div class="position-relative w-100 search-input-overall taskboard-search">
                <input type="text" name="nameFilter" [(ngModel)]="searchReport" placeholder="Search Report"
                    class="w-100" />
                <i class="fas fa-search"></i>
            </div>
        </div>
    </div>
    <div class="row mt-lg-3 taskboard-division">
        <div class="col-12 d-xl-flex align-items-xl-center justify-content-xl-center">
            <div
                class="text-center mr-xl-5 mb-3 mb-xl-0 total-count d-flex flex-column align-items-center justify-content-center">
                <h5>{{totalReport}}</h5>
                <h6 class="mb-0">Total Reports (Weekly)</h6>
            </div>
            <div class="d-flex align-items-center justify-content-center date-wrapper">
                <p-calendar ngbTooltip="Select a week" container="body" [showIcon]="true" appendTo="body"
                    [monthNavigator]="true" [yearNavigator]="true" [(ngModel)]="viewScheduleDate"
                    yearRange="{{yearRange}}" (onSelect)="scheduleDateChange(viewScheduleDate)" class="only-icon-cal">
                </p-calendar>
                <div class="disabled-date">
                    {{dateList[0] | date:'dd'}}
                    {{dateList[0] | date:'MMM'}}
                </div>
                <div class="enabled-date hvr-shrink" [ngClass]="activeDate1 == true ? 'active-date' : ''"
                    (click)="getDateList(dateList[1],1)">
                    {{dateList[1] | date:'dd'}}
                    {{dateList[1] | date:'MMM'}}
                </div>
                <div class="enabled-date hvr-shrink" [ngClass]="activeDate2 == true ? 'active-date' : ''"
                    (click)="getDateList(dateList[2],2)">
                    {{dateList[2] | date:'dd'}}
                    {{dateList[2] | date:'MMM'}}
                </div>
                <div class="enabled-date hvr-shrink" [ngClass]="activeDate3 == true ? 'active-date' : ''"
                    (click)="getDateList(dateList[3],3)">
                    {{dateList[3] | date:'dd'}}
                    {{dateList[3] | date:'MMM'}}
                </div>
                <div class="enabled-date hvr-shrink" [ngClass]="activeDate4 == true ? 'active-date' : ''"
                    (click)="getDateList(dateList[4],4)">
                    {{dateList[4] | date:'dd'}}
                    {{dateList[4] | date:'MMM'}}
                </div>
                <div class="enabled-date hvr-shrink" [ngClass]="activeDate5 == true ? 'active-date' : ''"
                    (click)="getDateList(dateList[5],5)">
                    {{dateList[5] | date:'dd'}}
                    {{dateList[5] | date:'MMM'}}
                </div>
                <div class="disabled-date">
                    {{dateList[6] | date:'dd'}}
                    {{dateList[6] | date:'MMM'}}
                </div>
                <i class="fas fa-times-circle clear-date cursor-pointer" (click)="deactiveDateCircle()"
                    ngbTooltip="Clear date selection" container="body"></i>
            </div>
            <div
                class="text-center ml-xl-5 mt-3 mt-xl-0 total-count d-flex flex-column align-items-center justify-content-center">
                <h5>{{totalReportInSelectedDate}}</h5>
                <h6 class="mb-0">{{'Total Reports ('+filterDate+')'}}</h6>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <div class="card suite-report-list">
                <div class="card-body list-body" data-simplebar data-simplebar-auto-hide="false">
                    <div class="row flex-nowrap count-div">
                        <div class="col-xl-2 col-8 col-sm-5 col-md-4 col-lg-3"
                            *ngFor="let report of filterReportSchedule | searchReportFilter:'ReportDisplayName':searchReport">
                            <div class="card w-100 card-list mb-0"
                                [ngClass]="{'active-report':report.IsReportSelected == true, 'mapped-card':report.ReportPackName != null, 'unmapped-card':report.ReportPackName == null}"
                                (click)="getReportApprovalLevel(report,0)">
                                <div class="card-header">
                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                        <span>
                                            {{report.SuiteName}} | <span class="report-highlight">
                                                {{report.ReportDisplayName}}
                                            </span>
                                        </span>
                                        <span class="support-text">Suite | Report</span>
                                    </div>
                                </div>
                                <div
                                    class="card-body d-flex flex-column align-items-center justify-content-center text-center">
                                    <div class="d-flex flex-column align-items-center justify-content-center mb-2">
                                        <span>{{report.FallDate | date: 'dd/MM/yyyy' }}</span>
                                        <span class="support-text">Report Due Date</span>
                                    </div>
                                    <!-- <div class="d-flex flex-column align-items-center justify-content-center">
                                        <span>3 | 5</span>
                                        <span class="support-text">Current | Max (Approval Level)</span>
                                    </div> -->
                                </div>
                                <div class="card-footer text-center">
                                    <div class="h-100 pr-2" data-simplebar data-simplebar-auto-hide="false">
                                        <i *ngIf="report.ReportPackName != null">
                                            Report mapped to
                                            '{{report.ReportPackName}}'
                                        </i>
                                        <i *ngIf="report.ReportPackName == null">
                                            Please map
                                            to a
                                            report pack
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row mb-3">
        <div class="col-12">
            <div class="d-flex align-items-center filter-wrapper">
                <div class="filter-wrapper-content d-flex align-items-center justify-content-center" *ngIf="selectedRp">
                    <div class="col-12 specify-overflow" id="overflowCheck">
                        <span class="value mr-3">
                            <span class="title">Report Pack Title | </span>{{selectedRp.Title}}</span>
                        <span class="value mr-3">
                            <span class="title">Report Date |
                            </span>{{selectedRp.ReportDate | date: 'dd/MM/yyyy'}}</span>
                        <span class="value">
                            <span class="title">Description | </span><span
                                *ngIf="selectedRp.RP_Description != null">{{selectedRp.RP_Description}}</span><span
                                *ngIf="selectedRp.RP_Description == null">No description provided</span></span>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="row mb-3">
        <div class="col-12">
            <app-report-pack-filter [selectedReportPacks]="selectedRp"></app-report-pack-filter>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="scrollable-taskboard-table">
                <p-table #taskBoardList [value]="reportApprovalList" [paginator]="true" [rows]="4"
                    class="table-irr data-scheduler" [columns]="headerCols">
                    <ng-template pTemplate="paginatorleft">
                        <button class="btn btn-clear-filter hvr-shrink" type="button"
                            (click)="taskBoardList.reset();clearFilters();">
                            <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                        </button>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                {{col.header}}
                                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                            </th>
                            <th>Actions</th>
                        </tr>
                        <tr>
                            <th *ngFor="let col of columns">
                                <p-calendar *ngIf="col.field =='appDate'" [(ngModel)]="reportDateFilters"
                                    selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy" [readonlyInput]="true"
                                    placeholder="Select a range" appendTo="body" [monthNavigator]="true"
                                    [yearNavigator]="true" yearRange="{{yearRange}}"
                                    (ngModelChange)="taskBoardList.filter(reportDateFilters, col.field, 'dateRangeFilter')">
                                </p-calendar>

                                <input *ngIf="col.field !='appDate'" type="text" class="w-100" placeholder="Filter Text"
                                    [value]="taskBoardList.filters && taskBoardList.filters[col.field] ? taskBoardList.filters[col.field].value: ''"
                                    (input)="taskBoardList.filter($event.target.value, col.field, 'contains')">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td>{{rowData.ApprovalLevelDesc}}</td>
                            <td>{{rowData.UserName}}</td>
                            <td>{{rowData.ApprovalDate | date:'dd/MM/yyyy'}}</td>
                            <td>{{rowData.comment}}</td>
                            <td>
                                <button class="btn-table-icon hvr-shrink ml-2" ngbTooltip="Assign to me"
                                    id='assignReportToMe'
                                    *ngIf="rowData.ApprovalDate ==='' && rowData.UserName !== loginUser.username && rowData.hasPermission"
                                    (click)="assignReportToMe(rowData)" container="body" type="button">
                                    <i class="fas fa-user-check fa-lg"></i>
                                </button>
                                <button class="btn-table-icon hvr-shrink ml-2" ngbTooltip="Release From me"
                                    id='releaseReportFromMe'
                                    *ngIf="rowData.ApprovalDate ==='' && rowData.UserName === loginUser.username && rowData.hasPermission"
                                    (click)="releaseReportFromMe(rowData)" container="body" type="button">
                                    <i class="fas fa-user-times fa-lg"></i>
                                </button>
                                <button class="btn-table-icon hvr-shrink ml-2" ngbTooltip="View report" id='viewReprot'
                                    (click)="gotoReport(rowData)" container="body" type="button">
                                    <i class="fas fa-eye fa-lg"></i>
                                </button>

                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>