import { Component, OnInit, OnChanges, ViewEncapsulation, Input, ViewChild, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { PromptModalComponent } from 'src/app/shared/prompt-modal/prompt-modal.component';
import { AlertService } from 'src/app/core/services/alert.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ApplicationConfigurationService } from 'src/app/core/services/applicationConfigurationService';
import { FilterUtils } from 'primeng/utils';
import { DatePipe } from "@angular/common";


/**
 * Component
 */
@Component({
  selector: 'app-application-configuration',
  templateUrl: './application-configuration.component.html',
  styleUrls: ['./application-configuration.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ApplicationConfigurationComponent implements OnInit, OnDestroy {

  @Input()
  public loading = true;
  public appConfigs: any[];
  public dataDictionary: any;
  headerCols: any;
  selectedRowForEdit: any;
  lastChangedDateFilters: Date[];

  dataTypeDict: any = [
    { type: 'string', editor: 'text', decimal: 0 },
    { type: 'text', editor: 'text', decimal: 0 },
    { type: 'boolean', editor: 'boolean', decimal: 0 },
    { type: 'date', editor: 'date', decimal: 0 },
    { type: 'number', editor: 'numeric', decimal: 0 },
    { type: 'decimal', editor: 'numeric', decimal: 5 },
    { type: 'int', editor: 'numeric', decimal: 0 },
    { type: 'integer', editor: 'numeric', decimal: 0 },
    { type: 'bigint', editor: 'numeric', decimal: 0 }
  ];
  private dateTime = new Date();
  private yearRange = "2000:" + this.dateTime.getFullYear().toString();

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthenticationService,
    private appConfigService: ApplicationConfigurationService,
    private alertService: AlertService,
    private datePipe: DatePipe,
  ) { }

  /**
   * on init
   */
  ngOnInit() {
    if (!this.authService.isPrivacyNoticeAcknowledged) {
      this.router.navigate(['/privacy-notice']);
    }

    this.headerCols = [
      { field: 'DisplayName', header: 'Display Name' },
      { field: 'Description', header: 'Description' },
      { field: 'ConfigValue', header: 'Configured Value' },
      { field: 'ConfigDefaultValue', header: 'Default Value' },
      { field: 'LastChangedBy', header: 'Last Changed By' },
      { field: 'LastChangedDate', header: 'Last Changed Date' }
    ];

    this.getApplicationConfiguration();

    FilterUtils['dateRangeFilter'] = (value, filter: [Date, Date]): boolean => {
      if (!filter || (!filter[0] && !filter[1]))
        return true;
      var val = new Date(value);
      var convertDate = new Date(val.getFullYear() + '/' + (val.getMonth() + 1) + '/' + val.getDate());
      var s = filter[0].getTime();
      var e;
      if (filter[1])
        e = filter[1].getTime() + (24 * 60 * 60 * 1000) - 1000;
      else e = s + (24 * 60 * 60 * 1000) - 1000;
      return convertDate.getTime() >= s && convertDate.getTime() <= e;
    }
  }

  private getApplicationConfiguration() {
    this.loading = true;
    this.appConfigService.getApplicationConfiguration().then
      ((data: any) => {
        this.appConfigs = JSON.parse(JSON.stringify(data.Configurations));
        this.dataDictionary = JSON.parse(JSON.stringify(data.DataDictionary));
        this.loading = false;
      }).catch(() => { this.loading = false; });
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  clearFilters() {
    this.lastChangedDateFilters = null;
  }

  closeDateRangeFilter(dateComponent: any, filters: Date[]) {
    if (filters[1]) {
      dateComponent.hideOverlay();
    }
  }

  openModal(editAppConfigModal, rowData) {
    this.selectedRowForEdit = JSON.parse(JSON.stringify(rowData));
    this.selectedRowForEdit.oldValue = this.selectedRowForEdit.ConfigValue;
    this.selectedRowForEdit.newConfigValue = '';
    this.selectedRowForEdit.comments = '';
    var dataType = this.dataTypeDict.filter(x => x.type == this.selectedRowForEdit.DataType)[0];
    this.selectedRowForEdit.editor = dataType.editor;
    this.selectedRowForEdit.decimals = dataType.decimals;
    this.selectedRowForEdit.cellOptions = this.selectedRowForEdit.DictGroupId != null ? this.dataDictionary.filter(x => x.DictGroup == this.selectedRowForEdit.DictGroupId) : [];
    this.modalService.open(editAppConfigModal, { size: 'lg', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
  }

  resetToDefault(rowData) {
    this.selectedRowForEdit = JSON.parse(JSON.stringify(rowData));
    this.selectedRowForEdit.newConfigValue = this.selectedRowForEdit.ConfigDefaultValue;
    this.submitChanges();
  }

  clearChanges() {
    this.selectedRowForEdit.newConfigValue = '';
    this.selectedRowForEdit.comments = '';
  }

  submitChanges() {
    if (this.selectedRowForEdit.editor == "date") {
      this.selectedRowForEdit.newConfigValue = this.datePipe.transform(
        this.selectedRowForEdit.newConfigValue,
        "yyyy-MM-dd"
      );
    }
    if (this.selectedRowForEdit.newConfigValue !== this.selectedRowForEdit.ConfigValue) {
      this.loading = true;
      var appConfig = {
        ConfigKey: this.selectedRowForEdit.ConfigKey, ConfigValue: this.selectedRowForEdit.newConfigValue,
        AppDefaultId: this.selectedRowForEdit.AppDefaultId, Notes: this.selectedRowForEdit.comments
      };
      this.appConfigService.updateApplicationConfiguration(appConfig).then
        (() => {
          this.selectedRowForEdit = null;
          this.modalService.dismissAll();
          this.getApplicationConfiguration();
          this.loading = false;
        }).catch(() => { this.loading = false; });
    }
    else {
      this.selectedRowForEdit.newConfigValue = '';
      this.alertService.info('New value cannot be same as old value. Please enter a different value!');
    }
  }
}