<div class="row" *ngIf="gridColumns">
    <kendo-grid [data]="gridData" scrollable="scrollable" style="width: 100%" [loading]="loading" [height]="450"
        [resizable]="true" [pageSize]="pageSize" [skip]="dynamicModel.skip" [pageable]="true"
        (pageChange)="onPageChange($event)" (remove)="removeRow($event)" (add)="addHandler(addPopOutModal, $event)">
        <ng-template kendoGridToolbarTemplate>
            <button class="k-button float-right" (click)="openPopUp(sortPopUp, 'lg')">
                <i class="fas fa-sort fa-lg" ngbTooltip="Sort"></i>
            </button>
            <button class="k-button float-right" (click)="openPopUp(filterPopUp, 'xl')">
                <i class="fas fa-filter fa-lg" ngbTooltip="Filter"></i>
            </button>
            <button class="k-button float-right" (click)="downloadResult()">
                <i class="fas fa-file-download fa-lg" ngbTooltip="Download Result"></i>
            </button>
            <button class="k-button btn btn-modal-pos small-font hvr-shrink" kendoGridAddCommand *ngIf="isRefDataAdminRole">Add new</button>
        </ng-template>

        <kendo-grid-column field="rowRefId" title="IRR Row ID" width="100" [hidden]="true" [locked]="true"
            [editable]="false">
        </kendo-grid-column>

        <kendo-grid-command-column title="Actions" width="120px" [locked]="true" *ngIf="isRefDataAdminRole">
            <ng-template kendoGridCellTemplate>
                <button class="icon-button hvr-shrink icon-trash" kendoGridRemoveCommand><i
                        class="fas fa-trash-alt fa-sm my-1"></i></button>
            </ng-template>
        </kendo-grid-command-column>

        <kendo-grid-column *ngFor="let c of gridColumns" [id]="c.columnId" [field]="c.field" [editable]="c.editable"
            [title]="c.title" format="{0:n}" [hidden]="c.hidden" width="120px"
            [class]="{'k-grid-align-left': c.leftalign, 'k-grid-align-right': c.rightalign, 'k-grid-align-center': c.centeralign}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="d-flex align-items-center justify-content-center">
                    <div class="col-12 p-0 text-truncate"
                        [ngClass]="[
                    localDraftCells.indexOf(dataItem.PrimaryKeyId + '_' + c.field) > -1 ? 'local-draft': '', c.editable ? 'editable': '']">
                        <div *ngIf="isRefDataAdminRole; else noAdminRole"
                            (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c)">
                            <div class="cell-data-padding">{{dataItem[c.field]}}</div>
                        </div>
                        <ng-template #noAdminRole>
                            <div class="cell-data-padding">{{dataItem[c.field]}}</div>
                        </ng-template>

                    </div>
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
    <div class="row float-right" [ngStyle]="{'padding-left': '15px'}" *ngIf="isRefDataAdminRole">

        <button class="btn btn-primary-ey btn-icon mb-2 hvr-shrink small-font form-btn" type="button"
            (click)='downloadAuditReport()'>
            <div class="icon-container hvr-shrink"><i class="fas fa-lg fa-download"></i></div>
            <span>Download Audit</span>
        </button>

        <button class="btn btn-primary-ey btn-icon mb-2 hvr-shrink small-font form-btn" type="button"
            (click)="saveRefDatachanges(false)">
            <div class="icon-container hvr-shrink"><i class="fas fa-lg fa-thumbs-up"></i></div>
            <span>Submit Form</span>
        </button>
        <button class="btn btn-primary-ey btn-icon mb-2 hvr-shrink small-font form-btn" type="button"
            (click)="undoChanges()">
            <div class="icon-container hvr-shrink"><i class="fas fa-lg fa-undo"></i></div>
            <span>Undo All Changes</span>
        </button>
    </div>
</div>

<ng-template #sortPopUp let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">Custom Sort<i
                class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="cancelSortChanges();modal.close()"></i></h5>
    </div>
    <div class="modal-body">
        <h6 class="text-white-50  font-italic small-font mt-3"><i class="fas fa-info-circle text-yellow mr-3"></i>Please
            click on the cell under columns 'Column' and 'Sort Order' to open the editor.</h6>
        <kendo-grid [data]="sortPopUpModel" scrollable="scrollable" [sortable]="false" [resizable]="true"
            [pageable]="false" (cellClick)="sortGridCellClickHandler($event)"
            (cellClose)="sortGridCellCloseHandler($event)" (remove)="removeSortLevel($event)"
            (add)="addSortLevel($event)">
            <ng-template kendoGridToolbarTemplate class="k-grid-toolbar">
                <button class="add-button hvr-shrink" kendoGridAddCommand>Add Level</button>
            </ng-template>
            <kendo-grid-column field="sortLevelText" title="" width="120" [editable]="false"></kendo-grid-column>
            <kendo-grid-column field="columnText" title="Column" width="140">
                <ng-template kendoGridEditTemplate let-formGroup>
                    <div>
                        <select [(ngModel)]="formGroup.value['columnName']">
                            <option *ngFor="let op of sortColumnList" [ngValue]="op.field">
                                {{op.title}}</option>
                        </select>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="sortOrderText" title="Sort Order" width="120">
                <ng-template kendoGridEditTemplate let-formGroup>
                    <div>
                        <select [(ngModel)]="formGroup.value['sortOrder']">
                            <option *ngFor="let op of sortOrderOptions" [ngValue]="op.sortOrder">
                                {{op.sortOrderText}}</option>
                        </select>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-command-column title="Actions" width="100">
                <ng-template kendoGridCellTemplate>
                    <button class="icon-button hvr-shrink icon-trash" kendoGridRemoveCommand><i
                            class="fas fa-trash-alt fa-sm my-1"></i></button>
                </ng-template>
            </kendo-grid-command-column>
        </kendo-grid>
    </div>
    <div class="modal-footer">
        <div class="float-right m-0">
            <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button"
                (click)='cancelSortChanges();modal.close()'>
                <span>Cancel</span>
            </button>
            <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button"
                (click)='clearSortSelection();modal.close()'>
                <span>Clear</span>
            </button>
            <button class="btn btn-modal-pos small-font hvr-shrink" type="button"
                (click)="confirmSortSelection();modal.close()">
                <span>Submit</span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #filterPopUp let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">Custom Filter<i
                class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="modal.close();cancelFilterChanges();"></i></h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <kendo-grid [kendoGridBinding]="filterPopUpModel" scrollable="'scrollable'" [sortable]="true"
                [resizable]="true" style="width: 100%" [height]="670" [pageable]="false" [filterable]="true">
                <kendo-grid-column field="title" title="Column" width="120" editable="false"></kendo-grid-column>
                <kendo-grid-column field="showColumn" title="Show Column" width="120" filter="boolean">
                    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No"></kendo-grid-messages>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div>
                            <kendo-switch [(ngModel)]="dataItem['showColumn']" onLabel="Yes" offLabel="No"
                                [disabled]="dataItem.disableShowColumnChange">
                            </kendo-switch>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="enableFilter" title="Enable Filter" width="120" filter="boolean">
                    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No"></kendo-grid-messages>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div>
                            <kendo-switch [(ngModel)]="dataItem['enableFilter']" onLabel="Yes" offLabel="No"
                                (valueChange)="onFilterSwitchChange($event, dataItem)">
                            </kendo-switch>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="filterType" title="Filter Type" width="150px" [filterable]="false">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div *ngIf="dataItem['enableFilter']">
                            <select [(ngModel)]="dataItem['filterType']">
                                <option *ngFor="let op of dataItem.filterTypeOptions" [ngValue]="op">
                                    {{op}}</option>
                            </select>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="value" title="Filter Value" width="500px" [filterable]="false">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div *ngIf="dataItem['enableFilter']">
                            <kendo-numerictextbox *ngIf="dataItem.columnType == 'numeric'"
                                [(ngModel)]="dataItem.numberValue" [decimals]="5" [autoCorrect]="true"
                                [min]="-1000000000000000" [max]="1000000000000000" [format]="'n'">
                            </kendo-numerictextbox>
                            <input *ngIf="dataItem.columnType == 'text' && dataItem.filterType == 'Contains'"
                                [(ngModel)]="dataItem.textValue" maxlength="50" />
                            <kendo-multiselect
                                *ngIf="dataItem.columnType == 'text' && dataItem.filterType == 'Dictionary'"
                                [data]="dataItem.dictionary" [(ngModel)]="dataItem.dictionarySelection"
                                [textField]="'acceptableValue'" [valueField]="'mappingValue'"></kendo-multiselect>
                            <select *ngIf="dataItem.columnType == 'boolean'" [(ngModel)]="dataItem['booleanValue']">
                                <option *ngFor="let op of dataItem.dictionary" [ngValue]="op.value">
                                    {{op.title}}</option>
                            </select>
                            <input *ngIf="dataItem.columnType == 'date'" [style.display]="'none'" />
                            <p-calendar *ngIf="dataItem.columnType == 'date' && dataItem.filterType == 'Range'"
                                [(ngModel)]="dataItem.dateLowerLimit" [showIcon]="true" [autoZIndex]="true"
                                dateFormat="dd/mm/yy" [readonlyInput]="true" [monthNavigator]="true"
                                yearRange="{{yearRange}}" [yearNavigator]="true" appendTo="body">
                            </p-calendar>
                            <p-calendar *ngIf="dataItem.columnType == 'date' && dataItem.filterType == 'Range'"
                                [(ngModel)]="dataItem.dateUpperLimit" [showIcon]="true" [autoZIndex]="true"
                                dateFormat="dd/mm/yy" [readonlyInput]="true" [monthNavigator]="true"
                                yearRange="{{yearRange}}" [yearNavigator]="true" appendTo="body">
                            </p-calendar>
                            <p-calendar *ngIf="dataItem.columnType == 'date' && dataItem.filterType != 'Range'"
                                [(ngModel)]="dataItem.dateValue" [showIcon]="true" [autoZIndex]="true"
                                dateFormat="dd/mm/yy" [readonlyInput]="true" [monthNavigator]="true"
                                yearRange="{{yearRange}}" [yearNavigator]="true" appendTo="body">
                            </p-calendar>
                        </div>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>
    </div>
    <div class="modal-footer">
        <div class="float-right m-0">
            <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button"
                (click)='modal.close();cancelFilterChanges()'>
                <span>Cancel</span>
            </button>
            <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button"
                (click)='clearFilterSelection();'>
                <span>Reset Filters</span>
            </button>
            <button class="btn btn-modal-pos small-font hvr-shrink" type="button"
                (click)="modal.close();confirmFilterSelection();">
                <span>Submit</span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #cellPopOutModal let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">Update RefData Cell<i
                class="fas fa-times ml-auto cursor-pointer" aria-label="Close" (click)="cellPopOutClose()"></i></h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-8">
                <form class="material-styling w-100 mt-3" #cellPopOutForm="ngForm">
                    <div *ngIf="cellPopOutModel != null">
                        <div class="row">
                            <div class="col-3">
                                <div class="group">
                                    <input type="text" value="{{cellPopOutModel.clientRefId}}" required disabled />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label class="non-input-label">Reference Id</label>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="group">
                                    <input type="text" value="{{cellPopOutModel.columnTitle}}" class="text-truncate"
                                        title="{{cellPopOutModel.columnTitle}}" required disabled />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label class="non-input-label">Column</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="group">
                                    <input type="text" value="{{cellPopOutModel.oldValue}}" required disabled />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label class="non-input-label">Cell Value</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="color-teal font-14 mb-2">
                                    New Value<sup><i class="fas fa-asterisk text-danger fa-xs ml-1"></i></sup>
                                </div>
                                <div>
                                    <kendo-numerictextbox
                                        *ngIf="cellPopOutModel.cellOptions.length == 0 && cellPopOutModel.editor == 'numeric'"
                                        required id="cellPopOutNewValue" name="cellPopOutNewValue"
                                        [(ngModel)]="cellPopOutModel.newValue" [decimals]="cellPopOutModel.decimals"
                                        [autoCorrect]="true" [min]="-1000000000000000" [max]="1000000000000000"
                                        [format]="'n'" class="w-100">
                                    </kendo-numerictextbox>
                                    <input type="text"
                                        *ngIf="cellPopOutModel.cellOptions.length == 0 && cellPopOutModel.editor == 'text'"
                                        id="cellPopOutNewValue" name="cellPopOutNewValue" required
                                        [(ngModel)]="cellPopOutModel.newValue" maxlength="50" trim-text
                                        class="w-100 cellPopOutInput" />
                                    <input
                                        *ngIf="cellPopOutModel.cellOptions.length == 0 && cellPopOutModel.editor == 'date'"
                                        [style.display]="'none'" />
                                    <p-calendar
                                        *ngIf="cellPopOutModel.cellOptions.length == 0 && cellPopOutModel.editor == 'date'"
                                        id="cellPopOutNewValue" name="cellPopOutNewValue" required
                                        [(ngModel)]="cellPopOutModel.newValue" [showIcon]="true" [autoZIndex]="true"
                                        dateFormat="dd/mm/yy" [readonlyInput]="true" [monthNavigator]="true"
                                        yearRange="{{yearRange}}" [yearNavigator]="true" appendTo="body">
                                    </p-calendar>
                                    <select *ngIf="cellPopOutModel.cellOptions.length > 0" class="w-100 cellPopOutInput"
                                        required [(ngModel)]="cellPopOutModel.newValue" id="cellPopOutNewValue"
                                        name="cellPopOutNewValue">
                                        <option *ngFor="let op of cellPopOutModel.cellOptions" [ngValue]="op.value">
                                            {{op.title}}</option>
                                    </select>
                                    <div *ngIf="cellPopOutModel.oldValue == cellPopOutModel.newValue"
                                        class="alert alert-danger">
                                        New value cannot be same as old value. Please enter a different value
                                    </div>
                                    <div *ngIf="!cellPopOutForm.valid && cellPopOutForm.controls.cellPopOutNewValue.errors"
                                        class="alert alert-danger">
                                        <div *ngIf="cellPopOutForm.controls.cellPopOutNewValue.errors.required">
                                            Please enter a value to save changes.
                                        </div>
                                        <div
                                            *ngIf="cellPopOutForm.controls.cellPopOutNewValue.errors.maxError || cellPopOutForm.controls.cellPopOutNewValue.errors.minError">
                                            The value should be between -1,000,000,000,000,000 and
                                            1,000,000,000,000,000.
                                        </div>
                                        <div *ngIf="cellPopOutForm.controls.cellPopOutNewValue.errors.pattern">
                                            The value contains invalid characters.
                                        </div>
                                        <div *ngIf="cellPopOutForm.controls.cellPopOutNewValue.errors.maxlength">
                                            The value cannot be greater than 50 characters.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="color-teal font-14 mb-2">
                                    Comment<sup><i class="fas fa-asterisk text-danger fa-xs ml-1"></i></sup>
                                </div>
                                <div>
                                    <textarea id="cellPopOutComment" name="cellPopOutComment" required maxlength="500"
                                        #cellPopOutComment="ngModel" rows="4" [(ngModel)]="cellPopOutModel.comment"
                                        pattern="^[A-Za-z0-9 _\-.]*$" trim-text placeholder="Enter Comments"
                                        class="w-100 cellPopOutInput"></textarea>
                                    <div *ngIf="!cellPopOutForm.valid && cellPopOutForm.controls.cellPopOutComment.errors"
                                        class="alert alert-danger">
                                        <div
                                            *ngIf="cellPopOutForm.controls.cellPopOutComment.errors.required && cellPopOutForm.controls.cellPopOutComment.touched">
                                            Please enter a comment to save changes.
                                        </div>
                                        <div *ngIf="cellPopOutForm.controls.cellPopOutComment.errors.pattern">
                                            The comment contains invalid characters.
                                        </div>
                                        <div *ngIf="cellPopOutForm.controls.cellPopOutComment.errors.maxlength">
                                            The comment cannot be greater than 500 characters.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row w-100 m-0">
            <div class="col-6 pl-0">
                <h6 class="mb-0 font-13">
                    <sup><i class="fas fa-asterisk text-danger fa-xs mr-1"></i></sup>
                    Required fields
                </h6>
                <h6 class="mb-0 font-13" *ngIf="cellAudit && cellAudit.length > 0">
                    <span class="text-info">Note : </span>
                    Unsaved audit rows will be shown in italics.
                </h6>
            </div>
            <div class="col-6 text-right pr-0">
                <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button" (click)='clearEditPopUp()'>
                    <span>Clear</span>
                </button>

                <button class="btn btn-modal-pos small-font hvr-shrink" type="button"
                    [disabled]="(cellPopOutForm && !cellPopOutForm.form.valid) || !isValidForm()"
                    (click)="submitCellEdit()">
                    <span>Submit</span>
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #addPopOutModal let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">Add RefData<i
                class="fas fa-times ml-auto cursor-pointer" aria-label="Close" (click)="cellPopOutClose()"></i></h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-16">
                <form class="material-styling w-100" #addPopOutForm="ngForm">
                    <div class="row w-100 m-0">

                        <div *ngFor="let col of addPopOutModel;  let i = index; trackBy:trackByFn">
                            <div class="col-12 pl-2">
                                <div class="group">
                                    <div class="non-input-label">
                                        {{col.columnTitle}}<sup><i
                                                class="fas fa-asterisk text-danger fa-xs ml-1"></i></sup>
                                    </div>
                                    <div>
                                    <kendo-numerictextbox
                                        *ngIf="col.cellOptions.length == 0 && col.editor == 'numeric'"
                                        required id="{{col.columnId}}" name="{{col.columnId}}" [decimals]="col.decimals"
                                        [(ngModel)]="col.newValue" [autoCorrect]="true" [min]="-1000000000000000" [max]="1000000000000000"
                                        [format]="'n'" class="w-100">
                                    </kendo-numerictextbox>
                                        <input type="text" *ngIf="col.cellOptions.length == 0 && (col.editor == 'text')"
                                            id="{{col.columnId}}" name="{{col.columnId}}" required
                                            [(ngModel)]="col.newValue" maxlength="50" trim-text
                                            class="w-100 cellPopOutInput" />
                                        <input *ngIf="col.cellOptions.length == 0 && col.editor == 'date'"
                                            [style.display]="'none'" />
                                        <p-calendar *ngIf="col.cellOptions.length == 0 && col.editor == 'date'"
                                            id="{{col.columnId}}" name="{{col.columnId}}" required
                                            [(ngModel)]="col.newValue" [showIcon]="true" [autoZIndex]="true"
                                            dateFormat="dd/mm/yy" [readonlyInput]="true" [monthNavigator]="true"
                                            yearRange="{{yearRange}}" [yearNavigator]="true" appendTo="body">
                                        </p-calendar>
                                        <select *ngIf="col.cellOptions.length > 0" class="w-100 cellPopOutInput"
                                            required [(ngModel)]="col.newValue" id="{{col.columnId}}"
                                            name="{{col.columnId}}">
                                            <option *ngFor="let op of col.cellOptions" [ngValue]="op.value">
                                                {{op.title}}</option>
                                        </select>

                                        <div *ngIf="!addPopOutForm.valid &&  addPopOutForm.controls[col.columnId].errors"
                                            class="alert alert-danger">
                                            <div *ngIf="addPopOutForm.controls[col.columnId].errors.required">
                                                Please enter a value to save changes.
                                            </div>
                                            <div
                                                *ngIf="addPopOutForm.controls[col.columnId].errors.maxError || addPopOutForm.controls[col.columnId].errors.minError">
                                                The value should be between -1,000,000,000,000,000 and
                                                1,000,000,000,000,000.
                                            </div>
                                            <div *ngIf="addPopOutForm.controls[col.columnId].errors.pattern">
                                                The value contains invalid characters.
                                            </div>
                                            <div *ngIf="addPopOutForm.controls[col.columnId].errors.maxlength">
                                                The value cannot be greater than 50 characters.
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <span class="highlight"></span> -->
                                    <span class="bar"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100 m-0">
                        <div class="col-12 pl-2">
                            <div class="group">
                                <div class="color-teal font-14 mb-2">
                                    Comment<sup><i class="fas fa-asterisk text-danger fa-xs ml-1"></i></sup>
                                </div>
                                <div>
                                    <textarea id="Comment" name="Comment" required maxlength="500" #Comment="ngModel"
                                        rows="4" [(ngModel)]="addPopOutModel.comment" pattern="^[A-Za-z0-9 _\-.]*$"
                                        trim-text placeholder="Enter Comments" class="w-100 cellPopOutInput"></textarea>
                                    <div *ngIf="!addPopOutForm.valid && addPopOutForm.controls.Comment.errors"
                                        class="alert alert-danger">
                                        <div
                                            *ngIf="addPopOutForm.controls.Comment.errors.required && addPopOutForm.controls.Comment.touched">
                                            Please enter a comment to save changes.
                                        </div>
                                        <div *ngIf="addPopOutForm.controls.Comment.errors.pattern">
                                            The comment contains invalid characters.
                                        </div>
                                        <div *ngIf="addPopOutForm.controls.Comment.errors.maxlength">
                                            The comment cannot be greater than 500 characters.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row w-100 m-0">
            <div class="col-6 pl-0">
                <h6 class="mb-0 font-13">
                    <sup><i class="fas fa-asterisk text-danger fa-xs mr-1"></i></sup>
                    Required fields
                </h6>
                <h6 class="mb-0 font-13" *ngIf="cellAudit && cellAudit.length > 0">
                    <span class="text-info">Note : </span>
                    Unsaved audit rows will be shown in italics.
                </h6>
            </div>
            <div class="col-6 text-right pr-0">
                <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button" (click)='clearAddPopUp()'>
                    <span>Clear</span>
                </button>

                <button class="btn btn-modal-pos small-font hvr-shrink" type="button"
                    [disabled]="(addPopOutForm && !addPopOutForm.form.valid)" (click)="submitAddCells()">
                    <span>Submit</span>
                </button>

            </div>
        </div>
    </div>
</ng-template>