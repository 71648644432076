import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BundleTypeManagementService } from "src/app/core/services/bundleTypeManagementService";
import { Router } from "@angular/router";
import { PromptModalComponent } from 'src/app/shared/prompt-modal/prompt-modal.component';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { AlertService } from "src/app/core/services/alert.service";
import { FilterUtils } from "primeng/utils";

@Component({
  selector: 'app-bundle-type-management',
  templateUrl: './bundle-type-management.component.html',
  styleUrls: ['./bundle-type-management.component.css']
})
export class BundleTypeManagementComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  headerCols: any;
  bundleTypesList: any = [];
  mappedReportList: any = [];
  selectedBundleType: any;
  selectedType: any[];
  promptResponse: Subscription;

  private dateTime = new Date();
  private yearRange = "2000:" + this.dateTime.getFullYear().toString();
  createdDateFilters: Date[];

  constructor(private bundleTypeManagementService: BundleTypeManagementService,
    private modalService: NgbModal,
    private commonService: CommonService,
    private alertService: AlertService,
    private router: Router) { }

  ngOnInit() {
    this.getBundleTypeList();
    this.headerCols = [
      { field: 'bundleTypeName', header: 'Bundle Type Name' },
      { field: 'bundleTypeDescription', header: 'Bundle Type Description' },
      { field: '', header: 'Reports' },
      { field: 'createdBy', header: 'Created By' },
      { field: 'createdDate', header: 'CreatedDate' },
      { field: '', header: 'Actions' },
    ];

    this.promptResponse = this.commonService.watchPrompt().subscribe(response => {
      this.modalService.dismissAll();
      if (response.callFor === 'bundleTypeActivation') {
        if (response.response === 'Yes') {
          this.activateordeactivateBundleType();
        }
        else this.selectedBundleType = null;
      }
      else if (response.callFor === 'bundleTypeDeletion') {
        if (response.response === 'Yes') {
          this.deleteBundleType();
        }
        else this.selectedBundleType = null;
      }
    });

    FilterUtils['dateRangeFilter'] = (value, filter: [Date, Date]): boolean => {
      if (!filter || (!filter[0] && !filter[1]))
        return true;
      var val = new Date(value);
      var convertDate = new Date(val.getFullYear() + '/' + (val.getMonth() + 1) + '/' + val.getDate());
      var s = filter[0].getTime();
      var e;
      if (filter[1])
        e = filter[1].getTime() + (24 * 60 * 60 * 1000) - 1000;
      else e = s + (24 * 60 * 60 * 1000) - 1000;
      return convertDate.getTime() >= s && convertDate.getTime() <= e;
    }
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  };


  private getBundleTypeList() {
    this.loading = true;
    this.bundleTypeManagementService.getBundleTypesList()
      .then((bundleTypes: any) => {
        this.bundleTypesList = bundleTypes;
        this.loading = false;
      }).catch(() => { this.loading = false; });
  }

  openMappedReportsModal(mappedReportsModal, rowData) {
    this.mappedReportList = rowData.reports;
    this.modalService.open(mappedReportsModal, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
  }

  closeMappedReportsModal() {
    this.modalService.dismissAll();
    this.mappedReportList = null;
  }

  activateordeactivateBundleType() {
    this.loading = true;
    this.bundleTypeManagementService.saveBundleTypeActivationStatus(this.selectedBundleType.bundleTypeId, !this.selectedBundleType.isActive).then(() => {
      let successMsg = this.selectedBundleType.isActive ? "Bundle Type has been deactivated successfully!" : "Bundle Type has been activated successfully!";
      this.alertService.success(successMsg);
      this.selectedBundleType = null;
      this.getBundleTypeList();
    }).catch(() => { this.loading = false; });
  }

  openPromptModal(rowData: any, deleteBundleType: boolean) {
    this.selectedBundleType = rowData;
    var openModal = this.modalService.open(PromptModalComponent, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
    if (!deleteBundleType) {
      openModal.componentInstance.promptCallFor = 'bundleTypeActivation';
      openModal.componentInstance.promptMessage = this.selectedBundleType.isActive ? 'Are you sure you want to deactivate this bundle type?' : 'Are you sure you want to activate this bundle type?';
    }
    else {
      openModal.componentInstance.promptCallFor = 'bundleTypeDeletion';
      openModal.componentInstance.promptMessage = 'Are you sure you want to delete this bundle type?'
    }
  }

  deleteBundleType() {
    this.loading = true;
    this.bundleTypeManagementService.deleteBundleType(this.selectedBundleType.bundleTypeId)
      .then(() => {
        this.selectedBundleType = null;
        this.getBundleTypeList();
      }).catch(() => {
        this.loading = false;
      });
  }

  addBundleType() {
    this.bundleTypeManagementService.editMode = false;
    this.bundleTypeManagementService.selectedBundleType = { bundleTypeId: 0, bundleTypeName: '', bundleTypeDescription: '', reports: [] };
    this.router.navigate(['/app/bundle-type-management/add-bundle-type']);
  }

  editBundleType(selectedBundleType) {
    this.bundleTypeManagementService.editMode = true;
    this.bundleTypeManagementService.selectedBundleType = selectedBundleType;
    this.router.navigate(['/app/bundle-type-management/edit-bundle-type']);
  }

  clearFilters() {
    this.createdDateFilters = null;
  }

  closeDateRangeFilter(dateComponent: any, filters: Date[]) {
    if (filters[1]) {
      dateComponent.hideOverlay();
    }
  }
}

