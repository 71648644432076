import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
// import { DropEvent } from 'ng-drag-drop';
import { getTable, getPNPTreeTable, getPNPTable2 } from '../../../sample-table.service';
import { AnalyticService } from 'src/app/core/services/analytics.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-pnp-analysis',
    templateUrl: './pnp-analysis.component.html',
    styleUrls: ['./pnp-analysis.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PNPAnalysisComponent implements OnInit {
    @ViewChild('pnpTab', { static: false }) pnpTab: any;
    pnpSuiteId: number;
    pnpReportId: number;
    pnpPeriod1Id: number;
    pnpPeriod2Id: number;
    selectedRows: any = [];
    selectedColumns: any = [];
    selectedPage: any;

    constructor(private alertService: AlertService) {
    }

    ngOnInit() {
    }

    goToStep1() {
        this.switchTab('step1');
    }
    goToStep2() {
        if (!this.pnpReportId) {
            this.alertService.warn('Please select a report to view the Report Pack Selection!');
        }
        else {
            this.switchTab('step2');
        }
    }

    goToStep3() {
        if (!this.pnpPeriod1Id || !this.pnpPeriod2Id) {
            this.alertService.warn('Please select two periods to access the Row and Column Filtering!');
        } else {
            this.switchTab('step3');
        }
    }

    goToStep4() {
        // if (!this.pnpReportId) {
        //     this.alertService.warn('Please select a report to view the period-on-period report!');
        // }
        if (!this.pnpReportId) {
            this.alertService.warn('Please select a report to access the Report Visualization!');
        }
        else if (!this.pnpPeriod1Id || !this.pnpPeriod2Id) {
            this.alertService.warn('Please select two periods to access the Report Visualization!');
        }
        else if (this.selectedRows.length == 0 || this.selectedColumns.length == 0) {
            this.alertService.warn('Please select row and column filters to access the Report Visualization!');
        }
        else {
            this.switchTab('step4');
        }
    }

    switchTab(id: string) {
        this.pnpTab.select(id);
    }

    beforeChange($event: NgbTabChangeEvent) {
        if ($event.nextId === 'step2' && (!this.pnpReportId)) {
            $event.preventDefault();
        } else if ($event.nextId === 'step3' && (!this.pnpPeriod1Id || !this.pnpPeriod2Id)) {
            $event.preventDefault();
        } else if ($event.nextId === 'step4' && (this.selectedRows.length == 0 || this.selectedColumns.length == 0)) {
            $event.preventDefault();
        }
    }
}