import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ErrorService } from 'src/app/core/services/error.service';
import { SupportService } from 'src/app/core/services/support.service';

/**
* Header Component.
*/
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
    /**
     * User  of header component
     */
    user: any;
    /**
     * Subscription  of user in header component
     */
    subscription: Subscription;
    /**
     * Sidebar  flag
     */
    sidebar = true;

    /**
     * Output click event
     */
    @Output() menuIconClicked = new EventEmitter();

    /**
     * User name or UnAuthorized user email id 
     */
    userNameOrEmail: string = null;

    /**
     * To Display Client database name
     */
    clientName: string = null;
    hasSupportAccess: boolean = false;
    clientEntityEnvironments: any;
    currentClientEntityEnvironmentName: string;

    /**
     * 
     */
    logOutButtonText: string = "Logout";

    /**
     * Creates an instance of header component.
     */
    constructor(
        private authService: AuthenticationService,
        private commonService: CommonService,
        private supportService: SupportService,
        private errorService: ErrorService
    ) { }

    /**
     * on init
     */
    ngOnInit() {
        this.user = this.authService.getLoggedInUser();
        if (this.user) {
            this.prepareUserDetails();
        }
        this.subscription = this.authService.watchUser().subscribe(user => {
            this.user = user;
            this.prepareUserDetails();
        });
    }

    prepareUserDetails() {
        this.userNameOrEmail = this.user.username;
        this.clientName = this.user.clientName;
        this.hasSupportAccess = this.user.hasSupportAccess;
        this.currentClientEntityEnvironmentName = this.user.currentClientEntityEnvironmentName;
        this.clientEntityEnvironments = this.user.clientEntityEnvironments;
        if (!this.userNameOrEmail) {
            this.logOutButtonText = "Sign in to different account"
            this.userNameOrEmail = this.errorService.unAuthorizedUserEmailId;
        }
    }

    /**
     * on destroy
     */
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    /**
     * Opens close sidebar
     */
    openCloseSidebar() {
        this.sidebar = !this.sidebar;
        this.commonService.setShowPanel(this.sidebar);
        this.menuIconClicked.emit(this.sidebar);
    }

    /**
     * Logs out from azure
     */
    onSignOut() {
        if (this.user && this.user.username) {
            this.authService.logoutUser();
        }
        else {
            this.signInWithDifferentAccount();
        }
    }

    signInWithDifferentAccount() {
        this.authService.msalLogOut();
    }

    switchClientEntityEnvironment(env) {
        this.supportService.switchClientEntityEnvironment(env.ClientEntityEnvironmentId);
    }
}
