<div class=" modal-header">
  <h5 class="modal-title d-flex align-items-center m-0 w-100">Review Validations<i
      class="fas fa-times ml-auto cursor-pointer" aria-label="Close" (click)="closeModal()"></i></h5>
</div>
<div class="modal-body">
  <p-table #vdt [value]="validationList" [paginator]="true" [rows]="8" class="table-irr validation-table"
    selectionMode="single" [(selection)]="selectedRpRow" [columns]="headerCols" [scrollable]="true"
    [scrollHeight]="'calc(100vh - 371px)'">
    <ng-template pTemplate="paginatorleft">
      <button class="btn btn-clear-filter hvr-shrink" type="button" (click)="vdt.reset();">
        <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
      </button>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field"> {{col.header}}
          <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
          </p-sortIcon>
        </th>
      </tr>
      <tr>
        <th *ngFor="let col of columns">
          <input type="text" class="w-100" placeholder="Filter Text"
            [value]="vdt.filters && vdt.filters[col.field] ? vdt.filters[col.field].value: ''"
            (input)="vdt.filter($event.target.value, col.field, 'contains')">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>{{rowData['validationID']}}</td>
        <td>{{rowData['lhs']}}</td>
        <td>{{rowData['operatorMath']}}</td>
        <td>{{rowData['rhs']}}</td>
        <td>{{rowData['lhs_sum']}}</td>
        <td>{{rowData['rhs_sum']}}</td>
        <td>{{rowData['condition']}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>