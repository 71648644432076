<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
    <div class="col-12 col-lg-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
    </div>
    <div class="col-12 col-lg-6 text-left text-lg-right">
        <button class="btn btn-primary-ey btn-icon small-font mr-2 hvr-shrink" type="button" (click)="onRefresh()">
            <div class="icon-container"><i class="fas fa-sync-alt"></i></div><span>Refresh</span>
        </button>
        <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button"
            (click)="openNewIngestionForm()">
            <div class="icon-container"><i class="fas fa-play"></i></div><span>Start New Ingestion</span>
        </button>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="data-ing-scroll">
            <p-table #dting [value]="ingestionsList" [paginator]="true" [rows]="9" class="table-irr data-ingestion"
                [columns]="headerCols" [scrollHeight]="'calc(100vh - 292px'" [scrollable]="true" >
                <ng-template pTemplate="paginatorleft">
                    <button class="btn btn-clear-filter hvr-shrink" type="button"
                        (click)="dting.reset();clearFilters();">
                        <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                    </button>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field"  style="min-width:100px">
                            {{col.header}}
                            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns">
                            <p-calendar *ngIf="col.field =='createddate'" [(ngModel)]="createdDateFilters" #rdFilter
                                selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy" [readonlyInput]="true"
                                placeholder="Select a range" appendTo="body" [monthNavigator]="true"
                                [yearNavigator]="true" yearRange="{{yearRange}}"
                                (ngModelChange)="dting.filter(createdDateFilters, col.field, 'dateRangeFilter')"
                                (onSelect)="closeDateRangeFilter(rdFilter, createdDateFilters)">
                            </p-calendar>

                            <input *ngIf="col.field !='createddate'" type="text" class="w-100" placeholder="Filter Text"
                                [value]="dting.filters && dting.filters[col.field] ? dting.filters[col.field].value: ''"
                                (input)="dting.filter($event.target.value, col.field, 'contains')">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>
                            <div>
                                {{rowData.filename}}
                            </div>
                            <div>
                                <span class="badge"
                                    [ngClass]="{'badge-completed':rowData.loadstatus === 'Completed' ,
                                  'badge-fail':rowData.loadstatus === 'Failed' || rowData.loadstatus === 'Abandoned',
                                  'badge-in-progress':rowData.loadstatus != 'Completed' && rowData.loadstatus != 'Failed' && rowData.loadstatus != 'Abandoned'}">
                                    {{rowData.loadstatus}}
                                </span>
                            </div>
                        </td>
                        <td>
                            {{rowData.reportPackId}}
                        </td>
                        <td>
                            {{rowData.reportPack}}
                        </td>
                        <td>{{rowData.username}}</td>
                        <td>
                            {{rowData.createddate | date: 'dd/MM/yyyy'}}<br />
                            {{rowData.createddate | date: 'hh:mm:ss a'}}
                        </td>
                        <td>{{rowData.rowslandedcount}}</td>
                        <td style="min-width:200px">
                            <div class="status-progress d-flex align-items-center position-relative">
                                <hr />
                                <div class="d-flex align-items-center justify-content-center flex-column mr-3 status-progress-container "
                                    [ngClass]="{'completed':rowData.templateValidationStatus==='Success',
                                                'failed':rowData.templateValidationStatus==='Failed',
                                                'in-progress':rowData.templateValidationStatus==='In Progress'}"
                                    container="body" ngbTooltip="Template
                                                Validation">
                                    <div class="circle-status d-flex align-items-center justify-content-center">
                                        <i class="fas fa-file-alt fa-lg"></i>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-center flex-column mr-3 status-progress-container "
                                    [ngClass]="{'completed':rowData.dataExtractionStatus==='Success',
                                            'failed':rowData.dataExtractionStatus==='Failed',
                                            'in-progress':rowData.dataExtractionStatus==='In Progress'}"
                                    container="body" ngbTooltip="Data Extraction">
                                    <div class="circle-status d-flex align-items-center justify-content-center">
                                        <i class="fas fa-external-link-alt fa-lg"></i>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-center flex-column mr-3 status-progress-container "
                                    [ngClass]="{'completed':rowData.dataValidationStatus==='Success',
                                            'failed':rowData.dataValidationStatus==='Failed',
                                            'in-progress':rowData.dataValidationStatus==='In Progress'}"
                                    container="body" ngbTooltip="Data
                                            Validation">
                                    <div class="circle-status d-flex align-items-center justify-content-center">
                                        <i class="fas fa-tasks fa-lg"></i>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-center flex-column mr-3 status-progress-container"
                                    [ngClass]="{'completed':rowData.dataLoadStatus==='Success',
                                            'failed':rowData.dataLoadStatus==='Failed',
                                            'in-progress':rowData.dataLoadStatus==='In Progress'}" container="body"
                                    ngbTooltip="Data
                                            Load">
                                    <div class="circle-status d-flex align-items-center justify-content-center">
                                        <i class="fas fa-cloud-upload-alt fa-lg"></i>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-center flex-column mr-3 status-progress-container"
                                    [ngClass]="{'completed':rowData.dataPrepStatus==='Success',
                                        'failed':rowData.dataPrepStatus==='Failed',
                                        'in-progress':rowData.dataPrepStatus==='In Progress'}" container="body"
                                    ngbTooltip="Data Preparation">
                                    <div class="circle-status d-flex align-items-center justify-content-center">
                                        <i class="fas fa-clipboard-check fa-lg"></i>
                                    </div>
                                </div>
                                <span class=" ml-auto">
                                    <i class="fas fa-file-download hvr-shrink failed-icon fa-lg"
                                        ngbTooltip="Download Error File" [hidden]="!rowData.errorDataExists"
                                        placement="left" container="body" (click)="downloadErrorFile(rowData)"></i>

                                    <i class="fas fa-exclamation-triangle hvr-shrink failed-icon ml-3 fa-lg"
                                        placement="left" *ngIf='rowData.loadstatus==="Failed"'
                                        ngbTooltip="{{rowData.processlog}}" container="body" triggers="click:blur"></i>

                                    <i class="fas fa-edit hvr-shrink fa-lg ml-3 cursor-pointer text-yellow"
                                        *ngIf="rowData.showDataEdit" ngbTooltip="Edit" container="body" placement="left"
                                        (click)="goToEditDataPage(rowData)"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer" let-columns></ng-template>
            </p-table>
        </div>
    </div>
</div>