<app-loader *ngIf="loading"></app-loader>
<div class="d-flex align-items-center filter-wrapper">
    <i class="fas fa-filter filter-wrapper-icon hvr-shrink mr-5" (click)="open(bundleTypeFilter)"
        ngbTooltip="View bundle types" container="body"></i>
    <div *ngIf="!selectedBundleType" class="filter-wrapper-content">No Bundle Type Selected</div>
    <div class="filter-wrapper-content align-items-center justify-content-center" *ngIf="selectedBundleType">
        <div class="col-12 specify-overflow" id="overflowCheck">
            <span class="value">
                <span class="title">Bundle Type | </span>{{selectedBundleType.bundleTypeName}}
            </span>
            <span class="value">
                <span class="title">Description | </span> {{selectedBundleType.bundleTypeDescription != null ?
                selectedBundleType.bundleTypeDescription : 'No description provided'}}
            </span>
        </div>
    </div>
    <i class="fas fa-align-center filter-wrapper-icon2 hvr-shrink ml-5"
        *ngIf="(selectedBundleType) && (selectedBundleType.bundleTypeDescription != null)" ngbTooltip="View description"
        container="body" (click)="openModal(descriptionContent); overflowToolCheck()"></i>
</div>

<ng-template #bundleTypeFilter>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">
            Apply Filter<i class="fas fa-times ml-auto cursor-pointer" aria-label="Close" (click)="closeModal();"></i>
        </h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="bundleType-filter-scroll">
                    <p-table #btdt [value]="bundleTypeList" [paginator]="true" [rows]="8" class="table-irr filter-table"
                        selectionMode="single" [(selection)]="selectedRow" dataKey="bundleTypeId" [columns]="headerCols"
                        [scrollable]="true" [scrollHeight]="'calc(100vh - 371px)'">
                        <ng-template pTemplate="paginatorleft">
                            <button class="btn btn-clear-filter hvr-shrink" type="button"
                                (click)="btdt.reset();clearFilters();">
                                <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                        ariaLabelDesc="Activate to sort in descending order"
                                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                                </th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns">
                                    <p-calendar *ngIf="col.field =='createdDate'" [(ngModel)]="createdDateFilters"
                                        #btFilter selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy"
                                        [readonlyInput]="true" placeholder="Select a range" appendTo="body"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="{{yearRange}}"
                                        (ngModelChange)="btdt.filter(createdDateFilters, col.field, 'dateRangeFilter')"
                                        (onSelect)="closeDateRangeFilter(btFilter, createdDateFilters)"></p-calendar>

                                    <input *ngIf="col.field !='createdDate'" type="text" class="w-100"
                                        placeholder="Filter Text"
                                        [value]="btdt.filters && btdt.filters[col.field] ? btdt.filters[col.field].value: ''"
                                        (input)="btdt.filter($event.target.value, col.field, 'contains')">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr [pSelectableRow]="rowData">
                                <td>{{rowData['bundleTypeName']}}</td>
                                <td>{{rowData['bundleTypeDescription']}}</td>
                                <td>{{rowData['createdBy']}}</td>
                                <td>{{rowData['createdDate'] | date: 'dd/MM/yyyy'}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="float-right">
            <button class="btn btn-modal-pos small-font hvr-shrink" (click)="setBundleTypeId();" type="button">
                <span>Submit</span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #descriptionContent>
    <div class="modal-body description-content text-center">
        <div class="row mb-3">
            <div class="col-12 text-right">
                <i class="fas fa-times-circle text-yellow cursor-pointer fa-lg" aria-label="Close"
                    (click)="closeModal();"></i>
            </div>
        </div>
        <div *ngIf="showDescription">
            <div class="row">
                <div class="col-12">
                    <p class="text-yellow mb-0">Bundle Type</p>
                    <p>{{selectedBundleType.bundleTypeName}}</p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <p class="text-yellow mb-0">Description</p>
                    <p>
                        {{selectedBundleType.bundleTypeDescription}}
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="!showDescription">
            <p>No detailed description for this bundle type.</p>
        </div>
    </div>
</ng-template>