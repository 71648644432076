import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';

import { RoleManagementService } from './role-management.service';
import { AlertService } from '../services/alert.service';

/**
 * Guard to authenticate with azure
 */
@Injectable()
export class CanActivateChildRoleGuard implements CanActivateChild {

  /**
   * Url  of can activate child role guard
   */
  private url: string[];
  /**
   * Resource  of can activate child role guard
   */
  private resource: string;
  /**
   * Suite of can activate child role guard
   */
  private suite: string;
  /**
   * Report of can activate child role guard
   */
  private report: string;

  /**
  * Sub Report of can activate child role guard
  */
  private subReport: string;

  private formName: string;

  /**
   * Role guard constructor
   */
  constructor(
    private router: Router,
    private roleService: RoleManagementService,
    private alertService: AlertService
  ) { }

  /**
   * Start role checking
   */
  canActivateChild(_, state): boolean {
    this.url = state.url.split('/');
    this.resource = this.url && this.url.length > 1 ? this.url[1] : null;
    this.formName = this.url && this.url.length > 2 ? this.url[2] : null;
    this.suite = this.url && this.url.length >= 3 ? this.url[3] : null;
    this.report = this.url && this.url.length >= 4 ? this.url[4] : null;
    this.subReport = this.url && this.url.length >= 5 ? (this.url[5] == 'subreports' ? null : this.url[5]) : null;
    //return this.hasPermission();
    return this.hasFormPermission(this.formName);
  }

  /**
   * Determines if has permissions
   */
  private hasPermission() {
    const hasPermission = this.roleService.checkPermissions('V', this.suite, (this.subReport == null ? this.report : this.subReport), this.resource);
    if (!hasPermission) {
      this.url.splice(this.url.length - 1, 1);
      const prevRoute = this.url.join('/');
      this.alertService.error('You cannot access the requested area', true);
      this.router.navigate([prevRoute]);
    }
    return hasPermission;
  }

  hasFormPermission(suite) {
    var resource = '';
    var report = null;
    var hasPermission = false;

    if (suite == 'task-board' || suite == 'reportpack-dashboard' || suite == 'suite') {
      resource = 'reporting';
    }
    else if (suite == 'data-ingestion' || suite == 'bulk-report-run') {
      resource = 'ingestion';
    }
    else if (suite == 'report-bundling') {
      resource = 'report-bundling';
    }
    else if (suite == 'user-management' || suite == 'reportpack-management' || suite == 'permission-management'
      || suite == 'role-management' || suite == 'report-schedule-management' || suite == 'bundle-type-management'
      || suite == 'report-pack-sequence-management' || suite == 'application-configuration') {
      resource = 'administration';
    }
    else if (suite == 'client-management' || suite == 'report-downloader' || suite == 'query-engine' || suite == 'ref-data') {
      resource = 'support';
    }

    if (resource == '') { return true; }
    hasPermission = this.roleService.checkPermissionsForMenu('V', null, report, resource);
    if (!hasPermission) {
      this.url.splice(this.url.length - 1, 1);
      const prevRoute = this.url.join('/');
      this.alertService.error('You cannot access the requested area', true);
      this.router.navigate(['/home']);
    }
    return hasPermission;

  }
}
