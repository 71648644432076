import { FilterUtils } from 'primeng/utils';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { DashboardService } from '../../../core/services/dashboard.service';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { DataIngestionService } from 'src/app/core/services/dataIngestion.service';

/**
 * IngestionPanelComponent
 */
@Component({
    selector: 'app-ingestion-panel',
    templateUrl: './ingestion-panel.component.html',
    styleUrls: ['./ingestion-panel.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class IngestionPanelComponent implements OnInit, OnDestroy {
    public loading = false;
    public ingestionsList: any[];
    public autoRefresh = interval(30000);
    private autoRef;
    templateGroups: any = [];
    completeTemplateList: any = [];
    private dateTime = new Date();
    private yearRange = "2000:" + this.dateTime.getFullYear().toString();
    headerCols: any[];
    createdDateFilters: Date[];

    /**
     * Creates an instance of approver panel with the list of ingestions.
     */
    constructor(
        private dashboardService: DashboardService,
        private router: Router,
        private authService: AuthenticationService,
        private dataIngestionService: DataIngestionService,
    ) {
    }

    /**
     * on init
     */
    ngOnInit() {

        // if (!this.authService.isPrivacyAcknowledged) {
        //     this.router.navigate(['/privacy-note']);
        // }

        this.headerCols = [
            { field: 'filename', header: 'File Name' },
            { field: 'reportPackId', header: 'Report Pack ID' },
            { field: 'reportPack', header: 'Report Pack Title' },
            { field: 'username', header: 'Ingested By' },
            { field: 'createddate', header: 'Ingested Date' },
            { field: 'rowslandedcount', header: 'Rows Landed' },
            { header: 'Status' }
        ];

        FilterUtils['dateRangeFilter'] = (value, filter: [Date, Date]): boolean => {
            if (!filter || (!filter[0] && !filter[1]))
                return true;
            var val = new Date(value);
            var convertDate = new Date(val.getFullYear() + '/' + (val.getMonth() + 1) + '/' + val.getDate());
            var s = filter[0].getTime();
            var e;
            if (filter[1])
                e = filter[1].getTime() + (24 * 60 * 60 * 1000) - 1000;
            else e = s + (24 * 60 * 60 * 1000) - 1000;
            return convertDate.getTime() >= s && convertDate.getTime() <= e;
        }

        this.getIngestions();
        this.autoRef = this.autoRefresh.subscribe(x => this.ingestionsList && this.ingestionsList.filter(x => x.loadstatus == 'In Progress' || x.loadstatus == 'Not Started').length > 0 ? this.getIngestions() : null);
    }

    private getIngestions() {
        this.loading = true;
        this.dataIngestionService.getIngestions()
            .then((data: any) => {
                this.ingestionsList = data;
                let filterLoadStatus = data.filter(function (inges) {
                    return (inges.loadstatus != "Completed" && inges.loadstatus != "Failed")
                });

                if (filterLoadStatus.length === 0) {
                    //stop the auto refresh interval 
                    this.autoRef.unsubscribe();
                }
                if (!this.dataIngestionService.dataForStartIngestion) {
                    this.dataIngestionService.getStartIngestionData().then((data: any) => {
                        this.prepTemplateList(data);
                        this.loading = false;
                    }).catch(() => { this.loading = false; });
                }
                else {
                    this.prepTemplateList(this.dataIngestionService.dataForStartIngestion);
                    this.loading = false;
                }
            }).catch(() => { this.loading = false; });
    }

    openNewIngestionForm() {
        this.router.navigate(['/app/data-ingestion/new-ingestion']);
    }

    /**
     * Determines whether refresh on
     */
    onRefresh() {
        this.loading = true;
        this.autoRef.unsubscribe();
        this.getIngestions();
        this.autoRefresh = interval(30000);
        this.autoRef = this.autoRefresh.subscribe(x => this.ingestionsList && this.ingestionsList.filter(x => x.loadstatus == 'In Progress' || x.loadstatus == 'Not Started').length > 0 ? this.getIngestions() : null);
    }

    ngOnDestroy() {
    }

    async downloadErrorFile(row) {
        await this.dashboardService.getIngestionErrorFile(row.loadid, row.filename.replace(/[^a-zA-Z0-9 ]/g, ""));
    }

    prepTemplateList(data) {
        var templateList = JSON.parse(JSON.stringify(data.IngestionTemplates));

        if (this.templateGroups.length == 0 && this.completeTemplateList.length == 0) {
            templateList.forEach(el => {
                if (this.templateGroups.filter(x => x.value == el.TemplateGroupId).length == 0) {
                    this.templateGroups.push({ value: el.TemplateGroupId, name: el.TemplateGroup });
                }

                this.completeTemplateList.push({
                    name: el.TemplateName, value: el.TemplateId, templateGroupId: el.TemplateGroupId, showFileUpload: el.ShowFileUpload, tableId: el.TableId,
                    reportDateMandatory: el.ReportDateMandatory, priorReportPackMappingRequired: el.PriorReportPackMappingRequired,
                    reportPackLinkType: el.ReportPackLinkType, correspondingReportId: el.CorrespondingReportId, correspondingReport: el.CorrespondingReportName
                });
            });
            this.dataIngestionService.ingestionTemplateGroups = JSON.parse(JSON.stringify(this.templateGroups));
            this.dataIngestionService.ingestionTemplates = JSON.parse(JSON.stringify(this.completeTemplateList));
        }
    }

    clearFilters() {
        this.createdDateFilters = null;
    }

    closeDateRangeFilter(dateComponent: any, filters: Date[]) {
        if (filters[1]) {
            dateComponent.hideOverlay();
        }
    }

    onChange(event) {
        console.log('event :' + event);
        console.log(event.value);
    }

    goToEditDataPage(row) {
        var model = {};

        var form = this.completeTemplateList.filter(x => x.tableId == row.tableId)[0];
        model['reportpacktitle'] = row.reportPack;
        model['fileName'] = row.filename;
        model['reportpackid'] = row.reportPackId;
        model['loadId'] = row.loadid;
        model['currentStatus'] = row.loadstatus;
        model['reportingperiod'] = row.reportingperiod;
        model['tableId'] = row.tableId;
        model['priorReportPackId'] = row.priorReportPackId;
        model['priorReportPackMappingRequired'] = form.priorReportPackMappingRequired;
        model['reportPackLinkType'] = form.reportPackLinkType;
        model['correspondingReportId'] = form.correspondingReportId;
        model['correspondingReport'] = form.correspondingReport;
        model['templateGroupDataTypes'] = this.getTableIdsForTemplateGroup(form.templateGroupId);
        this.dataIngestionService.ingestionLoadData = model;
        if (row.IsFormTypeIngestion) {
            this.router.navigate(['/app/data-ingestion/ingestion-form']);
        }
        else {
            this.router.navigate(['/app/data-ingestion/ingestion-file']);
        }
    }

    getTableIdsForTemplateGroup(templateGroupId: number) {
        var templateGroupDataTypes = [];
        this.completeTemplateList.filter(x => x.templateGroupId == templateGroupId).forEach(el => {
            templateGroupDataTypes.push(el.tableId);
        });
        return templateGroupDataTypes;
    }
}
