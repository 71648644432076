import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { TreeNode } from 'primeng';
import { ReportTreeService } from 'src/app/core/services/reportTree.service';

@Component({
  selector: 'app-report-tree',
  templateUrl: './report-tree.component.html',
  styleUrls: ['./report-tree.component.css']
})
export class ReportTreeComponent implements OnInit {
  @Input() selectedReports: string;
  @Input() selectDependencies: boolean = false;
  @Output() selectedReportsChange = new EventEmitter<string>();

  selectedNodes: TreeNode[];
  reportTreeHeaders: any;
  loading: boolean = false;
  reportDataTree: any[] = [];
  selectAllChecked: boolean = false;


  constructor(private reportTreeService: ReportTreeService) {

  }

  ngOnInit(): void {


    this.reportTreeHeaders = [
      { field: 'DisplayName', header: 'Name' },
      { field: 'Description', header: 'Description' }
    ];

    if (this.reportTreeService.reportTreeDataList == null || this.reportTreeService.reportTreeDataList == undefined) {
      this.loading = true;
      this.reportTreeService.getReportTreeData().then(() => {
        this.loading = false;
        this.reportDataTree = JSON.parse(JSON.stringify(this.reportTreeService.reportTreeDataList));
        this.prepareDataPrepNodeSelection();
      }).catch(() => {
        this.loading = false;
      })
    }
    else {
      this.reportDataTree = JSON.parse(JSON.stringify(this.reportTreeService.reportTreeDataList));
      this.prepareDataPrepNodeSelection();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.prepareDataPrepNodeSelection();
  }

  prepareDataPrepNodeSelection() {
    if (this.selectedReports != null && this.selectedReports != '') {
      var selectedReportsArray = this.selectedReports.split('|');
      this.selectedNodes = [];
      this.reportDataTree.forEach(parentNode => {
        var selectedChildrenNodes = parentNode.children.filter(childNode => selectedReportsArray.map(x => x).includes(childNode.data.Name));
        if (selectedChildrenNodes.length == parentNode.children.length) this.selectedNodes.push(parentNode);
        this.selectedNodes.push(...selectedChildrenNodes);
      });

    }
    else {
      this.selectedNodes = [];
    }
    this.countSelectedReports();
  }

  selectAllReports(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    let selectedReportsArray = this.selectedNodes.map(node => node.data.Name);
    this.selectedReports = selectedReportsArray.join('|');
    this.selectedReportsChange.emit(this.selectedReports);
    this.countSelectedReports();
  }

  nodeSelectChange(event: any) {
    if (this.selectDependencies) {
      if (event.node.data.isSourceReport == true) {
        event.preventDefault();
      }
      else {
        var reportDependencies = [];
        this.selectedNodes.filter(node => node.parent).map(x => x.data.SourceDependencies).forEach(dList => {
          if (dList && dList.length > 0) {
            reportDependencies = reportDependencies.concat(dList.filter((item) => reportDependencies.indexOf(item) < 0));
          }
        });

        this.reportDataTree.forEach(suite => {
          suite.children.forEach(report => {
            if (reportDependencies.filter(x => x.ParentReportId == report.data.Id).length > 0) report.data.isSourceReport = true;
            else report.data.isSourceReport = false;
            if (report.data.isSourceReport == true && this.selectedNodes.filter(x => x.data.Id == report.data.Id).length == 0) {
              this.selectedNodes.push(report);
            }
          });
        });
      }
    }

    let selectedReportsArray = this.selectedNodes !== null && this.selectedNodes !== undefined && this.selectedNodes.length > 0 ?
      this.selectedNodes.filter(node => node.parent).map(x => x.data.Name) : [];
    this.selectedReports = selectedReportsArray.length > 0 ? selectedReportsArray.join('|') : '';
    this.countSelectedReports();
    this.selectedReportsChange.emit(this.selectedReports);
  }

  countSelectedReports() {
    this.reportDataTree.forEach(rowNode => {
      if (rowNode.children.length > 0) {
        let selReport = this.selectedNodes.filter(sNode => (sNode.parent && sNode.parent.data.Name == rowNode.data.Name) ||
          rowNode.children.filter(childNode => childNode.data.Name == sNode.data.Name).length > 0);
        rowNode.data.selectedReportsCount = selReport.length ? '(Selected Reports: ' + selReport.length + ')' : '';
      }
    });
  }

  clearDataPrepFilters() {
    this.reportDataTree = JSON.parse(JSON.stringify(this.reportTreeService.reportTreeDataList));
    this.selectedReports='';
    this.prepareDataPrepNodeSelection();
  }

  onDataPrepFilterChange(event: any) {
    var filteredList = event.filteredValue;
    this.prepareDataPrepNodeSelection();
  }
}
