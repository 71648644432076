import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../security/authentication.service';

@Injectable()
export class PrivacyNoticeService {
    baseURL: string = environment.apiUrl;
    constructor(private apiService: ApiService, private authService: AuthenticationService) { }

    getPrivacyNotice() {
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('getPrivacyNotice', '')
                .toPromise()
                .then(
                    res => {
                        resolve(res);
                    },
                    msg => {
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    savePrivacyNoticeSigning(userId, privacyNoticeId) {
        const promise = new Promise((resolve, reject) => {
            this.apiService.put('saveUserPrivacySigning/', userId, privacyNoticeId, 'Privacy notice acknowledged!')
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getPrivacyNoticeExcel() {
        window.location.href = this.baseURL + 'getPrivacyNoticeExcel?token=' + this.authService.getTokenHeader();
    }

    updatePrivacyNotice(userId,Content,userUpdateFlag=false){
        const promise = new Promise((resolve, reject) => {
            this.apiService.put('updatePrivacyNotice/', userId, Content, 'Privacy notice updated')
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }
}
