import { FilterUtils } from 'primeng/utils';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { Subscription } from 'rxjs';
import { getTable } from '../../sample-table.service';

/**
 * Component
 */
@Component({
    selector: 'app-report-pack-dashboard',
    templateUrl: './reportpack-dashboard.component.html',
    styleUrls: ['./reportpack-dashboard.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ReportPackDashboardComponent implements OnInit {
    reportPackId: number;
    reportPackIdSub: Subscription;
    reportPackSummary: any;
    reportList: any;
    gridList: any;
    selectedStatusFilter: any;
    loading = false;
    isCollapsed = true;
    first: number = 0;
    selectedRp: any;
    headerCols: any;
    startDateFilter: Date[];
    endDateFilter: Date[];
    statusChangedDateFilter: Date[];
    private dateTime = new Date();
    private yearRange = "2000:" + this.dateTime.getFullYear().toString();

    constructor(
        private dashboardService: DashboardService
    ) { }

    ngOnInit() {
        this.headerCols = [
            { field: 'SuiteName', header: 'Suite' },
            { field: 'ReportDisplayName', header: 'Report' },
            { field: 'SubReportDisplayName', header: 'Sub Report' },
            { field: 'StartDate', header: 'Start Date' },
            { field: 'StatusChangedDate', header: 'Status Changed Date' },
            { field: 'EndDate', header: 'End Date' },
            { field: 'Status', header: 'Status' },
        ];

        FilterUtils['dateRangeFilter'] = (value, filter: [Date, Date]): boolean => {
            if (!filter || (!filter[0] && !filter[1]))
                return true;

            var val = new Date(value);
            var s = filter[0].getTime();
            var e;
            if (filter[1])
                e = filter[1].getTime() + (24 * 60 * 60 * 1000) - 1000;
            else e = s + (24 * 60 * 60 * 1000) - 1000;
            return val.getTime() >= s && val.getTime() <= e;
        }

        this.reportPackId = this.dashboardService.reportPackID;
        this.selectedRp = this.dashboardService.selectedReportPack;
        this.reportPackIdSub = this.dashboardService.watchReportPackId().subscribe(rpId => {
            this.reportPackId = rpId;
            this.selectedRp = this.dashboardService.selectedReportPack;
            this.getReportPackSummary();
        });

        this.getReportPackSummary();
    }

    getReportPackSummary() {
        if (this.reportPackId != null && this.reportPackId != 0) {
            this.loading = true;

            this.dashboardService.getReportPackSummary()
                .then((data: any) => {
                    this.reportPackSummary = data.Summary;
                    this.reportList = data.ReportsList;
                    this.gridList = data.ReportsList;
                    this.selectedStatusFilter = null;
                    this.loading = false;
                }, () => {
                    this.loading = false;
                });
        }
    }

    filterGridData(statusFilter: any) {
        if (this.selectedStatusFilter == statusFilter) {
            this.selectedStatusFilter = null;
            this.gridList = this.reportList;
        }
        else {
            this.selectedStatusFilter = statusFilter;
            this.gridList = this.reportList.filter(x => x.Status.indexOf(statusFilter) > -1);
        }
        this.first = 0;
    }

    clearFilters() {
        this.startDateFilter = null;
        this.statusChangedDateFilter = null;
        this.endDateFilter = null;
        this.selectedStatusFilter = null;
        this.gridList = this.reportList;
    }

    closeDateRangeFilter(dateComponent: any, filters: Date[]) {
        if (filters[1]) {
            dateComponent.hideOverlay();
        }
    }


    getClass(status) {
        switch (status) {
            case 'Not Started':
                return 'notStarted';
            case 'Developed':
                return 'developed';
            case 'Prepared':
                return 'prepared';
            case 'Completed':
                return 'completed';
            default:
                return 'aip';
        }
    }
}