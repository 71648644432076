<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
  <div class="col-12 col-lg-6">
    <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
      [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
  </div>
  <div class="col-12  text-left text-lg-right" *ngIf="selectedRp">
    <button class="btn btn-primary-ey btn-icon small-font mr-2 hvr-shrink" type="button" (click)="onRefresh()">
      <div class="icon-container"><i class="fas fa-sync-alt"></i></div><span>Refresh</span>
    </button>
    <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button" (click)="prepareReportData(false)">
      <div class="icon-container"><i class="far fa-calendar-check fa-lg"></i></div><span>Prepare
        Data</span>
    </button>
    <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" *appPermission="['S',null,null,'support']"
      type="button" (click)="prepareReportData(true)">
      <div class="icon-container"><i class="far fa-calendar-check fa-lg"></i></div><span>Re-Prepare
        Data</span>
    </button>
  </div>
</div>
<app-report-pack-filter></app-report-pack-filter>
<app-no-report-pack *ngIf="!selectedRp"></app-no-report-pack>
<div class="row mt-3" *ngIf="selectedRp">
  <div class="col-12">
    <h6 *ngIf="ingestionsInProgress" class="text-white-50 font-italic small-font mt-3"><i
        class="fas fa-exclamation-triangle hvr-shrink failed-icon mr-3"></i>There are ingestion(s) in progress for this
      report pack. Please initiate data preparation after ingestion(s) are complete for the selected report pack!</h6>
  </div>
  <div class="col-12">
    <h6 class="text-white-50  font-italic small-font mt-3"><i class="fas fa-info-circle text-yellow mr-3"></i>Please
      note that the source dependencies of the selected reports will included by default (if not already
      prepared/selected) in the final preparation list. </h6>
    <h6 *ngIf="hasNewLoad" class="text-white-50  font-italic small-font mt-3"><i
        class="fas fa-info-circle text-yellow mr-3"></i>The report(s) are not prepared for the latest data load. Please
      prepare data as necessary.</h6>
    <div class="data-admin-table">
      <p-treeTable #dtadmn [value]="gridReportTreeList" selectionMode="checkbox" class="table-irr"
        [(selection)]="selectedNodes"
        (onNodeSelect)="nodeSelectChange($event)" (onNodeUnselect)="nodeSelectChange($event)" [columns]="headerCols"
        [paginator]="true" [rows]="4" [scrollable]="true" [scrollHeight]="'calc(100vh - 400px)'" sortField="DisplayName"
        sortOrder="1">
        <ng-template pTemplate="paginatorleft">
          <button class="btn btn-clear-filter hvr-shrink" type="button" (click)="dtadmn.reset();clearFilters();">
            <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
          </button>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [ttSortableColumn]="col.field">
              <ng-container *ngIf="col.field === 'DisplayName'; else notNameColumn">
                <p-treeTableHeaderCheckbox (click)="SelectAllReports($event)"></p-treeTableHeaderCheckbox>
              </ng-container>
              {{col.header}}
              <p-treeTableSortIcon [field]="col.field"></p-treeTableSortIcon>
          </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns">
              <p-calendar *ngIf="col.field =='LastPreparedDate'" [(ngModel)]="preparedDateFilters" #rdFilter
                selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy" [readonlyInput]="true"
                placeholder="Select a range" appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="{{yearRange}}"
                (ngModelChange)="dtadmn.filter(preparedDateFilters, col.field, 'dateRangeFilter')"
                (onSelect)="closeDateRangeFilter(rdFilter, preparedDateFilters)">
              </p-calendar>

              <input type="text" class="w-100" placeholder="Filter Text"
                *ngIf="col.field !='LastPreparedDate' && col.header!= 'Status'"
                [value]="dtadmn.filters && dtadmn.filters[col.field] ? dtadmn.filters[col.field].value: ''"
                (input)="dtadmn.filter($event.target.value, col.field, 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
          <tr>
            <td
              [title]="rowData.isSourceReport == true ? 'This report cannot be disabled as it has dependencies selected!': ''">
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
              <p-treeTableCheckbox [value]="rowNode" ngDefaultControl
                [ngClass]="rowData.isSourceReport == true ? 'disabled-checkbox':''">
              </p-treeTableCheckbox>
              {{rowData.DisplayName}} {{rowData.selectedReportsCount}}
              <i *ngIf="rowData.PrepLoadId!= 0 && rowData.PrepLoadId < rowData.MaxLoadId"
                class="fas fa-info-circle text-yellow small-font float-right"></i>
              <i *ngIf="rowData.Status === 'In Progress' || rowData.Status === 'Not Started'"
                class="fas fa-spinner fa-pulse fa-lg float-right"></i>
              <button class="btn btn-icon-round hvr-shrink overlay-btn ml-2 float-right"
                *ngIf="(rowData.SourceDependencies && rowData.SourceDependencies.length > 0) || (rowData.Dependents && rowData.Dependents.length > 0)"
                (click)="viewDependencies(dependencyListModal, rowData)" type="button" ngbTooltip="View Dependencies"
                container="body" tooltipClass="reporting-tooltip">
                <i class="fab fa-codepen fa-lg dependency-icon float-right"></i>
              </button>
            </td>
            <td>{{rowData.Description}}</td>
            <td>{{rowData.LastPreparedBy}}</td>
            <td>{{rowData.LastPreparedDate | date: 'dd/MM/yyyy'}}</td>
            <td>
              <div class="status-progress d-flex align-items-center position-relative" *ngIf="rowData.Status">
                <div
                  class="d-flex align-items-center justify-content-center flex-column mr-5 status-progress-container disabled-view "
                  [ngClass]="{'not-applicable':rowData.Status==='Not Applicable'}">
                  <div class="circle-status d-flex align-items-center justify-content-center">
                    <i class="fas fa-exclamation fa-lg"></i>
                  </div><span>
                    Not Applicable</span>
                </div>
                <div
                  class="d-flex align-items-center justify-content-center flex-column mr-5 status-progress-container disabled-view "
                  [ngClass]="{'completed':rowData.Status==='Success'}">
                  <div class="circle-status d-flex align-items-center justify-content-center">
                    <i class="fas fa-check fa-lg"></i>
                  </div><span>Data
                    Preparation Completed</span>
                </div>
                <div
                  class="d-flex align-items-center justify-content-center flex-column mr-5 status-progress-container disabled-view"
                  [ngClass]="{'failed':rowData.Status==='Failed'}">
                  <div class="circle-status d-flex align-items-center justify-content-center">
                    <i class="fas fa-times fa-lg"></i>
                  </div><span>Data
                    Preparation Failed</span>
                </div>
                <i class="fas fa-exclamation-triangle hvr-shrink failed-icon fa-lg ml-auto" placement="left"
                  *ngIf='rowData.ErrorMessage != "" &&  rowData.ErrorMessage != null && rowData.Status==="Failed"'
                  ngbTooltip={{rowData.ErrorMessage}}></i>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-treeTable>
    </div>
  </div>
</div>

<ng-template #dependencyListModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title d-flex align-items-center m-0 w-100">View Report Dependencies
      <i class="fas fa-times ml-auto cursor-pointer" aria-label="Close" (click)="closeViewDependenciesModal()">
      </i>
    </h5>
  </div>
  <div class="modal-body reports-modal-container">
    <div class="flex flex-column gap-3">
      <div class="dependencies-radio-buttons">
        <p-radioButton name="dependencyDirection" value="ViewSource" inputId="viewDependencySource"
          [(ngModel)]="dependencyDirection"></p-radioButton>
        <label for="viewDependencySource" class="ml-2"> View Reports that {{reportSelectedForDependencyView}}
          depends on</label>
      </div>
      <div class="dependencies-radio-buttons">
        <p-radioButton name="dependencyDirection" value="ViewDependents" inputId="viewDependents"
          [(ngModel)]="dependencyDirection"></p-radioButton>
        <label for="viewDependents" class="ml-2"> View Reports that are dependent on
          {{reportSelectedForDependencyView}}</label>
      </div>
    </div>
    <div class="horizontal-line"></div>
    <div *ngIf="dependencyDirection == 'ViewSource' && selectedReportSourceDependencies.length == 0">
      <p style="font-style: italic;">{{reportSelectedForDependencyView}} is not dependent on any report.</p>
    </div>
    <div *ngIf="dependencyDirection == 'ViewSource' && selectedReportSourceDependencies.length > 0">
      <ul>
        <li *ngFor="let d of selectedReportSourceDependencies">{{ d.ParentSuiteName }} - {{d.ParentDisplayName}}</li>
      </ul>
    </div>
    <div *ngIf="dependencyDirection == 'ViewDependents' && selectedReportDependents.length == 0">
      <p style="font-style: italic;">There are no reports dependent on {{reportSelectedForDependencyView}}.</p>
    </div>
    <div *ngIf="dependencyDirection == 'ViewDependents' && selectedReportDependents.length > 0">
      <ul>
        <li *ngFor="let d of selectedReportDependents">{{ d.SuiteName }} - {{d.ReportDisplayName}}</li>
      </ul>
    </div>
  </div>
</ng-template>