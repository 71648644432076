import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AlertService } from 'src/app/core/services/alert.service';
import { ReportBundlingService } from 'src/app/core/services/reportBundling.service';
import { Router } from '@angular/router';

@Component({
  selector: 'report-bundling-form',
  templateUrl: './report-bundling-form.component.html',
  styleUrls: ['./report-bundling-form.component.css'],
})
export class ReportBundlingFormComponent implements OnInit {
  loading: boolean = false;
  selectedBundle: any;
  viewMode: boolean = false;

  headerCols: any;
  selectedReportPack: any;
  selectedBundleType: any;
  bundleTypeReports: any;
  actionList: any = [];
  dropDownSettings: IDropdownSettings;

  showWarning: boolean = false;
  warningMessage: string;

  constructor(private alertService: AlertService,
    private reportBundlingService: ReportBundlingService,
    private router: Router) { }

  ngOnInit() {
    if (!this.reportBundlingService.selectedBundle) {
      this.goToReportBundlingPage();
    }
    else {
      this.viewMode = this.reportBundlingService.viewMode;
      this.selectedBundle = JSON.parse(JSON.stringify(this.reportBundlingService.selectedBundle));

      if (this.viewMode) {
        this.headerCols = [
          { field: 'suiteName', header: 'Suite' },
          { field: 'reportName', header: 'Report' },
          { field: 'reportDescription', header: 'Report Description' },
          { field: 'reportingTypeName', header: 'Reporting Type' },
          { field: 'generatedFileName', header: 'Reference Report' }
        ];
        this.getBundleReferenceReports();
      } else {
        this.getBundlingActions();
        this.dropDownSettings = {
          singleSelection: false,
          idField: 'actionId',
          textField: 'actionName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 5,
          allowSearchFilter: false
        };
        this.headerCols = [
          { field: 'suiteName', header: 'Suite' },
          { field: 'reportName', header: 'Report' },
          { field: 'reportDescription', header: 'Report Description' },
          { field: 'reportingTypeName', header: 'Reporting Type' }
        ];
      }
    }
  }

  goToReportBundlingPage() {
    this.reportBundlingService.selectedBundle = null;
    this.router.navigate(['/app/report-bundling']);
  }

  clearChanges() {
    this.selectedBundle = JSON.parse(JSON.stringify(this.reportBundlingService.selectedBundle));
    this.selectedBundleType = null;
    this.selectedReportPack = null;
    this.bundleTypeReports = null;
  }

  onReportPackSelection() {
    if (this.selectedReportPack) {
      this.selectedBundle.reportPackId = this.selectedReportPack.ReportPackID;
      if (this.selectedBundle.bundleTypeId && this.selectedBundle.bundleTypeId != 0) {
        this.getBundleTypeReports();
      }
    }
    else {
      this.selectedBundle.reportPackId = 0;
    }
  }

  onBundleTypeSelection() {
    if (this.selectedBundleType) {
      this.selectedBundle.bundleTypeId = this.selectedBundleType.bundleTypeId;
      if (!this.selectedBundle.reportPackId || this.selectedBundle.reportPackId == 0) {
        this.bundleTypeReports = this.selectedBundleType.reports;
      }
      else this.getBundleTypeReports();
    }
    else {
      this.selectedBundle.bundleTypeId = 0;
      this.bundleTypeReports = null;
    }
  }

  getBundlingActions() {
    if (!this.reportBundlingService.bundlingActionList || this.reportBundlingService.bundlingActionList.length == 0) {
      this.loading = true;
      this.reportBundlingService.getBundlingActions()
        .then((data: any) => {
          this.actionList = data;
          this.loading = false;
        }).catch(() => { this.loading = false; });
    }
    else this.actionList = this.reportBundlingService.bundlingActionList;
  }

  getBundleTypeReports() {
    this.loading = true;
    this.reportBundlingService.getBundleTypeReports(this.selectedBundle.reportPackId, this.selectedBundle.bundleTypeId)
      .then((data: any) => {
        this.bundleTypeReports = data;
        this.showWarning = this.bundleTypeReports.filter(x => !x.isDataPrepared).length > 0;
        this.warningMessage = 'Data has not been prepared for the latest load for certain reports.'
        this.loading = false;
      }).catch(() => { this.loading = false; });
  }

  getBundleReferenceReports() {
    this.loading = true;
    this.reportBundlingService.getBundleReferenceReports(this.selectedBundle.bundleId)
      .then((data: any) => {
        this.bundleTypeReports = data;
        this.showWarning = this.bundleTypeReports.filter(x => !x.isLatestLoadData || !x.hasLatestData).length > 0;
        this.warningMessage = 'Certain reports do not have the latest data for the report pack.'
        this.loading = false;
      }).catch(() => { this.loading = false; });
  }

  downloadGeneratedFile(fileName) {
    this.alertService.info('The requested file will be downloaded in a while.');
    this.reportBundlingService.downloadFile(fileName);
  }

  submitReportBundle() {
    if (!this.selectedBundle.bundleName || this.selectedBundle.bundleName.trim() == '') {
      this.alertService.error('Bundle Name is a mandatory field.'); return;
    }
    else if (!this.selectedBundle.bundleTypeId || this.selectedBundle.bundleTypeId == 0) {
      this.alertService.error('Please select the bundle type.'); return;
    }
    else if (!this.selectedBundle.reportPackId || this.selectedBundle.reportPackId == 0) {
      this.alertService.error('Please enter the report pack.'); return;
    }
    else if (!this.selectedBundle.actions || this.selectedBundle.actions.length == 0) {
      this.alertService.error('Please select atleast one action to be performed.'); return;
    }
    else {
      this.loading = true;
      this.reportBundlingService.startReportBundling(this.selectedBundle)
        .then(() => {
          this.loading = false;
          this.goToReportBundlingPage();
        }).catch(() => { this.loading = false; });
    }
  }
}
