/**
 * Power BI Reports Model
 */
export class PowerBiReport {
    /**
     * DisplayName
     */
    displayName:string;

    /**
     * ReportName
     */
    reportName:string;

    /**
     * ReportRefId
     */
    reportRefId:string;

    /**
     * ReportUrl
     */
    reportUrl:string;

    /**
     * GroupId
     */
    groupId:number;

    /**
     * ParentReportId
     */

     parentReportId:number;

    /**
     * ReportLogoContent
     */
    reportLogoContent:string;

    /**
     * parentGroupId
     */
    parentGroupId:number;

    /**
     * subReports
     */
    subReports:PowerBiReport[];

    /**
     * reportId
     */
    reportId:number;
}