import { Component, OnInit } from '@angular/core';

 /**
  * Loader Component.
  */
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
