import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class ReportScheduleService {
  reportScheduleList: object = [];
  reportList: object = [];
  scheduledReport: object = [];

  constructor(private apiService: ApiService) { }

  getReportScheduleList() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getScheduledReports/', '')
        .toPromise()
        .then(
          res => {
            this.reportScheduleList = res;
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  submitReportSchedule(param, message) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.post('submitReportSchedule/', param, message)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  deleteReportSchedule(param) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.post('DeleteReportSchedule/', param, 'Report schedule deleted successfully')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getScheduledReports(weekStartDate) {
    const params = {
      fallDate: weekStartDate.getFullYear().toString() + "-" + (weekStartDate.getMonth()+1).toString() + "-" + weekStartDate.getDate().toString()
    };

    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getreportschedule/', params)
        .toPromise()
        .then(
          res => {
            this.scheduledReport = res;
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }
  getReportApprovalLevel(report) {
    const params = {
       ReportShortName:report.dynamicReport == true? report.reportName :report.ReportShortName, 
       SuiteName:report.SuiteName, 
       ScheduleDate:report.FallDate, 
       reportPackId : 0,
       RSID : report.RSID,
       CountryCode: report.dynamicReport == true? report.ReportShortName:null
    };

    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getReportApprovalLevel/', params)
        .toPromise()
        .then(
          res => {
            this.scheduledReport = res;
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

  assignReportToMe(RSI,RSIU){
    const params = {
      reportScheduleInstanceDto: RSI, 
      reportScheduleInstanceUserDto:RSIU 
   };

   const promise = new Promise((resolve, reject) => {
     this.apiService.post('assignReportToMe/', params,'Report assigned successfully')
       .toPromise()
       .then(
         res => {
           resolve(res);
         },
         msg => {
           reject(msg);
         }
       );
   });
   return promise;
  }

  releaseReportFromMe(param){
   const promise = new Promise((resolve, reject) => {
     this.apiService.post('releaseReportFromMe/', param,'Report released')
       .toPromise()
       .then(
         res => {
           resolve(res);
         },
         msg => {
           reject(msg);
         }
       );
   });
   return promise;
  }

  releaseReportPack(params){
   const promise = new Promise((resolve, reject) => {
     this.apiService.post('releaseReportPack/', params,'Report pack released')
       .toPromise()
       .then(
         res => {
           resolve(res);
         },
         msg => {
           reject(msg);
         }
       );
   });
   return promise;
  }

  updateUser(params){
   const promise = new Promise((resolve, reject) => {
     this.apiService.post('updateReportUser/', params,'Report assigned successfully')
       .toPromise()
       .then(
         res => {
           resolve(res);
         },
         msg => {
           reject(msg);
         }
       );
   });
   return promise;
  }

  getReportScheduleInstanceUser(params){
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getReportScheduleInstanceUser/', params)
        .toPromise()
        .then(
          res => {
            // this.scheduledReport = res;
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;    
   }

   checkInstanceMappedWithReportPack(params){
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('checkReportPackIsLinked/', params)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;    
   }

   checkRP_IsMappedWithOtherDueDate(params){
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('checkRPMappedWithOtherDueDate/', params)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;    
   }
   checkReportPackMappedWithReport(params){
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('checkReportPackMappedWithReport/', params)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;    
   }

   creatAdhocReportSchedule(param, message) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.post('creatAdhocReportSchedule/', param, message)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  isSubReportSignedOff(params){
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('IsSubReportSignedOff/', params)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;    
   }
   EditReportSchedule(param, message) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.post('editReportSchedule/', param, message)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}