import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import * as pbi from 'powerbi-client';
import { AnalyticService } from 'src/app/core/services/analytics.service';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-corepAnalyticsDashboard-panel',
  templateUrl: './corep_analytics_dashboard.component.html',
  styleUrls: ['./corep_analytics_dashboard.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class COREPAnalyticsDashboardComponent implements OnInit, OnDestroy {
  public screenHeight: number;
  @ViewChild('reportContainer', { static: false }) reportContainer: ElementRef;

  public loading = false;
  analyticsPowerBiReports:any[] = [];
  analyticReportName:string = '';
  redirectSubscription: Subscription;
  isAccessDenied: any;

  constructor(private analyticService: AnalyticService, private titleService: Title, 
    private router:ActivatedRoute, private route: Router) { }

  async ngOnInit() {
    this.redirectSubscription = this.router.queryParamMap.subscribe(queryParams => {  
      const currentUrl = this.route.url.split('/');
      const report = currentUrl && currentUrl.length > 3 ? currentUrl[3] : null;
      if (report) {
        this.analyticReportName = report;
      }
      this.LoadPowerBiReport();
    });   
  }
  
  LoadPowerBiReport(){
    this.loading = true;
    this.analyticService.getPowerBIToken(this.analyticReportName).then
      ((result: any) => {
        if (result.embedToken) {
          this.isAccessDenied = false;
          this.titleService.setTitle('IRR: ' + result.reportDisplayName);
          this.showReport(result.embedToken, result.reportUrl, result.reportId);
          this.loading = false;
        }
        else {
          this.loading = false;
          this.isAccessDenied = true;
        }
      }, () => {
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this.redirectSubscription.unsubscribe()
   }

  showReport(embedToken,reportUrl,reportId) {
    this.loading = false;
    var models = pbi.models;
    // Embed URL
    let config: pbi.IEmbedConfiguration  = {
      type: 'report',
      tokenType: models.TokenType.Embed,
      accessToken: embedToken,
      embedUrl: reportUrl,
      id: reportId,
      settings: {filterPaneEnabled: false,navContentPaneEnabled: false}
    };
    let reportContainer = this.reportContainer.nativeElement;
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    powerbi.reset(reportContainer);
    let report = powerbi.embed(reportContainer, config);
    report.off("loaded");
    report.on("loaded", () => {
      console.log("Loaded");
    });
    report.on("error", (event) => {
      console.log(event.detail);
    });
  }
}