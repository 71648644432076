import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AnalyticService } from 'src/app/core/services/analytics.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { elementAt } from 'rxjs/operators';

@Component({
    selector: 'app-pnp-report-filter',
    templateUrl: './report-filter.component.html',
    styleUrls: ['./report-filter.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class PnpReportFilterComponent implements OnInit {
    @Input() reportId: number;
    @Input() period1Id: number;
    @Input() period2Id: number;
    @Input() selectedRows: any;
    @Input() selectedColumns: any;
    @Input() selectedPage: any;
    @Output() previousTab = new EventEmitter();
    @Output() nextTab = new EventEmitter();
    @Output() selectedPageChange = new EventEmitter();
    @Output() selectedRowsChange = new EventEmitter();
    @Output() selectedColumnsChange = new EventEmitter();
    filterType: number;
    pnpFilter: any = [];
    rowFilter: any = [];
    columnFilter: any = [];
    loading: boolean = false;
    rowFilterTableHeaders: any = [];
    columnFilterTableHeaders: any = [];
    pageDropdownList: any = [];
    showFilters: boolean = null;
    subReports: any = [];
    selectedSubReport: any;
    totalColumnCount: any = 0;

    constructor(private analyticsService: AnalyticService, private alertService: AlertService) {
    }

    ngOnInit() {
        this.getFilters();
    }

    getFilters() {
        if (this.analyticsService.pnpFilterList && this.analyticsService.pnpFilterList.reportId == this.reportId) {
            this.pnpFilter = JSON.parse(JSON.stringify(this.analyticsService.pnpFilterList));
            this.filterType = this.pnpFilter.FilterType;
            this.preparePageSelectList(this.pnpFilter.RowFilterList);
            this.prepareRowFilter(this.pnpFilter.RowFilterList);
            this.prepareColumnFilter(this.pnpFilter.ColumnFilterList);
        }
        else {
            this.loading = true;
            this.selectedPage = null;
            this.selectedRows.length = 0;
            this.selectedColumns.length = 0;
            this.analyticsService.getFiltersForPnp(this.reportId, this.period1Id, this.period2Id).then((filter: any) => {
                this.pnpFilter = JSON.parse(JSON.stringify(this.analyticsService.pnpFilterList));
                this.filterType = this.pnpFilter.FilterType;
                this.preparePageSelectList(this.pnpFilter.RowFilterList);
                this.prepareRowFilter(this.pnpFilter.RowFilterList);
                this.prepareColumnFilter(this.pnpFilter.ColumnFilterList);
                this.loading = false;
            }).catch(() => { this.loading = false; });
        }
    }

    preparePageSelectList(rowFilterList: any) {
        if (this.filterType == 1) {
            var pages = rowFilterList.map(x => ({ pageName: x.PageName, description: x.PageDescription }));
            pages.forEach(element => {
                var headerIndex = this.pageDropdownList.filter(x => x.pageName == element.pageName);
                if (headerIndex.length <= 0) {
                    this.pageDropdownList.push({ pageName: element.pageName, description: element.pageName + ' - ' + element.description });
                }
            });

            if (this.selectedPage)
                this.showFilters = true;
            else this.showFilters = false;
        }
        else if (this.filterType == 2 || this.filterType == 3)
            this.showFilters = true;
    }

    prepareRowFilter(rowFilterList: any) {
        //Initializing row filter table elements
        this.rowFilterTableHeaders = [];
        this.rowFilter = [];

        //Adding headers for row filter table
        this.rowFilterTableHeaders.push({ field: 'rowcode', header: 'Row Code' });
        this.rowFilterTableHeaders.push({ field: 'description', header: 'Description' });
        this.rowFilterTableHeaders.push({ field: 'checked', header: 'Actions', checked: false });

        // Adding data for row filter table
        if (this.filterType == 1 && this.selectedPage) {
            rowFilterList.filter(x => x.PageName == this.selectedPage.pageName).forEach(row => {
                var isSelected = this.selectedRows.filter(x => x == row.RowCode).length > 0;
                this.rowFilter.push({ rowcode: row.RowCode, description: row.RowDescription, pageName: row.PageName, checked: (isSelected ? true : false) });
            });
        } else if (this.filterType == 2 || this.filterType == 3) {
            rowFilterList.forEach(row => {
                var isSelected = this.selectedRows.filter(x => x == row.RowCode).length > 0;
                this.rowFilter.push({ rowcode: row.RowCode, description: row.RowDescription, pageName: row.PageName, checked: (isSelected ? true : false) });
            });
        }

        this.rowFilterTableHeaders.filter(x => x.field == 'checked')[0].checked = (this.selectedRows.length == this.rowFilter.length);
    }

    prepareColumnFilter(columnFilterList: any) {
        //Initializing column filter table elements
        this.columnFilterTableHeaders = [];
        this.columnFilter = [];

        //Adding common headers for column filter table
        this.columnFilterTableHeaders.push({ field: 'columnName', header: 'Column Name', reportId: null, reportName: null });
        this.columnFilterTableHeaders.push({ field: 'columnDescription', header: 'Description', reportId: null, reportName: null });

        // Adding data & filter type based columns for column filter table
        if (this.filterType == 1) {
            this.columnFilterTableHeaders.push({ field: 'checked', header: 'Actions', reportId: this.reportId, reportName: columnFilterList[0].ReportName, checked: false });
            if (this.selectedPage) {
                columnFilterList.filter(x => x.PageName == this.selectedPage.pageName).forEach(col => {
                    var columnIndex = this.columnFilter.filter(x => x.fieldName == col.ColumnFieldName);
                    if (columnIndex.length <= 0) {
                        var isSelected = this.selectedColumns.filter(x => x.PageName == col.PageName && x.ColumnName == col.ColumnName && x.ReportId == this.reportId && x.ColumnFieldName == col.ColumnFieldName).length > 0;
                        var colFilterRow = { columnName: col.ColumnName, columnDescription: col.ColumnDescription, pageName: col.PageName, fieldName: col.ColumnFieldName, checked: isSelected ? true : false };
                        this.columnFilter.push(colFilterRow);
                    }
                });
                this.columnFilterTableHeaders.filter(x => x.reportId == this.reportId)[0].checked = (this.selectedColumns.filter(x => x.ReportId == this.reportId).length == this.columnFilter.length);
            }
            this.totalColumnCount = this.columnFilter.length;
        }
        else if (this.filterType == 2) {
            if (this.subReports.length == 0) {
                columnFilterList.forEach(col => {
                    if (this.subReports.filter(x => x.reportName == col.ReportName).length == 0) {
                        this.subReports.push({ reportId: col.ReportId, reportName: col.ReportName, isSelected: this.selectedColumns.filter(x => x.ReportName == col.ReportName).length > 0 });
                    }
                });
                this.selectedColumnsChange.emit(this.selectedColumns);
            }
            if (!this.selectedSubReport) this.selectedSubReport = this.subReports[0];
            this.columnFilterTableHeaders.push({ field: 'checked', header: 'Actions', reportId: this.selectedSubReport.reportId, reportName: this.selectedSubReport.reportName, checked: false });
            columnFilterList.filter(x => x.ReportName == this.selectedSubReport.reportName).forEach(col => {
                var columnIndex = this.columnFilter.filter(x => x.fieldName == col.ColumnFieldName);
                if (columnIndex.length <= 0) {
                    var isSelected = this.selectedColumns.filter(x => x.PageName == col.PageName && x.ColumnName == col.ColumnName && x.ReportId == this.selectedSubReport.reportId && x.ReportName == this.selectedSubReport.reportName && x.ColumnFieldName == col.ColumnFieldName).length > 0;
                    var colFilterRow = { columnName: col.ColumnName, columnDescription: col.ColumnDescription, pageName: col.PageName, fieldName: col.ColumnFieldName, checked: isSelected ? true : false };
                    this.columnFilter.push(colFilterRow);
                }
            });
            this.totalColumnCount = columnFilterList.length;
            this.columnFilterTableHeaders.filter(x => x.reportId == this.selectedSubReport.reportId)[0].checked = (this.selectedColumns.filter(x => x.ReportId == this.selectedSubReport.reportId && x.ReportName == this.selectedSubReport.reportName).length == this.columnFilter.length);
        }
        else if (this.filterType == 3) {
            this.columnFilterTableHeaders.push({ field: 'checked', header: 'Actions', reportId: this.reportId, reportName: columnFilterList[0].ReportName, checked: false });
            columnFilterList.forEach(col => {
                var columnIndex = this.columnFilter.filter(x => x.fieldName == col.ColumnFieldName);
                if (columnIndex.length <= 0) {
                    var isSelected = this.selectedColumns.filter(x => x.PageName == col.PageName && x.ColumnName == col.ColumnName && x.ReportId == this.reportId && x.ColumnFieldName == col.ColumnFieldName).length > 0;
                    var colFilterRow = { columnName: col.ColumnName, columnDescription: col.ColumnDescription, pageName: col.PageName, fieldName: col.ColumnFieldName, checked: isSelected ? true : false };
                    this.columnFilter.push(colFilterRow);
                }
            });
            this.columnFilterTableHeaders.filter(x => x.reportId == this.reportId)[0].checked = (this.selectedColumns.filter(x => x.ReportId == this.reportId).length == this.columnFilter.length);
            this.totalColumnCount = this.columnFilter.length;
        }
    }

    onRowHeaderCheckBoxToggle(checked: boolean) {
        this.selectedRows = [];
        this.rowFilter.forEach(row => {
            if (checked == true && this.selectedRows.length < 200) {
                this.selectedRows.push(row.rowcode);
                row.checked = checked;
            }
            else row.checked = false;
        });
        this.rowFilterTableHeaders.filter(x => x.field == 'checked')[0].checked = (this.selectedRows.length == this.rowFilter.length);
        this.selectedRowsChange.emit(this.selectedRows);
        if (this.selectedRows.length == 200 && this.rowFilter.length > 200) {
            this.alertService.info('Please note that only the first 200 rows were selected!');
        }
    }


    onRowSelectChange(rowcode: string) {
        var index = this.selectedRows.indexOf(rowcode);
        if (index <= -1 && this.selectedRows.length < 200)
            this.selectedRows.push(rowcode);
        else if (index <= -1 && this.selectedRows.length == 200)
            this.alertService.error("The row was not selected, as the row selection has reached its limit!");
        else this.selectedRows.splice(index, 1);
        this.rowFilterTableHeaders.filter(x => x.field == 'checked')[0].checked = (this.selectedRows.length == this.rowFilter.length);
        this.selectedRowsChange.emit(this.selectedRows);
    }

    onColumnHeaderCheckBoxToggle(field: string, checked: boolean, colHeaderCheckBox: any) {
        if (this.selectedColumns.length == 10 && checked) {
            colHeaderCheckBox.checked = false;
            this.alertService.error("The column selection has reached its limit of 10 rows!");
        }
        else {
            var columnHeader = this.columnFilterTableHeaders.filter(x => x.field == field)[0];
            this.selectedColumns = this.selectedColumns.filter(x => x.ReportId != columnHeader.reportId || x.ReportName != columnHeader.reportName);
            this.columnFilter.forEach(colRow => {
                if (checked == true && this.selectedColumns.length < 10) {
                    colRow[field] = checked;
                    this.selectedColumns.push({ PageName: colRow.pageName, ColumnName: colRow.columnName, ReportId: columnHeader.reportId, ColumnFieldName: colRow.fieldName, ReportName: columnHeader.reportName });
                }
                else colRow[field] = false;
            });
            if (this.subReports.length != 0)
                this.subReports.filter(x => x.reportId == columnHeader.reportId && x.reportName == columnHeader.reportName)[0].isSelected = this.selectedColumns.filter(x => x.ReportId == columnHeader.reportId && x.ReportName == columnHeader.reportName).length > 0;
            this.selectedColumnsChange.emit(this.selectedColumns);
            colHeaderCheckBox.checked = this.selectedColumns.filter(x => x.ReportId == columnHeader.reportId && x.ReportName == columnHeader.reportName).length == this.columnFilter.length;
            if (this.selectedColumns.length == 10 && this.totalColumnCount > 10 && !colHeaderCheckBox.checked) {
                this.alertService.info('Please note that only a total of 10 rows were selected!');
            }
        }
    }

    onColumnSelectChange(pageName: string, columnName: string, reportId: number, reportName: string, fieldName: string, checkBox: any) {
        var index = this.selectedColumns.findIndex(x => x.PageName == pageName && x.ColumnName == columnName && x.ReportId == reportId && x.ReportName == reportName && x.ColumnFieldName == fieldName);

        if (index <= -1 && this.selectedColumns.length < 10)
            this.selectedColumns.push({ PageName: pageName, ColumnName: columnName, ReportId: reportId, ColumnFieldName: fieldName, ReportName: reportName });
        else if (index <= -1 && this.selectedColumns.length == 10) {
            checkBox.checked = false;
            this.alertService.error("The row was not selected, as the column selection has reached its limit!");
        }
        else this.selectedColumns.splice(index, 1);

        this.columnFilterTableHeaders.filter(x => x.reportId == reportId && x.reportName == reportName)[0].checked = (this.selectedColumns.filter(x => x.ReportId == reportId && x.ReportName == reportName).length == this.columnFilter.length);
        if (this.subReports.length != 0)
            this.subReports.filter(x => x.reportId == reportId && x.reportName == reportName)[0].isSelected = this.selectedColumns.filter(x => x.ReportId == reportId && x.ReportName == reportName).length > 0;
        this.selectedColumnsChange.emit(this.selectedColumns);
    }

    goToPreviousTab() {
        this.previousTab.emit(true);
    }

    goToNextTab() {
        this.nextTab.emit(true);
    }

    onPageSelect() {
        this.selectedPageChange.emit(this.selectedPage);
        this.selectedRows.length = 0;
        this.selectedColumns.length = 0;
        this.prepareRowFilter(this.pnpFilter.RowFilterList);
        this.prepareColumnFilter(this.pnpFilter.ColumnFilterList);
        this.showFilters = this.selectedPage ? true : false;
    }

    onSubReportSelect() {
        this.prepareColumnFilter(this.pnpFilter.ColumnFilterList);
    }
}
