import { NgModule } from '@angular/core';
import { ReportingComponent } from './component/reporting.component';
import { SuiteDashboardComponent } from './suite-dashboard/suite-dashboard.component';
import { CoreModule } from 'src/app/core/core.module';
import { ReportDashboardComponent } from './report-dashboard/report-dashboard.component';
import { SubReportComponent } from './sub-report/sub-report.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReportGridComponent } from './report-grid/report-grid.component';
import { ValidationReviewGridComponent } from './validation-review-grid/validation-review-grid.component';
import { RouterModule } from '@angular/router';
import { ReportPackDashboardComponent } from './report-pack-dashboard/reportpack-dashboard.component';
import { ChartModule } from 'primeng/chart';
import { ProgressBarModule } from 'primeng/progressbar';

@NgModule({
  imports: [
    CoreModule,
    SharedModule,
    RouterModule,
    ChartModule,
    ProgressBarModule
  ],
  declarations: [
    ReportingComponent,
    SuiteDashboardComponent,
    ReportDashboardComponent,
    SubReportComponent,
    ReportGridComponent,
    ReportPackDashboardComponent,
    ValidationReviewGridComponent
  ],
  exports: [
    ReportingComponent,
    SuiteDashboardComponent,
    ReportDashboardComponent,
    SubReportComponent,
    ReportGridComponent,
    ReportPackDashboardComponent,
    ValidationReviewGridComponent
  ]
})
export class ReportingModule {
}
