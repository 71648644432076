import { environment } from '../../../environments/environment';

/**
 * Msal configuration
 */
export function msalConfig(): any {
  return {
    auth: {
      clientId: environment.azure_application_id,
      authority: 'https://login.microsoftonline.com/'+ environment.azure_tenant_id,
      redirectUri: window.location.origin + '/',
      PostLogoutRedirectUri: window.location.origin + '/Logout'
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
}
