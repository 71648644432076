<app-loader *ngIf="loading"></app-loader>
<div class="row">
    <div class="col-12 col-sm-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
    </div>
</div>
<div class="row header col-12">
    {{ editMode ? 'Edit Report Pack' : 'View Report Pack' }}
</div>
<div *ngIf="reportPack">
    <div class="row">
        <div class="col-5">
            <form #rpForm="ngForm">
                <div class="row form-row">
                    <div class="col-4"><label>Report Pack Title</label></div>
                    <div class="col-8" *ngIf="editMode">
                        <input type="text" required minlength="4" maxlength="50" [(ngModel)]="reportPack.title"
                            class="rp-form-control" #reportpackttl="ngModel" id="reportpacktitle" name="reportpacktitle"
                            pattern="^[A-Za-z0-9 _\-.]*$" trim-text />
                        <div *ngIf="reportpackttl.invalid && (reportpackttl.dirty || reportpackttl.touched)"
                            class="alert alert-danger">
                            <div *ngIf="reportpackttl.errors.required">
                                Report pack title is a mandatory field.
                            </div>
                            <div *ngIf="reportpackttl.errors.pattern">
                                Report pack title is not valid, as it contains special characters.
                            </div>
                            <div *ngIf="reportpackttl.errors.maxlength">
                                Report pack title should not exceed 50 characters.
                            </div>
                            <div *ngIf="reportpackttl.errors.minlength">
                                Report pack title should be atleast 4 characters long.
                            </div>
                        </div>
                    </div>
                    <div class="col-8 rp-form-label" *ngIf="!editMode">
                        <label>{{reportPack.title}}</label>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col-4"><label>Report Pack Description</label></div>
                    <div class="col-8" *ngIf="editMode">
                        <textarea class="rp-form-control" [(ngModel)]="reportPack.description" maxlength="150"
                            name="reportpackDescription" #reportpackdsc="ngModel" pattern="[^^/:*|'<>\\{}\[\]]*"
                            id="reportpackdesc" trim-text></textarea>
                        <div *ngIf="reportpackdsc.invalid && (reportpackdsc.dirty || reportpackdsc.touched)"
                            class="alert alert-danger">
                            <div *ngIf="reportpackdsc.errors.pattern">
                                The report pack description is not valid, as it contains special
                                characters.
                            </div>
                            <div *ngIf="reportpackdsc.errors.maxlength">
                                Report pack description should not exceed 150 characters.
                            </div>
                        </div>
                    </div>
                    <div class="col-8 rp-form-label" *ngIf="!editMode">
                        <label>{{reportPack.description}}</label>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col-4"><label>Reporting Date</label></div>
                    <div class="col-8 rp-form-label">
                        <label>{{reportPack.reportDate | date:'dd/MM/yyyy'}}</label>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col-4"><label>Accounting Framework</label></div>
                    <div class="col-8" *ngIf="editMode">
                        <select id="accountingFramework" name="accountingFramework"
                            [(ngModel)]="reportPack.accountingFrameworkId" class="rp-form-control w-100 select-arrow">
                            <option *ngFor="let af of  accountingFrameworks"
                                [selected]="af.AccountingFrameworkId == reportPack.accountingFrameworkId"
                                value={{af.AccountingFrameworkId}}>
                                {{af.AccountingFrameworkName}}
                            </option>
                        </select>
                    </div>
                    <div class="col-8 rp-form-label" *ngIf="!editMode">
                        <label>{{reportPack.accountingFrameworkName}}</label>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col-4"><label>Reporting Level</label></div>
                    <div class="col-8" *ngIf="editMode">
                        <select id="reportingLevel" name="reportingLevel" [(ngModel)]="reportPack.reportingLevelId"
                            class="rp-form-control w-100 select-arrow">
                            <option *ngFor="let rl of  reportingLevels"
                                [selected]="rl.ReportingLevelId == reportPack.reportingLevelId"
                                value={{rl.ReportingLevelId}}>
                                {{rl.ReportingLevelName}}
                            </option>
                        </select>
                    </div>
                    <div class="col-8 rp-form-label" *ngIf="!editMode">
                        <label>{{reportPack.reportingLevelName}}</label>
                    </div>
                </div>

                <div class="row form-row">
                    <div class="col-4"><label>Is Active </label></div>
                    <div class="col-8" *ngIf="editMode">
                        <p-checkbox class="rp-form-control" name="isActive" value="isActive"
                            [(ngModel)]='reportPack.isActive' [binary]="true" [disabled]="false">
                        </p-checkbox>
                    </div>
                    <div class="col-8 rp-form-label" *ngIf="!editMode">
                        <label>{{reportPack.isActive ? "Yes" : "No"}}</label>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col-4"><label>Created By</label></div>
                    <div class="col-8 rp-form-label">
                        <label>{{reportPack.createdBy }}</label>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col-4"><label>Created Date</label></div>
                    <div class="col-8 rp-form-label">
                        <label>{{reportPack.createdDate | date:'dd/MM/yyyy'}}</label>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-7">
            <div *ngIf="reportPack.templateGroups">
                <p-table #dttemplategroups [value]="reportPack.templateGroups" class="table-irr report-pack"
                    [scrollable]="true" [scrollHeight]="'calc(100vh - 200px)'">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th>Template Group</th>
                            <th>Data Loads</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td>
                                {{ rowData.templateGroupName }}
                            </td>
                            <td>
                                <span *ngIf="rowData.dataLoads.length>0" class="text-link"
                                    (click)='viewDataLoads(rowData.dataLoads, dataLoadModal)'>View Data Loads</span>
                                <span *ngIf="!rowData.dataLoads || rowData.dataLoads.length == 0">
                                    No Data Loads
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <div *ngIf="editMode" class="row form-row">
        <div class="col-8"></div>
        <div class="col-4">
            <app-report-pack-sequence-filter [(selectedRpSequences)]="selectedReportPackSequences"
                [rpsIdsToIgnore]="rpsIdsToIgnore" [selectionMode]="'multiple'"
                (selectedRpSequencesChange)="onNewRPSequenceMappings()">
            </app-report-pack-sequence-filter>
        </div>
    </div>
    <div class="row form-row">
        <p-table #dtrps [value]="reportPack.ReportPackSequenceMappings" class="table-irr report-pack"
            [columns]="reportPackSequenceHeaders" [paginator]="true" [rows]="4" [scrollable]="true"
            [scrollHeight]="'calc(100vh - 600px)'">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngIf="editMode" style="width: 30px;"></th>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field"> {{col.header}}
                        <p-sortIcon *ngIf="col.field !=''" [field]="col.field" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order">
                        </p-sortIcon>
                    </th>
                    <th *ngIf="editMode">Actions</th>
                </tr>
                <tr>
                    <th *ngIf="editMode" style="width: 30px;"></th>
                    <th *ngFor="let col of columns">
                        <input *ngIf="col.field!=''" type="text" class="w-100" placeholder="Filter Text"
                            [value]="dtrps.filters && dtrps.filters[col.field] ? dtrps.filters[col.field].value: ''"
                            (input)="dtrps.filter($event.target.value, col.field, 'contains')">
                    </th>
                    <th *ngIf="editMode"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr>
                    <td *ngIf="editMode" style="width: 30px;">
                        <h6 *ngIf="rowData.IsDeleted" class="small-font mt-3"><i
                                class="fas fa-times-circle hvr-shrink failed-icon mr-3"></i>
                        </h6>
                    </td>
                    <td>{{rowData.RPSequenceName}}</td>
                    <td>{{rowData.RPSequenceDescription}}</td>
                    <td>{{rowData.Order}}</td>
                    <td>{{rowData.IsDefault ? 'Yes':'No'}}</td>
                    <td *ngIf="editMode">
                        <textarea class="rp-form-control textarea textarea-wrapper" type="text"
                            pattern="[^^/:*|'<>\\{}\[\]]*" maxlength="150" trim-text
                            [(ngModel)]="rowData.Comments"></textarea>
                    </td>
                    <td *ngIf="!editMode">{{rowData.Comments}}</td>
                    <td *ngIf="editMode">
                        <button class="btn-table-icon hvr-shrink" type="button" *ngIf="!rowData.IsDefault"
                            (click)="markAsDefaultSequence(rowData, true)" ngbTooltip="Mark as Default" container="body"
                            tooltipClass="reporting-tooltip">
                            <i class="fas fa-check fa-lg"></i>
                        </button>
                        <button class="btn-table-icon hvr-shrink" type="button" *ngIf="rowData.IsDefault"
                            (click)="markAsDefaultSequence(rowData, false)" ngbTooltip="Mark as Default"
                            container="body" tooltipClass="reporting-tooltip">
                            <i class="fas fa-check fa-lg"></i>
                        </button>
                        <button *ngIf="!rowData.IsDeleted" class="btn-table-icon hvr-shrink" type="button"
                            (click)="onMappingDelete(rowData)" ngbTooltip="Unmap report pack sequence" container="body"
                            tooltipClass="reporting-tooltip">
                            <i class="fas fa-trash-alt fa-lg"></i>
                        </button>
                        <button *ngIf="rowData.IsDeleted" class="btn-table-icon hvr-shrink" type="button"
                            (click)="onUndoMappingDelete(rowData)" ngbTooltip="Undo Delete" container="body"
                            tooltipClass="reporting-tooltip">
                            <i class="fas fa-undo fa-lg"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
                <button class="btn btn-clear-filter hvr-shrink" type="button" (click)="dtrps.reset();">
                    <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                </button>
            </ng-template>
        </p-table>
    </div>
    <div class="row form-row float-right m-0 d-flex flex-column justify-content-center d-md-block mt-3">
        <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button"
            (click)='backToReportPackManagement()'>
            <span>Back to Report Packs</span>
        </button>
        <button *ngIf="editMode" class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button" (click)='clear()'>
            <span>Clear</span>
        </button>
        <button *ngIf="editMode" class="btn btn-modal-pos small-font hvr-shrink" type="button"
            [disabled]="rpForm && rpForm.form ? !rpForm.form.valid : false" (click)="updateReportPack()">
            <span>Save</span>
        </button>
    </div>
</div>

<ng-template #dataLoadModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">
            Data Loads<i class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="closeDataLoadsModal()"></i>
        </h5>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="data-ing-scroll">
                <p-table #dting [value]="dataLoadsList" [paginator]="true" [rows]="9" class="table-irr data-ingestion"
                    [columns]="dataLoadHeaders" [scrollable]="true" [scrollHeight]="'calc(100vh - 292px'">
                    <ng-template pTemplate="paginatorleft">
                        <button class="btn btn-clear-filter hvr-shrink" type="button"
                            (click)="dting.reset();clearFilters();">
                            <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                        </button>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                {{col.header}}
                                <p-sortIcon *ngIf="col.header !='Status'" [field]="col.field"
                                    ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                            </th>
                        </tr>
                        <tr>
                            <th *ngFor="let col of columns">
                                <p-calendar *ngIf="col.field =='createddate'" [(ngModel)]="createdDateFilters" #rdFilter
                                    selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy" [readonlyInput]="true"
                                    placeholder="Select a range" appendTo="body" [monthNavigator]="true"
                                    [yearNavigator]="true" yearRange="{{yearRange}}"
                                    (ngModelChange)="dting.filter(createdDateFilters, col.field, 'dateRangeFilter')"
                                    (onSelect)="closeDateRangeFilter(rdFilter, createdDateFilters)">
                                </p-calendar>

                                <input *ngIf="col.field !='createddate' && col.header !='Status'" type="text"
                                    class="w-100" placeholder="Filter Text"
                                    [value]="dting.filters && dting.filters[col.field] ? dting.filters[col.field].value: ''"
                                    (input)="dting.filter($event.target.value, col.field, 'contains')">
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td>
                                <div>
                                    {{rowData.filename}}
                                </div>
                                <div>
                                    <span class="badge"
                                        [ngClass]="{'badge-completed':rowData.loadstatus === 'Completed' ,
                                        'badge-fail':rowData.loadstatus === 'Failed',
                                        'badge-in-progress':rowData.loadstatus != 'Completed' && rowData.loadstatus != 'Failed'}">
                                        {{rowData.loadstatus}}
                                    </span>
                                </div>
                            </td>
                            <td>{{rowData.username}}</td>
                            <td>
                                {{rowData.createddate | date: 'dd/MM/yyyy'}}<br />
                                {{rowData.createddate | date: 'hh:mm:ss a'}}
                            </td>
                            <td>
                                <div class="status-progress d-flex align-items-center position-relative">
                                    <hr />
                                    <div class="d-flex align-items-center justify-content-center flex-column mr-3 status-progress-container "
                                        [ngClass]="{'completed':rowData.templateValidationStatus==='Success',
                                                'failed':rowData.templateValidationStatus==='Failed',
                                                'in-progress':rowData.templateValidationStatus==='In Progress'}"
                                        container="body" ngbTooltip="Template
                                                Validation">
                                        <div class="circle-status d-flex align-items-center justify-content-center">
                                            <i class="fas fa-file-alt fa-lg"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center flex-column mr-3 status-progress-container "
                                        [ngClass]="{'completed':rowData.dataExtractionStatus==='Success',
                                            'failed':rowData.dataExtractionStatus==='Failed',
                                            'in-progress':rowData.dataExtractionStatus==='In Progress'}"
                                        container="body" ngbTooltip="Data Extraction">
                                        <div class="circle-status d-flex align-items-center justify-content-center">
                                            <i class="fas fa-external-link-alt fa-lg"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center flex-column mr-3 status-progress-container "
                                        [ngClass]="{'completed':rowData.dataValidationStatus==='Success',
                                            'failed':rowData.dataValidationStatus==='Failed',
                                            'in-progress':rowData.dataValidationStatus==='In Progress'}"
                                        container="body" ngbTooltip="Data
                                            Validation">
                                        <div class="circle-status d-flex align-items-center justify-content-center">
                                            <i class="fas fa-tasks fa-lg"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center flex-column mr-3 status-progress-container"
                                        [ngClass]="{'completed':rowData.dataLoadStatus==='Success',
                                            'failed':rowData.dataLoadStatus==='Failed',
                                            'in-progress':rowData.dataLoadStatus==='In Progress'}" container="body"
                                        ngbTooltip="Data
                                            Load">
                                        <div class="circle-status d-flex align-items-center justify-content-center">
                                            <i class="fas fa-cloud-upload-alt fa-lg"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center flex-column mr-3 status-progress-container"
                                        [ngClass]="{'completed':rowData.dataPrepStatus==='Success',
                                        'failed':rowData.dataPrepStatus==='Failed',
                                        'in-progress':rowData.dataPrepStatus==='In Progress'}" container="body"
                                        ngbTooltip="Data Preparation">
                                        <div class="circle-status d-flex align-items-center justify-content-center">
                                            <i class="fas fa-clipboard-check fa-lg"></i>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>