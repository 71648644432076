import { BrowserModule, Title } from '@angular/platform-browser';
import { TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LandingModule } from './landing/landing.module';
import { CoreModule } from './core/core.module';
import { PlaceholderModule } from './placeholder/placeholder.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesModule } from './pages/pages.module';
import { PrivacyNoticeModule } from './privacy-notice/privacy-notice.module';
import { SharedModule } from './shared/shared.module';
import 'simplebar';
import { DatePipe } from '@angular/common';

 @NgModule({

    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        LandingModule,
        PagesModule,
        PrivacyNoticeModule,
        CoreModule,
        PlaceholderModule,
        BrowserAnimationsModule,
        SharedModule
    ],
    providers: [
        Title,
        TitleCasePipe,
        DatePipe,
    ],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule { }
