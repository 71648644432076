import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { Router } from '@angular/router';
import { ReportPackSequenceService } from 'src/app/core/services/reportPackSequence.service';
import { FilterUtils } from "primeng/utils";

@Component({
  selector: 'app-report-pack-sequence-form',
  templateUrl: './report-pack-sequence-form.component.html',
  styleUrls: ['./report-pack-sequence-form.component.css']
})
export class ReportPackSequenceFormComponent implements OnInit {
  loading = false;
  reportPackSequence: any;
  editMode: boolean;

  reportPackHeaders: any;
  rpIdsToIgnore: any = [];

  reportDateFilters: Date[];
  private dateTime = new Date();
  private yearRange = "2000:" + this.dateTime.getFullYear().toString();

  selectedReportPacks: any = [];

  constructor(private rpSequenceService: ReportPackSequenceService,
    private alertService: AlertService,
    private router: Router) { }

  ngOnInit() {
    if (!this.rpSequenceService.selectedRPSequence) {
      this.backToReportPackSequenceManagement();
    }
    else {
      this.editMode = this.rpSequenceService.editMode;
      this.reportPackSequence = JSON.parse(JSON.stringify(this.rpSequenceService.selectedRPSequence));
      this.prepareRpsToIgnore();

      this.reportPackHeaders = [
        { field: 'Order', header: 'Order' },
        { field: 'ReportPackTitle', header: 'Report Pack Title' },
        { field: 'ReportPackDescription', header: 'Description' },
        { field: 'ReportDate', header: 'Report Date' },
        { field: 'Comments', header: 'Comments' },
        { field: '', header: 'Actions' }
      ];

      FilterUtils['dateRangeFilter'] = (value, filter: [Date, Date]): boolean => {
        if (!filter || (!filter[0] && !filter[1]))
          return true;
        var val = new Date(value);
        var convertDate = new Date(val.getFullYear() + '/' + (val.getMonth() + 1) + '/' + val.getDate());
        var s = filter[0].getTime();
        var e;
        if (filter[1])
          e = filter[1].getTime() + (24 * 60 * 60 * 1000) - 1000;
        else e = s + (24 * 60 * 60 * 1000) - 1000;
        return convertDate.getTime() >= s && convertDate.getTime() <= e;
      }
    }
  }

  prepareRpsToIgnore() {
    if (this.reportPackSequence.ReportPackSequenceMappings && this.reportPackSequence.ReportPackSequenceMappings.length > 0) {
      this.rpIdsToIgnore = this.reportPackSequence.ReportPackSequenceMappings.filter(x => !x.IsDeleted).map(x => x.ReportPackId);
    }
  }

  backToReportPackSequenceManagement() {
    this.router.navigate(['/app/report-pack-sequence-management']);
  }

  closeDateRangeFilter(dateComponent: any, filters: Date[]) {
    if (filters[1]) {
      dateComponent.hideOverlay();
    }
  }

  clearFilters() {
    this.reportDateFilters = null;
  }

  clearChanges() {
    this.reportPackSequence = JSON.parse(JSON.stringify(this.rpSequenceService.selectedRPSequence));
    this.prepareRpsToIgnore();
  }

  onNewRPMappings() {
    var rpsId = this.reportPackSequence.RPSId;
    var maxOrder = this.reportPackSequence.ReportPackSequenceMappings && this.reportPackSequence.ReportPackSequenceMappings.length > 0
      ? Math.max(...this.reportPackSequence.ReportPackSequenceMappings.filter(x => !x.IsDeleted).map(x => x.Order))
      : 0;
    if (this.selectedReportPacks && this.selectedReportPacks.length > 0) {
      this.selectedReportPacks.sort((a, b) => a.ReportDate < b.ReportDate ? 1 : -1).forEach(rp => {
        var mapping = {
          RPSId: rpsId, ReportPackId: rp.ReportPackID, ReportPackTitle: rp.Title, ReportPackDescription: rp.RP_Description,
          ReportDate: rp.ReportDate, IsNewMapping: true, Order: ++maxOrder, Comment: null
        }
        this.reportPackSequence.ReportPackSequenceMappings = [...this.reportPackSequence.ReportPackSequenceMappings, mapping];
      });
      this.prepareRpsToIgnore();
      this.selectedReportPacks = [];
    }
  }

  reOrderMappingList() {
    var index = 0;
    this.reportPackSequence.ReportPackSequenceMappings.sort((a, b) => (a.index - b.index)).filter(x => !x.IsDeleted)
      .forEach(mapping => {
        mapping.Order = ++index;
      });
      this.reportPackSequence.ReportPackSequenceMappings = JSON.parse(JSON.stringify(this.reportPackSequence.ReportPackSequenceMappings));
    }

  changeObjectIndex(reportPackId: number, newIndex: number): void {
    const indexToMove = this.reportPackSequence.ReportPackSequenceMappings.findIndex(obj => obj.ReportPackId === reportPackId);

    if (indexToMove !== -1) {
      const objectToMove = this.reportPackSequence.ReportPackSequenceMappings.splice(indexToMove, 1)[0]; // Remove the object from the array
      this.reportPackSequence.ReportPackSequenceMappings.splice(newIndex, 0, objectToMove); // Insert the object at the new index
    }
    this.reOrderMappingList();
  }

  onOrderChange(rowData) {
    this.changeObjectIndex(rowData.ReportPackId, rowData.Order - 1);
  }

  onMappingDelete(rowData) {
    if (this.editMode && !rowData.IsNewMapping) {
      rowData.Order = null;
      rowData.IsDeleted = true;
      this.changeObjectIndex(rowData.ReportPackId, this.reportPackSequence.ReportPackSequenceMappings.length - 1);
    }
    else {
      this.reportPackSequence.ReportPackSequenceMappings = this.reportPackSequence.ReportPackSequenceMappings.filter(x => x.ReportPackId != rowData.ReportPackId);
      this.reOrderMappingList();
    }
    this.prepareRpsToIgnore();
  }

  onUndoMappingDelete(rowData) {
    var maxOrder = Math.max(...this.reportPackSequence.ReportPackSequenceMappings.filter(x => !x.IsDeleted).map(x => x.Order));
    rowData.Order = maxOrder + 1;
    rowData.IsDeleted = null;
    this.changeObjectIndex(rowData.ReportPackId, maxOrder);
    this.reOrderMappingList();
    this.prepareRpsToIgnore();
  }

  saveReportPackSequenceChanges() {
    if (!this.editMode && (this.reportPackSequence.RPSequenceName === null || this.reportPackSequence.RPSequenceName === '')) {
      this.alertService.error('Report Pack Sequence Name is a mandatory field!');
      return;
    }

    this.loading = true;
    if (!this.editMode) {
      this.rpSequenceService.addReportPackSequence(this.reportPackSequence).then((data: any) => {
        this.rpSequenceService.selectedRPSequence = null;
        this.loading = false;
        this.backToReportPackSequenceManagement();
      }).catch(() => { this.loading = false; });
    }
    else {
      this.rpSequenceService.updateReportPackSequence(this.reportPackSequence).then((data: any) => {
        this.rpSequenceService.selectedRPSequence = null;
        this.loading = false;
        this.backToReportPackSequenceManagement();
      }).catch(() => { this.loading = false; });
    }
  }
}