import { PromptModalComponent } from 'src/app/shared/prompt-modal/prompt-modal.component';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RolePermissionManagementService } from 'src/app/core/services/rolePermissionManagement.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';

/**
 * Component
 */
@Component({
    selector: 'app-role-management',
    templateUrl: './role-management.component.html',
    styleUrls: ['./role-management.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class RoleManagementComponent implements OnInit, OnDestroy {
    rolePermission: any[];
    headerCols: any;
    roleList: any[];
    rolePermissionList: any[];
    permissions: any = [];
    treeReportList: any = [];
    cols: any;
    loading = true;
    roleid;
    rolename = '';
    roledesc = '';
    isActive = false;
    action = '';
    selectedNodes: any = [];
    promptResponse: Subscription;
    selectedRow: any;

    constructor(private alertService: AlertService,
        private modalService: NgbModal,
        private rolePermissionManagementService: RolePermissionManagementService,
        private commonService: CommonService) { }

    ngOnInit() {
        this.headerCols = [
            { field: 'reportDisplayName', header: 'Report Name' }
        ];

        this.cols = [
            { field: 'rolename', header: 'Role' },
            { field: 'roledesc', header: 'Description' }
        ];

        this.promptResponse = this.commonService.watchPrompt().subscribe(response => {
            this.modalService.dismissAll();
            if (response === 'Yes') {
                this.triggerDelete();
            }
        });

        this.getRoleList();
    }

    ngOnDestroy(): void {
        this.modalService.dismissAll();
    }

    getRoleList() {
        this.loading = true;
        this.rolePermissionManagementService.getRoleList()
            .then((roleList: any) => {
                this.roleList = roleList;
                this.loading = false;
            }).catch(() => { this.loading = false; });
    }

    open(addRole, rowData, action) {
        this.loading = true;
        this.action = action;
        if (action === 'Edit') {
            this.rolename = rowData.rolename;
            this.roledesc = rowData.roledesc;
            this.isActive = rowData.isActive;
            this.roleid = rowData.roleid;
            this.selectedRow = rowData;

            this.rolePermissionManagementService.getRolePermission(this.roleid)
                .then((rolePermissionList: any) => {
                    this.treeReportList = JSON.parse(JSON.stringify(rolePermissionList.data));
                    this.rolePermissionList = JSON.parse(JSON.stringify(rolePermissionList.data));
                    this.filterSelectedReport(this.rolePermissionList);
                    this.loading = false;
                }).catch(() => { this.loading = false; });

            this.modalService.open(addRole, { size: 'lg', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
        }
        else if (action === 'Add') {
            this.roleid = 0;
            this.rolename = '';
            this.roledesc = '';
            this.isActive = true;
            this.selectedNodes = [];

            this.rolePermissionManagementService.getRolePermission(0)
                .then((rolePermissionList: any) => {
                    this.treeReportList = JSON.parse(JSON.stringify(rolePermissionList.data));
                    this.rolePermissionList = JSON.parse(JSON.stringify(rolePermissionList.data));
                    this.loading = false;
                }).catch(() => { this.loading = false; });

            this.modalService.open(addRole, { size: 'lg', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
        }
    }

    openPermList(viewRolePerm, rowData) {

        this.rolename = rowData.rolename;
        this.permissions = [];
        rowData.permissions.forEach(element => {
            let permissionType = '';
            if (element.permissiontype === 'P') {
                permissionType = 'Preparer';
                this.permissions.push(element.ReportDisplayName + ' - ' + permissionType);
            }
            else if (element.permissiontype.indexOf('A') !== -1) {
                permissionType = 'Approver Level ' + element.permissiontype.substring(1);
                this.permissions.push(element.ReportDisplayName + ' - ' + permissionType);
            }
        });


        this.modalService.open(viewRolePerm, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
    }

    async submitRole(action) {
        if (action === 'Add' && await this.roleNameValidation()) {
            this.alertService.error('Role name already exist');
            return;
        }

        this.loading = true;
        const role = {
            roleid: this.roleid,
            rolename: this.rolename,
            roledesc: this.roledesc,
            isActive: this.isActive
        };

        if (action === 'Add') {
            if (this.roleid === 0) {
                this.rolePermissionManagementService.addRole(role).then((roleList: any) => {
                    this.rolePermissionManagementService.getRoleList()
                        .then((roleList: any) => {
                            this.roleList = roleList;
                            this.roleList.forEach(element => {
                                if (element.rolename === this.rolename) {
                                    this.roleid = element.roleid;
                                    this.rolename = element.rolename;
                                }
                            });
                            this.loading = false;
                        }).catch(() => { this.loading = false; });
                }).catch(() => { this.loading = false; });
            }
            else {
                this.rolePermissionManagementService.editRole(this.roleid, role, 'Role updated successfully').then((roleList: any) => {
                    this.getRoleList();
                }).catch(() => { this.loading = false; });
            }
        }
        else if (action === 'Edit') {
            this.rolePermissionManagementService.editRole(this.roleid, role, 'Role updated successfully').then((roleList: any) => {
                this.getRoleList();
                if (!this.isActive) { this.modalService.dismissAll(); }
                //this.loading = false;
            }).catch(() => { this.loading = false; });
        }
    }

    async roleNameValidation() {
        var nameExist = false;
        this.roleList.forEach(element => {
            if (element.rolename === this.rolename) {
                nameExist = true;
            }
        });
        return nameExist;
    }

    deleteRole(rowData) {
        //this.loading = true;
        this.openPromptModal();

        this.roleid = rowData.roleid;
        this.rolename = rowData.rolename;
        this.roledesc = rowData.roledesc;
    }

    filterSelectedReport(reportList) {
        this.selectedNodes = [];
        if (reportList.length > 0) {
            reportList.forEach(element => {
                if (element.data.selectedPermission !== null && element.data.selectedPermission !== '') {
                    this.selectedNodes.push(element);
                }
                if (element.children != null) {
                    element.children.forEach(child => {
                        if (child.data.selectedPermission !== null && child.data.selectedPermission !== '') {
                            this.selectedNodes.push(child);
                        }
                        if (child.children != null) {
                            child.children.forEach(subChild => {
                                if (subChild.data.selectedPermission !== null && subChild.data.selectedPermission !== '') {
                                    this.selectedNodes.push(subChild);
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    submitPermission() {

        if (this.roleid === 0) {
            this.alertService.error("Role detail missing, Please submit the role and then map the permission");
            return;
        }
        // if (!this.isActive) {
        //     this.alertService.error("Role should be active to map the permission");
        //     return;
        // }
        let permissionDtos = [];

        let emptyReport = '';
        this.selectedNodes.forEach(element => {
            if (element.data.parent !== '' && (element.data.selectedPermission === null || element.data.selectedPermission === '')) {
                if (emptyReport === '') {
                    emptyReport = element.data.reportDisplayName;
                } else { emptyReport = emptyReport + ' , ' + element.data.reportDisplayName ; }
            }
        });

        if (emptyReport != '') {
            this.alertService.warn("Please select the permission for the list of reports - " + emptyReport);
            return;
        }
        this.loading = true;
        this.selectedNodes.forEach(element => {
            let permission: any = {};
            permission.permissionReport = element.data.reportName;
            permission.permissionType = element.data.selectedPermission;
            permissionDtos.push(permission);
        });

        const param = {
            roleid: this.roleid,
            permissionDtos: permissionDtos
        };

        this.rolePermissionManagementService.submitRolePermission(param).then((res: any) => {
            this.getRoleList();
            this.loading = false;
        }).catch(() => { this.loading = false; });
    }

    reportSelect(event) {
        this.enableDropdonw(this.rolePermissionList, event.node.data.reportName);
    }
    reportUnselect(event) {
        this.enableDropdonw(this.rolePermissionList, event.node.data.reportName);
    }

    enableDropdonw(reportList, reportname) {
        if (reportList.length > 0) {
            reportList.forEach(element => {
                if (element.data.reportName === reportname) {
                    element.data.isDisable = !element.data.isDisable;
                    if (element.data.isDisable) { element.data.selectedPermission = null; }
                    return;
                }
                if (element.children != null) {
                    element.children.forEach(child => {
                        if (child.data.reportName === reportname) {
                            child.data.isDisable = !child.data.isDisable;
                            if (child.data.isDisable) { child.data.selectedPermission = null; }
                            return;
                        }
                        if (child.children != null) {
                            child.children.forEach(subChild => {
                                if (subChild.data.reportName === reportname) {
                                    subChild.data.isDisable = !subChild.data.isDisable;
                                    if (subChild.data.isDisable) { subChild.data.selectedPermission = null; }
                                    return;
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    openPromptModal() {
        this.modalService.open(PromptModalComponent, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
    }
    triggerDelete() {
        this.loading = true;
        const param = {
            roleid: this.roleid,
            rolename: this.rolename,
            roledesc: this.roledesc,
            isActive: false
        };

        this.rolePermissionManagementService.editRole(this.roleid, param, 'Role deleted successfully').then((roleList: any) => {
            this.getRoleList();
        }).catch(() => { this.loading = false; });
    }


    clear(action) {
        if (action === 'Edit') {
            this.rolename = this.selectedRow.rolename;
            this.roledesc = this.selectedRow.roledesc;
            this.isActive = this.selectedRow.isActive;
            this.roleid = this.selectedRow.roleid;
            this.filterSelectedReport(this.rolePermissionList);
        }
        else if (action === 'Add') {
            this.rolename = '';
            this.roledesc = '';
            this.isActive = true;
            this.roleid = 0;
            this.selectedNodes = [];
        }
    }

    clearPermission(action) {

        this.selectedNodes.forEach(element => {
            this.enableDropdonw(this.rolePermissionList, element.data.reportName);
        });

        this.selectedNodes = [];


        if (action === 'Edit') {
            this.filterSelectedReport(this.rolePermissionList);
        }
    }

    clearFiltersPopup() {
        this.rolePermissionList = JSON.parse(JSON.stringify(this.treeReportList));
    }

}
