<app-loader #rps *ngIf="loading"></app-loader>
<div class="step3-pnp animated fadeIn fast">
    <div class="row mt-3">
        <div class="col-6">
            <div class="card card-drag-drop">
                <div class="card-header text-center"><span class="badge-header-name">Period 1</span><button
                        *ngIf="selectedPeriod1" type="button" class="btn-clear-drop" container="body"
                        (click)='period1Unselect()' ngbTooltip="Clear Period 1">
                        <i class="far fa-times-circle fa-lg"></i>
                    </button></div>
                <div class="card-body d-flex align-items-center justify-content-center text-center">
                    <i *ngIf="!selectedPeriod1" class="text-secondary">Drag a report pack from the below table and drop
                        it here</i>
                    <div *ngIf="selectedPeriod1" class="mt-3">
                        <div class="mb-2">Report Pack Title - {{selectedPeriod1.Title}}</div>
                        <div>Report Date - {{selectedPeriod1.ReportDate | date: 'dd/MM/yyyy'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card card-drag-drop">
                <div class="card-header text-center"><span class="badge-header-name">Period 2</span><button
                        *ngIf="selectedPeriod2" type="button" class="btn-clear-drop" container="body"
                        (click)='period2Unselect()' ngbTooltip="Clear Period 2">
                        <i class="far fa-times-circle fa-lg"></i>
                    </button></div>
                <div class="card-body d-flex align-items-center justify-content-center text-center">
                    <i *ngIf="!selectedPeriod2" class="text-secondary">Drag a report pack from the below table and drop
                        it here</i>
                    <div *ngIf="selectedPeriod2" class="mt-3">
                        <div class="mb-2">Report Pack Title - {{selectedPeriod2.Title}}</div>
                        <div>Report Date - {{selectedPeriod2.ReportDate | date: 'dd/MM/yyyy'}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <div class="list-group">
                <p-table #rpdt [value]="reportPackSelectList" [paginator]="true" [rows]="9"
                    class="table-irr filter-table" dataKey="ReportPackID" [columns]="headerCols">
                    <ng-template pTemplate="paginatorleft">
                        <button class="btn btn-clear-filter hvr-shrink" type="button"
                            (click)="rpdt.reset();clearFilters();">
                            <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                        </button>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field"> {{col.header}}
                                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                            </th>
                        </tr>
                        <tr>
                            <th *ngFor="let col of columns">
                                <p-calendar *ngIf="col.field =='ReportDate'" [(ngModel)]="reportDateFilters" #rdFilter
                                    selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy" [readonlyInput]="true"
                                    placeholder="Select a range" appendTo="body" [monthNavigator]="true"
                                    [yearNavigator]="true" yearRange="{{yearRange}}"
                                    (ngModelChange)="rpdt.filter(reportDateFilters, col.field, 'dateRangeFilter')"
                                    (onSelect)="closeDateRangeFilter(rdFilter, reportDateFilters)"></p-calendar>

                                <p-calendar *ngIf="col.field =='IngestedDate'" [(ngModel)]="ingestedDateFilters"
                                    #idFilter selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [readonlyInput]="true" placeholder="Select a range" appendTo="body"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="{{yearRange}}"
                                    (ngModelChange)="rpdt.filter(ingestedDateFilters, 'IngestedDate', 'dateRangeFilter')"
                                    (onSelect)="closeDateRangeFilter(idFilter, ingestedDateFilters)">
                                </p-calendar>

                                <input *ngIf="col.field !='IngestedDate' && col.field !='ReportDate'" type="text"
                                    class="w-100" placeholder="Filter Text"
                                    [value]="rpdt.filters && rpdt.filters[col.field] ? rpdt.filters[col.field].value: ''"
                                    (input)="rpdt.filter($event.target.value, col.field, 'contains')">
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr class="list-group-item">
                            <td>{{rowData['Title']}}</td>
                            <td>{{rowData['FileName']}}</td>
                            <td>{{rowData['ReportDate'] | date: 'dd/MM/yyyy'}}</td>
                            <td>{{rowData['IngestedBy']}}</td>
                            <td>{{rowData['IngestedDate'] | date: 'dd/MM/yyyy'}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-6">
            <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button" (click)="goToPreviousTab()">
                <div class="icon-container"><i class="fas fa-arrow-left fa-lg"></i></div><span>Prev</span>
            </button>
        </div>
        <div class="col-6 text-right">
            <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button" (click)="goToNextTab()">
                <div class="icon-container"><i class="fas fa-arrow-right fa-lg"></i></div><span>Next</span>
            </button>
        </div>
    </div>
</div>