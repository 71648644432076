import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { AnalyticService } from 'src/app/core/services/analytics.service';
import { PowerBiReport } from 'src/app/shared/models/powerbi-reports.model';
import { Router } from '@angular/router';

@Component({
  selector: 'all-analytics',
  templateUrl: './all-analytics.component.html',
  styleUrls: ['./all-analytics.component.css']
})
export class PowerBiReportsComponent implements OnInit {

  loading:boolean = false;
  analyticsPowerBiReports:any[];
  reportGroups:any[] = [];
  loginUser: any;
  token:string;
  childReports:any[];
  isDisplayChildReports:boolean = false;
  powerBiSubReports:any[] = [];
  populateBreadCrum:any[] = [];
  searchReport: string;

  constructor(private analyticService:AnalyticService, private titleService: Title,
    private authService: AuthenticationService,
    private router:Router) { }

  ngOnInit() {    
    this.getPowerBiReports();    
  }

  getPowerBiReports(){        
    var allAnalytics = {
      GroupDisplayName: "All Analytics",
      GroupId: 0
    }
    this.populateBreadCrum.push(allAnalytics);   
    this.loading = true;
    this.analyticService.GetPowerBiAnalyticsReport().then(() => {
      // filtering the reports based on show in menu flag
      this.analyticsPowerBiReports = this.analyticService.analyticsReports.filter(item => item.ShowInMenu == false);
      this.getReportGroups();
      this.loading = false;
    }, () => {
      this.loading = false;
     });
  }

  getReportGroups(){    
    this.analyticsPowerBiReports.map(item => {
      let repGroup:PowerBiReport = new PowerBiReport();
      if(!item.ParentGroupId){
        repGroup.displayName = item.GroupId ? item.GroupDisplayName : item.DisplayName;
        repGroup.reportName =  item.GroupId ? item.GroupName : item.ReportName;
        repGroup.reportRefId = item.ReportRefId;
        repGroup.reportLogoContent = item.GroupId ? item.GroupReportLogoContent : item.ReportLogoContent;
        repGroup.reportUrl = item.ReportUrl;
        repGroup.groupId = item.GroupId;
        repGroup.parentGroupId = item.ParentGroupId;
        if(!this.reportGroups.find(x => x.reportName == repGroup.reportName)){
          this.reportGroups.push(repGroup);
        } 
      }           
    });
  }

  showChildReports(reportGroup, isBreadCrumbNav:boolean = false){
    this.isDisplayChildReports = true;
    if(!isBreadCrumbNav){
      let breadCrumState = {
        GroupDisplayName: reportGroup.displayName,
        GroupId: reportGroup.groupId
      }
      this.populateBreadCrum.push(breadCrumState);
    }
    
    this.childReports = this.analyticsPowerBiReports.filter(item => item.GroupId == reportGroup.groupId);
    this.analyticsPowerBiReports.map(item => {
      if(item.ParentGroupId == reportGroup.groupId){
        var repGrp = {
          DisplayName : item.GroupDisplayName,
          GroupDisplayName : item.GroupDisplayName,
          GroupId : item.GroupId,
          GroupId1 : item.GroupId1,
          GroupName : item.GroupName,
          GroupReportLogoContent : item.GroupReportLogoContent,
          ParentGroupId : item.ParentGroupId,
          ReportLogoContent : item.GroupReportLogoContent,
          ReportName : item.GroupName,
          ReportRefId : item.ReportRefId,
          ReportUrl : item.ReportUrl 
        }
        this.childReports.push(repGrp);
      }
    });
    this.powerBiSubReports = this.childReports;
    return this.childReports;
  }

  showReportFromReportGroup(powerBiReport:PowerBiReport){
    this.navigateToPowerBiReport(powerBiReport.reportName);
  }

  navigateToPowerBiReport(reportName) {
    let reportUrl = this.router.serializeUrl(
      this.router.createUrlTree(['/app/all-analytics/all-analytics-dashboard'], { queryParams: { reportname: reportName } })
    );
    window.open(reportUrl, '_blank');
  }

  navigateRouteToReport(reportName) {
    if(reportName && reportName == "Period-on-Period Analysis"){
      this.navigateRoutePNP();
    }
    else{
      let reportUrl = this.router.serializeUrl(
        this.router.createUrlTree(['/app/all-analytics/all-analytics-dashboard'],{ queryParams: { reportname: reportName } })
      );
      window.open(reportUrl, '_blank');
    }      
  }

  showChildReportsComp(report, isBreadCrumbNav:boolean = false){
    this.isDisplayChildReports = true;
    let filGrp:any[] = [];
    if(!isBreadCrumbNav){
      let breadCrumState = {
        GroupDisplayName: report.GroupDisplayName,
        GroupId: report.GroupId
      }
      this.populateBreadCrum.push(breadCrumState);
    }    
    this.powerBiSubReports = this.analyticsPowerBiReports.filter(item => item.GroupId == report.GroupId);
    this.analyticsPowerBiReports.map(item => {
      if(item.ParentGroupId == report.GroupId){
        var repGrp = {
          DisplayName : item.GroupDisplayName,
          GroupDisplayName : item.GroupDisplayName,
          GroupId : item.GroupId,
          GroupId1 : item.GroupId1,
          GroupName : item.GroupName,
          GroupReportLogoContent : item.GroupReportLogoContent,
          ParentGroupId : item.ParentGroupId,
          ReportLogoContent : item.GroupReportLogoContent,
          ReportName : item.GroupName,
          ReportRefId : item.ReportRefId,
          ReportUrl : item.ReportUrl 
        }
        if(!filGrp.find(item => item.GroupName == repGrp.GroupName)){
          filGrp.push(repGrp);
        }        
      }
    });
    filGrp.map(item => {
      this.powerBiSubReports.push(item)
    });
  }

  checkLastBreadCrum(selBreadcrum:any):boolean{    
    let isLastReport: boolean = false;
    let tmpBread = this.populateBreadCrum[this.populateBreadCrum.length-1];
    if(tmpBread.GroupDisplayName == selBreadcrum.GroupDisplayName){
      isLastReport = true;
    }
    return isLastReport;
  }

  updatePageByBreadCrumb(selectedReport:any){
    if(selectedReport){
      let curIndex = this.populateBreadCrum.indexOf(selectedReport);
      if(curIndex+1 <= this.populateBreadCrum.length-1){
        this.populateBreadCrum.splice(curIndex+1, this.populateBreadCrum.length-1);
      }      
    }
    if(!selectedReport || (selectedReport && selectedReport.GroupId == 0)){
      selectedReport = {
        GroupDisplayName: "All Analytics",
        GroupId: 0
      }
      this.showChildReports(selectedReport, true);
      this.isDisplayChildReports = false;
    }
    else{
        this.showChildReportsComp(selectedReport, true);
    }
  }
  
  navigateRoutePNP() {
    this.router.navigate(['/app/all-analytics/period-on-period-analysis']);
  }
}
