import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Alert, AlertType } from '../../shared/models/alert.model';
import { ToastrService } from 'ngx-toastr';

/**
 * Alert Service
 */
@Injectable()
export class AlertService {
    /**
     * Subject  of alert
     */
    private subject = new Subject<Alert>();
    /**
     * Keep after route change
     */
    private keepAfterRouteChange = false;

    /**
     * Creates an instance of alert service.
     */
    constructor(private router: Router, private toastr: ToastrService) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    this.keepAfterRouteChange = false;
                } else {
                    this.clear();
                }
            }
        });
    }

    /**
     * Gets alerts
     */
    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    /**
     * Success alert
     */
    success(message: string, keepAfterRouteChange = false) {
        this.toastr.success(message, 'Success!', {
            progressBar: true,
            progressAnimation: 'decreasing',
            positionClass: 'toast-top-center',
            easing: 'ease-in-out'
        });
    }

    /**
     * Errors alert
     */
    error(message: string, keepAfterRouteChange = false) {
        this.toastr.error(message, 'Error!', {
            progressBar: true,
            progressAnimation: 'decreasing',
            positionClass: 'toast-top-center',
            easing: 'ease-in-out'
        });
    }

    /**
     * Infos alert
     */
    info(message: string, keepAfterRouteChange = false) {
        this.toastr.info(message, 'Information!', {
            progressBar: true,
            progressAnimation: 'decreasing',
            positionClass: 'toast-top-center',
            easing: 'ease-in-out'
        });
    }

    /**
     * Warns alert
     */
    warn(message: string, keepAfterRouteChange = false) {
        this.toastr.warning(message, 'Warning!', {
            progressBar: true,
            progressAnimation: 'decreasing',
            positionClass: 'toast-top-center',
            easing: 'ease-in-out'
        });
    }

    /**
     * Create alert
     */
    alert(type: AlertType, message: string, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next(<Alert>{ type: type, message: message });
    }

    /**
     * Clears alerts
     */
    clear() {
        this.subject.next(null);
    }
}
