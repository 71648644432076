<app-loader *ngIf="loading"></app-loader>
<div *ngIf="!hasReport">
    The selected report pack does not contain data for the report: {{fullFormName}}!
</div>
<div class="row mb-3 reportGridContainer">
    <div class="col-12 col-lg-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
    </div>
    <div class="col-12 col-lg-6 text-left text-lg-right" *ngIf="hasReport">
        <button class="btn btn-primary-ey btn-icon small-font mr-2 hvr-shrink" (click)="openTaskboardRoleModal()"
            [hidden]='isAssigned'>
            <div class="icon-container"><i class="fas fa-lg fa-thumbs-up"></i></div><span>Assign to me</span>
        </button>
        <div ngbDropdown class="d-inline-block bootstrap-custom-dropdown">
            <button class="btn btn-primary-ey btn-icon small-font hvr-shrink mr-2" id="dropdownBasic1"
                ngbDropdownToggle>
                <div class="icon-container"><i class="fas fa-list-ul fa-lg"></i></div><span>Actions</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <!-- <button ngbDropdownItem (click)="ViewValidations(validationGrid)">Review Validation</button> -->
                <!-- <button ngbDropdownItem (click)="saveState()">Save Changes</button> -->
                <div *ngFor="let rp of reportingTypeDetails">
                    <button ngbDropdownItem
                        (click)="onReportDownLoadRequest(reportListPopUp,rp.ReportingTypeId)">Download
                        {{rp.ReportingTypeName}}
                        Report</button>
                </div>
                <!-- <button ngbDropdownItem (click)="openAuditExceptionView(AuditExceptionView, 'Audit')">View Audit
                    Report</button> -->
                <!-- <button ngbDropdownItem (click)="openAuditExceptionView(AuditExceptionView, 'Exception')">View Exception
                    Report</button> -->
                <button ngbDropdownItem (click)="downloadChangeAuditReport()" id='audit'>Download Change Audit
                    Report</button>
                <button ngbDropdownItem (click)="openModal(commentsHistory)">View Approval Comments History</button>
            </div>
        </div>
        <button class="btn btn-primary-ey btn-icon small-font mr-2 hvr-shrink" *ngIf="canEdit"
            (click)="validateSubmitAndApprove(submitApprove)">
            <div class="icon-container"><i class="fas fa-lg fa-thumbs-up"></i></div><span>Submit & Approve</span>
        </button>
    </div>
    <div class="col-12" *ngIf="dependentReports!=''">
        <h6 class="text-white-50  font-italic small-font mt-3">
            <i class="fas fa-info-circle text-yellow mr-3"></i>
            The following report(s) are dependent on this report: {{dependentReports}}. Any effects in the dependent
            report(s) due to change in data should be replicated or updated manually.
        </h6>
    </div>
</div>
<app-report-pack-filter></app-report-pack-filter>
<div class="main" *ngIf="hasReport">
    <div class="row mt-lg-3 mb-lg-2">
        <div class="col-12 report-grid-name d-flex align-items-center">
            <label class="label1 mb-0 col-12 col-lg-6 p-lg-0">Form Name: {{this.fullFormName}}</label>
            <div class="page col-12 col-lg-6 float-right text-right pr-0">
                <div class="select-arrow">
                    <label class="label2">Page(s): </label>
                    <select (change)="setPageReportId($event.target.value)"
                        class="form-control d-inline w-auto ml-2 col-12 col-lg-0 select-arrow">
                        <option *ngFor="let pageId of reportPageList" [selected]="pageId.PageName == true"
                            value={{pageId.PageName}}>
                            {{pageId.PageName + ' ' + pageId.PageShortTitle}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="tabDv">
        <h6 class="text-white-50  font-italic small-font mt-3"><i class="fas fa-info-circle text-yellow mr-3"></i>The
            editable cells are highlighted in white. Please double click on the
            grid cell to open the editor.</h6>
        <app-kendo-grid *ngIf="columns" [columns]="columns" [columnGroups]="columngroups" [gridData]="dataAdapter"
            [cellRegArtReferenceUrlList]="cellRegArtReferenceUrlList" [cellEditPropertyList]="cellEditPropertyList"
            [latestAudit]="latestChangeAudit" [cellAudit]="boxCodeAudit" [cellDecimalPrecision]="cellDecimalPrecision"
            [uncomputedCells]="uncomputedCells" [blockedCells]="blockedCells" [blankCells]="blankCells"
            [pageable]="true" [cellDataTypes]="cellDataTypes" [cellOptions]="cellDataDictionary"
            (validateCellChange)="validateChangedCell($event)" [showExcelExport]="false" [forIFForm]="false"
            [excelReportFileName]="" [editType]="'cellPopOut'" [allowEdit]="isAssigned"
            (allowDataEdit)="openTaskboardRoleModal()" (getCellAudit)="getBoxCodeAudit($event)" [hasDynamicRows]="false"
            [scrollable]="true">
        </app-kendo-grid>
    </div>
</div>
<ng-template #validationGrid>
    <app-validation-review-grid></app-validation-review-grid>
</ng-template>

<ng-template #taskBoardRole let-modal>
    <div class="modal-body text-center">
        <div class="row">
            <div class="col-12">
                <p ngbAutoFocus>This report isn't assigned to you.</p>
                <p>
                    Click <i class="text-yellow">Assign Report</i> , to assign this report to yourself and edit
                    report content
                </p>
                <p>Or</p>
                <p>
                    Click <i class="text-yellow">Create Ad hoc Schedule</i> , to create a ad hoc scheduler for this
                    report
                </p>
                <p>Or</p>
                <p>Click <i class="text-yellow">Cancel</i> for a read-only access to this report</p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="w-100 text-center">
            <button class="btn btn-modal-pos small-font hvr-shrink mr-2" (click)="assignReportTaskboard()" type="button"
                [disabled]="!visibleAssignTo">
                <span>Assign Report</span>
            </button>
            <button class="btn btn-modal-pos small-font hvr-shrink" type="button" (click)="createAdhocSchedule()"
                [disabled]="!visibleAdhoc">
                <span>Create Ad Hoc Schedule</span>
            </button>
        </div>
        <div class="w-100 text-center mt-3">
            <button class="btn btn-modal-neg small-font hvr-shrink" (click)="modal.close()" type="button">
                <span>Cancel</span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #submitApprove let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">
            Submit & Approve<i class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="modal.close()"></i>
        </h5>
    </div>
    <div class="modal-body">
        <form class="material-styling w-100 mt-3">
            <div class="group w-100">
                <textarea required rows="2" name="approverComments" [(ngModel)]="approverComments"></textarea>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>Comments</label>
            </div>
        </form>
        <div class="row mt-3">
            <div class="col-12">
                <div class="sub-app-scroll">
                    <p-table #subApprove [value]="approverCommentHistory" [paginator]="true" [rows]="5"
                        class="table-irr submit-approve" [columns]="headerCols" [scrollable]="true"
                        [scrollHeight]="'calc(100vh - 550px'">
                        <ng-template pTemplate="paginatorleft">
                            <button class="btn btn-clear-filter hvr-shrink" type="button"
                                (click)="subApprove.reset();clearFilters();">
                                <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                        ariaLabelDesc="Activate to sort in descending order"
                                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                                </th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns">
                                    <p-calendar *ngIf="col.field =='PostDate'" [(ngModel)]="postedDateFilters" #rdFilter
                                        selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy"
                                        [readonlyInput]="true" placeholder="Select a range" appendTo="body"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="{{yearRange}}"
                                        (ngModelChange)="subApprove.filter(postedDateFilters, col.field, 'dateRangeFilter')"
                                        (onSelect)="closeDateRangeFilter(rdFilter, postedDateFilters)">
                                    </p-calendar>

                                    <input *ngIf="col.field !='PostDate'" type="text" class="w-100"
                                        placeholder="Filter Text"
                                        [value]="subApprove.filters && subApprove.filters[col.field] ? subApprove.filters[col.field].value: ''"
                                        (input)="subApprove.filter($event.target.value, col.field, 'contains')">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>{{rowData.ApproverLevel}}</td>
                                <td>{{rowData.UserName}}</td>
                                <td>{{rowData.Email}}</td>
                                <td>{{rowData.PostDate | date:'dd/MM/yyyy'}}</td>
                                <td>{{rowData.Comments }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="float-right m-0">
            <button class="btn btn-modal-pos small-font hvr-shrink" type="button" (click)="onSubmitForApproval()">
                <span>Submit & Approve</span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #commentsHistory let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">
            Approval Comments History<i class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="modal.close()"></i>
        </h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="sub-app-scroll">
                    <p-table #subApprove [value]="approverCommentHistory" [paginator]="true" [rows]="5"
                        class="table-irr submit-approve" [columns]="headerCols" [scrollable]="true"
                        [scrollHeight]="'calc(100vh - 550px'">
                        <ng-template pTemplate="paginatorleft">
                            <button class="btn btn-clear-filter hvr-shrink" type="button"
                                (click)="subApprove.reset();clearFilters();">
                                <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                        ariaLabelDesc="Activate to sort in descending order"
                                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                                </th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns">
                                    <p-calendar *ngIf="col.field =='PostDate'" [(ngModel)]="postedDateFilters" #rdFilter
                                        selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy"
                                        [readonlyInput]="true" placeholder="Select a range" appendTo="body"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="{{yearRange}}"
                                        (ngModelChange)="subApprove.filter(postedDateFilters, col.field, 'dateRangeFilter')"
                                        (onSelect)="closeDateRangeFilter(rdFilter, postedDateFilters)">
                                    </p-calendar>

                                    <input *ngIf="col.field !='PostDate'" type="text" class="w-100"
                                        placeholder="Filter Text"
                                        [value]="subApprove.filters && subApprove.filters[col.field] ? subApprove.filters[col.field].value: ''"
                                        (input)="subApprove.filter($event.target.value, col.field, 'contains')">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>{{rowData.ApproverLevel}}</td>
                                <td>{{rowData.UserName}}</td>
                                <td>{{rowData.Email}}</td>
                                <td>{{rowData.PostDate | date:'dd/MM/yyyy'}}</td>
                                <td>{{rowData.Comments }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #reportListPopUp let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">
            {{downloadPopupHeader}} <i class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="modal.close()"></i>
        </h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="sub-app-scroll">
                    <label class="non-input-label">Report Name: {{dashboardService.reportAliasName}}</label>
                    <label class="non-input-label ml-3">Report Pack Name: {{selectedRp.Title}}</label>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-2" *ngFor="let rep of reportList">
                <div class="audit-file-list d-flex flex-column align-items-center justify-content-center w-100 hvr-shrink"
                    (click)="downloadReport(rep.id)">
                    <i class="fas fa-file-excel text-success my-2"></i>
                    <span [ngbTooltip]="(rep.displayName.length > 50) ? truncateTooltip : ''"
                        container="body">{{rep.displayName | truncate : 50}}</span>
                    <ng-template #truncateTooltip>{{rep.displayName}}</ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #AuditExceptionView let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">
            {{auditExceptionViewHeader}} <i class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="closeAuditExceptionView();modal.close();"></i>
        </h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="sub-app-scroll">
                    <label class="non-input-label">Report Name: {{dashboardService.reportAliasName}}</label>
                    <label class="non-input-label ml-3">Report Pack Name: {{selectedRp.Title}}</label>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 d-flex align-items-center"
                *ngIf="auditExceptionPages && selectedAuditExceptionPage && auditExceptionPages.length>1">
                <div class="page col-12 col-lg-6 float-right text-right pr-0">
                    <div class="select-arrow">
                        <label class="label2">Page(s): </label>
                        <select (change)="onAuditExceptionPageChange($event.target.value)"
                            class="form-control d-inline w-auto ml-2 col-12 col-lg-0 select-arrow">
                            <option *ngFor="let page of auditExceptionPages"
                                [selected]="page.ReportTemplateId == selectedAuditExceptionPage.ReportTemplateId"
                                value={{page.ReportTemplateId}}>
                                {{page.DisplayName}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="auditExceptionGridData">
            <div class="col-12">
                <div class="auditexceptionview-scroll">
                    <p-table #aeViewList [value]="auditExceptionGridData.Data" [paginator]="true" [rows]="20"
                        class="table-irr" selectionMode="none" [columns]="auditExceptionGridData.Columns"
                        [scrollable]="true" [scrollHeight]="'calc(100vh - 292px)'">
                        <ng-template pTemplate="paginatorleft">
                            <button class="btn btn-clear-filter hvr-shrink" type="button" (click)="aeViewList.reset()">
                                <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" [pSortableColumn]="col"> {{col}}
                                    <p-sortIcon [field]="col" ariaLabel="Activate to sort"
                                        ariaLabelDesc="Activate to sort in descending order"
                                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                                </th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns">
                                    <input type="text" class="w-100" placeholder="Filter Text"
                                        [value]="aeViewList.filters && aeViewList.filters[col] ? aeViewList.filters[col].value: ''"
                                        (input)="aeViewList.filter($event.target.value, col, 'contains')">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                                <td *ngFor="let col of columns">{{rowData[col]}} </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</ng-template>