import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class RefDataService {
  constructor(private apiService: ApiService) { }

  getTableList() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('refData/getTableList/', null)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getTableResult(model: any) {
    const params = {
      tableId: model.tableId,
      pageNumber: model.pageNumber,
      pageSize: model.pageSize,
      isInitialLoad: model.isInitialLoad,
      sortList: model.sortList,
      filter: model.filter
    };
    const promise = new Promise((resolve, reject) => {
      this.apiService.post('refData/getTableResult/', params, '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  downloadTableResult(model: any) {
    const params = {
      tableId: model.tableId,
      tableDisplayName: model.tableDisplayName.replace(/[^a-zA-Z0-9]/g, '')
      //sortList: model.sortList,
      //filter: model.filter
    };
    const promise = new Promise((resolve, reject) => {
      this.apiService.getFile('refData/downloadTableResult/', params, 'application/json')
        .toPromise()
        .then(
          res => { // Success
            this.downloadFile(res)
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  downloadFile(data: any) {
    let blob = data.body;
    const contentHeader = data.headers.get('content-disposition');
    if (contentHeader !== null && blob !== null) {
      let fileName = data.headers.get('content-disposition').split(';')[1].trim().split('=')[1].replace(/"/g, '');
      saveAs(blob, fileName);
    }
  }

  downloadFormAuditReport(model: any) {
    const params = {
      tableId: model.tableId,
      tableDisplayName: model.tableDisplayName.replace(/[^a-zA-Z0-9]/g, '')
      //sortList: model.sortList,
      //filter: model.filter
    };
    const promise = new Promise((resolve, reject) => {
      this.apiService.getFile('refData/downloadAuditReport/', params, 'application/json')
        .toPromise()
        .then(
          res => { // Success
            this.downloadFile(res)
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
}

  saveRefDataTablechanges(refDataTableId, model) {
    const params = {
      refDataTableID: refDataTableId,
      formData: model
    };
    const promise = new Promise((resolve, reject) => {
      this.apiService.post('refData/saveRefTableData/', params, '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}