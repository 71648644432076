<app-header></app-header>
<div class="row d-flex align-items-center mx-0 padding-top-align">
    <div class="col-6 pt-4">
        <img src="assets/images/IRR-Logo3.svg" class="placeholder-irr-logo">

    </div>
    <div class="col-6 text-right pt-4">
        <img src="assets/images/EY-Logo-White.png" class="placeholder-ey-logo">
    </div>
</div>
<div class="row mx-0">
    <div class="col-12 text-center">
        <h1 class="loading-app-name"> <b>I</b>ntegrated <b>R</b>egulatory <b>R</b>eporting</h1>
    </div>
</div>
<div class="row mx-0 d-flex align-items-center justify-content-center text-center">
    <div class="col-md-8 error-code">
        <img src="assets/images/access-denied.svg" class="access-denied-img mt-5">
        <p class="attribution-line">Designed by <a href="https://www.freepik.com" target="_blank">Freepik</a></p> 
    </div>
    <div class="col-md-12 text-white mt-5 text-center">
        <h2>Access Denied</h2>        
        <h5>You do not have permission to access this page. Please contact application admin for access related queries.</h5>
    </div>
</div>