import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class ReportTreeService {

    reportTreeDataList: any;
    constructor(private apiService: ApiService) {
    }

    getReportTreeData() {
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('getReportTreeData/', '', '')
                .toPromise()
                .then((res: any) => {
                    this.reportTreeDataList = JSON.parse(JSON.stringify(res));
                    resolve(res);
                }, msg => {
                    reject(msg);
                }
                );
        });
        return promise;
    }

}
