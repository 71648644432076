import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Validation Review Grid Component
 */
@Component({
  selector: 'app-validation-review-grid',
  templateUrl: './validation-review-grid.component.html',
  styleUrls: ['./validation-review-grid.component.css']
})
export class ValidationReviewGridComponent implements OnInit, OnDestroy {

  validationList: any;
  headerCols: any;
  selectedRpRow: any;
  /**
  * Creates an instance of Validation Review Grid.
  */
  constructor(private modalService: NgbModal, private dashboardService: DashboardService) { }

  /**
* on Init.
*/
  ngOnInit() {
    this.validationList = this.dashboardService.validationData;

    this.headerCols = [
      { field: 'validationID', header: 'Ref #' },
      { field: 'lhs', header: 'LHS' },
      { field: 'operatorMath', header: 'Operator' },
      { field: 'rhs', header: 'RHS' },
      { field: 'lhs_sum', header: 'LHS Result' },
      { field: 'rhs_sum', header: 'RHS Result' },
      { field: 'condition', header: 'Pass/Fail' }
    ];
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
