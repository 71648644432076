import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class RolePermissionManagementService {
    roleList: object = [];
    rolePermission:object =[];
    
    constructor(private apiService: ApiService) { }

    getRoleList() {
        const promise = new Promise((resolve, reject) => {
          this.apiService.get('RolePermission/getRoleList/', '')
            .toPromise()
            .then(
              res => {
                this.roleList = res;
                resolve(res);
              },
              msg => {
                reject(msg);
              }
            );
        });
        return promise;
      }

      addRole(role){
        const promise = new Promise((resolve, reject) => {
            this.apiService.post('addRole/', role, 'Role added successfully')
              .toPromise()
              .then(
                res => { // Success
                  resolve(res);
                },
                msg => { // Error
                  reject(msg);
                }
              );
          });
          return promise;
      }

      editRole(roleid,params,message){
        const promise = new Promise((resolve, reject) => {
            this.apiService.put('updateRole/', roleid, params, message)
              .toPromise()
              .then(
                res => { // Success
                  resolve(res);
                },
                msg => { // Error
                  reject(msg);
                }
              );
          });
          return promise;
      }

      getRolePermission(roleid){
        const params = {
            roleid: roleid
          };
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('RolePermission/getreportpermission/', params)
              .toPromise()
              .then(
                res => {
                  this.rolePermission = res;
                  resolve(res);
                },
                msg => {
                  reject(msg);
                }
              );
          });
          return promise;
      }

      submitRolePermission(params){
        const promise = new Promise((resolve, reject) => {
            this.apiService.put('addRolePermission/', params.roleid, params.permissionDtos, 'Role Permission updated successfully')
              .toPromise()
              .then(
                res => { // Success
                  resolve(res);
                },
                msg => { // Error
                  reject(msg);
                }
              );
          });
          return promise;
      }
}

