import { Injectable } from '@angular/core';
import { AuthenticationService } from '../security/authentication.service';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';

@Injectable()
export class ReportDownloaderService {

    baseURL: string = environment.apiUrl;
    reportTreeList: any = [];
    reportingTypes: any = [];

    constructor(private apiService: ApiService, private authService: AuthenticationService) { }

    getReportTreeList(reportPackId) {
        const params = {
            reportPackId: reportPackId
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('reportDownloader/getReportDownloaderTreeData', params)
                .toPromise()
                .then(
                    res => {
                        this.reportTreeList = res;
                        resolve(res);
                    },
                    msg => {
                        reject(msg);
                    }
                );
        });
        return promise;
    }


    downloadReport(reportPackId, reportId, formName, alias, reportTemplateId){
        var params = {
            reportId: reportId,
            reportpackid: reportPackId,
            formname: formName,
            reportAliasName : alias,
            reportTemplateId : reportTemplateId
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.getFile('downloadReport', params)
                .toPromise()
                .then(
                    res => { // Success
                        this.downLoadFile(res)
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    downLoadFile(data: any) {
        let blob = data.body;
        const contentHeader = data.headers.get('content-disposition');
        if (contentHeader !== null && blob !== null) {
            let fileName = data.headers.get('content-disposition').split(';')[1].trim().split('=')[1].replace(/"/g, '');
            saveAs(blob, fileName);
        }
    }

    getReportingTypes() {
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('reportDownloader/getReportingTypes',null)
                .toPromise()
                .then(
                    res => {
                        this.reportingTypes = res;
                        resolve(res);
                    },
                    msg => {
                        reject(msg);
                    }
                );
        });
        return promise;
    }
}