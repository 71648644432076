<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
    <div class="col-12 col-lg-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
    </div>
</div>
<div class="row header col-12">
    {{ editMode ? 'Edit Bundle Type' : 'Add Bundle Type' }}
</div>

<div *ngIf="bundleType">
    <div class="row">
        <div class="col-12">
            <form #bundleTypeForm="ngForm">
                <div class="row form-row">
                    <div class="col-2"><label>Bundle Type Name</label></div>
                    <div class="col-3">
                        <input type="text" required minlength="4" maxlength="50" [disabled]="editMode"
                            [(ngModel)]="bundleType.bundleTypeName" class="bundle-type-form-control"
                            #bundleTypeName="ngModel" id="bundleTypeName" name="bundleTypeName"
                            pattern="^[A-Za-z0-9 _\-.]*$" trim-text />
                        <div *ngIf="bundleTypeName.invalid && (bundleTypeName.dirty || bundleTypeName.touched)"
                            class="alert alert-danger">
                            <div *ngIf="bundleTypeName.errors.required">
                                Bundle Type Name is a mandatory field.
                            </div>
                            <div *ngIf="bundleTypeName.errors.pattern">
                                Bundle Type Name is not valid, as it contains special characters.
                            </div>
                            <div *ngIf="bundleTypeName.errors.maxlength">
                                Bundle Type Name should not exceed 50 characters.
                            </div>
                            <div *ngIf="bundleTypeName.errors.minlength">
                                Bundle Type Name must be at least 4 characters long.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col-2"><label>Description</label></div>
                    <div class="col-3">
                        <textarea class="bundle-type-form-control textarea-wrapper"
                            [(ngModel)]="bundleType.bundleTypeDescription" name="bundleTypeDescription"
                            #bundleTypeDescription="ngModel" pattern="[^^/:*|'<>\\{}\[\]]*" trim-text id="bundleTypeDescription"
                            maxlength="150"></textarea>
                        <div *ngIf="bundleTypeDescription.invalid && (bundleTypeDescription.dirty || bundleTypeDescription.touched)"
                            class="alert alert-danger">
                            <div *ngIf="bundleTypeDescription.errors.pattern">
                                Bundle Type Description is not valid, as it contains special characters.
                            </div>
                            <div *ngIf="bundleTypeDescription.errors.maxlength">
                                Bundle Type Description should not exceed 150 characters.
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row" *ngIf="reportTreeList">
        <div class="col-12">
            <div class="w-100 justify-content-center info-message">
                Please select reports for the bundle type from the below grid.
            </div>
            <p-treeTable #dtBundleTypes [value]="reportTreeList" selectionMode="checkbox" class="table-irr"
                [(selection)]="selectedNodes" [columns]="reportTreeHeaders" [scrollable]="true"
                [scrollHeight]="'calc(100vh - 500px)'" [paginator]="true" [rows]="8"
                (onNodeSelect)="nodeSelectChange($event)" (onNodeUnselect)="nodeSelectChange($event)" >
                <ng-template pTemplate="paginatorleft">
                    <button class="btn btn-clear-filter hvr-shrink" type="button"
                        (click)="dtBundleTypes.reset();clearReportFilters()">
                        <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                    </button>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [ttSortableColumn]="col.field">
                            <ng-container *ngIf="col.field === 'DisplayName'; else notNameColumn">
                                <p-treeTableHeaderCheckbox (click)="selectAllReports($event)"></p-treeTableHeaderCheckbox>
                            </ng-container>
                            {{col.header}}
                            <p-treeTableSortIcon [field]="col.field"></p-treeTableSortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns">
                            <input type="text" class="w-100" placeholder="Filter Text"
                                [value]="dtBundleTypes.filters && dtBundleTypes.filters[col.field] ? dtBundleTypes.filters[col.field].value: ''"
                                (input)="dtBundleTypes.filter($event.target.value, col.field, 'contains')">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                    <tr>
                        <td>
                            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                            <p-treeTableCheckbox [value]="rowNode" (click)="countSelections()">
                            </p-treeTableCheckbox>
                            {{rowData.DisplayName}} {{rowData.selectedCount}}
                        </td>
                        <td>{{rowData.Description}}</td>
                    </tr>
                </ng-template>
            </p-treeTable>
        </div>
    </div>
    <div class="row float-right m-0 d-flex flex-column justify-content-center d-md-block mt-3">
        <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button"
            (click)='backToBundleTypeManagement()'>
            <span>Back to Bundle Type Management</span>
        </button>
        <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button" (click)="clearChanges()"
            id='btnClearChanges'>
            <span>Clear</span>
        </button>
        <button class="btn btn-modal-pos small-font hvr-shrink" type="button" (click)='submit()' id='btnSubmit'
            [disabled]="bundleTypeForm && bundleTypeForm.form ? !bundleTypeForm.form.valid : false">
            <span>Submit</span>
        </button>
    </div>
</div>