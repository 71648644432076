import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/security/authentication.service';

@Component({
    selector: 'app-server-error',
    templateUrl: './server-error.component.html',
    styleUrls: ['./server-error.component.css']
})
export class ServerErrorComponent implements OnInit {

    constructor(private router: Router, private authService: AuthenticationService) { }

    ngOnInit() {
    }

    goBack() {
        this.authService.msalLogOut();
    }

}
