import { NgModule } from '@angular/core';
import { RoleManagementComponent } from './role-management/role-management.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { ReportPackComponent } from './report-pack-panel/reportpack-panel.component';
import { ReportScheduleManagementComponent } from './report-schedule-management/report-schedule-management.component';
import { PermissionManagementComponent } from './permission-management/permission-management.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReportPackFormComponent } from './report-pack-form/report-pack-form.component';
import { BundleTypeManagementComponent } from './bundle-type-management/bundle-type-management.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BundleTypeFormComponent } from './bundle-type-form/bundle-type-form.component';
import { ReportPackSequenceManagementComponent } from './report-pack-sequence-management/report-pack-sequence-management.component';
import { ReportPackSequenceFormComponent } from './report-pack-sequence-form/report-pack-sequence-form.component';
import { ApplicationConfigurationComponent } from './application-configuration/application-configuration.component';

@NgModule({
    imports: [
        SharedModule,
        NgMultiSelectDropDownModule.forRoot()
    ],
    declarations: [
        RoleManagementComponent,
        UserManagementComponent,
        ReportPackComponent,
        ReportScheduleManagementComponent,
        PermissionManagementComponent,
        ReportPackFormComponent,
        BundleTypeManagementComponent,
        BundleTypeFormComponent,
        ReportPackSequenceManagementComponent,
        ReportPackSequenceFormComponent,
        ApplicationConfigurationComponent
    ],
    exports: [
        RoleManagementComponent,
        UserManagementComponent,
        ReportPackComponent,
        ReportScheduleManagementComponent,
        PermissionManagementComponent,
        ReportPackFormComponent,
        BundleTypeManagementComponent,
        BundleTypeFormComponent,
        ReportPackSequenceManagementComponent,
        ReportPackSequenceFormComponent
    ]
})
export class AdminModule {
}
