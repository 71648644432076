<app-loader *ngIf="loading"></app-loader>
<div class="row">
    <div class="col-12 col-lg-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'" class="last-cap">
        </app-ng7-dynamic-breadcrumb>
    </div>
</div>
<div class="row no-load-data mt-3" *ngIf="!ingestionLoadData">
    <div class="col-12 d-flex flex-column align-items-center justify-content-center text-center">
        <i class="icon-error-report main"></i>
        <h4 class="w-75">Load information is not available. Please click on the view icon ( <i
                class="fas fa-file-alt hvr-shrink fa-lg cursor-pointer text-yellow"></i> ) in the 'Data Load' page to
            view the form.</h4>
    </div>
</div>
<div *ngIf="ingestionLoadData">
    <div class="row mb-3">
        <div class="col-12 d-flex align-items-center">
            <div class="text-center color-teal d-flex align-items-center ">
                <p class="mb-0 font-weight-bold">Form Name : </p>
                <p class="mb-0 ml-2 form-data">{{ingestionLoadData.fileName}}</p>
            </div>
            <div class="text-center color-teal d-flex align-items-center ml-5">
                <p class="mb-0 font-weight-bold">Report Pack Name : </p>
                <p class="mb-0 ml-2 form-data">{{ingestionLoadData.reportpacktitle}}</p>
            </div>
            <div class="text-center color-teal d-flex align-items-center ml-5">
                <p class="mb-0 font-weight-bold">Reporting Period : </p>
                <p class="mb-0 ml-2 form-data">{{ingestionLoadData.reportingperiod | date: 'dd/MM/yyyy'}}</p>
            </div>
            <div class="text-center color-teal d-flex align-items-center ml-5"
                *ngIf="ingestionLoadData.correspondingReport">
                <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button"
                    (click)="openPopUp(reportRunPopUp)">
                    <div class="icon-container"><i class="fa fa-check-square fa-lg"></i></div><span>Report Run</span>
                </button>
            </div>
        </div>
    </div>

    <div class="col-12">
        <h6 *ngIf="dataPrepInProgress" class="text-white-50 font-italic small-font mt-3"><i
                class="fas fa-exclamation-triangle hvr-shrink failed-icon mr-3"></i>Data preparation is in progress for
            the report pack. Please save as draft and submit your changes after data preparation is complete for the
            report pack! </h6>
    </div>

    <div class="row mb-3" *ngIf="formPageDropDownOptions && formPageDropDownOptions.length != 0">
        <div class="col-12 d-flex">
            <div class="text-center color-teal d-flex align-items-left">
                <p class="mb-0 font-weight-bold">{{formPageDropDownTitle}} : </p>
                <select (change)="onFormPageChange($event.target.value)" id="formPages" name="formPages"
                    [(ngModel)]="selectedFormPage" class="form-page-dropdown select-arrow mb-0 ml-2">
                    <option *ngFor="let option of formPageDropDownOptions"
                        [selected]="option.OptionValue == selectedFormPage" value={{option.OptionValue}}>
                        {{option.OptionName}}
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div class="row" class="form" *ngIf="formLayout">
        <div class="col-12">
            <div class="if-card">
                <h6 class="text-white-50  font-italic small-font mt-3"><i
                        class="fas fa-info-circle text-yellow mr-3"></i>The editable cells are highlighted in white.
                    Please {{editType == 'inline' ? '': 'double'}} click on the grid cell to open the editor.</h6>
                <h6 *ngIf="hasError" class="text-white-50  font-italic small-font mt-3"><i
                        class="fas fa-info-circle text-yellow mr-3"></i>The form has errors. Please correct them as
                    necessary.</h6>
                <div class="if-card-body main-if-card" data-simplebar data-simplebar-auto-hide="false">
                    <div class="if-card" *ngFor="let grid of formLayout">
                        <div class="if-card-header">
                            <div class="text-center"><b>{{grid.title}}</b></div>
                            <div class="float-right">
                                <i class="fas fa-exclamation-triangle hvr-shrink failed-icon ml-3 fa-lg"
                                    placement="left" *ngIf='grid.gridError' ngbTooltip="{{grid.gridError}}"
                                    container="body"></i>
                            </div>
                        </div>
                        <div class="if-card-body child-if-card">
                            <div class="grdDv" *ngIf="grid.columns">
                                <app-kendo-grid [gridId]="grid.id" [columns]="grid.columns"
                                    [columnGroups]="grid.columnGroups" [gridData]="grid.gridData"
                                    [cellEditPropertyList]="cellEditPropertyList" [latestAudit]="latestAudit"
                                    [cellAudit]="cellAudit" [cellDecimalPrecision]="cellDecimalPrecision"
                                    [blockedCells]="blockedCells" [blankCells]="blankCells"
                                    [cellDataTypes]="cellDataTypes" [pageable]="false" [forIFForm]="true"
                                    [cellOptions]="cellDataDictionary" [scrollable]="true"
                                    (validateCellChange)="submitCellChange($event)" [allowEdit]="true"
                                    [showExcelExport]="false" [hasDynamicRows]="grid.dynamicRows"
                                    [excelReportFileName]="" [editType]="editType" (addGridRow)="addNewRow($event)"
                                    (removeGridRow)="removeRow($event)" (getCellAudit)="getFormCellAudit($event)">
                                </app-kendo-grid>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="if-card-footer d-flex flex-column align-items-center justify-content-center d-md-block mt-3">
                    <div class="float-right">
                        <button class="btn btn-secondary-ey btn-icon hvr-shrink small-font form-btn" type="button"
                            (click)='backToIngestion()'>
                            <div class="icon-container"><i class="fas fa-arrow-left fa-lg"></i></div><span>Back to
                                Ingestion</span>
                        </button>

                        <button class="btn btn-secondary-ey btn-icon hvr-shrink small-font form-btn" type="button"
                            (click)='downloadAuditReport()'>
                            <div class="icon-container hvr-shrink"><i class="fas fa-lg fa-download"></i></div>
                            <span>Download Audit</span>
                        </button>

                        <button class="btn btn-secondary-ey btn-icon hvr-shrink small-font form-btn" type="button"
                            (click)="saveIngestionForm(true, false)">
                            <div class="icon-container hvr-shrink"><i class="fas fa-lg fa-thumbs-up"></i></div>
                            <span>Save As Draft</span>
                        </button>

                        <button class="btn btn-secondary-ey btn-icon hvr-shrink small-font form-btn" type="button"
                            (click)="saveIngestionForm(false, false)">
                            <div class="icon-container hvr-shrink"><i class="fas fa-lg fa-thumbs-up"></i></div>
                            <span>Submit
                                Form</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #reportRunPopUp let-modal>
    <div class="modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">Report Run
            <i class="fas fa-times ml-auto cursor-pointer" aria-label="Close" (click)="modal.close()"></i>
        </h5>
    </div>
    <div class='modal-body'>
        <app-report-tree [(selectedReports)]="selectedReports" selectDependencies="true"></app-report-tree>

        <div class="float-right">
            <button class="btn btn-modal-pos small-font hvr-shrink" (click)="clearReportSelection()" type="button">
                <span>Clear All</span>
            </button>
            <button class="btn btn-modal-pos small-font hvr-shrink" (click)="submitReportSelection();modal.close();"
                type="button">
                <span>Submit</span>
            </button>
        </div>
    </div>
</ng-template>