<div class="row">
  <div class="col-12 col-lg-6">
    <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
      [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
  </div>
  <div class="col-12 col-lg-6 text-left text-lg-right mb-3 mb-lg-0">
    <div *ngIf="selectedRp" class="w-100 legend-placeholder">
      <span class="mr-3"><i class="fas fa-circle mr-2"></i>Not Started</span>
      <span class="mr-3"><i class="fas fa-circle mr-2"></i>In Progress</span>
      <span class="mr-3"><i class="fas fa-circle mr-2"></i>Completed</span>
    </div>
  </div>
</div>
<app-report-pack-filter></app-report-pack-filter>
<div class="row mt-3 align-items-center justify-content-center" *ngIf="selectedRp">
  <div class="col-6">
    <div class="position-relative w-50 search-input-overall">
      <input type="text" name="nameFilter" [(ngModel)]="nameFilter" (input)="onFilterChange()"
        placeholder="Search Report" class="w-100" />
      <i class="fas fa-search"></i>
    </div>
  </div>
  <div class="col-6 text-right">
    <button type="button" class="btn btn-icon-round btn-round-dark hvr-shrink float-right" (click)="toggleSortOrder()">
      <i *ngIf="sortOrder == ''" class="fas fa-sort fa-lg"></i>
      <i *ngIf="sortOrder == 'asc'" class="fas fa-sort-up fa-lg"></i>
      <i *ngIf="sortOrder == 'desc'" class="fas fa-sort-down fa-lg"></i>
    </button>
    <div ngbDropdown class="d-inline-block float-right mr-2 bootstrap-dropdown-menu" *ngIf="reportPackId">
      <button class="btn btn-icon-round btn-round-dark hvr-shrink" id="dropdownBasic1" ngbDropdownToggle><i
          class="fas fa-cog fa-lg"></i></button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem>
          <p-radioButton name="status" value="Not Started" [(ngModel)]="statusFilter" label="Not Started"
            (onClick)="onFilterChange()"></p-radioButton>
        </button>
        <button ngbDropdownItem>
          <p-radioButton name="status" value="In Progress" [(ngModel)]="statusFilter" label="In Progress"
            (onClick)="onFilterChange()"></p-radioButton>
        </button>
        <button ngbDropdownItem>
          <p-radioButton name="status" value="Completed" [(ngModel)]="statusFilter" label="Completed"
            (onClick)="onFilterChange()"></p-radioButton>
        </button>
      </div>
    </div>
  </div>
</div>
<app-loader *ngIf="loading"></app-loader>
<div class="scrollable-report-pack3 mt-3">
  <div class="row mr-0" *ngIf="subreports && suitesPermissions">
    <div class="col-6 col-sm-3 col-xl-2" *ngFor="let report of filteredReports">
      <div class="card card-suites level-2">
        <div class="card-header">
          <div class="suites-name d-flex align-items-center w-100">
            <div class="suites-first-half">
              <div container="body" ngbTooltip="{{report.reportName}}">{{report.reportName}}</div>
              <div ngbTooltip="{{report.ReportDisplayName}}" class="font-lighter report-display-name">{{report.ReportDisplayName}}</div>
            </div>
          </div>
        </div>
        <div class="card-body d-flex align-items-center justify-content-center" *ngIf="reportPackId">
          <circle-progress *ngIf="report.Status == 'Not Started'" [percent]="report.CompletionPercent"
            [subtitle]="report.Status" [radius]="55" [outerStrokeWidth]="5" [innerStrokeWidth]="3"
            [outerStrokeColor]="'#35a4e8'" [innerStrokeColor]="'#000'" [animation]="true" [animationDuration]="300"
            titleColor="#35a4e8" unitsColor="#35a4e8" subtitleFontSize="11">
          </circle-progress>
          <circle-progress *ngIf="report.Status == 'In Progress'" [percent]="report.CompletionPercent"
            [subtitle]="report.Status" [radius]="55" [outerStrokeWidth]="5" [innerStrokeWidth]="3"
            [outerStrokeColor]="'#27acaa'" [innerStrokeColor]="'#000'" [animation]="true" [animationDuration]="300"
            titleColor="#27acaa" unitsColor="#27acaa" subtitleFontSize="11">
          </circle-progress>
          <circle-progress *ngIf="report.Status == 'Completed'" [percent]="report.CompletionPercent"
            [subtitle]="report.Status" [radius]="55" [outerStrokeWidth]="5" [innerStrokeWidth]="3"
            [outerStrokeColor]="'#9c82d4'" [innerStrokeColor]="'#000'" [animation]="true" [animationDuration]="300"
            titleColor="#9c82d4" unitsColor="#9c82d4" subtitleFontSize="11">
          </circle-progress>
          <div [ngClass]="report.overlayTextValue == true ? 'overlay hover-overlay' : 'overlay'">
            <i class="fas fa-times-circle fa-lg" (click)="report.overlayTextValue = false; "></i>
            <div class="text w-100" data-simplebar data-simplebar-auto-hide="false">
              <ul class="dependency text-left pl-3">
                <li *ngFor="let parent of report.ParentReportList" [ngClass]="getClass(parent['Status'])">
                  {{ parent.ReportDisplayName }} - {{parent.Status }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-footer d-flex align-items-center justify-content-center">
          <button class="btn btn-icon-round hvr-shrink" type="button"
            (click)="navigateRoute(report.reportAbbr, report.reportId, report.reportName,report.reportAliasName,report.ReportDisplayName)"
            ngbTooltip="Go To Report" container="body" tooltipClass="reporting-tooltip">
            <i class="fas fa-eye"></i>
          </button>
          <button class="btn btn-icon-round hvr-shrink ml-2" type="button" *ngIf="selectedRp && report.IsStatusReset"
            ngbTooltip="The status for the report has been reset as a new data load is present for the report pack"
            container="body" tooltipClass="reporting-tooltip">
            <i class="fas fa-question"></i>
          </button>
          <button class="btn btn-icon-round hvr-shrink overlay-btn ml-2" *ngIf="selectedRp && report.ParentReportList.length>0"
            (click)="report.overlayTextValue = true" type="button" ngbTooltip="View Dependencies" container="body"
            tooltipClass="reporting-tooltip">
            <i class="fab fa-codepen fa-lg"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>