import { Directive, ElementRef, HostListener, Renderer2, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[trim-text]'
})

export class TrimTextDirective {

  constructor(private element: ElementRef, private renderer: Renderer2, @Self() private ngControl: NgControl) { }

  @HostListener('blur') onBlur() {
    let userInputValue = this.element.nativeElement.value.trim();
    this.renderer.setProperty(this.element.nativeElement, 'value', userInputValue);
    this.renderer.setAttribute(this.element.nativeElement, 'value', userInputValue);
    this.ngControl.control.patchValue(userInputValue);
  }

}