import { Injectable } from '@angular/core';
import { ApiService } from './api.service';


@Injectable()
export class UserManagementService {

  userList: object = [];
  roleList: object = [];
  /**
   * Creates an instance of ingestion service.
   */
  constructor(private apiService: ApiService) { }

  getUsers() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('users/', '')
        .toPromise()
        .then(
          res => { // Success
            this.userList = res;
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
  getRoles() {

    const params = {
      flag: true
    };

    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getRoleList/', params)
        .toPromise()
        .then(
          res => { // Success
            this.roleList = res;
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  addUser(params) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.post('users/', params, 'User added')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  editUser(userid, params) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.put('users/', userid, params, 'User updated')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  deleteUser(userid) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.delete('users/', userid, 'User deleted')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  } 

}
