/**
 * Mock fixture's routes
 */
export enum Mock {
  'users/' = './assets/fixtures/Users/users.json',
  'users/28' = './assets/fixtures/Users/role_admin.json',
  'users/admin/' = './assets/fixtures/Users/role_admin.json',
  'users/preparer/' = './assets/fixtures/Users/role_preparer.json',
  'users/preparerbt/' = './assets/fixtures/Users/role_preparer_bt.json',
  'users/approver1/' = './assets/fixtures/Users/role_approver_1.json',
  'users/approver2/' = './assets/fixtures/Users/role_approver_2.json',
  'users/approver3/' = './assets/fixtures/Users/role_approver_3.json',
  'approver-panel/' = './assets/fixtures/Approver-Panel/approver-panel.json',
  'ingestion-panel/' = './assets/fixtures/Ingestion-Panel/ingestion-panel.json',
  'reportSuite/' = './assets/fixtures/Suites/reportSuite.json',
  'gridConfig/' = './assets/fixtures/Reports/gridConfig.json',
  'BoEReportsMetadata/' = './assets/fixtures/Reports/BoEReportsMetadata.json',
  'permissions/' = './assets/fixtures/Permissions/permissions.json',
  'roles/' = './assets/fixtures/Roles/roles.json'
}
