import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../security/authentication.service';
import { ApiService } from './api.service';


/**
 * Injectable
 */
@Injectable()
export class DataIngestionService {

    ingestionsList: object = [];
    ingestionLoadData: any;
    dataForStartIngestion: any;
    baseURL: string = environment.apiUrl;
    ingestionTemplateGroups: any;
    ingestionTemplates: any;
    /**
     * Creates an instance of ingestion service.
     */
    constructor(private apiService: ApiService, private authService: AuthenticationService) { }

    /**
     * Report Suite Metadata Extraction
     */
    getIngestions() {
        const params = {
            desc: true
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('ingestions/', params)
                .toPromise()
                .then(
                    res => { // Success
                        this.ingestionsList = res;
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getStartIngestionData() {
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('getStartIngestionData/', '', '')
                .toPromise()
                .then(
                    res => { // Success
                        this.dataForStartIngestion = res;
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getDataPreparationReportList(reportPackId) {
        const params = {
            reportPackId: reportPackId
        };

        const promise = new Promise((resolve, reject) => {
            this.apiService.get('getDataPreparationList', params)
                .toPromise()
                .then(
                    res => {
                        resolve(res);
                    },
                    msg => {
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    startIngestion(formData) {
        const promise = new Promise<void>((resolve, reject) => {
            this.apiService.post('ingestions', formData, 'File uploaded successfully. The data ingestion will start in a while!', false, true)
                .toPromise()
                .then(
                    res => { // Success
                        resolve();
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getFormPageDetails(templateId: number) {
        const params = {
            templateId: templateId
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('ingestions/getFormPages/', params)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    checkForActiveDataPreparations(reportPackId: number) {
        const params = {
            reportPackId: reportPackId
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('ingestions/hasActiveDataPreparations/', params)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    checkForActiveIngestions(reportPackId: number) {
        const params = {
            reportPackId: reportPackId
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('ingestions/hasActiveIngestions/', params)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getIngestionFormData(reportPackId: number, tableId: number, reportId: number, reportName: string, reportAlias: string) {
        const params = {
            reportPackId: reportPackId,
            reportId: reportId,
            reportName: reportName,
            reportAlias: reportAlias,
            tableId: tableId
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('ingestions/getFormData/', params)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    saveIngestionForm(formData: any[], draftVersion: boolean) {
        const data = {
            reportPackId: this.ingestionLoadData.reportpackid, reportPackTitle: this.ingestionLoadData.reportpacktitle, reportPackDesc: this.ingestionLoadData.reportpackdesc,
            reportingPeriod: this.ingestionLoadData.reportingperiod ? new Date(this.ingestionLoadData.reportingperiod).toDateString() : null, changereason: this.ingestionLoadData.changereason, reportsToPrepare: this.ingestionLoadData.reportsToPrepare, formData: formData,
            tableId: this.ingestionLoadData.tableId, fileName: this.ingestionLoadData.fileName, loadId: this.ingestionLoadData.loadId, draftVersion: draftVersion,
            currentStatus: this.ingestionLoadData.currentStatus, accountingFrameworkId: this.ingestionLoadData.accountingFrameworkId,
            reportingLevelId: this.ingestionLoadData.reportingLevelId, reportPackSequenceId: this.ingestionLoadData.reportPackSequenceId,
            reportPackSequenceName: this.ingestionLoadData.reportPackSequenceName, reportPackSequenceDescription: this.ingestionLoadData.reportPackSequenceDescription
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.post('saveIngestionForm', data, 'Changes saved successfully!')
                .toPromise()
                .then(
                    res => { // Success
                        console.log('Save call resolved : ' + JSON.stringify(res));
                        resolve(res);
                    },
                    msg => { // Error
                        console.log('Save call rejected: ' + JSON.stringify(msg));
                        reject(msg);
                    }
                );
        });
        return promise;

    }

    getFormCellAudit(reportPackId, tableId: number, rowRefId: number, clientRefId: string, columnId: number) {
        const params = {
            reportPackId: reportPackId,
            tableId: tableId,
            rowRefId: rowRefId,
            clientRefId: clientRefId,
            columnId: columnId
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('getFormCellAudit', params)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    /**
  * Download the form audit report.
  */
    downloadFormAuditReport() {
        window.location.href = this.baseURL + 'getformauditreport?reportPackId=' + this.ingestionLoadData.reportpackid + '&tableId=' + this.ingestionLoadData.tableId + '&reportPackName=' + this.ingestionLoadData.reportpacktitle.replace(/[^a-zA-Z0-9]/g, '') + '&formName=' + this.ingestionLoadData.fileName.replace(/[^a-zA-Z0-9]/g, '') + '&token=' + this.authService.getTokenHeader();
    }

    downloadErrorAuditReport() {
        window.location.href = this.baseURL + 'getIngestionErrorAudit?reportPackId=' + this.ingestionLoadData.reportpackid + '&reportPackName=' + this.ingestionLoadData.reportpacktitle.replace(/[^a-zA-Z0-9]/g, '') + '&token=' + this.authService.getTokenHeader();
    }

    /* File Edit Changes  */
    getIngestionFileData(fileEditModel: any) {
        const params = {
            reportPackId: fileEditModel.reportPackId,
            tableId: fileEditModel.tableId,
            pageNumber: fileEditModel.pageNumber,
            pageSize: fileEditModel.pageSize,
            onlyErrorRows: fileEditModel.onlyErrorRows,
            isInitialLoad: fileEditModel.isInitialLoad,
            sortList: fileEditModel.sortList,
            filter: fileEditModel.filter
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.post('ingestions/getFileData/', params, '')
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    /* File Edit Changes  */

    getReportPacks() {

        const params = {
            desc: true
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('getAllReportPacks/', '', '')
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    abandonDraftLoad(reportPackId: number, tableId: number) {
        const promise = new Promise((resolve, reject) => {
            this.apiService.put('abandonDraftLoad/', reportPackId, tableId, 'Draft Load abandoned successfully.')
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }
}