import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { SuiteService } from 'src/app/core/services/suite.service';
import { RoleManagementService } from 'src/app/core/security/role-management.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { Title } from "@angular/platform-browser";

/**
  * Report Dashboard Component.
  */
@Component({
    selector: 'app-report-dashboard',
    templateUrl: './report-dashboard.component.html',
    styleUrls: ['./report-dashboard.component.css']
})
export class ReportDashboardComponent implements OnInit, OnDestroy {
    /**
       * loading
       */
    loading;
    /**
     * Url  of report dashboard component
     */
    url: string[];
    /**
     * Suite  of report dashboard component
     */
    suite: string;
    /**
     * Report pack id of report dashboard component
     */
    reportPackId: number = 0;
    reportPackIdSub: Subscription;
    /**
     * Suites permissions of suite dashboard component
     */
    suitesPermissions: any;
    /**
     * Suites permissions sub of suite dashboard component
     */
    suitesPermissionsSub: Subscription;
    /**
     * Reports of report dashboard component
     */
    reports: any;
    options: any;
    filteredReports: any;
    nameFilter: string = '';
    statusFilter: string = '';
    sortOrder: string = '';
    selectedRp: any;
    scrollHeight = true;
    /**
    * Creates an instance of the Report Dashboard component.
    */
    constructor(
        private router: Router,
        private dashboardService: DashboardService,
        private alertService: AlertService,
        private suiteService: SuiteService,
        private roleService: RoleManagementService,
        private authService: AuthenticationService,
        private titleService: Title
    ) {

        this.options = {
            title: { display: false },
            legend: { display: false },
            rotation: 1 * Math.PI,
            circumference: 1 * Math.PI
        }

    }

    /**
    * on Init.
    */
    ngOnInit() {
        if (!this.authService.isPrivacyNoticeAcknowledged) {
            this.router.navigate(['/privacy-notice']);
        }
        this.selectedRp = this.dashboardService.selectedReportPack;
        this.suite = this.suiteService.getCurrentSuite();
        this.reportPackId = !this.dashboardService.reportPackID ? 0 : this.dashboardService.reportPackID;
        if (this.reportPackId) this.scrollHeight = false;
        else this.scrollHeight = true;

        this.getReports();

        this.suitesPermissions = this.roleService.getSuitePermissions();
        if (!this.suitesPermissions) {
            this.suitesPermissionsSub = this.roleService.watchSuitePermissions()
                .subscribe(permissions => {
                    this.suitesPermissions = permissions;
                });
        }

        this.reportPackIdSub = this.dashboardService.watchReportPackId().subscribe(rpId => {
            this.reportPackId = rpId;
            this.getReports();
            this.selectedRp = this.dashboardService.selectedReportPack;
            this.scrollHeight = false;
        });

        this.titleService.setTitle('IRR: ' + this.suite.toUpperCase());
    }

    getReports() {
        this.loading = true;
        this.dashboardService.getSuiteReports(this.suite).then((data: any) => {
            const filterReports = data.filter((report) => this.roleService.checkPermissions('V', this.suite, report.reportAbbr.toLowerCase()));
            this.reports = filterReports.filter(x => x.suiteAbbr.trim().toLowerCase() == this.suite);
            if (this.reportPackId) {
                this.reports.filter(x => x.hasChildren).forEach(element => {
                    element.statusData = {
                        labels: ['Not Started', 'In Progress', 'Completed'],
                        datasets: [
                            {
                                data: [element.notStartedReports, element.inProgressReports, element.completedReports],
                                backgroundColor: ["#35a4e8", "#27acaa", "#9c82d4"],
                                borderWidth: 2,
                                borderColor: '#c4c4cd'
                            },
                        ]
                    }
                });
            }
            this.onFilterChange();
            this.loading = false;
        });
    }

    onFilterChange() {
        if (this.nameFilter.trim() != '' && this.statusFilter.trim() != '')
            this.filteredReports = this.reports.filter(x => (x.reportName.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1
                || x.ReportDisplayName.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1)
                && x.Status === this.statusFilter);
        else if (this.nameFilter.trim() != '')
            this.filteredReports = this.reports.filter(x => (x.reportName.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1
                || x.ReportDisplayName.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1));
        else if (this.statusFilter.trim() != '')
            this.filteredReports = this.reports.filter(x => x.Status === this.statusFilter);
        else this.filteredReports = this.reports;
        this.sortList();
    }

    toggleSortOrder() {
        if (this.sortOrder == '' || this.sortOrder == 'desc')
            this.sortOrder = 'asc';
        else if (this.sortOrder == 'asc')
            this.sortOrder = 'desc';
        else this.sortOrder = '';
        this.sortList();
    }

    sortList() {
        if (this.sortOrder != '') {
            if (this.sortOrder == 'asc')
                this.filteredReports.sort((a, b) => a.ReportDisplayName > b.ReportDisplayName ? 1 : -1);
            else if (this.sortOrder == 'desc')
                this.filteredReports.sort((a, b) => a.ReportDisplayName < b.ReportDisplayName ? 1 : -1);
        }
    }

    clearFilter() {
        this.nameFilter = '';
        this.statusFilter = '';
        this.onFilterChange();
    }


    getClass(status) {
        switch (status) {
            case 'Not Started':
                return 'mb-1 txt-ns';
            case 'In Progress':
                return 'mb-1 txt-ip';
            case 'Completed':
                return 'mb-1 txt-c';
            default:
                return 'mb-1 txt-ns';
        }
    }

    /**
     * on destroy
     */
    ngOnDestroy() {
        if (this.suitesPermissionsSub) {
            this.suitesPermissionsSub.unsubscribe();
        }
        if (this.reportPackIdSub) {
            this.reportPackIdSub.unsubscribe();
        }
    }
    /**
    * Navigates to selected report and prompts to select a report pack.
    */
    async navigateRoute(formName: string, reportId: number, fullFormName: string, hasChildren: boolean, reportAliasName: string, reportdisplayname: string) {
        if (!hasChildren) {
            this.dashboardService.formName = formName;
            this.dashboardService.reportId = reportId;
            this.dashboardService.fullFormName = fullFormName;
            this.dashboardService.reportAliasName = reportAliasName;
            this.dashboardService.suitename = this.suite;
            this.dashboardService.parentReportDisplayName = '';

            var report = this.filteredReports.find(x => x.reportAbbr === formName && x.reportAliasName === reportAliasName);

            this.dashboardService.isReviewValidation = report.isReviewValidation;
            this.dashboardService.auditProcName = report.auditProcName;
            this.dashboardService.exceptionsProcName = report.exceptionsProcName;
            if (this.reportPackId === 0) {
                this.reportPackId = !this.dashboardService.reportPackID ? 0 : this.dashboardService.reportPackID;
            }
            if (!this.reportPackId) {
                this.loading = false;
                this.alertService.warn('Please select a report pack to continue!', true);
            } else
                if (this.reportPackId !== 0) {
                    this.loading = true;
                    await this.dashboardService.getReportData().then(() => {
                        var pageHeaders = this.dashboardService.reportData.reportPageHeaders ? this.dashboardService.reportData.reportPageHeaders : [];
                        this.loading = false;
                        if (!pageHeaders || !pageHeaders.length) {
                            this.alertService.warn('Cannot access the report!', true);
                        }
                        else {
                            this.dashboardService.formName = formName;
                            this.dashboardService.parentFormName = formName;
                            this.dashboardService.reportDisplayName = reportdisplayname;
                            this.router.navigate(['/app/suite', this.suite, reportdisplayname.toLowerCase().trim()]);
                        }
                    }).catch(() => { this.loading = false; });
                }
        }
        else {
            if (this.reportPackId === 0) {
                this.reportPackId = this.dashboardService.reportPackID;
            }
            if (!this.reportPackId) {
                this.loading = false;
                this.alertService.warn('Please select a report pack to continue!', true);
            }
            else {
                this.dashboardService.formName = formName;
                this.dashboardService.reportId = reportId;
                this.dashboardService.fullFormName = fullFormName;
                this.dashboardService.parentReportDisplayName = reportdisplayname;
                this.dashboardService.parentFormName = formName;
                this.dashboardService.reportDisplayName = reportdisplayname;
                this.router.navigate([`/app/suite/${this.suite}/${reportdisplayname.toLowerCase().trim()}/subreports`])
            }
        }
    }
}
