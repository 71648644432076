import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FilterUtils } from 'primeng/utils';
import { AlertService } from 'src/app/core/services/alert.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
    selector: 'app-pnp-report-pack-selection',
    templateUrl: './report-pack-selection.component.html',
    styleUrls: ['./report-pack-selection.component.css']
})
export class PnpReportPackSelectionComponent implements OnInit {
    @Input() period1Id: number;
    @Input() period2Id: number;
    @Output() period1IdChange = new EventEmitter();
    @Output() period2IdChange = new EventEmitter();
    @Output() previousTab = new EventEmitter();
    @Output() nextTab = new EventEmitter();
    reportPackList: any;
    reportPackSelectList: any;
    selectedPeriod1: any;
    selectedPeriod2: any;
    loading = false;
    isReport = false;
    headerCols: any[];
    reportDateFilters: Date[];
    ingestedDateFilters: Date[];
    dateTime = new Date();
    yearRange = "2000:" + this.dateTime.getFullYear().toString();
    refreshPackList: any;

    constructor(private dashboardService: DashboardService, private alertService: AlertService) { }

    ngOnInit() {
        this.headerCols = [
            { field: 'Title', header: 'Report Pack Title' },
            { field: 'FileName', header: 'File Name' },
            { field: 'ReportDate', header: 'Report Date' },
            { field: 'IngestedBy', header: 'Ingested By' },
            { field: 'IngestedDate', header: 'Ingested Date' }
        ];

        FilterUtils['dateRangeFilter'] = (value, filter: [Date, Date]): boolean => {
            if (!filter || (!filter[0] && !filter[1]))
                return true;

            var val = new Date(value);
            var s = filter[0].getTime();
            var e;
            if (filter[1])
                e = filter[1].getTime() + (24 * 60 * 60 * 1000) - 1000;
            else e = s + (24 * 60 * 60 * 1000) - 1000;
            return val.getTime() >= s && val.getTime() <= e;
        }

        this.getSelectionList();
    }

    getSelectionList() {
        if (this.dashboardService.reportPackIdList[0]) {
            this.reportPackList = JSON.parse(JSON.stringify(this.dashboardService.reportPackIdList));
            this.UpdateSelection();
        }
        else {
            this.loading = true;
            this.dashboardService.getReportPacks().then(() => {
                this.reportPackList = JSON.parse(JSON.stringify(this.dashboardService.reportPackIdList));
                this.UpdateSelection();
                this.loading = false;
            }).catch(() => { this.loading = false; });
        }
    }

    UpdateSelection() {
        this.reportPackSelectList = JSON.parse(JSON.stringify(this.reportPackList));
        if (this.period1Id) {
            var period1 = this.reportPackSelectList.filter(x => x.ReportPackID == this.period1Id)[0];
            this.period1Select(period1);
        }
        if (this.period2Id) {
            var period2 = this.reportPackSelectList.filter(x => x.ReportPackID == this.period2Id)[0];
            this.period2Select(period2);
        }
    }


    closeDateRangeFilter(dateComponent: any, filters: Date[]) {
        if (filters[1]) {
            dateComponent.hideOverlay();
        }
    }

    clearFilters() {
        this.ingestedDateFilters = null;
        this.reportDateFilters = null;
    }

    period1Select(reportpack: any) {
        if (reportpack.ReportPackID) {
            if (!this.selectedPeriod1) {
                this.selectedPeriod1 = reportpack;
                let index = this.reportPackSelectList.indexOf(this.selectedPeriod1);
                this.reportPackSelectList.splice(index, 1);
                this.refreshPackList = this.reportPackSelectList;
                this.period1Id = this.selectedPeriod1.ReportPackID;
                this.period1IdChange.emit(this.period1Id);
            }
            else this.alertService.warn('Please remove the selected period to add a new one!');
        }

        this.reportPackSelectList = JSON.parse(JSON.stringify(this.refreshPackList));
    }

    period1Unselect() {
        this.reportPackSelectList = JSON.parse(JSON.stringify(this.reportPackList));
        if (this.selectedPeriod2) {
            var index = this.reportPackSelectList.indexOf(this.selectedPeriod2);
            this.reportPackSelectList.splice(index, 1);
        }
        this.selectedPeriod1 = null;
        this.period1Id = null;
        this.period1IdChange.emit(this.period1Id);
    }


    period2Select(reportpack: any) {
        if (reportpack.ReportPackID) {
            if (!this.selectedPeriod2) {
                this.selectedPeriod2 = reportpack;
                let index = this.reportPackSelectList.indexOf(this.selectedPeriod2);
                this.reportPackSelectList.splice(index, 1);
                this.refreshPackList = this.reportPackSelectList;
                this.period2Id = this.selectedPeriod2.ReportPackID;
                this.period2IdChange.emit(this.period2Id);
            }
            else this.alertService.warn('Please remove the selected period to add a new one!');
        }

        this.reportPackSelectList = JSON.parse(JSON.stringify(this.refreshPackList));
    }

    period2Unselect() {
        this.reportPackSelectList = JSON.parse(JSON.stringify(this.reportPackList));
        if (this.selectedPeriod1) {
            var index = this.reportPackSelectList.indexOf(this.selectedPeriod1);
            this.reportPackSelectList.splice(index, 1);
        }
        this.selectedPeriod2 = null;
        this.period2Id = null;
        this.period2IdChange.emit(this.period2Id);
    }

    goToPreviousTab() {
        this.previousTab.emit(true);
    }

    goToNextTab() {
        this.nextTab.emit(true);
    }
}
