import { NgModule } from '@angular/core';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import { LogoutComponent } from './logout/logout.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { UnderMaintenanceComponent } from './under-maintenance/under-maintenance.component';

@NgModule({
  declarations: [
    AccessDeniedComponent,
    ServerErrorComponent,
    LogoutComponent,
    UnderMaintenanceComponent
  ],
  imports: [
    RouterModule,
    SharedModule
  ],
  exports: [
    AccessDeniedComponent,
    ServerErrorComponent,
    LogoutComponent,
    UnderMaintenanceComponent
  ]
})
export class PlaceholderModule { }
