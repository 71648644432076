<app-loader *ngIf="loading"></app-loader>
<div *ngIf="isAccessDenied">
    <div class="row mx-0 d-flex align-items-center justify-content-center text-center">
        <div class="col-md-10 error-code">
            <img src="assets/images/no-report-access.svg" class="my-5 no-report-img">
        </div>
        <div class="col-md-8 text-white">
            <h1>Access Denied</h1>
            <h4>You do not have permission to view this report</h4>
        </div>
    </div>
</div>
<div *ngIf="!isAccessDenied">
<div class="iframe-height w-100" #reportContainer></div> 
</div>