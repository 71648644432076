<div id="gridDiv" class="grdDv" *ngIf="groupedColumns">
    <ng-template #template let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showTooltip($event)">
        <kendo-grid [data]="gridView" scrollable="scrollable" style="width: 100%" (excelExport)="onExcelExport($event)"
            [id]='gridId' (cellClick)="editType =='inline' ? cellClickHandler($event): false"
            [height]="scrollable ? 670 : 'auto'" [scrollable]="scrollable ? 'scrollable':'none'" [resizable]="true"
            (cellClose)="editType =='inline' ? cellCloseHandler($event) : false" [pageSize]="pageSize" [skip]="skip"
            [pageable]="pageable" (pageChange)="pageChange($event)" (remove)="removeRow($event)" (add)="addRow($event)">
            <ng-template kendoGridToolbarTemplate *ngIf="showExcelExport">
                <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
            </ng-template>
            <ng-template kendoGridToolbarTemplate *ngIf="hasDynamicRows" class="k-grid-toolbar">
                <button class="add-button hvr-shrink" kendoGridAddCommand>Add Row</button>
            </ng-template>
            <ng-container *ngFor="let c1 of groupedColumns">
                <kendo-grid-column-group *ngIf="c1.children" [title]="c1.title">
                    <ng-container *ngFor="let c2 of c1.children">
                        <kendo-grid-column-group *ngIf="c2.children" [title]="c2.title">
                            <ng-container *ngFor="let c3 of c2.children">
                                <kendo-grid-column-group *ngIf="c3.children" [title]="c3.title">
                                    <ng-container *ngFor="let c4 of c3.children">
                                        <kendo-grid-column-group *ngIf="c4.children" [title]="c4.title">
                                            <ng-container *ngFor="let c5 of c4.children">
                                                <kendo-grid-column-group *ngIf="c5.children" [title]="c5.title">
                                                    <ng-container *ngFor="let c6 of c5.children">
                                                        <kendo-grid-column-group *ngIf="c6.children" [title]="c6.title">
                                                            <ng-container *ngFor="let c7 of c6.children">
                                                                <kendo-grid-column-group *ngIf="c7.children"
                                                                    [title]="c7.title">
                                                                    <ng-container *ngFor="let c8 of c7.children">
                                                                        <kendo-grid-column-group *ngIf="c8.children"
                                                                            [title]="c8.title">
                                                                            <ng-container
                                                                                *ngFor="let c9 of c8.children">
                                                                                <kendo-grid-column-group
                                                                                    *ngIf="c9.children"
                                                                                    [title]="c9.title">
                                                                                    <ng-container
                                                                                        *ngFor="let c10 of c10.children">
                                                                                        <kendo-grid-column
                                                                                            *ngIf="!c10.children"
                                                                                            [id]="c10.columnId"
                                                                                            [field]="c10.field"
                                                                                            [editable]="c10.editable && editType == 'inline'"
                                                                                            [title]="c10.title"
                                                                                            format="{0:n}"
                                                                                            [width]="c10.width"
                                                                                            [locked]="c10.pinned"
                                                                                            [hidden]="c10.hidden"
                                                                                            [class]="{'k-grid-align-left': c10.leftalign, 'k-grid-align-right': c10.rightalign, 'k-grid-align-center': c10.centeralign}">
                                                                                            <ng-template
                                                                                                kendoGridCellTemplate
                                                                                                let-dataItem>
                                                                                                <div
                                                                                                    [ngClass]="blankCellsList.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) > -1 || (blankCellsList.indexOf(c10.tabCode) > -1 && unBlankCellsList.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) == -1) ?  'blank-cell' : blockedCellsList.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) > -1 || (blockedCellsList.indexOf(c10.tabCode) > -1 && unblockedCellsList.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) == -1) || (forIFForm && ((c10.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) == -1) ? 'blocked-cell' : ''">
                                                                                                </div>
                                                                                                <div class="d-flex align-items-center justify-content-center grdCell"
                                                                                                    *ngIf="blankCellsList.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c10.tabCode) == -1 || unBlankCellsList.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c10.tabCode) == -1 || unblockedCellsList.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) > -1) && !(forIFForm && ((c10.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) == -1)">
                                                                                                    <div class="col-12 p-0 text-truncate d-flex align-items-center justify-content-center "
                                                                                                        (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c10)"
                                                                                                        title="{{!dataItem[c10.field] && (c10.isUncomputed || uncomputedCellsList.indexOf(c10.tabCode.replace('%',dataItem.boxcode)) > -1) && computedCellsList.indexOf(c10.tabCode.replace('%',dataItem.boxcode)) == -1 ? 'Uncomputed' : (dataItem[c10.field] | dataTypeFormat: (cellDataTypeJson[c10.tabCode.replace('%', dataItem.boxcode)] ? cellDataTypeJson[c10.tabCode.replace('%', dataItem.boxcode)]: c10.editor) : (cellDecimalPrecisionJson[c10.tabCode.replace('%',dataItem.boxcode)] ? cellDecimalPrecisionJson[c10.tabCode.replace('%',dataItem.boxcode)] : c10.decimals))}}">
                                                                                                        <div class=" col-11 cell-data-padding"
                                                                                                            [ngClass]="{'editable' : ((c10.editable && dataItem.canadjust && blankCellsList.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c10.tabCode) == -1 || unBlankCellsList.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c10.tabCode) == -1 || unblockedCellsList.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) > -1) && nonEditableCells.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) == -1) || editableCells.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) > -1), 'text-style' : !dataItem[c10.field] && (c10.isUncomputed || uncomputedCellsList.indexOf(c10.tabCode.replace('%',dataItem.boxcode)) > -1)}">
                                                                                                            {{
                                                                                                            !dataItem[c10.field]
                                                                                                            &&
                                                                                                            (c10.isUncomputed
                                                                                                            ||
                                                                                                            uncomputedCellsList.indexOf(c10.tabCode.replace('%',dataItem.boxcode))
                                                                                                            > -1) &&
                                                                                                            computedCellsList.indexOf(c10.tabCode.replace('%',dataItem.boxcode))
                                                                                                            == -1 ?
                                                                                                            'Uncomputed'
                                                                                                            :
                                                                                                            (dataItem[c10.field]
                                                                                                            |
                                                                                                            dataTypeFormat:
                                                                                                            (cellDataTypeJson[c10.tabCode.replace('%',
                                                                                                            dataItem.boxcode)]
                                                                                                            ?
                                                                                                            cellDataTypeJson[c10.tabCode.replace('%',
                                                                                                            dataItem.boxcode)].editor
                                                                                                            :
                                                                                                            c10.editor)
                                                                                                            :
                                                                                                            (cellDecimalPrecisionJson[c10.tabCode.replace('%',dataItem.boxcode)]
                                                                                                            ?
                                                                                                            cellDecimalPrecisionJson[c10.tabCode.replace('%',dataItem.boxcode)]
                                                                                                            :
                                                                                                            (cellDataTypeJson[c10.tabCode.replace('%',
                                                                                                            dataItem.boxcode)]
                                                                                                            ?
                                                                                                            cellDataTypeJson[c10.tabCode.replace('%',
                                                                                                            dataItem.boxcode)].decimals
                                                                                                            :
                                                                                                            c10.decimals)))
                                                                                                            }}
                                                                                                        </div>
                                                                                                        <div class="col-1 cell-data-padding"
                                                                                                            title=""
                                                                                                            *ngIf="regArtReferenceCells.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) > -1"
                                                                                                            (click)="redirectToRegArt(c10.tabCode.replace('%', dataItem.boxcode))">
                                                                                                            <i
                                                                                                                class="fa fa-bars cursor-pointer"></i>
                                                                                                        </div>
                                                                                                        <div class="col-1 cell-data-padding"
                                                                                                            *ngIf="regArtReferenceCells.indexOf(c10.tabCode.replace('%', dataItem.boxcode)) == -1"
                                                                                                            (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c10)">
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                kendoGridEditTemplate
                                                                                                let-formGroup
                                                                                                *ngIf="editType == 'inline'">
                                                                                                <div>
                                                                                                    <kendo-numerictextbox
                                                                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'numeric'"
                                                                                                        [(value)]="formGroup.value[c10.field]"
                                                                                                        [decimals]="cellEditor.decimals"
                                                                                                        [autoCorrect]="true"
                                                                                                        [min]="-10000000000000"
                                                                                                        [max]="10000000000000"
                                                                                                        [format]="'#.##########'">
                                                                                                    </kendo-numerictextbox>
                                                                                                    <input type="text"
                                                                                                        class="w-100"
                                                                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'text'"
                                                                                                        [(ngModel)]="formGroup.value[c10.field]"
                                                                                                        maxlength="50"
                                                                                                        pattern="^[A-Za-z0-9 _\-.]*$"
                                                                                                        trim-text />
                                                                                                    <input
                                                                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                                                                        [style.display]="'none'" />
                                                                                                    <p-calendar
                                                                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                                                                        [(ngModel)]="formGroup.value[c10.field]"
                                                                                                        [showIcon]="true"
                                                                                                        [autoZIndex]="true"
                                                                                                        dateFormat="dd/mm/yy"
                                                                                                        [readonlyInput]="true"
                                                                                                        [monthNavigator]="true"
                                                                                                        yearRange="{{yearRange}}"
                                                                                                        [yearNavigator]="true"
                                                                                                        appendTo="body">
                                                                                                    </p-calendar>
                                                                                                    <select
                                                                                                        *ngIf="cellEditor.cellOptions.length > 0"
                                                                                                        class="cellPopOutInput"
                                                                                                        [(ngModel)]="formGroup.value[c10.field]">
                                                                                                        <option
                                                                                                            *ngFor="let op of cellEditor.cellOptions"
                                                                                                            [ngValue]="op.value">
                                                                                                            {{op.title}}
                                                                                                        </option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </ng-template>
                                                                                        </kendo-grid-column>
                                                                                    </ng-container>
                                                                                </kendo-grid-column-group>
                                                                                <kendo-grid-column *ngIf="!c9.children"
                                                                                    [id]="c9.columnId"
                                                                                    [field]="c9.field"
                                                                                    [editable]="c9.editable && editType == 'inline'"
                                                                                    [title]="c9.title" format="{0:n}"
                                                                                    [width]="c9.width"
                                                                                    [locked]="c9.pinned"
                                                                                    [hidden]="c9.hidden"
                                                                                    [class]="{'k-grid-align-left': c9.leftalign, 'k-grid-align-right': c9.rightalign, 'k-grid-align-center': c9.centeralign}">
                                                                                    <ng-template kendoGridCellTemplate
                                                                                        let-dataItem>
                                                                                        <div
                                                                                            [ngClass]="blankCellsList.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) > -1 || (blankCellsList.indexOf(c8.tabCode) > -1 && unBlankCellsList.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) == -1) ?  'blank-cell' : blockedCellsList.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) > -1 || (blockedCellsList.indexOf(c9.tabCode) > -1 && unblockedCellsList.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) == -1) || (forIFForm && ((c9.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) == -1) ? 'blocked-cell' : ''">
                                                                                        </div>
                                                                                        <div class="d-flex align-items-center justify-content-center grdCell"
                                                                                            *ngIf="blankCellsList.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c9.tabCode) == -1 || unBlankCellsList.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c9.tabCode) == -1 || unblockedCellsList.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) > -1) && !(forIFForm && ((c9.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) == -1)">
                                                                                            <div class="col-12 p-0 text-truncate d-flex align-items-center justify-content-center"
                                                                                                (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c9)"
                                                                                                title="{{!dataItem[c9.field] && (c9.isUncomputed || uncomputedCellsList.indexOf(c9.tabCode.replace('%',dataItem.boxcode)) > -1) && computedCellsList.indexOf(c9.tabCode.replace('%',dataItem.boxcode)) == -1 ? 'Uncomputed' : (dataItem[c9.field] | dataTypeFormat: (cellDataTypeJson[c9.tabCode.replace('%', dataItem.boxcode)] ? cellDataTypeJson[c9.tabCode.replace('%', dataItem.boxcode)]: c9.editor) : (cellDecimalPrecisionJson[c9.tabCode.replace('%',dataItem.boxcode)] ? cellDecimalPrecisionJson[c9.tabCode.replace('%',dataItem.boxcode)] : c9.decimals))}}">
                                                                                                <div class="col-11 cell-data-padding"
                                                                                                    [ngClass]="{'editable': ((c9.editable && dataItem.canadjust && blankCellsList.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c9.tabCode) == -1 || unBlankCellsList.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c9.tabCode) == -1 || unblockedCellsList.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) > -1) && nonEditableCells.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) == -1) || editableCells.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) > -1), 'text-style': !dataItem[c9.field] && (c9.isUncomputed || uncomputedCellsList.indexOf(c9.tabCode.replace('%',dataItem.boxcode)) > -1)}">
                                                                                                    {{
                                                                                                    !dataItem[c9.field]
                                                                                                    && (c9.isUncomputed
                                                                                                    ||
                                                                                                    uncomputedCellsList.indexOf(c9.tabCode.replace('%',dataItem.boxcode))
                                                                                                    > -1) &&
                                                                                                    computedCellsList.indexOf(c9.tabCode.replace('%',dataItem.boxcode))
                                                                                                    == -1 ? 'Uncomputed'
                                                                                                    :
                                                                                                    (dataItem[c9.field]
                                                                                                    |
                                                                                                    dataTypeFormat:
                                                                                                    (cellDataTypeJson[c9.tabCode.replace('%',
                                                                                                    dataItem.boxcode)]
                                                                                                    ?
                                                                                                    cellDataTypeJson[c9.tabCode.replace('%',
                                                                                                    dataItem.boxcode)].editor
                                                                                                    : c9.editor)
                                                                                                    :
                                                                                                    (cellDecimalPrecisionJson[c9.tabCode.replace('%',dataItem.boxcode)]
                                                                                                    ?
                                                                                                    cellDecimalPrecisionJson[c9.tabCode.replace('%',dataItem.boxcode)]
                                                                                                    :
                                                                                                    (cellDataTypeJson[c9.tabCode.replace('%',
                                                                                                    dataItem.boxcode)]
                                                                                                    ?
                                                                                                    cellDataTypeJson[c9.tabCode.replace('%',
                                                                                                    dataItem.boxcode)].decimals
                                                                                                    :
                                                                                                    c9.decimals)))
                                                                                                    }}
                                                                                                </div>
                                                                                                <div class="col-1 cell-data-padding"
                                                                                                    title=""
                                                                                                    *ngIf="regArtReferenceCells.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) > -1"
                                                                                                    (click)="redirectToRegArt(c9.tabCode.replace('%', dataItem.boxcode))">
                                                                                                    <i
                                                                                                        class="fa fa-bars cursor-pointer"></i>
                                                                                                </div>
                                                                                                <div class="col-1 cell-data-padding"
                                                                                                    *ngIf="regArtReferenceCells.indexOf(c9.tabCode.replace('%', dataItem.boxcode)) == -1"
                                                                                                    (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c9)">
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </ng-template>
                                                                                    <ng-template kendoGridEditTemplate
                                                                                        let-formGroup
                                                                                        *ngIf="editType == 'inline'">
                                                                                        <div>
                                                                                            <kendo-numerictextbox
                                                                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'numeric'"
                                                                                                [(value)]="formGroup.value[c9.field]"
                                                                                                [decimals]="cellEditor.decimals"
                                                                                                [autoCorrect]="true"
                                                                                                [min]="-10000000000000"
                                                                                                [max]="10000000000000"
                                                                                                [format]="'#.##########'">
                                                                                            </kendo-numerictextbox>
                                                                                            <input type="text"
                                                                                                class="w-100"
                                                                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'text'"
                                                                                                [(ngModel)]="formGroup.value[c9.field]"
                                                                                                maxlength="50"
                                                                                                pattern="^[A-Za-z0-9 _\-.]*$"
                                                                                                trim-text />
                                                                                            <input
                                                                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                                                                [style.display]="'none'" />
                                                                                            <p-calendar
                                                                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                                                                [(ngModel)]="formGroup.value[c9.field]"
                                                                                                [showIcon]="true"
                                                                                                [autoZIndex]="true"
                                                                                                dateFormat="dd/mm/yy"
                                                                                                [readonlyInput]="true"
                                                                                                [monthNavigator]="true"
                                                                                                yearRange="{{yearRange}}"
                                                                                                [yearNavigator]="true"
                                                                                                appendTo="body">
                                                                                            </p-calendar>
                                                                                            <select
                                                                                                *ngIf="cellEditor.cellOptions.length > 0"
                                                                                                class="cellPopOutInput"
                                                                                                [(ngModel)]="formGroup.value[c9.field]">
                                                                                                <option
                                                                                                    *ngFor="let op of cellEditor.cellOptions"
                                                                                                    [ngValue]="op.value">
                                                                                                    {{op.title}}
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </kendo-grid-column>
                                                                            </ng-container>
                                                                        </kendo-grid-column-group>
                                                                        <kendo-grid-column *ngIf="!c8.children"
                                                                            [id]="c8.columnId" [field]="c8.field"
                                                                            [editable]="c8.editable && editType == 'inline'"
                                                                            [title]="c8.title" format="{0:n}"
                                                                            [width]="c8.width" [locked]="c8.pinned"
                                                                            [hidden]="c8.hidden"
                                                                            [class]="{'k-grid-align-left': c8.leftalign, 'k-grid-align-right': c8.rightalign, 'k-grid-align-center': c8.centeralign}">
                                                                            <ng-template kendoGridCellTemplate
                                                                                let-dataItem>
                                                                                <div
                                                                                    [ngClass]="blankCellsList.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) > -1 || (blankCellsList.indexOf(c8.tabCode) > -1 && unBlankCellsList.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) == -1) ?  'blank-cell' : blockedCellsList.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) > -1 || (blockedCellsList.indexOf(c8.tabCode) > -1 && unblockedCellsList.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) == -1) || (forIFForm && ((c8.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) == -1) ? 'blocked-cell' : ''">
                                                                                </div>
                                                                                <div class="d-flex align-items-center justify-content-center grdCell"
                                                                                    *ngIf="blankCellsList.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c8.tabCode) == -1 || unBlankCellsList.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c8.tabCode) == -1 || unblockedCellsList.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) > -1) && !(forIFForm && ((c8.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) == -1)">
                                                                                    <div class="col-12 p-0 text-truncate d-flex align-items-center justify-content-center"
                                                                                        (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c8)"
                                                                                        title="{{!dataItem[c8.field] && (c8.isUncomputed || uncomputedCellsList.indexOf(c8.tabCode.replace('%',dataItem.boxcode)) > -1) && computedCellsList.indexOf(c8.tabCode.replace('%',dataItem.boxcode)) == -1 ? 'Uncomputed' : (dataItem[c8.field] | dataTypeFormat: (cellDataTypeJson[c8.tabCode.replace('%', dataItem.boxcode)] ? cellDataTypeJson[c8.tabCode.replace('%', dataItem.boxcode)]: c8.editor) : (cellDecimalPrecisionJson[c8.tabCode.replace('%',dataItem.boxcode)] ? cellDecimalPrecisionJson[c8.tabCode.replace('%',dataItem.boxcode)] : c8.decimals))}}">
                                                                                        <div class="col-11 cell-data-padding"
                                                                                            [ngClass]="{'editable': ((c8.editable && dataItem.canadjust && blankCellsList.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c8.tabCode) == -1 || unBlankCellsList.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c8.tabCode) == -1 || unblockedCellsList.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) > -1) && nonEditableCells.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) == -1) || editableCells.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) > -1), 'text-style': !dataItem[c8.field] && (c8.isUncomputed || uncomputedCellsList.indexOf(c8.tabCode.replace('%',dataItem.boxcode)) > -1)}">
                                                                                            {{
                                                                                            !dataItem[c8.field] &&
                                                                                            (c8.isUncomputed ||
                                                                                            uncomputedCellsList.indexOf(c8.tabCode.replace('%',dataItem.boxcode))
                                                                                            > -1) &&
                                                                                            computedCellsList.indexOf(c8.tabCode.replace('%',dataItem.boxcode))
                                                                                            == -1 ? 'Uncomputed' :
                                                                                            (dataItem[c8.field]
                                                                                            |
                                                                                            dataTypeFormat:
                                                                                            (cellDataTypeJson[c8.tabCode.replace('%',
                                                                                            dataItem.boxcode)]
                                                                                            ?
                                                                                            cellDataTypeJson[c8.tabCode.replace('%',
                                                                                            dataItem.boxcode)].editor
                                                                                            : c8.editor)
                                                                                            :
                                                                                            (cellDecimalPrecisionJson[c8.tabCode.replace('%',dataItem.boxcode)]
                                                                                            ?
                                                                                            cellDecimalPrecisionJson[c8.tabCode.replace('%',dataItem.boxcode)]
                                                                                            :
                                                                                            (cellDataTypeJson[c8.tabCode.replace('%',
                                                                                            dataItem.boxcode)]
                                                                                            ?
                                                                                            cellDataTypeJson[c8.tabCode.replace('%',
                                                                                            dataItem.boxcode)].decimals
                                                                                            :
                                                                                            c8.decimals)))
                                                                                            }}
                                                                                        </div>
                                                                                        <div class="col-1 cell-data-padding"
                                                                                            title=""
                                                                                            *ngIf="regArtReferenceCells.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) > -1"
                                                                                            (click)="redirectToRegArt(c8.tabCode.replace('%', dataItem.boxcode))">
                                                                                            <i
                                                                                                class="fa fa-bars cursor-pointer"></i>
                                                                                        </div>
                                                                                        <div class="col-1 cell-data-padding"
                                                                                            *ngIf="regArtReferenceCells.indexOf(c8.tabCode.replace('%', dataItem.boxcode)) == -1"
                                                                                            (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c8)">
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </ng-template>
                                                                            <ng-template kendoGridEditTemplate
                                                                                let-formGroup
                                                                                *ngIf="editType == 'inline'">
                                                                                <div>
                                                                                    <kendo-numerictextbox
                                                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'numeric'"
                                                                                        [(value)]="formGroup.value[c8.field]"
                                                                                        [decimals]="cellEditor.decimals"
                                                                                        [autoCorrect]="true"
                                                                                        [min]="-10000000000000"
                                                                                        [max]="10000000000000"
                                                                                        [format]="'#.##########'">
                                                                                    </kendo-numerictextbox>
                                                                                    <input type="text" class="w-100"
                                                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'text'"
                                                                                        [(ngModel)]="formGroup.value[c8.field]"
                                                                                        maxlength="50"
                                                                                        pattern="^[A-Za-z0-9 _\-.]*$"
                                                                                        trim-text />
                                                                                    <input
                                                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                                                        [style.display]="'none'" />
                                                                                    <p-calendar
                                                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                                                        [(ngModel)]="formGroup.value[c8.field]"
                                                                                        [showIcon]="true"
                                                                                        [autoZIndex]="true"
                                                                                        dateFormat="dd/mm/yy"
                                                                                        [readonlyInput]="true"
                                                                                        [monthNavigator]="true"
                                                                                        yearRange="{{yearRange}}"
                                                                                        [yearNavigator]="true"
                                                                                        appendTo="body">
                                                                                    </p-calendar>
                                                                                    <select
                                                                                        *ngIf="cellEditor.cellOptions.length > 0"
                                                                                        class="cellPopOutInput"
                                                                                        [(ngModel)]="formGroup.value[c8.field]">
                                                                                        <option
                                                                                            *ngFor="let op of cellEditor.cellOptions"
                                                                                            [ngValue]="op.value">
                                                                                            {{op.title}}</option>
                                                                                    </select>
                                                                                </div>
                                                                            </ng-template>
                                                                        </kendo-grid-column>
                                                                    </ng-container>
                                                                </kendo-grid-column-group>
                                                                <kendo-grid-column *ngIf="!c7.children"
                                                                    [id]="c7.columnId" [field]="c7.field"
                                                                    [editable]="c7.editable && editType == 'inline'"
                                                                    [title]="c7.title" format="{0:n}" [width]="c7.width"
                                                                    [locked]="c7.pinned" [hidden]="c7.hidden"
                                                                    [class]="{'k-grid-align-left': c7.leftalign, 'k-grid-align-right': c7.rightalign, 'k-grid-align-center': c7.centeralign}">
                                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                                        <div
                                                                            [ngClass]="blankCellsList.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) > -1 || (blankCellsList.indexOf(c7.tabCode) > -1 && unBlankCellsList.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) == -1) ?  'blank-cell' : blockedCellsList.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) > -1 || (blockedCellsList.indexOf(c7.tabCode) > -1 && unblockedCellsList.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) == -1) || (forIFForm && ((c7.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) == -1) ? 'blocked-cell' : ''">
                                                                        </div>
                                                                        <div class="d-flex align-items-center justify-content-center grdCell"
                                                                            *ngIf="blankCellsList.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c7.tabCode) == -1 || unBlankCellsList.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c7.tabCode) == -1 || unblockedCellsList.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) > -1) && !(forIFForm && ((c7.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) == -1)">
                                                                            <div class="col-12 p-0 text-truncate d-flex align-items-center justify-content-center"
                                                                                (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c7)"
                                                                                title="{{!dataItem[c7.field] && (c7.isUncomputed || uncomputedCellsList.indexOf(c7.tabCode.replace('%',dataItem.boxcode)) > -1) && computedCellsList.indexOf(c7.tabCode.replace('%',dataItem.boxcode)) == -1 ? 'Uncomputed' : (dataItem[c7.field] | dataTypeFormat: (cellDataTypeJson[c7.tabCode.replace('%', dataItem.boxcode)] ? cellDataTypeJson[c7.tabCode.replace('%', dataItem.boxcode)]: c7.editor) : (cellDecimalPrecisionJson[c7.tabCode.replace('%',dataItem.boxcode)] ? cellDecimalPrecisionJson[c7.tabCode.replace('%',dataItem.boxcode)] : c7.decimals))}}">
                                                                                <div class=" col-11 cell-data-padding"
                                                                                    [ngClass]="{'editable': ((c7.editable && dataItem.canadjust && blankCellsList.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c7.tabCode) == -1 || unBlankCellsList.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c7.tabCode) == -1 || unblockedCellsList.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) > -1) && nonEditableCells.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) == -1) || editableCells.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) > -1), 'text-style': !dataItem[c7.field] && (c7.isUncomputed || uncomputedCellsList.indexOf(c7.tabCode.replace('%',dataItem.boxcode)) > -1)}">
                                                                                    {{
                                                                                    !dataItem[c7.field] &&
                                                                                    (c7.isUncomputed ||
                                                                                    uncomputedCellsList.indexOf(c7.tabCode.replace('%',dataItem.boxcode))
                                                                                    > -1) &&
                                                                                    computedCellsList.indexOf(c7.tabCode.replace('%',dataItem.boxcode))
                                                                                    == -1 ? 'Uncomputed' :
                                                                                    (dataItem[c7.field]
                                                                                    |
                                                                                    dataTypeFormat:
                                                                                    (cellDataTypeJson[c7.tabCode.replace('%',
                                                                                    dataItem.boxcode)]
                                                                                    ?
                                                                                    cellDataTypeJson[c7.tabCode.replace('%',
                                                                                    dataItem.boxcode)].editor
                                                                                    : c7.editor)
                                                                                    :
                                                                                    (cellDecimalPrecisionJson[c7.tabCode.replace('%',dataItem.boxcode)]
                                                                                    ?
                                                                                    cellDecimalPrecisionJson[c7.tabCode.replace('%',dataItem.boxcode)]
                                                                                    :
                                                                                    (cellDataTypeJson[c7.tabCode.replace('%',
                                                                                    dataItem.boxcode)]
                                                                                    ?
                                                                                    cellDataTypeJson[c7.tabCode.replace('%',
                                                                                    dataItem.boxcode)].decimals :
                                                                                    c7.decimals)))
                                                                                    }}
                                                                                </div>
                                                                                <div class="col-1 cell-data-padding"
                                                                                    title=""
                                                                                    *ngIf="regArtReferenceCells.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) > -1"
                                                                                    (click)="redirectToRegArt(c7.tabCode.replace('%', dataItem.boxcode))">
                                                                                    <i
                                                                                        class="fa fa-bars cursor-pointer"></i>
                                                                                </div>
                                                                                <div class="col-1 cell-data-padding"
                                                                                    *ngIf="regArtReferenceCells.indexOf(c7.tabCode.replace('%', dataItem.boxcode)) == -1"
                                                                                    (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c7)">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-template>
                                                                    <ng-template kendoGridEditTemplate let-formGroup
                                                                        *ngIf="editType == 'inline'">
                                                                        <div>
                                                                            <kendo-numerictextbox
                                                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'numeric'"
                                                                                [(value)]="formGroup.value[c7.field]"
                                                                                [decimals]="cellEditor.decimals"
                                                                                [autoCorrect]="true"
                                                                                [min]="-10000000000000"
                                                                                [max]="10000000000000"
                                                                                [format]="'#.##########'">
                                                                            </kendo-numerictextbox>
                                                                            <input type="text" class="w-100"
                                                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'text'"
                                                                                [(ngModel)]="formGroup.value[c7.field]"
                                                                                maxlength="50"
                                                                                pattern="^[A-Za-z0-9 _\-.]*$" trim-text />
                                                                            <input
                                                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                                                [style.display]="'none'" />
                                                                            <p-calendar
                                                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                                                [(ngModel)]="formGroup.value[c7.field]"
                                                                                [showIcon]="true" [autoZIndex]="true"
                                                                                dateFormat="dd/mm/yy"
                                                                                [readonlyInput]="true"
                                                                                [monthNavigator]="true"
                                                                                yearRange="{{yearRange}}"
                                                                                [yearNavigator]="true" appendTo="body">
                                                                            </p-calendar>
                                                                            <select
                                                                                *ngIf="cellEditor.cellOptions.length > 0"
                                                                                class="cellPopOutInput"
                                                                                [(ngModel)]="formGroup.value[c7.field]">
                                                                                <option
                                                                                    *ngFor="let op of cellEditor.cellOptions"
                                                                                    [ngValue]="op.value">
                                                                                    {{op.title}}</option>
                                                                            </select>
                                                                        </div>
                                                                    </ng-template>
                                                                </kendo-grid-column>
                                                            </ng-container>
                                                        </kendo-grid-column-group>
                                                        <kendo-grid-column *ngIf="!c6.children" [id]="c6.columnId"
                                                            [field]="c6.field"
                                                            [editable]="c6.editable && editType == 'inline'"
                                                            [title]="c6.title" format="{0:n}" [width]="c6.width"
                                                            [locked]="c6.pinned" [hidden]="c6.hidden"
                                                            [class]="{'k-grid-align-left': c6.leftalign, 'k-grid-align-right': c6.rightalign, 'k-grid-align-center': c6.centeralign}">
                                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                                <div
                                                                    [ngClass]="blankCellsList.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) > -1 || (blankCellsList.indexOf(c6.tabCode) > -1 && unBlankCellsList.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) == -1) ?  'blank-cell' : blockedCellsList.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) > -1 || (blockedCellsList.indexOf(c6.tabCode) > -1 && unblockedCellsList.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) == -1) || (forIFForm && ((c6.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) == -1) ? 'blocked-cell' : ''">
                                                                </div>
                                                                <div class="d-flex align-items-center justify-content-center grdCell"
                                                                    *ngIf="blankCellsList.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c6.tabCode) == -1 || unBlankCellsList.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c6.tabCode) == -1 || unblockedCellsList.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) > -1) && !(forIFForm && ((c6.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) == -1)">
                                                                    <div class="col-12 p-0 text-truncate d-flex align-items-center justify-content-center"
                                                                        (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c6)"
                                                                        title="{{!dataItem[c6.field] && (c6.isUncomputed || uncomputedCellsList.indexOf(c6.tabCode.replace('%',dataItem.boxcode)) > -1) && computedCellsList.indexOf(c6.tabCode.replace('%',dataItem.boxcode)) == -1 ? 'Uncomputed' : (dataItem[c6.field] | dataTypeFormat: (cellDataTypeJson[c6.tabCode.replace('%', dataItem.boxcode)] ? cellDataTypeJson[c6.tabCode.replace('%', dataItem.boxcode)]: c6.editor) : (cellDecimalPrecisionJson[c6.tabCode.replace('%',dataItem.boxcode)] ? cellDecimalPrecisionJson[c6.tabCode.replace('%',dataItem.boxcode)] : c6.decimals))}}">
                                                                        <div class="col-11 cell-data-padding"
                                                                            [ngClass]="{'editable': ((c6.editable && dataItem.canadjust && blankCellsList.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c6.tabCode) == -1 || unBlankCellsList.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c6.tabCode) == -1 || unblockedCellsList.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) > -1) && nonEditableCells.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) == -1) || editableCells.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) > -1), 'text-style': !dataItem[c6.field] && (c6.isUncomputed || uncomputedCellsList.indexOf(c6.tabCode.replace('%',dataItem.boxcode)) > -1)}">
                                                                            {{
                                                                            !dataItem[c6.field] && (c6.isUncomputed ||
                                                                            uncomputedCellsList.indexOf(c6.tabCode.replace('%',dataItem.boxcode))
                                                                            > -1) &&
                                                                            computedCellsList.indexOf(c6.tabCode.replace('%',dataItem.boxcode))
                                                                            == -1 ? 'Uncomputed' :
                                                                            (dataItem[c6.field]
                                                                            |
                                                                            dataTypeFormat:
                                                                            (cellDataTypeJson[c6.tabCode.replace('%',
                                                                            dataItem.boxcode)]
                                                                            ? cellDataTypeJson[c6.tabCode.replace('%',
                                                                            dataItem.boxcode)].editor
                                                                            : c6.editor)
                                                                            :
                                                                            (cellDecimalPrecisionJson[c6.tabCode.replace('%',dataItem.boxcode)]
                                                                            ?
                                                                            cellDecimalPrecisionJson[c6.tabCode.replace('%',dataItem.boxcode)]
                                                                            : (cellDataTypeJson[c6.tabCode.replace('%',
                                                                            dataItem.boxcode)]
                                                                            ? cellDataTypeJson[c6.tabCode.replace('%',
                                                                            dataItem.boxcode)].decimals :
                                                                            c6.decimals)))
                                                                            }}
                                                                        </div>
                                                                        <div class="col-1 cell-data-padding" title=""
                                                                            *ngIf="regArtReferenceCells.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) > -1"
                                                                            (click)="redirectToRegArt(c6.tabCode.replace('%', dataItem.boxcode))">
                                                                            <i class="fa fa-bars cursor-pointer"></i>
                                                                        </div>
                                                                        <div class="col-1 cell-data-padding"
                                                                            *ngIf="regArtReferenceCells.indexOf(c6.tabCode.replace('%', dataItem.boxcode)) == -1"
                                                                            (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c6)">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template kendoGridEditTemplate let-formGroup
                                                                *ngIf="editType == 'inline'">
                                                                <div>
                                                                    <kendo-numerictextbox
                                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'numeric'"
                                                                        [(value)]="formGroup.value[c6.field]"
                                                                        [decimals]="cellEditor.decimals"
                                                                        [autoCorrect]="true" [min]="-10000000000000"
                                                                        [max]="10000000000000" [format]="'#.##########'">
                                                                    </kendo-numerictextbox>
                                                                    <input type="text" class="w-100"
                                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'text'"
                                                                        [(ngModel)]="formGroup.value[c6.field]"
                                                                        maxlength="50" pattern="^[A-Za-z0-9 _\-.]*$" trim-text />
                                                                    <input
                                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                                        [style.display]="'none'" />
                                                                    <p-calendar
                                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                                        [(ngModel)]="formGroup.value[c6.field]"
                                                                        [showIcon]="true" [autoZIndex]="true"
                                                                        dateFormat="dd/mm/yy" [readonlyInput]="true"
                                                                        [monthNavigator]="true"
                                                                        yearRange="{{yearRange}}" [yearNavigator]="true"
                                                                        appendTo="body">
                                                                    </p-calendar>
                                                                    <select *ngIf="cellEditor.cellOptions.length > 0"
                                                                        class="cellPopOutInput"
                                                                        [(ngModel)]="formGroup.value[c6.field]">
                                                                        <option
                                                                            *ngFor="let op of cellEditor.cellOptions"
                                                                            [ngValue]="op.value">
                                                                            {{op.title}}</option>
                                                                    </select>
                                                                </div>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                    </ng-container>
                                                </kendo-grid-column-group>
                                                <kendo-grid-column *ngIf="!c5.children" [id]="c5.columnId"
                                                    [field]="c5.field" [editable]="c5.editable && editType == 'inline'"
                                                    [title]="c5.title" format="{0:n}" [width]="c5.width"
                                                    [locked]="c5.pinned" [hidden]="c5.hidden"
                                                    [class]="{'k-grid-align-left': c5.leftalign, 'k-grid-align-right': c5.rightalign, 'k-grid-align-center': c5.centeralign}">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        <div
                                                            [ngClass]="blankCellsList.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) > -1 || (blankCellsList.indexOf(c5.tabCode) > -1 && unBlankCellsList.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) == -1) ?  'blank-cell' : blockedCellsList.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) > -1 || (blockedCellsList.indexOf(c5.tabCode) > -1 && unblockedCellsList.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) == -1) || (forIFForm && ((c5.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) == -1) ? 'blocked-cell' : ''">
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-center grdCell"
                                                            *ngIf="blankCellsList.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c5.tabCode) == -1 || unBlankCellsList.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c5.tabCode) == -1 || unblockedCellsList.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) > -1) && !(forIFForm && ((c5.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) == -1)">
                                                            <div class="col-12 p-0 text-truncate d-flex align-items-center justify-content-center"
                                                                (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c5)"
                                                                title="{{!dataItem[c5.field] && (c5.isUncomputed || uncomputedCellsList.indexOf(c5.tabCode.replace('%',dataItem.boxcode)) > -1) && computedCellsList.indexOf(c5.tabCode.replace('%',dataItem.boxcode)) == -1 ? 'Uncomputed' : (dataItem[c5.field] | dataTypeFormat: (cellDataTypeJson[c5.tabCode.replace('%', dataItem.boxcode)] ? cellDataTypeJson[c5.tabCode.replace('%', dataItem.boxcode)]: c5.editor) : (cellDecimalPrecisionJson[c5.tabCode.replace('%',dataItem.boxcode)] ? cellDecimalPrecisionJson[c5.tabCode.replace('%',dataItem.boxcode)] : c5.decimals))}}">
                                                                <div class="col-11 cell-data-padding"
                                                                    [ngClass]="{'editable': ((c5.editable && dataItem.canadjust && blankCellsList.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c5.tabCode) == -1 || unBlankCellsList.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c5.tabCode) == -1 || unblockedCellsList.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) > -1) && nonEditableCells.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) == -1) || editableCells.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) > -1), 'text-style': !dataItem[c5.field] && (c5.isUncomputed || uncomputedCellsList.indexOf(c5.tabCode.replace('%',dataItem.boxcode)) > -1)}">
                                                                    {{
                                                                    !dataItem[c5.field] && (c5.isUncomputed ||
                                                                    uncomputedCellsList.indexOf(c5.tabCode.replace('%',dataItem.boxcode))
                                                                    > -1) &&
                                                                    computedCellsList.indexOf(c5.tabCode.replace('%',dataItem.boxcode))
                                                                    == -1 ? 'Uncomputed' :
                                                                    (dataItem[c5.field]
                                                                    |
                                                                    dataTypeFormat:
                                                                    (cellDataTypeJson[c5.tabCode.replace('%',
                                                                    dataItem.boxcode)]
                                                                    ? cellDataTypeJson[c5.tabCode.replace('%',
                                                                    dataItem.boxcode)].editor
                                                                    : c5.editor)
                                                                    :
                                                                    (cellDecimalPrecisionJson[c5.tabCode.replace('%',dataItem.boxcode)]
                                                                    ?
                                                                    cellDecimalPrecisionJson[c5.tabCode.replace('%',dataItem.boxcode)]
                                                                    : (cellDataTypeJson[c5.tabCode.replace('%',
                                                                    dataItem.boxcode)]
                                                                    ? cellDataTypeJson[c5.tabCode.replace('%',
                                                                    dataItem.boxcode)].decimals :
                                                                    c5.decimals)))
                                                                    }}
                                                                </div>
                                                                <div class="col-1 cell-data-padding" title=""
                                                                    *ngIf="regArtReferenceCells.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) > -1"
                                                                    (click)="redirectToRegArt(c5.tabCode.replace('%', dataItem.boxcode))">
                                                                    <i class="fa fa-bars cursor-pointer"></i>
                                                                </div>
                                                                <div class="col-1 cell-data-padding"
                                                                    *ngIf="regArtReferenceCells.indexOf(c5.tabCode.replace('%', dataItem.boxcode)) == -1"
                                                                    (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c5)">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template kendoGridEditTemplate let-formGroup
                                                        *ngIf="editType == 'inline'">
                                                        <div>
                                                            <kendo-numerictextbox
                                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'numeric'"
                                                                [(value)]="formGroup.value[c5.field]"
                                                                [decimals]="cellEditor.decimals" [autoCorrect]="true"
                                                                [min]="-10000000000000" [max]="10000000000000"
                                                                [format]="'#.##########'">
                                                            </kendo-numerictextbox>
                                                            <input type="text" class="w-100"
                                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'text'"
                                                                [(ngModel)]="formGroup.value[c5.field]" maxlength="50"
                                                                pattern="^[A-Za-z0-9 _\-.]*$" trim-text />
                                                            <input
                                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                                [style.display]="'none'" />
                                                            <p-calendar
                                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                                [(ngModel)]="formGroup.value[c5.field]"
                                                                [showIcon]="true" [autoZIndex]="true"
                                                                dateFormat="dd/mm/yy" [readonlyInput]="true"
                                                                [monthNavigator]="true" yearRange="{{yearRange}}"
                                                                [yearNavigator]="true" appendTo="body">
                                                            </p-calendar>
                                                            <select *ngIf="cellEditor.cellOptions.length > 0"
                                                                class="cellPopOutInput"
                                                                [(ngModel)]="formGroup.value[c5.field]">
                                                                <option *ngFor="let op of cellEditor.cellOptions"
                                                                    [ngValue]="op.value">
                                                                    {{op.title}}</option>
                                                            </select>
                                                        </div>
                                                    </ng-template>
                                                </kendo-grid-column>
                                            </ng-container>
                                        </kendo-grid-column-group>
                                        <kendo-grid-column *ngIf="!c4.children" [id]="c4.columnId" [field]="c4.field"
                                            [editable]="c4.editable && editType == 'inline'" [title]="c4.title"
                                            format="{0:n}" [width]="c4.width" [locked]="c4.pinned" [hidden]="c4.hidden"
                                            [class]="{'k-grid-align-left': c4.leftalign, 'k-grid-align-right': c4.rightalign, 'k-grid-align-center': c4.centeralign}">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <div
                                                    [ngClass]="blankCellsList.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) > -1 || (blankCellsList.indexOf(c4.tabCode) > -1 && unBlankCellsList.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) == -1) ?  'blank-cell' : blockedCellsList.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) > -1 || (blockedCellsList.indexOf(c4.tabCode) > -1 && unblockedCellsList.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) == -1) || (forIFForm && ((c4.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) == -1) ? 'blocked-cell' : ''">
                                                </div>
                                                <div class="d-flex align-items-center justify-content-center grdCell"
                                                    *ngIf="blankCellsList.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c4.tabCode) == -1 || unBlankCellsList.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c4.tabCode) == -1 || unblockedCellsList.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) > -1) && !(forIFForm && ((c4.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) == -1)">
                                                    <div class="col-12 p-0 text-truncate d-flex align-items-center justify-content-center"
                                                        (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c4)"
                                                        title="{{!dataItem[c4.field] && (c4.isUncomputed || uncomputedCellsList.indexOf(c4.tabCode.replace('%',dataItem.boxcode)) > -1) && computedCellsList.indexOf(c4.tabCode.replace('%',dataItem.boxcode)) == -1 ? 'Uncomputed' : (dataItem[c4.field] | dataTypeFormat: (cellDataTypeJson[c4.tabCode.replace('%', dataItem.boxcode)] ? cellDataTypeJson[c4.tabCode.replace('%', dataItem.boxcode)]: c4.editor) : (cellDecimalPrecisionJson[c4.tabCode.replace('%',dataItem.boxcode)] ? cellDecimalPrecisionJson[c4.tabCode.replace('%',dataItem.boxcode)] : c4.decimals))}}">
                                                        <div class="col-11 cell-data-padding"
                                                            [ngClass]="{'editable': ((c4.editable && dataItem.canadjust && blankCellsList.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c4.tabCode) == -1 || unBlankCellsList.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c4.tabCode) == -1 || unblockedCellsList.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) > -1) && nonEditableCells.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) == -1) || editableCells.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) > -1), 'text-style': !dataItem[c4.field] && (c4.isUncomputed || uncomputedCellsList.indexOf(c4.tabCode.replace('%',dataItem.boxcode)) > -1)}">
                                                            {{
                                                            !dataItem[c4.field] && (c4.isUncomputed ||
                                                            uncomputedCellsList.indexOf(c4.tabCode.replace('%',dataItem.boxcode))
                                                            > -1) &&
                                                            computedCellsList.indexOf(c4.tabCode.replace('%',dataItem.boxcode))
                                                            == -1 ? 'Uncomputed' :
                                                            (dataItem[c4.field]
                                                            |
                                                            dataTypeFormat:
                                                            (cellDataTypeJson[c4.tabCode.replace('%',
                                                            dataItem.boxcode)]
                                                            ? cellDataTypeJson[c4.tabCode.replace('%',
                                                            dataItem.boxcode)].editor
                                                            : c4.editor)
                                                            :
                                                            (cellDecimalPrecisionJson[c4.tabCode.replace('%',dataItem.boxcode)]
                                                            ?
                                                            cellDecimalPrecisionJson[c4.tabCode.replace('%',dataItem.boxcode)]
                                                            : (cellDataTypeJson[c4.tabCode.replace('%',
                                                            dataItem.boxcode)]
                                                            ? cellDataTypeJson[c4.tabCode.replace('%',
                                                            dataItem.boxcode)].decimals :
                                                            c4.decimals)))
                                                            }}
                                                        </div>
                                                        <div class="col-1 cell-data-padding" title=""
                                                            *ngIf="regArtReferenceCells.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) > -1"
                                                            (click)="redirectToRegArt(c4.tabCode.replace('%', dataItem.boxcode))">
                                                            <i class="fa fa-bars cursor-pointer"></i>
                                                        </div>
                                                        <div class="col-1 cell-data-padding"
                                                            *ngIf="regArtReferenceCells.indexOf(c4.tabCode.replace('%', dataItem.boxcode)) == -1"
                                                            (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c4)">
                                                        </div>
                                                    </div>

                                                </div>
                                            </ng-template>
                                            <ng-template kendoGridEditTemplate let-formGroup
                                                *ngIf="editType == 'inline'">
                                                <div>
                                                    <kendo-numerictextbox
                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'numeric'"
                                                        [(value)]="formGroup.value[c4.field]"
                                                        [decimals]="cellEditor.decimals" [autoCorrect]="true"
                                                        [min]="-10000000000000" [max]="10000000000000"
                                                        [format]="'#.##########'">
                                                    </kendo-numerictextbox>
                                                    <input type="text" class="w-100"
                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'text'"
                                                        [(ngModel)]="formGroup.value[c4.field]" maxlength="50"
                                                        pattern="^[A-Za-z0-9 _\-.]*$" trim-text />
                                                    <input
                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                        [style.display]="'none'" />
                                                    <p-calendar
                                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                        [(ngModel)]="formGroup.value[c4.field]" [showIcon]="true"
                                                        [autoZIndex]="true" dateFormat="dd/mm/yy" [readonlyInput]="true"
                                                        [monthNavigator]="true" yearRange="{{yearRange}}"
                                                        [yearNavigator]="true" appendTo="body">
                                                    </p-calendar>
                                                    <select *ngIf="cellEditor.cellOptions.length > 0"
                                                        class="cellPopOutInput" [(ngModel)]="formGroup.value[c4.field]">
                                                        <option *ngFor="let op of cellEditor.cellOptions"
                                                            [ngValue]="op.value">
                                                            {{op.title}}</option>
                                                    </select>
                                                </div>
                                            </ng-template>
                                        </kendo-grid-column>
                                    </ng-container>
                                </kendo-grid-column-group>
                                <kendo-grid-column *ngIf="!c3.children" [id]="c3.columnId" [field]="c3.field"
                                    [editable]="c3.editable && editType == 'inline'" [title]="c3.title" format="{0:n}"
                                    [width]="c3.width" [locked]="c3.pinned" [hidden]="c3.hidden"
                                    [class]="{'k-grid-align-left': c3.leftalign, 'k-grid-align-right': c3.rightalign, 'k-grid-align-center': c3.centeralign}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <div
                                            [ngClass]="blankCellsList.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) > -1 || (blankCellsList.indexOf(c3.tabCode) > -1 && unBlankCellsList.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) == -1) ?  'blank-cell' : blockedCellsList.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) > -1 || (blockedCellsList.indexOf(c3.tabCode) > -1 && unblockedCellsList.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) == -1) || (forIFForm && ((c3.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) == -1) ? 'blocked-cell' : ''">
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center grdCell"
                                            *ngIf="blankCellsList.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c3.tabCode) == -1 || unBlankCellsList.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) > -1) &&  blockedCellsList.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c3.tabCode) == -1 || unblockedCellsList.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) > -1) && !(forIFForm && ((c3.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) == -1)">
                                            <div class="col-12 p-0 text-truncate d-flex align-items-center justify-content-center"
                                                (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c3)"
                                                title="{{!dataItem[c3.field] && (c3.isUncomputed || uncomputedCellsList.indexOf(c3.tabCode.replace('%',dataItem.boxcode)) > -1) && computedCellsList.indexOf(c3.tabCode.replace('%',dataItem.boxcode)) == -1 ? 'Uncomputed' : (dataItem[c3.field] | dataTypeFormat: (cellDataTypeJson[c3.tabCode.replace('%', dataItem.boxcode)] ? cellDataTypeJson[c3.tabCode.replace('%', dataItem.boxcode)]: c3.editor) : (cellDecimalPrecisionJson[c3.tabCode.replace('%',dataItem.boxcode)] ? cellDecimalPrecisionJson[c3.tabCode.replace('%',dataItem.boxcode)] : c3.decimals))}}">
                                                <div class="col-11 cell-data-padding"
                                                    [ngClass]="{'editable': ((c3.editable && dataItem.canadjust && blankCellsList.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c3.tabCode) == -1 || unBlankCellsList.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c3.tabCode) == -1 || unblockedCellsList.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) > -1) && nonEditableCells.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) == -1) || editableCells.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) > -1), 'text-style': !dataItem[c3.field] && (c3.isUncomputed || uncomputedCellsList.indexOf(c3.tabCode.replace('%',dataItem.boxcode)) > -1)}">
                                                    {{
                                                    !dataItem[c3.field] && (c3.isUncomputed ||
                                                    uncomputedCellsList.indexOf(c3.tabCode.replace('%',dataItem.boxcode))
                                                    > -1) &&
                                                    computedCellsList.indexOf(c3.tabCode.replace('%',dataItem.boxcode))
                                                    == -1 ? 'Uncomputed' :
                                                    (dataItem[c3.field]
                                                    |
                                                    dataTypeFormat:
                                                    (cellDataTypeJson[c3.tabCode.replace('%',
                                                    dataItem.boxcode)]
                                                    ? cellDataTypeJson[c3.tabCode.replace('%', dataItem.boxcode)].editor
                                                    : c3.editor)
                                                    :
                                                    (cellDecimalPrecisionJson[c3.tabCode.replace('%',dataItem.boxcode)]
                                                    ? cellDecimalPrecisionJson[c3.tabCode.replace('%',dataItem.boxcode)]
                                                    : (cellDataTypeJson[c3.tabCode.replace('%', dataItem.boxcode)]
                                                    ? cellDataTypeJson[c3.tabCode.replace('%',
                                                    dataItem.boxcode)].decimals :
                                                    c3.decimals)))
                                                    }}
                                                </div>
                                                <div class="col-1 cell-data-padding" title=""
                                                    *ngIf="regArtReferenceCells.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) > -1"
                                                    (click)="redirectToRegArt(c3.tabCode.replace('%', dataItem.boxcode))">
                                                    <i class="fa fa-bars cursor-pointer"></i>
                                                </div>
                                                <div class="col-1 cell-data-padding"
                                                    *ngIf="regArtReferenceCells.indexOf(c3.tabCode.replace('%', dataItem.boxcode)) == -1"
                                                    (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c3)">
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template kendoGridEditTemplate let-formGroup *ngIf="editType == 'inline'">
                                        <div>
                                            <kendo-numerictextbox
                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'numeric'"
                                                [(value)]="formGroup.value[c3.field]" [decimals]="cellEditor.decimals"
                                                [autoCorrect]="true" [min]="-10000000000000" [max]="10000000000000"
                                                [format]="'#.##########'">
                                            </kendo-numerictextbox>
                                            <input type="text" class="w-100"
                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'text'"
                                                [(ngModel)]="formGroup.value[c3.field]" maxlength="50"
                                                pattern="^[A-Za-z0-9 _\-.]*$" trim-text />
                                            <input
                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                [style.display]="'none'" />
                                            <p-calendar
                                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                                [(ngModel)]="formGroup.value[c3.field]" [showIcon]="true"
                                                [autoZIndex]="true" dateFormat="dd/mm/yy" [readonlyInput]="true"
                                                [monthNavigator]="true" yearRange="{{yearRange}}" [yearNavigator]="true"
                                                appendTo="body">
                                            </p-calendar>
                                            <select *ngIf="cellEditor.cellOptions.length > 0" class="cellPopOutInput"
                                                [(ngModel)]="formGroup.value[c3.field]">
                                                <option *ngFor="let op of cellEditor.cellOptions" [ngValue]="op.value">
                                                    {{op.title}}</option>
                                            </select>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                            </ng-container>
                        </kendo-grid-column-group>
                        <kendo-grid-column *ngIf="!c2.children" [id]="c2.columnId" [field]="c2.field"
                            [editable]="c2.editable && editType == 'inline'" [title]="c2.title" format="{0:n}"
                            [width]="c2.width" [locked]="c2.pinned" [hidden]="c2.hidden"
                            [class]="{'k-grid-align-left': c2.leftalign, 'k-grid-align-right': c2.rightalign, 'k-grid-align-center': c2.centeralign}">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div
                                    [ngClass]="blankCellsList.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) > -1 || (blankCellsList.indexOf(c2.tabCode) > -1 && unBlankCellsList.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) == -1) ?  'blank-cell' : blockedCellsList.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) > -1 || (blockedCellsList.indexOf(c2.tabCode) > -1 && unblockedCellsList.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) == -1) || (forIFForm && ((c2.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) == -1) ? 'blocked-cell' : ''">
                                </div>
                                <div class="d-flex align-items-center justify-content-center grdCell"
                                    *ngIf="blankCellsList.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c2.tabCode) == -1 || unBlankCellsList.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c2.tabCode) == -1 || unblockedCellsList.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) > -1) && !(forIFForm && ((c2.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) == -1)">
                                    <div class="col-12 p-0 text-truncate d-flex align-items-center justify-content-center"
                                        (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c2)"
                                        title="{{!dataItem[c2.field] && (c2.isUncomputed || uncomputedCellsList.indexOf(c2.tabCode.replace('%',dataItem.boxcode)) > -1) && computedCellsList.indexOf(c2.tabCode.replace('%',dataItem.boxcode)) == -1 ? 'Uncomputed' : (dataItem[c2.field] | dataTypeFormat: (cellDataTypeJson[c2.tabCode.replace('%', dataItem.boxcode)] ? cellDataTypeJson[c2.tabCode.replace('%', dataItem.boxcode)]: c2.editor) : (cellDecimalPrecisionJson[c2.tabCode.replace('%',dataItem.boxcode)] ? cellDecimalPrecisionJson[c2.tabCode.replace('%',dataItem.boxcode)] : c2.decimals))}}">
                                        <div class="col-11 cell-data-padding"
                                            [ngClass]="{'editable': ((c2.editable && dataItem.canadjust && blankCellsList.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c2.tabCode) == -1 || unBlankCellsList.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c2.tabCode) == -1 || unblockedCellsList.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) > -1) && nonEditableCells.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) == -1) || editableCells.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) > -1), 'text-style': !dataItem[c2.field] && (c2.isUncomputed || uncomputedCellsList.indexOf(c2.tabCode.replace('%',dataItem.boxcode)) > -1)}">
                                            {{
                                            !dataItem[c2.field] && (c2.isUncomputed ||
                                            uncomputedCellsList.indexOf(c2.tabCode.replace('%',dataItem.boxcode)) > -1)
                                            && computedCellsList.indexOf(c2.tabCode.replace('%',dataItem.boxcode)) == -1
                                            ? 'Uncomputed' :
                                            (dataItem[c2.field]
                                            |
                                            dataTypeFormat:
                                            (cellDataTypeJson[c2.tabCode.replace('%',
                                            dataItem.boxcode)]
                                            ? cellDataTypeJson[c2.tabCode.replace('%', dataItem.boxcode)].editor
                                            : c2.editor)
                                            : (cellDecimalPrecisionJson[c2.tabCode.replace('%',dataItem.boxcode)]
                                            ? cellDecimalPrecisionJson[c2.tabCode.replace('%',dataItem.boxcode)]
                                            : (cellDataTypeJson[c2.tabCode.replace('%', dataItem.boxcode)]
                                            ? cellDataTypeJson[c2.tabCode.replace('%', dataItem.boxcode)].decimals :
                                            c2.decimals)))
                                            }}
                                        </div>
                                        <div class="col-1 cell-data-padding" title=""
                                            *ngIf="regArtReferenceCells.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) > -1"
                                            (click)="redirectToRegArt(c2.tabCode.replace('%', dataItem.boxcode))">
                                            <i class="fa fa-bars cursor-pointer"></i>
                                        </div>
                                        <div class="col-1 cell-data-padding"
                                            *ngIf="regArtReferenceCells.indexOf(c2.tabCode.replace('%', dataItem.boxcode)) == -1"
                                            (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c2)">
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template kendoGridEditTemplate let-formGroup *ngIf="editType == 'inline'">
                                <div>
                                    <kendo-numerictextbox
                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'numeric'"
                                        [(value)]="formGroup.value[c2.field]" [decimals]="cellEditor.decimals"
                                        [autoCorrect]="true" [min]="-10000000000000" [max]="10000000000000"
                                        [format]="'#.##########'">
                                    </kendo-numerictextbox>
                                    <input type="text" class="w-100"
                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'text'"
                                        [(ngModel)]="formGroup.value[c2.field]" maxlength="50"
                                        pattern="^[A-Za-z0-9 _\-.]*$" trim-text />
                                    <input *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                        [style.display]="'none'" />
                                    <p-calendar
                                        *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                        [(ngModel)]="formGroup.value[c2.field]" [showIcon]="true" [autoZIndex]="true"
                                        dateFormat="dd/mm/yy" [readonlyInput]="true" [monthNavigator]="true"
                                        yearRange="{{yearRange}}" [yearNavigator]="true" appendTo="body">
                                    </p-calendar>
                                    <select *ngIf="cellEditor.cellOptions.length > 0" class="cellPopOutInput"
                                        [(ngModel)]="formGroup.value[c2.field]">
                                        <option *ngFor="let op of cellEditor.cellOptions" [ngValue]="op.value">
                                            {{op.title}}</option>
                                    </select>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                    </ng-container>
                </kendo-grid-column-group>
                <kendo-grid-column *ngIf="!c1.children" [id]="c1.columnId" [field]="c1.field"
                    [editable]="c1.editable && editType == 'inline'" [title]="c1.title" format="{0:n}"
                    [width]="c1.width" [locked]="c1.pinned" [hidden]="c1.hidden"
                    [class]="{'k-grid-align-left': c1.leftalign, 'k-grid-align-right': c1.rightalign, 'k-grid-align-center': c1.centeralign}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div
                            [ngClass]="blankCellsList.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) > -1 || (blankCellsList.indexOf(c1.tabCode) > -1 && unBlankCellsList.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) == -1) ?  'blank-cell' : blockedCellsList.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) > -1 || (blockedCellsList.indexOf(c1.tabCode) > -1 && unblockedCellsList.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) == -1) || (forIFForm && ((c1.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) == -1) ? 'blocked-cell' : ''">
                        </div>
                        <div class="d-flex align-items-center justify-content-center grdCell"
                            *ngIf="blankCellsList.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c1.tabCode) == -1 || unBlankCellsList.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c1.tabCode) == -1 || unblockedCellsList.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) > -1) && !(forIFForm && ((c1.editable && !dataItem.canadjust) || nonEditableCells.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) > -1) && editableCells.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) == -1)">
                            <div class="col-12 p-0 text-truncate d-flex align-items-center justify-content-center"
                                (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c1)"
                                title="{{!dataItem[c1.field] && (c1.isUncomputed || uncomputedCellsList.indexOf(c1.tabCode.replace('%',dataItem.boxcode)) > -1) && computedCellsList.indexOf(c1.tabCode.replace('%',dataItem.boxcode)) == -1 ? 'Uncomputed' : (dataItem[c1.field] | dataTypeFormat: (cellDataTypeJson[c1.tabCode.replace('%', dataItem.boxcode)] ? cellDataTypeJson[c1.tabCode.replace('%', dataItem.boxcode)]: c1.editor) : (cellDecimalPrecisionJson[c1.tabCode.replace('%',dataItem.boxcode)] ? cellDecimalPrecisionJson[c1.tabCode.replace('%',dataItem.boxcode)] : c1.decimals))}}">
                                <div class="col-11 cell-data-padding"
                                    [ngClass]="{'editable': ((c1.editable && dataItem.canadjust && blankCellsList.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) == -1 && (blankCellsList.indexOf(c1.tabCode) == -1 || unBlankCellsList.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) > -1) && blockedCellsList.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) == -1 && (blockedCellsList.indexOf(c1.tabCode) == -1 || unblockedCellsList.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) > -1) && nonEditableCells.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) == -1) || editableCells.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) > -1), 'text-style': !dataItem[c1.field] && (c1.isUncomputed || uncomputedCellsList.indexOf(c1.tabCode.replace('%',dataItem.boxcode)) > -1)}">
                                    {{
                                    !dataItem[c1.field] && (c1.isUncomputed ||
                                    uncomputedCellsList.indexOf(c1.tabCode.replace('%',dataItem.boxcode)) > -1) &&
                                    computedCellsList.indexOf(c1.tabCode.replace('%',dataItem.boxcode)) == -1 ?
                                    'Uncomputed' :
                                    (dataItem[c1.field]
                                    |
                                    dataTypeFormat:
                                    (cellDataTypeJson[c1.tabCode.replace('%',
                                    dataItem.boxcode)]
                                    ? cellDataTypeJson[c1.tabCode.replace('%', dataItem.boxcode)].editor
                                    : c1.editor)
                                    : (cellDecimalPrecisionJson[c1.tabCode.replace('%',dataItem.boxcode)]
                                    ? cellDecimalPrecisionJson[c1.tabCode.replace('%',dataItem.boxcode)]
                                    : (cellDataTypeJson[c1.tabCode.replace('%', dataItem.boxcode)]
                                    ? cellDataTypeJson[c1.tabCode.replace('%', dataItem.boxcode)].decimals :
                                    c1.decimals)))
                                    }}
                                </div>
                                <div class="col-1 cell-data-padding" title=""
                                    *ngIf="regArtReferenceCells.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) > -1"
                                    (click)="redirectToRegArt(c1.tabCode.replace('%', dataItem.boxcode))">
                                    <i class="fa fa-bars cursor-pointer"></i>
                                </div>
                                <div class="col-1 cell-data-padding"
                                    *ngIf="regArtReferenceCells.indexOf(c1.tabCode.replace('%', dataItem.boxcode)) == -1"
                                    (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c1)">
                                </div>
                            </div>

                        </div>
                    </ng-template>
                    <ng-template kendoGridEditTemplate let-formGroup *ngIf="editType == 'inline'">
                        <div>
                            <kendo-numerictextbox
                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'numeric'"
                                [(value)]="formGroup.value[c1.field]" [decimals]="cellEditor.decimals"
                                [autoCorrect]="true" [min]="-10000000000000" [max]="10000000000000"
                                [format]="'#.##########'">
                            </kendo-numerictextbox>
                            <input type="text" class="w-100"
                                *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'text'"
                                [(ngModel)]="formGroup.value[c1.field]" maxlength="50" pattern="^[A-Za-z0-9 _\-.]*$" trim-text />
                            <input *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                [style.display]="'none'" />
                            <p-calendar *ngIf="cellEditor.cellOptions.length == 0 && cellEditor.editor == 'date'"
                                [(ngModel)]="formGroup.value[c1.field]" [showIcon]="true" [autoZIndex]="true"
                                dateFormat="dd/mm/yy" [readonlyInput]="true" [monthNavigator]="true"
                                yearRange="{{yearRange}}" [yearNavigator]="true" appendTo="body">
                            </p-calendar>
                            <select *ngIf="cellEditor.cellOptions.length > 0" class="cellPopOutInput"
                                [(ngModel)]="formGroup.value[c1.field]">
                                <option *ngFor="let op of cellEditor.cellOptions" [ngValue]="op.value">
                                    {{op.title}}</option>
                            </select>
                        </div>
                    </ng-template>
                </kendo-grid-column>
            </ng-container>
            <kendo-grid-command-column title="Actions" *ngIf="hasDynamicRows"
                [width]="columns.length > 25 ? '80' : '10'">
                <ng-template kendoGridCellTemplate>
                    <button class="icon-button hvr-shrink icon-trash" kendoGridRemoveCommand><i
                            class="fas fa-trash-alt fa-sm my-1"></i></button>
                </ng-template>
            </kendo-grid-command-column>
            <kendo-grid-excel [fileName]="excelReportFileName" [fetchData]="allGridData"></kendo-grid-excel>
        </kendo-grid>
    </div>
</div>


<ng-template #cellPopOutModal let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">Update<i class="fas fa-times ml-auto cursor-pointer"
                aria-label="Close" (click)="cellPopOutClose()"></i></h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-8">
                <form class="material-styling w-100 mt-3" #cellPopOutForm="ngForm">
                    <div *ngIf="cellPopOutModel != null">
                        <div class="row">
                            <div class="col-3">
                                <div class="group">
                                    <input type="text" value="{{cellPopOutModel.rowCode}}" required disabled />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label class="non-input-label">Item Code</label>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="group">
                                    <input type="text" value="{{cellPopOutModel.boxCode}}" required disabled />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label class="non-input-label">Box Code</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="group">
                                    <input type="text" value="{{cellPopOutModel.headTitle}}" class="text-truncate"
                                        title="{{cellPopOutModel.headTitle}}" required disabled />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label class="non-input-label"> Item Description</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="group">
                                    <input type="text" value="{{cellPopOutModel.columnTitle}}" class="text-truncate"
                                        title="{{cellPopOutModel.columnTitle}}" required disabled />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label class="non-input-label">Column</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="group">
                                    <input type="text"
                                        value="{{cellPopOutModel.oldValue | dataTypeFormat : cellPopOutModel.editor : cellPopOutModel.decimals}}"
                                        required disabled />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label class="non-input-label">Cell Value</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="color-teal font-14 mb-2">
                                    New Value<sup><i class="fas fa-asterisk text-danger fa-xs ml-1"></i></sup>
                                </div>
                                <div>
                                    <kendo-numerictextbox
                                        *ngIf="cellPopOutModel.cellOptions.length == 0 && cellPopOutModel.editor == 'numeric'"
                                        required id="cellPopOutNewValue" name="cellPopOutNewValue"
                                        [(ngModel)]="cellPopOutModel.newValue" [decimals]="cellPopOutModel.decimals"
                                        [autoCorrect]="true" [min]="-1000000000000000" [max]="1000000000000000"
                                        [format]="'#.##########'" class="w-100">
                                    </kendo-numerictextbox>
                                    <input type="text"
                                        *ngIf="cellPopOutModel.cellOptions.length == 0 && cellPopOutModel.editor == 'text'"
                                        id="cellPopOutNewValue" name="cellPopOutNewValue" required
                                        [(ngModel)]="cellPopOutModel.newValue" maxlength="50"
                                        pattern="^[A-Za-z0-9 _\-.]*$" trim-text class="w-100 cellPopOutInput" />
                                    <input
                                        *ngIf="cellPopOutModel.cellOptions.length == 0 && cellPopOutModel.editor == 'date'"
                                        [style.display]="'none'" />
                                    <p-calendar
                                        *ngIf="cellPopOutModel.cellOptions.length == 0 && cellPopOutModel.editor == 'date'"
                                        id="cellPopOutNewValue" name="cellPopOutNewValue" required
                                        [(ngModel)]="cellPopOutModel.newValue" [showIcon]="true" [autoZIndex]="true"
                                        dateFormat="dd/mm/yy" [readonlyInput]="true" [monthNavigator]="true"
                                        yearRange="{{yearRange}}" [yearNavigator]="true" appendTo="body">
                                    </p-calendar>
                                    <select *ngIf="cellPopOutModel.cellOptions.length > 0" class="w-100 cellPopOutInput"
                                        required [(ngModel)]="cellPopOutModel.newValue" id="cellPopOutNewValue"
                                        name="cellPopOutNewValue">
                                        <option *ngFor="let op of cellPopOutModel.cellOptions" [ngValue]="op.value">
                                            {{op.title}}</option>
                                    </select>
                                    <div *ngIf="cellPopOutModel.oldValue == cellPopOutModel.newValue"
                                        class="alert alert-danger">
                                        New value cannot be same as old value. Please enter a different value
                                    </div>
                                    <div *ngIf="!cellPopOutForm.valid && cellPopOutForm.controls.cellPopOutNewValue.errors"
                                        class="alert alert-danger">
                                        <div *ngIf="cellPopOutForm.controls.cellPopOutNewValue.errors.required">
                                            Please enter a value to save changes.
                                        </div>
                                        <div
                                            *ngIf="cellPopOutForm.controls.cellPopOutNewValue.errors.maxError || cellPopOutForm.controls.cellPopOutNewValue.errors.minError">
                                            The value should be between -1,000,000,000,000,000 and
                                            1,000,000,000,000,000.
                                        </div>
                                        <div *ngIf="cellPopOutForm.controls.cellPopOutNewValue.errors.pattern">
                                            The value contains invalid characters.
                                        </div>
                                        <div *ngIf="cellPopOutForm.controls.cellPopOutNewValue.errors.maxlength">
                                            The value cannot be greater than 50 characters.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="color-teal font-14 mb-2">
                                    Comment<sup><i class="fas fa-asterisk text-danger fa-xs ml-1"></i></sup>
                                </div>
                                <div>
                                    <textarea id="cellPopOutComment" name="cellPopOutComment" required maxlength="500"
                                        #cellPopOutComment="ngModel" rows="4" [(ngModel)]="cellPopOutModel.comment"
                                        pattern="^[A-Za-z0-9 _\-.]*$" trim-text placeholder="Enter Comments"
                                        class="w-100 cellPopOutInput"></textarea>
                                    <div *ngIf="!cellPopOutForm.valid && cellPopOutForm.controls.cellPopOutComment.errors"
                                        class="alert alert-danger">
                                        <div
                                            *ngIf="cellPopOutForm.controls.cellPopOutComment.errors.required && cellPopOutForm.controls.cellPopOutComment.touched">
                                            Please enter a comment to save changes.
                                        </div>
                                        <div *ngIf="cellPopOutForm.controls.cellPopOutComment.errors.pattern">
                                            The comment contains invalid characters.
                                        </div>
                                        <div *ngIf="cellPopOutForm.controls.cellPopOutComment.errors.maxlength">
                                            The comment cannot be greater than 500 characters.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-4 d-flex align-items-center justify-content-center text-center border-left-yellow"
                *ngIf="cellPopOutModel.latestAudit !=null">
                <div class="p-5">
                    <p>This cell was last modified by <span
                            class="text-yellow">{{cellPopOutModel.latestAudit.changedby}}</span> on <span
                            class="text-yellow">{{cellPopOutModel.latestAudit.changeddate | date: 'dd-MM-yyyy hh:mm:ss'}}</span>
                        and the value was changed
                        from <span class="text-yellow">{{cellPopOutModel.latestAudit.oldvalue | dataTypeFormat :
                            cellPopOutModel.editor : cellPopOutModel.decimals}}</span>
                        to
                        <span class="text-yellow">{{cellPopOutModel.latestAudit.newvalue | dataTypeFormat :
                            cellPopOutModel.editor : cellPopOutModel.decimals}}</span>
                    </p>
                    <span class="cursor-pointer change-log" (click)="viewChangeLog()">View Change Log</span>
                </div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="cellAudit && cellAudit.length > 0">
            <div class="col-12">
                <div class="">
                    <p-table #changeLog [paginator]="true" [rows]="12" class="table-irr change-log-table"
                        [value]="cellAudit" [columns]="auditTableCols" [scrollable]="true"
                        [scrollHeight]="'calc(100vh - 292px)'">
                        <ng-template pTemplate="paginatorleft">
                            <button class="btn btn-clear-filter hvr-shrink" type="button"
                                (click)="changeLog.reset();clearFilters();">
                                <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field"> {{col.header}}
                                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                        ariaLabelDesc="Activate to sort in descending order"
                                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                                </th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns">
                                    <p-calendar *ngIf="col.field =='ChangedDate'" [(ngModel)]="modifiedDateFilters"
                                        #clFilter selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy"
                                        [readonlyInput]="true" placeholder="Select a range" appendTo="body"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="{{yearRange}}"
                                        (ngModelChange)="changeLog.filter(modifiedDateFilters, col.field, 'dateRangeFilter')"
                                        (onSelect)="closeDateRangeFilter(clFilter, modifiedDateFilters)"></p-calendar>

                                    <input *ngIf="col.field !='ChangedDate'" type="text" class="w-100"
                                        placeholder="Filter Text"
                                        [value]="changeLog.filters && changeLog.filters[col.field] ? changeLog.filters[col.field].value: ''"
                                        (input)="changeLog.filter($event.target.value, col.field, 'contains')">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr [ngClass]="{'font-italic': rowData.isSaved == false }">
                                <td>{{rowData['oldValue'] | dataTypeFormat : cellPopOutModel.editor :
                                    cellPopOutModel.decimals}}</td>
                                <td>{{rowData['newValue'] | dataTypeFormat : cellPopOutModel.editor :
                                    cellPopOutModel.decimals}}</td>
                                <td>{{rowData['comment']}}</td>
                                <td>{{rowData['changedBy']}}</td>
                                <td>{{rowData['changedDate'] | date: 'dd-MM-yyyy hh:mm:ss'}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row w-100 m-0">
            <div class="col-6 pl-0">
                <h6 class="mb-0 font-13">
                    <sup><i class="fas fa-asterisk text-danger fa-xs mr-1"></i></sup>
                    Required fields
                </h6>
                <h6 class="mb-0 font-13" *ngIf="cellAudit && cellAudit.length > 0">
                    <span class="text-info">Note : </span>
                    Unsaved audit rows will be shown in italics.
                </h6>
            </div>
            <div class="col-6 text-right pr-0">
                <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button" (click)='clearEditPopUp()'>
                    <span>Clear</span>
                </button>
                <button class="btn btn-modal-pos small-font hvr-shrink" type="button"
                    [disabled]="(cellPopOutForm && !cellPopOutForm.form.valid) || !isValidForm()"
                    (click)="submitCellEdit()">
                    <span>Submit</span>
                </button>
            </div>
        </div>
    </div>
</ng-template>