<div class="row">
    <div class="col-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
                                    [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'" class="last-cap">
        </app-ng7-dynamic-breadcrumb>
    </div>
    <div class="col-6 text-right">

    </div>
</div>
<div class="row pnp-steps-container d-flex animated fadeIn fast">
    <div class="col-12">
        <ngb-tabset type="pills" class="pnp-steps" #pnpTab (tabChange)="beforeChange($event)">
            <ngb-tab id="step1">
                <ng-template ngbTabTitle>
                    <div class="w-100 d-flex align-items-center justify-content-center flex-column hvr-shrink tab-title-click">
                        <div>1. Report Selection</div>
                    </div>
                </ng-template>
                <ng-template ngbTabContent>
                    <app-pnp-report-selection [(suiteId)]="pnpSuiteId" [(reportId)]="pnpReportId"
                                              (nextTab)="goToStep2()">
                    </app-pnp-report-selection>
                </ng-template>
            </ngb-tab>
            <ngb-tab id="step2">
                <ng-template ngbTabTitle>
                    <div class="w-100 d-flex align-items-center justify-content-center flex-column hvr-shrink tab-title-click"
                         (click)="goToStep2()">
                        <div>2. Report Pack Selection</div>
                    </div>
                </ng-template>
                <ng-template ngbTabContent>
                    <app-pnp-report-pack-selection [(period1Id)]="pnpPeriod1Id" [(period2Id)]="pnpPeriod2Id"
                                                   (previousTab)="goToStep1()" (nextTab)="goToStep3()">
                    </app-pnp-report-pack-selection>
                </ng-template>
            </ngb-tab>
            <ngb-tab id="step3">
                <ng-template ngbTabTitle>
                    <div class="w-100 d-flex align-items-center justify-content-center flex-column hvr-shrink tab-title-click"
                         (click)="goToStep3()">
                        <div>3. Row and Column Filtering</div>
                    </div>
                </ng-template>
                <ng-template ngbTabContent>
                    <app-pnp-report-filter [reportId]="pnpReportId" [(selectedRows)]="selectedRows" [period1Id]="pnpPeriod1Id" [period2Id]="pnpPeriod2Id"
                                           [(selectedColumns)]="selectedColumns" [(selectedPage)]="selectedPage"
                                           (previousTab)="goToStep2()" (nextTab)="goToStep4()">
                    </app-pnp-report-filter>
                </ng-template>
            </ngb-tab>
            <ngb-tab id="step4">
                <ng-template ngbTabTitle>
                    <div class="w-100 d-flex align-items-center justify-content-center flex-column hvr-shrink tab-title-click"
                         (click)="goToStep4()">
                        <div>4. Visualization</div>
                    </div>
                </ng-template>
                <ng-template ngbTabContent>
                    <app-pnp-report [reportId]="pnpReportId" [period1Id]="pnpPeriod1Id" [period2Id]="pnpPeriod2Id"
                                    [rowFilter]="selectedRows" [columnFilter]="selectedColumns" (previousTab)="goToStep3()">
                    </app-pnp-report>
                </ng-template>
            </ngb-tab>
        </ngb-tabset>

    </div>
</div>