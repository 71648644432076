<app-loader #rs *ngIf="loading"></app-loader>
<div class="step1-pnp animated fadeIn fast">
    <div class="row mt-3">
        <div class="col-6">
            <div class="card card-drag-drop">
                <div class="card-header text-center">
                    <span class="badge-header-name">Suite</span><button *ngIf="selectedSuite" type="button" class="btn-clear-drop" container="body"
                                                                        (click)='suiteUnselect()' ngbTooltip="Clear Suite">
                        <i class="far fa-times-circle fa-lg"></i>
                    </button>
                </div>
                <div class="card-body d-flex align-items-center justify-content-center text-center">
                    <i *ngIf="!selectedSuite" class="text-secondary">
                        Drag a suite from the below table and drop
                        it here
                    </i>
                    <div *ngIf="selectedSuite" class="mt-3">
                        <div class="mb-2">Suite Name - {{selectedSuite.reportSuiteAbbr}}</div>
                        <div>Suite Description - {{selectedSuite.subHeader}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card card-drag-drop">
                <div class="card-header text-center">
                    <span class="badge-header-name">Report</span><button *ngIf="selectedReport" type="button" class="btn-clear-drop" container="body"
                                                                         (click)='reportUnselect()' ngbTooltip="Clear Report">
                        <i class="far fa-times-circle fa-lg"></i>
                    </button>
                </div>
                <div class="card-body d-flex align-items-center justify-content-center text-center">
                    <i *ngIf="!selectedReport" class="text-secondary">
                        Drag a report from the below table and drop
                        it here
                    </i>
                    <div *ngIf="selectedReport" class="mt-3">
                        <div class="mb-2">Report Name - {{selectedReport.ReportDisplayName}}</div>
                        <div>Report Description - {{selectedReport.reportName}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-6">
            <p-table #suiteSel [value]="suiteSelectList" [paginator]="true" [rows]="8" class="table-irr suite-sel-table"
                     [columns]="suiteCols" [scrollable]="true" scrollHeight="350px">
                <ng-template pTemplate="paginatorleft">
                    <button class="btn btn-clear-filter hvr-shrink" type="button"
                            (click)="suiteSel.reset()">
                        <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                    </button>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                        ariaLabelDesc="Activate to sort in descending order"
                                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns">
                            <input type="text" class="w-100" placeholder="Filter Text"
                                   [value]="suiteSel.filters && suiteSel.filters[col.field] ? suiteSel.filters[col.field].value: ''"
                                   (input)="suiteSel.filter($event.target.value, col.field, 'contains')">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr class="list-group-item">
                        <td>{{rowData.reportSuiteAbbr}}</td>
                        <td>{{rowData.subHeader}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="col-6" *ngIf="!selectedSuite">
            <div class="w-100 h-100 d-flex flex-column align-items-center justify-content-center drag-img">
                <img src="assets/images/drag-drop-notification.svg">
                <h5 class="mt-5 text-info"><i>Please drag and drop a suite to enable report selection</i></h5>
            </div>
        </div>
        <div class="col-6" *ngIf="selectedSuite">
            <p-table #reportSel [value]="reportSelectList" [paginator]="true" [rows]="8"
                     class="table-irr report-sel-table" [columns]="reportCols" [scrollable]="true" scrollHeight="350px" sortField="ReportDisplayName" sortOrder="1">
                <ng-template pTemplate="paginatorleft">
                    <button class="btn btn-clear-filter hvr-shrink" type="button"
                            (click)="reportSel.reset()">
                        <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                    </button>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                        ariaLabelDesc="Activate to sort in descending order"
                                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns">
                            <input type="text" class="w-100" placeholder="Filter Text"
                                   [value]="reportSel.filters && reportSel.filters[col.field] ? reportSel.filters[col.field].value: ''"
                                   (input)="reportSel.filter($event.target.value, col.field, 'contains')">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr class="list-group-item">
                        <td>{{rowData.ReportDisplayName}}</td>
                        <td>{{rowData.reportName}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-6">
        </div>
        <div class="col-6 text-right">
            <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button" (click)="goToNextTab()">
                <div class="icon-container"><i class="fas fa-arrow-right fa-lg"></i></div><span>Next</span>
            </button>
        </div>
    </div>
</div>