<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
    <div class="col-12 col-lg-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
    </div>
    <div class="col-12 col-lg-6 text-left text-lg-right">
        <button class="btn btn-primary-ey btn-icon small-font mr-2 hvr-shrink" type="button" (click)="refreshList()">
            <div class="icon-container"><i class="fas fa-sync-alt"></i></div><span>Refresh</span>
        </button>
        <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button" (click)="openBundlingForm()">
            <div class="icon-container"><i class="fas fa-play"></i></div><span>Start New Bundling</span>
        </button>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="data-ing-scroll">
            <p-table #dting [value]="bundleList" [paginator]="true" [rows]="9" class="table-irr" [columns]="headerCols"
                [scrollable]="true" [scrollHeight]="'calc(100vh - 292px'">
                <ng-template pTemplate="paginatorleft">
                    <button class="btn btn-clear-filter hvr-shrink" type="button"
                        (click)="dting.reset();clearFilters();">
                        <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                    </button>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon *ngIf="col.field !='status' && col.field != 'generatedFileName'"
                                [field]="col.field" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                        </th>
                        <th>Actions</th>
                        <th style="width: 70px;"></th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns">
                            <input
                                *ngIf="col.field !='status' && col.field != 'generatedFileName' && col.field != 'createdDate'"
                                type="text" class="w-100" placeholder="Filter Text"
                                [value]="dting.filters && dting.filters[col.field] ? dting.filters[col.field].value: ''"
                                (input)="dting.filter($event.target.value, col.field, 'contains')">

                            <p-calendar *ngIf="col.field =='createdDate'" [(ngModel)]="createdDateFilters" #rdFilter
                                selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy" [readonlyInput]="true"
                                placeholder="Select a range" appendTo="body" [monthNavigator]="true"
                                [yearNavigator]="true" yearRange="{{yearRange}}"
                                (ngModelChange)="dting.filter(createdDateFilters, col.field, 'dateRangeFilter')"
                                (onSelect)="closeDateRangeFilter(rdFilter, createdDateFilters)">
                            </p-calendar>
                        </th>
                        <th></th>
                        <th style="width: 70px;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.bundleName}}</td>
                        <td>{{rowData.bundleTypeName}}</td>
                        <td>{{rowData.reportPackId}}</td>
                        <td>{{rowData.reportPackName}}</td>
                      
                        <!-- <td *ngIf="!rowData.generatedFileName"> </td> -->
                        <td>{{rowData.createdBy}}</td>
                        <td>{{rowData.createdDate | date: 'dd/MM/yyyy'}}</td>
                        <td>
                            <div *ngIf="rowData.actions && rowData.actions.length > 0"
                                class="status-progress d-flex align-items-center position-relative" style="float:left">
                                <div *ngFor="let action of rowData.actions"
                                    class="d-flex align-items-center justify-content-center flex-column mr-3 status-progress-container "
                                    [ngClass]="{'completed': action.status === 'Completed',
                                            'failed':action.status === 'Failed',
                                            'in-progress':action.status === 'In Progress',
                                            'not-started': action.status === 'Not Started'}" container="body"
                                    ngbTooltip="{{action.actionName}}">
                                    <div class="circle-status d-flex align-items-center justify-content-center">
                                        <i *ngIf="action.actionName == 'Report Bundling'"
                                            class="fas fa-clipboard-check fa-lg"></i>
                                        <i *ngIf="action.actionName == 'Email'"
                                            class="fas fa-external-link-alt fa-lg"></i>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><button class="btn-table-icon hvr-shrink" type="button" (click)="viewReportBundle(rowData)"
                                ngbTooltip="View" container="body" tooltipClass="reporting-tooltip">
                                <i class="fas fa-eye"></i>
                            </button>
                            <button *ngIf="rowData.generatedFileName" class="btn-table-icon hvr-shrink" type="button"  (click)="openModal(promptContent, rowData)" 
                            
                                ngbTooltip="Download Generated File" container="body" tooltipClass="reporting-tooltip">
                                <i class="fas fa-download"></i>
                            </button>
                        </td>
                        <td style="width: 70px;"><i
                                class="fas fa-exclamation-triangle hvr-shrink failed-icon ml-3 fa-lg" placement="left"
                                *ngIf='rowData.actions[0].status=="Failed"'
                                ngbTooltip="{{rowData.actions[0].logMessage}}" container="body"></i></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<ng-template #promptContent>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">
            Download Generated Report<i class="fas fa-times ml-auto cursor-pointer" aria-label="Close" (click)="closeModal();"></i>
        </h5>
    </div>
    <div class="modal-body description-content text-center">
        <div>
            <p *ngIf="isPartialUpload">The selected report pack has underwent partial upload / file edit, Do you want to reprepare data or download existing generated file?</p>
            <p *ngIf="!isPartialUpload">File is ready to Download!</p>
        </div>
    </div>
    <div class="modal-footer">
        <div class="float-right">
            <button *ngIf="isPartialUpload" class="btn btn-modal-pos small-font hvr-shrink" (click)="rePrepareData()" type="button">
                <span>Re-Prepare Data</span>
            </button>
            <button class="btn btn-modal-pos small-font hvr-shrink" (click)="downloadGeneratedFile(selectedRow.generatedFileName)" type="button">
                <span>Download Generated File</span>
            </button>
        </div>
    </div>
</ng-template>