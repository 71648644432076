import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class QueryEngineService {

  constructor(private apiService: ApiService) { }

  getQueryList() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('queryEngine/getQueryList/', null)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getQueryResult(model: any) {
    const params = {
      queryId: model.queryId,
      reportPackId: model.reportPackId,
      pageNumber: model.pageNumber,
      pageSize: model.pageSize,
      isInitialLoad: model.isInitialLoad,
      sortList: model.sortList,
      filter: model.filter
    };
    const promise = new Promise((resolve, reject) => {
      this.apiService.post('queryEngine/getQueryResult/', params, '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  downloadQueryResult(model: any) {
    const params = {
      queryId: model.queryId,
      queryDisplayName: model.queryDisplayName.replace(/[^a-zA-Z0-9]/g, ''),
      reportPackId: model.reportPackId,
      reportPackTitle: model.reportPackTitle.replace(/[^a-zA-Z0-9]/g, '')
      //sortList: model.sortList,
      //filter: model.filter
    };
    const promise = new Promise((resolve, reject) => {
      this.apiService.getFile('queryEngine/downloadQueryResult/', params, 'application/json')
        .toPromise()
        .then(
          res => { // Success
            this.downloadFile(res)
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  downloadFile(data: any) {
    let blob = data.body;
    const contentHeader = data.headers.get('content-disposition');
    if (contentHeader !== null && blob !== null) {
      let fileName = data.headers.get('content-disposition').split(';')[1].trim().split('=')[1].replace(/"/g, '');
      saveAs(blob, fileName);
    }
  }

}
