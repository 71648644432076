import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { Router } from '@angular/router';
import { DataIngestionService } from 'src/app/core/services/dataIngestion.service';
import { Subscription } from 'rxjs';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common'
@Component({
    selector: 'app-ingestion-file-edit',
    templateUrl: './ingestion-file-edit.component.html',
    styleUrls: ['./ingestion-file-edit.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class IngestionFileEditComponent implements OnInit, OnDestroy {
    public loading = false;
    ingestionLoadData: any;
    fileData: any;
    gridColumns: any;
    gridData: any;
    draftCells: any;
    errorCells: any;
    localDraftCells: any = [];
    columnList: any;
    editType = 'cellPopOut'
    dataDictionary: any;
    latestAudit: any = [];
    formChanges: any = [];
    formAudit: any = [];
    cellPopOutModel: any = null;
    cellAudit: any;
    modifiedDateFilters: Date[];
    runReports: boolean = true;
    user: any;
    pageSize: number = 100;
    skip: number = 0;
    private userSubscription: Subscription;
    fileEditModel: any = {};
    sortPopUpModel: any = [];
    sortColumnList: any = [];
    sortOrderOptions: any = [{ sortOrder: 'ASC', sortOrderText: 'A to Z' }, { sortOrder: 'DESC', sortOrderText: 'Z to A' }];
    showOnlyErrorRows: boolean = false;
    filterList: any = [];
    filterPopUpModel: any = [];
    filterTypeOptions: any = {
        freeText: ['Contains'],
        text: ['Contains', 'Dictionary'],
        numeric: ['Greater Than', 'Less Than', 'Equal To'],
        boolean: ['Equals'],
        date: ['Range', 'Equal To', 'Not Equal To']
    };
    dataTypeDict: any = [
        { type: 'string', editor: 'text', decimal: 0 },
        { type: 'text', editor: 'text', decimal: 0 },
        { type: 'boolean', editor: 'boolean', decimal: 0 },
        { type: 'date', editor: 'date', decimal: 0 },
        { type: 'number', editor: 'numeric', decimal: 0 },
        { type: 'decimal', editor: 'numeric', decimal: 5 },
        { type: 'int', editor: 'numeric', decimal: 0 },
        { type: 'integer', editor: 'numeric', decimal: 0 },
        { type: 'bigint', editor: 'numeric', decimal: 0 }
    ];
    private dateTime = new Date();
    private yearRange = "2000:" + this.dateTime.getFullYear().toString();
    auditTableCols: any[];
    cellErrorMessageJson: any = {};
    dataPrepInProgress: boolean = false;
    selectedReports: any;
    constructor(
        private alertService: AlertService,
        private dataIngestionService: DataIngestionService,
        private router: Router,
        private authService: AuthenticationService,
        private modalService: NgbModal,
        private formBuilder: UntypedFormBuilder,
        private datePipe: DatePipe
    ) { }

    ngOnInit() {
        if (!this.authService.isPrivacyNoticeAcknowledged) {
            this.router.navigate(['/privacy-notice']);
        }

        this.ingestionLoadData = this.dataIngestionService.ingestionLoadData;

        if (!this.ingestionLoadData) {
            this.router.navigate(['/app/data-ingestion']);
        }
        else {
            if (this.ingestionLoadData.reportpackid && this.ingestionLoadData.reportpackid != 0) {
                this.checkForActiveDataPreps(this.ingestionLoadData.reportpackid);
            }

            if (!this.gridData || !this.gridColumns) {
                this.fileEditModel = { reportPackId: this.ingestionLoadData.reportpackid, tableId: this.ingestionLoadData.tableId, pageNumber: 1, pageSize: this.pageSize, onlyErrorRows: false, isInitialLoad: true, sortList: [], filter: [] };
                this.getFileData(true);
            }

            this.user = this.authService.getLoggedInUser();
            if (!this.user) {
                this.userSubscription = this.authService.watchUser().subscribe(user => {
                    this.user = user;
                });
            }
        }
        this.auditTableCols = [
            { field: 'oldValue', header: 'Old Value' },
            { field: 'newValue', header: 'New Value' },
            { field: 'comment', header: 'Comments' },
            { field: 'changedBy', header: 'Changed By' },
            { field: 'changedDate', header: 'Changed Date' }
        ];
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        this.modalService.dismissAll();
    };

    checkForActiveDataPreps(reportPackId: number) {
        this.dataIngestionService.checkForActiveDataPreparations(reportPackId).then((hasActiveDataPreps: any) => {
            this.dataPrepInProgress = hasActiveDataPreps;
        });
    }

    getFileData(isInitialLoad: boolean) {
        this.loading = true;
        this.fileEditModel.pageNumber = (this.skip / this.pageSize) + 1;
        this.fileEditModel.isInitialLoad = isInitialLoad;
        this.dataIngestionService.getIngestionFileData(this.fileEditModel)
            .then((fileData: any) => {
                this.fileData = JSON.parse(JSON.stringify(fileData))
                this.prepareDataGridFromFetchedData();
                if (this.fileEditModel.isInitialLoad) {
                    this.dataDictionary = JSON.parse(JSON.stringify(this.fileData.DataDictionary));
                    this.prepareColumns(JSON.parse(JSON.stringify(this.fileData.Columns)));
                }
                this.loading = false;
            }).catch(() => { this.loading = false; });
    }

    prepareDataGridFromFetchedData() {
        this.gridData = {
            data: JSON.parse(JSON.stringify(this.fileData.Data)),
            total: this.fileData.TotalCount,
            totalErrorCount: this.fileData.TotalErrorCount,
            totalDraftRows: this.fileData.TotalDraftRows
        };

        this.latestAudit = this.fileData.ChangeAudit;
        this.draftCells = this.fileData.DraftCells.map(x => x.cellId);
        this.errorCells = this.fileData.ErrorCells.map(x => x.cellId);
        this.prepareCellErrorMessage(this.fileData.ErrorCells);
    }

    prepareColumns(columns) {
        this.gridColumns = [];
        this.columnList = [];
        this.sortColumnList = [];
        this.filterList = [];

        columns.forEach(col => {

            var editor = this.dataTypeDict.filter(x => x.type == col.type)[0].editor;

            var dc = {
                title: col.Text, field: col.dataField, dataType: col.type, editor: editor, columnId: col.ColumnId,
                pinned: col.ClientRefIdColumn ? true : false, editable: col.IsEditable, hidden: false,
                leftalign: editor === 'numeric' ? true : false, rightalign: editor != 'numeric' ? true : false, centeralign: false,
                isMandatory: col.IsMandatory, isNullable: col.IsNullable
            };
            this.columnList.push(dc);

            this.sortColumnList.push({ title: col.Text, field: col.dataField, columnId: col.ColumnId });

            var dictionary = this.dataDictionary && this.dataDictionary.filter(x => x.columnId == col.ColumnId).length > 0 ? this.dataDictionary.filter(x => x.columnId == col.ColumnId) : [];
            var filterTypes = editor == 'text' && col.DictGroup == null ? this.filterTypeOptions['freeText'] : this.filterTypeOptions[editor];
            var fc = {
                title: col.Text, columnName: col.dataField, columnId: col.ColumnId, showColumn: this.gridData.data[0].hasOwnProperty(col.dataField),
                disableShowColumnChange: col.ClientRefIdColumn ? true : false, columnType: editor, enableFilter: false, filterType: filterTypes[0],
                filterTypeOptions: filterTypes, dictionary: editor == 'boolean' ? [{ value: true, title: 'True' }, { value: false, title: "False" }] : dictionary,
                textValue: null, numberValue: null, booleanValue: null, dateValue: null, dateLowerLimit: null, dateUpperLimit: null, dictionarySelection: []
            }
            this.filterList.push(fc);

            if (fc.showColumn == true) this.gridColumns.push(dc);
        });

        this.filterPopUpModel = JSON.parse(JSON.stringify(this.filterList));
        this.updateFileEditFilter();
    }

    prepareCellErrorMessage(data: any) {
        this.cellErrorMessageJson = [];
        data.forEach(d => {
            this.cellErrorMessageJson[d.cellId] = d.ErrorMessage;
        });
    }

    pageChange(event: PageChangeEvent) {
        this.skip = event.skip;
        this.getFileData(false);
    }

    openPopUp(modal, size: string) {
        this.modalService.open(modal, { size: size, windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
    }

    /* Sort Pop-up functions */

    cancelSortChanges() {
        this.sortPopUpModel = JSON.parse(JSON.stringify(this.fileEditModel.sortList));
    }

    clearSortSelection() {
        if (this.fileEditModel.sortList.length > 0) {
            this.getFileData(false);
        }
        this.sortPopUpModel = [];
        this.fileEditModel.sortList = [];
    }

    confirmSortSelection() {
        this.fileEditModel.sortList = JSON.parse(JSON.stringify(this.sortPopUpModel));
        this.getFileData(false);
    }

    sortGridCellClickHandler({ sender, column, rowIndex, columnIndex, dataItem, isEdited }) {
        if (!isEdited) {
            sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
        }
    }

    sortGridCellCloseHandler(args: any) {
        const { formGroup, column, dataItem } = args;

        if (!formGroup.valid) {
            args.preventDefault();
        } else {
            if (column.field == 'columnText' && dataItem['columnId'] != formGroup.value['columnId']) {
                var row = this.sortPopUpModel.filter(x => x.sortLevel == dataItem.sortLevel)[0];
                var col = this.sortColumnList.filter(x => x.columnId == formGroup.value['columnId'])[0];
                row.columnId = col.columnId;
                row.columnName = col.field;
                row.columnText = col.title;
                this.updateSortColumnList();
            }
            else if ((column.field == 'sortOrderText' && dataItem['sortOrder'] != formGroup.value['sortOrder'])) {
                var row = this.sortPopUpModel.filter(x => x.sortLevel == dataItem.sortLevel)[0];
                row.sortOrder = formGroup.value['sortOrder'];
                row.sortOrderText = this.sortOrderOptions.filter(x => x.sortOrder == row.sortOrder)[0].sortOrderText;
            }
        }
    }

    addSortLevel() {
        var sortLevel = this.getMaxValue(this.sortPopUpModel, 'sortLevel') + 1;
        if (sortLevel <= 10) {
            var rowTemplate = { sortLevel: sortLevel, sortLevelText: sortLevel == 1 ? 'Sort By' : 'Then By', columnId: null, columnName: '', columnText: '', sortOrder: '', sortOrderText: '' };
            this.sortPopUpModel.push(rowTemplate);
        } else this.alertService.info("The sort level selection has reached the maximum limit!");
    }

    removeSortLevel({ dataItem }) {
        this.sortPopUpModel = this.sortPopUpModel.filter(x => x.sortLevel != dataItem.sortLevel);
        this.sortPopUpModel.forEach(level => {
            level.sortLevel = this.sortPopUpModel.findIndex(x => x.sortLevel == level.sortLevel) + 1;
            if (level.sortLevel == 1) level.sortLevelText = 'Sort By';
        });
        this.updateSortColumnList();
    }

    updateSortColumnList() {
        this.columnList.filter(x => this.sortPopUpModel.filter(y => y.columnId == x.columnId).length == 0).forEach(el => {
            this.sortColumnList.push({ columnId: el.columnId, title: el.title, field: el.field });
        });
    }

    public createFormGroup(dataItem: any): UntypedFormGroup {
        var formGroup = dataItem;
        return this.formBuilder.group(formGroup);
    }

    getMaxValue(array: any, field: string) {
        var val = 0;
        array.forEach(el => {
            if (el[field] > val) val = el[field];
        });
        return val;
    }

    /* Sort Pop-up functions */

    /* Filter pop-up functions */
    cancelFilterChanges() {
        this.showOnlyErrorRows = this.fileEditModel.onlyErrorRows;
        this.filterPopUpModel = JSON.parse(JSON.stringify(this.filterList));
        var currentFilters = this.fileEditModel.filter;
        this.filterPopUpModel.forEach(model => {
            var f = currentFilters.filter(x => x.columnId == model.columnId)[0];
            if (f) {
                var dictionarySelection = f.textValue && f.textValue.trim() != '' ? f.textValue.split('|') : [];
                model.showColumn = f.showColumn && f.showColumn == 1 ? true : false;
                model.enableFilter = f.enableFilter;
                model.filterType = f.filterType;
                model.textValue = f.filterType == 'Dictionary' ? null : f.textValue;
                model.numberValue = f.numberValue;
                model.booleanValue = f.booleanValue;
                model.dateLowerLimit = f.dateLowerLimit;
                model.dateUpperLimit = f.dateUpperLimit;
                model.dateValue = f.dateValue;
                model.dictionarySelection = model.dictionary.filter(x => dictionarySelection.indexOf(x.mappingValue) > -1);
            }
            else {
                model.showColumn = false;
            }
        });
    }

    clearFilterSelection() {
        this.showOnlyErrorRows = false;
        this.filterPopUpModel = JSON.parse(JSON.stringify(this.filterList));
    }

    onFilterSwitchChange(switchValue: boolean, dataItem: any) {
        if (!switchValue) {
            var filterRow = this.filterPopUpModel.filter(x => x.columnId == dataItem.columnId)[0];
            filterRow.textValue = null;
            filterRow.numberValue = null;
            filterRow.booleanValue = null;
            filterRow.dateLowerLimit = null;
            filterRow.dateUpperLimit = null;
            filterRow.dateValue = null;
        }
    }

    confirmFilterSelection() {
        this.fileEditModel.onlyErrorRows = this.showOnlyErrorRows;
        this.gridColumns = JSON.parse(JSON.stringify(this.columnList.filter(x => this.filterPopUpModel.filter(y => y.columnId == x.columnId && y.showColumn == true).length > 0)));
        this.updateFileEditFilter();
        this.getFileData(false);
    }

    updateFileEditFilter() {
        this.fileEditModel.filter = [];
        this.filterPopUpModel.filter(x => x.enableFilter == true || x.showColumn == true).forEach(el => {
            if (el.dictionarySelection.length > 0) el.textValue = el.dictionarySelection.map(x => x.mappingValue).join('|');
            this.fileEditModel.filter.push({
                columnName: el.columnName, columnId: el.columnId, columnType: el.columnType, filterType: el.filterType, textValue: el.textValue,
                numberValue: el.numberValue, booleanValue: el.booleanValue, dateLowerLimit: el.dateLowerLimit, dateUpperLimit: el.dateUpperLimit,
                dateValue: el.dateValue, showColumn: el.showColumn ? 1 : 0, enableFilter: el.enableFilter
            });
        });
    }

    /* Filter pop-up functions */

    /* Edit & save data functions */

    showEditPopUp(cellPopOutModal: any, dataItem: any, column: any) {
        if (this.editType == 'cellPopOut' && column.editable) {
            this.cellPopOutModel = this.getChangedCell(dataItem, column, null);
            this.modalService.open(cellPopOutModal, { size: 'xl', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
        }
    }

    getChangedCell(dataItem, column, newValue: any) {
        var lastCellAudit = this.latestAudit.filter(x => x.RowRefId == dataItem.rowRefId && x.ClientRefId == dataItem.clientRefId && x.ColumnId == column.columnId);
        var convertedType = this.dataTypeDict.filter(x => x.type == column.dataType)[0];
        var dictionaryOptions = this.dataDictionary.filter(x => x.columnId == column.columnId);
        var cellOptions = convertedType.type == 'boolean' ? [{ value: 1, title: 'True' }, { value: 0, title: "False" }] : (dictionaryOptions.length > 0 ? dictionaryOptions.map(x => ({ value: x.acceptableValue, title: x.acceptableValue })) : []);

        var changedCell = {
            rowRefId: dataItem.rowRefId, column: column.field, clientRefId: dataItem.clientRefId, columnId: column.columnId, columnTitle: column.title,
            dataType: column.dataType, oldValue: dataItem[column.field], isNullable: column.isNullable,
            newValue: newValue ? newValue : (convertedType.editor == 'numeric' ? 0 : (convertedType.editor == 'text' ? '' : null)),
            cellOptions: cellOptions, editor: convertedType.editor, decimals: convertedType.decimal, comment: '', latestAudit: lastCellAudit ? lastCellAudit[0] : null,
        };

        return changedCell;
    }

    submitCellEdit() {
        if (this.cellPopOutModel.editor == 'date') {
            this.cellPopOutModel.newValue = this.datePipe.transform(this.cellPopOutModel.newValue, 'yyyy-MM-dd');
        }

        this.submitCellChange(this.cellPopOutModel);
        this.modalService.dismissAll();
        this.cellPopOutModel = null;
        this.cellAudit = null;
    }

    submitCellChange(changedCell) {
        //Updating changed data in form data and grid
        this.gridData.data.filter(x => x.rowRefId == changedCell.rowRefId && x.clientRefId == changedCell.clientRefId)[0][changedCell.column] = changedCell.newValue;
        this.localDraftCells.push(changedCell.rowRefId + '_' + changedCell.clientRefId + '_' + changedCell.columnId);

        //Updating form changes
        var prevCellChange = this.formChanges.filter(x => x.columnId == changedCell.columnId && x.rowRefId == changedCell.rowRefId && x.gridId == changedCell.gridId)[0];
        if (prevCellChange) {
            prevCellChange.value = changedCell.newValue;
            prevCellChange.changed = true;
            prevCellChange.comment = changedCell.comment;
            prevCellChange.changeType = 'U';
        }
        else this.formChanges.push({ columnId: changedCell.columnId, rowRefId: changedCell.rowRefId, clientRefId: changedCell.clientRefId, value: changedCell.newValue, gridId: changedCell.gridId, comment: changedCell.comment, changeType: 'U' });

        if (this.ingestionLoadData.loadId != 0 && !changedCell.isNewRow)
            this.addAudit(changedCell, false);

        if (this.formChanges.length == 30) {
            this.alertService.info('There more than 30 cells changes done. Please save/submit the data');
        }
    }

    addAudit(changedCell: any, isDelete: boolean) {
        const changedDate = new Date();
        //Adding to audit
        var audit = this.formAudit.filter(x => x.rowRefId == changedCell.rowRefId && x.clientRefId == changedCell.clientRefId && x.columnId == changedCell.columnId)[0];
        if (audit) {
            audit.newValue = changedCell.newValue;
            audit.comment = changedCell.comment;
            audit.changedDate = changedDate;
        } else {
            audit = {
                id: this.formAudit.length + 1, rowRefId: changedCell.rowRefId, clientRefId: changedCell.clientRefId, columnId: changedCell.columnId, rowCode: changedCell.rowCode,
                rowDescription: changedCell.headTitle, columnTitle: changedCell.columnTitle, oldValue: changedCell.oldValue, newValue: changedCell.newValue,
                comment: changedCell.comment, changedDate: changedDate
            }
            this.formAudit.push(audit);
        }

        if (!isDelete) {
            //Updating latest audit data
            var latestCellAudit = this.latestAudit.filter(x => x.RowRefId == changedCell.rowRefId && x.ClientRefId == changedCell.clientRefId && x.ColumnId == changedCell.columnId)[0];
            if (latestCellAudit) {
                latestCellAudit.OldValue = this.ingestionLoadData.currentStatus != 'Draft' ? changedCell.oldValue : latestCellAudit.oldvalue;
                latestCellAudit.NewValue = changedCell.newValue;
                latestCellAudit.Comment = changedCell.comment;
                latestCellAudit.ChangedBy = this.user.username;
                latestCellAudit.ChangedDate = changedDate;
            }
            else {
                this.latestAudit.push({
                    RowCode: changedCell.rowCode, RowRefId: changedCell.rowRefId, ClientRefId: changedCell.clientRefId, ColumnId: changedCell.columnId,
                    RowDescription: changedCell.description, ColumnTitle: changedCell.columnTitle, OldValue: changedCell.oldValue, NewValue: changedCell.newValue,
                    Comment: changedCell.comment, ChangedBy: this.user.username, ChangedDate: changedDate
                });
            }
        }
    }

    isValidForm() {
        if (this.cellPopOutModel.newValue == undefined || this.cellPopOutModel.newValue == null || (this.cellPopOutModel.editor == 'text' && this.cellPopOutModel.newValue == '')
            || this.cellPopOutModel.comment == undefined || this.cellPopOutModel.comment == ''
            || this.cellPopOutModel.newValue == this.cellPopOutModel.oldValue)
            return false;
        else return true;
    }

    async saveIngestionForm(draftVersion: boolean) {
        if (this.formChanges.length > 0 || (this.ingestionLoadData.currentStatus == 'Draft' && !draftVersion)) {
            this.loading = true;
            await this.dataIngestionService.saveIngestionForm(this.formChanges, draftVersion)
                .then((model: any) => {
                    this.loading = false;
                    this.ingestionLoadData.currentStatus = 'Draft';
                    this.ingestionLoadData.reportpackid = model.reportpackid;
                    this.ingestionLoadData.loadId = model.loadId;
                    if (!draftVersion) this.backToIngestion();
                    else {
                        this.formChanges = [];
                        this.localDraftCells = [];
                        this.getFileData(false);
                    }
                }).catch(() => { this.loading = false; });
        }
        else {
            this.alertService.info('No changes to save!');
        }
    }

    backToIngestion() {
        this.dataIngestionService.ingestionLoadData = null;
        this.router.navigate(['/app/data-ingestion']);
    }

    downloadAuditReport() {
        this.alertService.info('The form audit report will be downloaded in a while. Please note that only saved changes will be downloaded!');
        this.dataIngestionService.downloadFormAuditReport();
    }

    downloadErrorAuditReport() {
        this.alertService.info('The error audit report will be downloaded in a while. Please note that only saved changes will be downloaded!');
        this.dataIngestionService.downloadErrorAuditReport();
    }


    cellPopOutClose() {
        this.modalService.dismissAll();
        this.cellPopOutModel = null;
        this.cellAudit = null;
    }

    clearEditPopUp() {
        this.cellPopOutModel.newValue = 0;
        this.cellPopOutModel.comment = '';
    }

    getFormCellAudit(cell: any) {
        this.loading = true;
        this.dataIngestionService.getFormCellAudit(this.ingestionLoadData.reportpackid, this.ingestionLoadData.tableId, cell.rowRefId, cell.clientRefId, cell.columnId)
            .then((data: any) => {
                var auditList = [];
                data.forEach(el => {
                    if (!el.IsDraft || this.formAudit.filter(x => x.rowRefId == cell.rowRefId && x.clientRefId == cell.clientRefId && x.columnId == cell.columnId).length == 0) {
                        auditList.push({
                            oldValue: el.OldValue, newValue: el.NewValue, comment: el.Comment, changedBy: el.ChangedBy, changedDate: el.ChangedDate, isSaved: !el.IsDraft
                        });
                    }
                });
                this.formAudit.filter(x => x.rowRefId == cell.rowRefId && x.clientRefId == cell.clientRefId && x.columnId == cell.columnId).forEach(el => {
                    auditList.push({
                        oldValue: el.oldValue, newValue: el.newValue, comment: el.comment, changedBy: this.user.username, changedDate: el.changedDate, isSaved: false
                    });
                });
                this.cellAudit = auditList.sort((a, b) => a.changedDate < b.changedDate ? 1 : -1);
                this.loading = false;
            }).catch(() => { this.loading = false; });
    }

    removeRow(data: any) {
        var row = data.dataItem;
        this.formChanges = this.formChanges.filter(x => !(x.rowRefId == row.rowRefId));

        this.gridColumns.forEach(col => {
            if (row[col.field] != null && row[col.field] != '' && row[col.field] != 0) {
                this.formChanges.push({ columnId: col.columnId, rowRefId: row.rowRefId, clientRefId: row.clientRefId, value: null, gridId: row.gridId, comment: 'Deleted', changeType: 'D' });
            }
        });
        this.gridData.data = this.gridData.data.filter(x => !(x.rowRefId == row.rowRefId));
    }

    undoChanges() {
        this.localDraftCells = [];
        this.formChanges = [];
        this.formAudit = [];
        this.prepareDataGridFromFetchedData();
    }

    openReportRunPopUp(reportRunPopUp) {
        this.modalService.open(reportRunPopUp, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
        this.selectedReports = this.dataIngestionService.ingestionLoadData.reportsToPrepare
    }

    submitReportSelection() {
        this.dataIngestionService.ingestionLoadData.reportsToPrepare = this.selectedReports;
    }

    clearReportSelection() {
        this.selectedReports = '';
    }
}