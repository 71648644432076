<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
    <div class="col-12 col-lg-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
    </div>
</div>
<div class="row header col-12">
    {{ editMode ? 'Edit Report Pack Sequence' : 'Add Report Pack Sequence' }}
</div>

<div *ngIf="reportPackSequence">
    <div class="row">
        <div class="col-12">
            <form #reportPackSequenceForm="ngForm">
                <div class="row form-row">
                    <div class="col-2"><label>Report Pack Sequence Name</label></div>
                    <div class="col-3">
                        <input type="text" required minlength="4" maxlength="50" [disabled]="editMode"
                            [(ngModel)]="reportPackSequence.RPSequenceName" class="rps-form-control"
                            #rpSequenceName="ngModel" id="rpSequenceName" name="rpSequenceName"
                            pattern="^[A-Za-z0-9 _\-.]*$" trim-text />
                        <div *ngIf="rpSequenceName.invalid && (rpSequenceName.dirty || rpSequenceName.touched)"
                            class="alert alert-danger">
                            <div *ngIf="rpSequenceName.errors.required">
                                Report Pack Sequence Name is a mandatory field.
                            </div>
                            <div *ngIf="rpSequenceName.errors.pattern">
                                Report Pack Sequence Name is not valid, as it contains special characters.
                            </div>
                            <div *ngIf="rpSequenceName.errors.maxlength">
                                Report Pack Sequence Name should not exceed 50 characters.
                            </div>
                            <div *ngIf="rpSequenceName.errors.minlength">
                                Report Pack Sequence Name must be at least 4 characters long.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="col-2"><label>Description</label></div>
                    <div class="col-3">
                        <textarea class="rps-form-control textarea-wrapper" [(ngModel)]="reportPackSequence.Description"
                            name="rpSequenceDescription" #rpSequenceDescription="ngModel" pattern="[^^/:*|'<>\\{}\[\]]*"
                            trim-text id="rpSequenceDescription" maxlength="150"></textarea>
                        <div *ngIf="rpSequenceDescription.invalid && (rpSequenceDescription.dirty || rpSequenceDescription.touched)"
                            class="alert alert-danger">
                            <div *ngIf="rpSequenceDescription.errors.pattern">
                                Report Pack Sequence Description is not valid, as it contains special characters.
                            </div>
                            <div *ngIf="rpSequenceDescription.errors.maxlength">
                                Report Pack Sequence Description should not exceed 150 characters.
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row form-row">
        <div class="col-8"></div>
        <div class="col-4 float-right">
            <app-report-pack-filter [(selectedReportPacks)]="selectedReportPacks" [showOnlyValidReportPacks]="true"
                [rpIdsToIgnore]="rpIdsToIgnore" [updateServiceOnRpSelection]="false" [selectionMode]="'multiple'"
                (selectedReportPacksChange)="onNewRPMappings()">
            </app-report-pack-filter>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p-table #dtRPSMappings [value]="reportPackSequence.ReportPackSequenceMappings"
                [scrollHeight]="'calc(100vh - 292px)'" [columns]="reportPackHeaders" [paginator]="true"
                [scrollable]="true" [rows]="5" class=" table-irr data-ingestion">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 40px;"></th>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field"> {{col.header}}
                            <p-sortIcon *ngIf="col.field !=''" [field]="col.field" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order">
                            </p-sortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th style="width: 40px;"></th>
                        <th *ngFor="let col of columns">
                            <p-calendar *ngIf="col.field =='ReportDate'" [(ngModel)]="reportDateFilters" #rdFilter
                                selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy" [readonlyInput]="true"
                                placeholder="Select a range" appendTo="body" [monthNavigator]="true"
                                [yearNavigator]="true" yearRange="{{yearRange}}"
                                (ngModelChange)="dtrps.filter(reportDateFilters, col.field, 'dateRangeFilter')"
                                (onSelect)="closeDateRangeFilter(rdFilter, reportDateFilters)">
                            </p-calendar>
                            <input *ngIf="col.field!='' && col.field !='ReportDate'" type="text" class="w-100"
                                placeholder="Filter Text"
                                [value]="dtRPSMappings.filters && dtRPSMappings.filters[col.field] ? dtRPSMappings.filters[col.field].value: ''"
                                (input)="dtRPSMappings.filter($event.target.value, col.field, 'contains')">
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                    <tr>
                        <td style="width: 40px;">
                            <h6 *ngIf="rowData.IsDeleted" class="small-font mt-3"><i
                                    class="fas fa-times-circle hvr-shrink failed-icon mr-3"></i>
                            </h6>
                        </td>
                        <td>
                            <kendo-numerictextbox id="rpSequenceOrder" [(ngModel)]="rowData.Order" id="rpSequenceOrder"
                                [disabled]="rowData.IsDeleted" name="rpSequenceOrder" [format]="'#'" [min]="1"
                                class="w-100" (valueChange)="onOrderChange(rowData)">
                            </kendo-numerictextbox>
                        </td>
                        <td>{{rowData.ReportPackTitle}}</td>
                        <td>{{rowData.ReportPackDescription}}</td>
                        <td>{{rowData['ReportDate'] | date: 'dd/MM/yyyy'}}</td>
                        <td>
                            <textarea class="textarea" type="text" pattern="[^^/:*|'<>\\{}\[\]]*"
                                class="rps-form-control textarea-wrapper" maxlength="150" trim-text
                                [(ngModel)]="rowData.Comments"></textarea>
                        </td>
                        <td>
                            <button *ngIf="!rowData.IsDeleted" class="btn-table-icon hvr-shrink" type="button"
                                (click)="onMappingDelete(rowData)" ngbTooltip="Unmap report pack" container="body"
                                tooltipClass="reporting-tooltip">
                                <i class="fas fa-trash-alt fa-lg"></i>
                            </button>
                            <button *ngIf="rowData.IsDeleted" class="btn-table-icon hvr-shrink" type="button"
                                (click)="onUndoMappingDelete(rowData)" ngbTooltip="Undo Delete" container="body"
                                tooltipClass="reporting-tooltip">
                                <i class="fas fa-undo fa-lg"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="paginatorleft">
                    <button class="btn btn-clear-filter hvr-shrink" type="button"
                        (click)="dtRPSMappings.reset(); clearFilters();">
                        <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                    </button>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="row float-right m-0 d-flex flex-column justify-content-center d-md-block mt-3">
        <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button"
            (click)='backToReportPackSequenceManagement()'>
            <span>Back to Report Pack Sequence Management</span>
        </button>
        <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button" (click)="clearChanges()"
            id='btnClearChanges'>
            <span>Clear</span>
        </button>
        <button class="btn btn-modal-pos small-font hvr-shrink" type="button" (click)='saveReportPackSequenceChanges()'
            id='btnSubmit'
            [disabled]="reportPackSequenceForm && reportPackSequenceForm.form ? !reportPackSequenceForm.form.valid : false">
            <span>Submit</span>
        </button>
    </div>
</div>