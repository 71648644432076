<div class="row d-flex align-items-center mx-0">
    <div class="col-6 pt-4">
        <img src="assets/images/IRR-Logo3.svg" class="placeholder-irr-logo">

    </div>
    <div class="col-6 text-right pt-4">
        <img src="assets/images/EY-Logo-White.png" class="placeholder-ey-logo">
    </div>
</div>
<div class="row mx-0">
    <div class="col-12 text-center">
        <h1 class="loading-app-name"> <b>I</b>ntegrated <b>R</b>egulatory <b>R</b>eporting</h1>
    </div>
</div>
<div class="row mx-0 d-flex align-items-center justify-content-center text-center">
    <div class="col-md-12 error-code">
        <img src="assets/images/server-error.svg" class="server-error-img mt-3">
        <p class="attribution-line">Designed by <a href="https://www.freepik.com" target="_blank">Freepik</a></p>
    </div>
    <div class="col-md-12 text-white mt-3">
        <h1>Server Error</h1>
        <h4>Looks like something went wrong, we're working on it</h4>
        <button class="btn btn-secondary-ey btn-icon small-font hvr-shrink mt-4" type="button" (click)="goBack()">
            <div class="icon-container"><i class="fas fa-arrow-left fa-lg"></i></div><span>Back to home</span>
        </button>
    </div>
</div>