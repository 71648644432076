<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
  <div class="col-12 col-sm-6">
    <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
      [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
  </div>  
</div>
<div class="row">
  <div class="col-12">
    <div class="report-pack-scroll">
      <p-table #reportpack [value]="reportpacks" [paginator]="true" [rows]="15" 
        class="table-irr pack-admin" [columns]="headerCols" [scrollable]="true" [scrollHeight]="'calc(100vh - 292px)'">
        <ng-template pTemplate="paginatorleft">
          <button class="btn btn-clear-filter hvr-shrink" type="button" (click)="reportpack.reset();clearFilters();">
            <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
          </button>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field"> {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon>
            </th>
            <th>Actions</th>
          </tr>
          <tr>
            <th *ngFor="let col of columns">
              <p-calendar *ngIf="col.field =='ReportDate'" [(ngModel)]="reportDateFilters" #rdFilter
                selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy" [readonlyInput]="true"
                placeholder="Select a range" appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="{{yearRange}}"
                (ngModelChange)="reportpack.filter(reportDateFilters, col.field, 'dateRangeFilter')"
                (onSelect)="closeDateRangeFilter(rdFilter, reportDateFilters)" [showButtonBar]="true"></p-calendar>

              <p-calendar *ngIf="col.field =='IngestedDate'" [(ngModel)]="ingestedDateFilters" #idFilter
                selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy" [readonlyInput]="true"
                placeholder="Select a range" appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="{{yearRange}}"
                (ngModelChange)="reportpack.filter(ingestedDateFilters, 'IngestedDate', 'dateRangeFilter')"
                (onSelect)="closeDateRangeFilter(idFilter, ingestedDateFilters)" [showButtonBar]="true">
              </p-calendar>

              <input *ngIf="col.field !='IngestedDate' && col.field !='ReportDate'" type="text" class="w-100"
                placeholder="Filter Text"
                [value]="reportpack.filters && reportpack.filters[col.field] ? reportpack.filters[col.field].value: ''"
                (input)="reportpack.filter($event.target.value, col.field, 'contains')">
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>        
          <tr>
            <td>{{rowData['ReportPackID']}}</td>
            <td>{{rowData['Title']}}</td>
            <td>{{rowData['RP_Description']}}</td>
            <td>{{rowData['ReportDate'] | date:'dd/MM/yyyy'}}</td>
            <td>{{rowData['IngestedBy']}}</td>
            <td>{{rowData['IngestedDate'] | date:'dd/MM/yyyy'}}</td>
            <td>
              <button class="btn-table-icon hvr-shrink" type="button" (click)="viewOrEditReportPack(rowData, false)"
                ngbTooltip="View" container="body" tooltipClass="reporting-tooltip">
                <i class="fas fa-eye"></i>
              </button>
              <button type="button" class="btn-table-icon hvr-shrink" container="body" ngbTooltip="Edit"
                id="editReportPack" (click)="viewOrEditReportPack(rowData, true)" triggers="hover" tooltipClass="reporting-tooltip">
                <i class="fas fa-edit fa-lg"></i>
              </button>
              <button *ngIf="rowData.isVisible === true" type="button"
                class="btn-table-icon hvr-shrink" container="body" id="deactivateReportPack"
                ngbTooltip="Deactivate" (click)='openPromptModal(rowData)' triggers="hover" tooltipClass="reporting-tooltip">
                <i class="fas fa-trash-alt fa-lg"></i>
              </button>
              <button *ngIf="rowData.isVisible === false" type="button"
                class="btn-table-icon hvr-shrink" container="body" id="activateReportPack" (click)='openPromptModal(rowData)'
                ngbTooltip="Activate" triggers="hover" tooltipClass="reporting-tooltip">
                <i class="fas fa-check fa-lg"></i>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>