import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AuthenticationService } from './core/security/authentication.service';

/**
 * Component
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  /**
   * Router outlet
   */
  @ViewChild('outlet', { static: false }) outlet: any;

  showLoading = true;
  /**
   * Creates an instance of app component.
   */
  public constructor(private titleService: Title, private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    // Change tab title on route
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }


  /**
   * Determines whether activate on
   */
  onActivate() {
    this.showLoading = false;
  }

}
