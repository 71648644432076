import { DatePipe, DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dataTypeFormat'
})

export class DataFormatPipe implements PipeTransform {
    constructor(public datePipe: DatePipe, public numberPipe: DecimalPipe) {
    }

    transform(value: any, dataType: string, decimalPrecision: number = 5) {

        if (value == 'Uncomputed') {
            return value;
        }

        if (dataType == 'date')
            value = value ? this.datePipe.transform(value.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"), 'dd-MM-yyyy') : null;
        else if (dataType == 'date-time')
            value = value ? this.datePipe.transform(value.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"), 'dd-MM-yyyy hh:mm:ss') : null;
        else if (dataType == 'numeric') {
            value = value ? this.numberPipe.transform(value, `1.${decimalPrecision}-${decimalPrecision}`) : 0;
        }
        else if (dataType == 'boolean') {
            value = value == 1 || value == 'true' ? true : false;
        }
        return value;
    }
}