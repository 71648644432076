<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
    <div class="col-12 col-sm-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
    </div>
    <div class="col-12 col-sm-6 text-left text-sm-right">
        <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button"
            (click)="updatePermissionLevels()">
            <div class="icon-container"><i class="fas fa-save fa-lg"></i></div><span>Save Changes</span>
        </button>
        <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button"
            (click)="clearChanges()"><span>Clear</span>
        </button>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <p-treeTable #permLevel [value]="gridReportTreeList" class="table-irr permissionLevel" [columns]="headerCols"
            [scrollable]="true" [scrollHeight]="'calc(100vh - 292px)'" [paginator]="true" [rows]="4"
            sortField="DisplayName" sortOrder="1">
            <ng-template pTemplate="paginatorleft">
                <button class="btn btn-clear-filter hvr-shrink" type="button" (click)="permLevel.reset();">
                    <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                </button>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [ttSortableColumn]="col.field">
                        {{col.header}}
                        <p-treeTableSortIcon [field]="col.field"></p-treeTableSortIcon>
                    </th>
                </tr>
                <tr>
                    <th *ngFor="let col of columns">
                        <input type="text" class="w-100" placeholder="Filter Text"
                            [value]="permLevel.filters && permLevel.filters[col.field] ? permLevel.filters[col.field].value: ''"
                            (input)="permLevel.filter($event.target.value, col.field, 'contains')">
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                <tr>
                    <td>
                        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                        {{rowData.DisplayName}}
                    </td>
                    <td>{{rowData.Description}}</td>
                    <td class="d-flex align-items-center">
                        <select *ngIf="rowData.approvalLevelList" [(ngModel)]="rowData.MaxApprovalLevelForReport"
                            [disabled]="rowData.SignOffsInProgress" appendTo="body"
                            class="permission-level-form-control w-100 select-arrow"
                            (change)="onApprovalLevelChange(rowNode)">
                            <option *ngFor="let l of  rowData.approvalLevelList"
                                [selected]="l.value == rowData.MaxApprovalLevelForReport" value={{l.value}}>
                                {{l.label}}
                            </option>
                        </select>
                        <i class="fas fa-exclamation-triangle hvr-shrink failed-icon fa-lg ml-5" placement="left"
                        *ngIf="rowData.parent != '' && rowData.SignOffsInProgress" container="body" triggers="hover"
                        ngbTooltip="Approval is in progress for this report."></i>
                    </td>
                </tr>
            </ng-template>
        </p-treeTable>
    </div>
</div>