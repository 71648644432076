import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

/**
 * Validate max date directive
 */
@Directive({
  selector: '[appMaxDate]',
  providers: [{provide: NG_VALIDATORS, useExisting: MaxDateValidatorDirective, multi: true}]
})
export class MaxDateValidatorDirective implements Validator {
  /**
   * Input  of max date validator directive
   */
  @Input('appMaxDate') maxDate: NgbDate;

  /**
   * Validates max date
   */
  validate(control: AbstractControl): {[key: string]: any} | null {
    return this.maxDate.after(control.value) || this.maxDate.equals(control.value) ?
             null : {'appMaxDate': true };
  }
}
