import { TreeTableModule } from 'primeng/treetable';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { RoleManagementService } from 'src/app/core/security/role-management.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { SuiteService } from 'src/app/core/services/suite.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';


/**
  * Suite Dashboard Component.
  */
@Component({
    selector: 'app-suite-dashboard',
    templateUrl: './suite-dashboard.component.html',
    styleUrls: ['./suite-dashboard.component.css']
})
export class SuiteDashboardComponent implements OnInit, OnDestroy {

    /**
     * Suites permissions of suite dashboard component
     */
    suitesPermissions: any;
    /**
     * Suites permissions sub of suite dashboard component
     */
    suitesPermissionsSub: Subscription;
    /**
     * Suites  of suite dashboard component
     */
    suites: any;
    reportPackId: number;
    reportPackIdSub: Subscription;
    data: any;
    options: any;
    defaultStatusData: any;
    loading: any;
    cardOverlay = true;
    selectedRp: any;

    /**
    * Creates an instance of the Suite Dashboard Component.
    */
    constructor(
        private router: Router,
        private suiteDataService: SuiteService,
        private roleService: RoleManagementService,
        private authService: AuthenticationService,
        private dashboardService: DashboardService
    ) {
        this.options = {
            title: { display: false },
            legend: { display: false },
            rotation: 1 * Math.PI,
            circumference: 1 * Math.PI,
        }

        this.defaultStatusData = {
            labels: ['Not Started', 'In Progress', 'Completed'],
            datasets: [
                {
                    data: [40, 35, 25],
                    backgroundColor: ["#747480", "#333333", "#2e2e38"],
                    borderWidth: 2,
                    borderColor: '#373737',
                    hoverBackgroundColor: ["#747480", "#333333", "#2e2e38"]
                },
            ]
        }
    }

    /**
    * on Init.
    */
    ngOnInit() {

        if (!this.authService.isPrivacyNoticeAcknowledged) {
            this.router.navigate(['/privacy-notice']);
        }

        this.selectedRp = this.dashboardService.selectedReportPack;

        this.reportPackId = !this.dashboardService.reportPackID ? 0 : this.dashboardService.reportPackID;
        if (this.reportPackId) this.cardOverlay = false;
        else this.cardOverlay = true;

        this.getSuites();

        this.suitesPermissions = this.roleService.getSuitePermissions();
        if (!this.suitesPermissions) {
            this.suitesPermissionsSub = this.roleService.watchSuitePermissions()
                .subscribe(permissions => {
                    this.suitesPermissions = permissions;
                });
        }
        this.reportPackIdSub = this.dashboardService.watchReportPackId().subscribe(rpId => {
            this.reportPackId = rpId;
            this.cardOverlay = false;
            this.getSuites();
            this.selectedRp = this.dashboardService.selectedReportPack;
        });
    }

    getSuites() {
        this.loading = true;
        this.suiteDataService.getSuites()
            .then((data: any) => {
                const filterSuites = data.filter((suite) => this.roleService.checkPermissions('V', suite.reportSuiteAbbr.toLowerCase()));
                this.suites = filterSuites;
                this.suites.forEach(element => {
                    element.statusData = !this.reportPackId ? this.defaultStatusData :
                        {
                            labels: ['Not Started', 'In Progress', 'Completed'],
                            datasets: [
                                {
                                    data: [element.notStartedReports, element.inProgressReports, element.completedReports],
                                    backgroundColor: ["#35a4e8", "#27acaa", "#9c82d4"],
                                    borderWidth: 2,
                                    borderColor: '#c4c4cd'
                                },
                            ]
                        }
                });
                this.loading = false;
            });
    }

    /**
     * on destroy
     */
    ngOnDestroy() {
        if (this.suitesPermissionsSub) {
            this.suitesPermissionsSub.unsubscribe();
        }
        if (this.reportPackIdSub) {
            this.reportPackIdSub.unsubscribe();
        }
    }

    /**
    * Navigates to selected Suite.
    */
    navigateRoute(suite: string) {
        const finalSuite = suite.toLowerCase().trim();
        this.suiteDataService.setLatestSuite(finalSuite);
        this.router.navigate(['/app/suite', finalSuite]);
    }
}
