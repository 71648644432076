import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { ReportBundlingService } from 'src/app/core/services/reportBundling.service';
import { FilterUtils } from 'primeng/utils';

@Component({
  selector: 'app-report-bundling',
  templateUrl: './report-bundling.component.html',
  styleUrls: ['./report-bundling.component.css']
})
export class ReportBundlingComponent implements OnInit {
  loading: boolean = false;
  bundleList: any[];
  headerCols: any[];
  private dateTime = new Date();
  private yearRange = "2000:" + this.dateTime.getFullYear().toString();
  createdDateFilters: Date[];

  constructor(private reportBundlingService: ReportBundlingService,
    private router: Router,
    private alertService: AlertService) { }

  ngOnInit() {
    this.headerCols = [
      { field: 'bundleName', header: 'Bundle Name' },
      { field: 'bundleTypeName', header: 'Bundle Type Name' },
      { field: 'reportPackName', header: 'Report Pack' },
      { field: 'generatedFileName', header: 'Generated Bundle' },
      { field: 'createdBy', header: 'Created By' },
      { field: 'createdDate', header: 'Created Date' },
      { field: 'status', header: 'Status' }
    ];

    FilterUtils['dateRangeFilter'] = (value, filter: [Date, Date]): boolean => {
      if (!filter || (!filter[0] && !filter[1]))
        return true;
      var val = new Date(value);
      var convertDate = new Date(val.getFullYear() + '/' + (val.getMonth() + 1) + '/' + val.getDate());
      var s = filter[0].getTime();
      var e;
      if (filter[1])
        e = filter[1].getTime() + (24 * 60 * 60 * 1000) - 1000;
      else e = s + (24 * 60 * 60 * 1000) - 1000;
      return convertDate.getTime() >= s && convertDate.getTime() <= e;
    }

    this.getBundleList();
  }

  getBundleList() {
    this.loading = true;
    this.reportBundlingService.getBundleList()
      .then((data: any) => {
        this.bundleList = data;
        this.loading = false;
      }).catch(() => { this.loading = false; });
  }

  refreshList() {
    this.getBundleList();
  }

  openBundlingForm() {
    this.reportBundlingService.viewMode = false;
    this.reportBundlingService.selectedBundle = { bundleId: 0, bundleName: '', bundleTypeId: null, reportPackId: null, actions: [] };
    this.router.navigate(['/app/report-bundling/new-report-bundle']);
  }

  downloadGeneratedFile(fileName) {
    this.alertService.info('The requested file will be downloaded in a while.');
    this.reportBundlingService.downloadFile(fileName);
  }

  viewReportBundle(rowData) {
    this.reportBundlingService.viewMode = true;
    this.reportBundlingService.selectedBundle = rowData;
    this.router.navigate(['/app/report-bundling/view-report-bundle']);
  }

  clearFilters() {
    this.createdDateFilters = null;
  }

  closeDateRangeFilter(dateComponent: any, filters: Date[]) {
    if (filters[1]) {
      dateComponent.hideOverlay();
    }
  }
}