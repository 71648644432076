import { Injectable } from '@angular/core';
import { Parameters } from '../../shared/models/parameters.model';
import { SaveAdjusmentParameters } from '../../shared/models/parameters.model';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { AlertService } from './alert.service';
import { AuthenticationService } from '../security/authentication.service';
import { Subject, Observable } from 'rxjs';
import { saveAs } from 'file-saver';

@Injectable()
export class DashboardService {
    /**
    * Type Declarations.
    */
    baseURL: string = environment.apiUrl;
    reportsList: Object = [];
    reportPackIdList: Object = [];
    reportPageList: any = {};
    reportData: any = {};
    saveadjparams: SaveAdjusmentParameters;
    params: Object = {};
    auditData: Object = [];
    exceptionData: Object = [];
    validationData: Object = [];
    formName: string = null;
    reportPackID: number = null;
    repotPackIdSub: any = new Subject<any>();
    selectedReportPack: any;
    reportPageID: string = null;
    reportId: number = null;
    fullFormName: string = null;
    reportAliasName: string = null;
    reports: any;
    reportsuitname: string = null;
    suitename: string = null;
    countryCode: string = null;
    hasReport = true;
    fromReport = false;
    isReviewValidation = false;
    auditProcName: string = null;
    exceptionsProcName: string = null;
    selectedLoadId: number = null;
    selectedFileName: string = null;
    parentReportDisplayName: string = '';
    reportDisplayName: string = '';
    parentFormName: string = '';

    /**
     * Creates an instance.
     */
    constructor(private apiService: ApiService, private alertService: AlertService, private authService: AuthenticationService) { }

    watchReportPackId(): Observable<any> {
        return this.repotPackIdSub.asObservable();
    }

    setReportPack(reportPack: any) {
        this.reportPackID = reportPack.ReportPackID;
        this.selectedReportPack = reportPack;
        this.repotPackIdSub.next(this.reportPackID);
    }

    /**
    * Loads suite reports.
    */
    getSuiteReports(suiteName: string) {
        var params = new Parameters();
        params.reportpackid = !this.reportPackID ? 0 : this.reportPackID;

        const promise = new Promise((resolve, reject) => {
            this.apiService.get('suite/' + suiteName + '/getReports', params)
                .toPromise()
                .then(
                    res => { // Success
                        this.reportsList = res;
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }
    /**
   * Loads sub reports.
   */
    getSubReports(suiteName: string, reportName: string) {
        const params = {
            suiteName: suiteName,
            reportName: reportName,
            reportPackId: !this.reportPackID ? 0 : this.reportPackID
        };

        const promise = new Promise((resolve, reject) => {
            this.apiService.get('suite/getSubReports', params)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }
    /**
 * Loads report pack dash board details
 */
    getReportPackSummary() {
        const params = {
            reportPackId: !this.reportPackID ? 0 : this.reportPackID
        };

        const promise = new Promise((resolve, reject) => {
            this.apiService.get('getReportPackDashboardDetails', params)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }
    /**
    * Loads report packs.
    */
    getReportPacks() {
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('getActiveReportPacks', '', '')
                .toPromise()
                .then(
                    res => { // Success
                        this.reportPackIdList = res;
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }
    /**
    * Loads the data.
    */
    getReportData() {
        const promise = new Promise((resolve, reject) => {
            const params = {
                formname: this.formName,
                reportPackID: this.reportPackID,
                suitename: this.suitename,
                reportId: this.reportId,
                reportAliasName: this.reportAliasName
            };
            this.apiService.get('Adjustment/GetAdjustmentData', params)
                .toPromise()
                .then(
                    res => { // Success
                        this.reportData = res;
                        if (this.reportData) {

                            if (!this.reportData.columnData) {
                                this.alertService.error('Column Data not found.');
                                reject('No columnData');
                            }

                            if (!this.reportData.columnGroups) {
                                this.alertService.error('Column groups not found.');
                                reject('No columnGroups');
                            }

                            if (!this.reportData.columnHeaders) {
                                this.alertService.error('Column headers not found.');
                                reject('No columnHeaders');
                            }

                            if (!this.reportData.reportPageHeaders) {
                                this.alertService.error('Report page headers not found.');
                                reject('No reportPageHeaders');
                            }

                            if (this.reportData.columnData
                                && this.reportData.columnData[0]) {
                                if (this.reportData.columnData[0].hasOwnProperty('reportid') && this.reportData.columnData[0].reportid) {
                                    this.reportId = this.reportData.columnData[0].reportid;
                                }
                                else if (this.reportId == undefined) {
                                    if (this.reportData.reportPageHeaders != undefined && this.reportData.reportPageHeaders.length > 0) {
                                        this.reportId = this.reportData.reportPageHeaders[0].ReportID;
                                    }
                                }

                                this.reportPageList = this.reportData.reportPageHeaders;

                                resolve(res);
                            } else {
                                this.alertService.info('Data is not available for this report. Please ingest new data or prepare the report from the Data Processing section.');
                                reject('No report data Found');
                            }
                        } else {
                            this.alertService.error('No data available.');
                            reject('No data');
                        }
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getReportGridData() {
        const promise = new Promise((resolve, reject) => {
            const params = {
                formname: this.formName,
                reportPackID: this.reportPackID,
                suitename: this.suitename,
                reportId: this.reportId,
                reportAliasName: this.reportAliasName
            };
            this.apiService.get('getReportGridData', params)
                .toPromise()
                .then(
                    res => { // Success
                        this.reportData.columnData = res;
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    /**
     * Fetches data for the validation review grid.
     */
    async getValidationData() {
        var params = {
            reportId: this.reportId,
            reportpackid: this.reportPackID,
            formname: this.formName,
            suitename: this.suitename
        };

        const promise = new Promise((resolve, reject) => {
            this.apiService.post('adjustment/runvalidationrules', params, '')
                .toPromise()
                .then(
                    res => { // Success
                        this.validationData = res;
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    /**
    * Download report
    */

    downloadReport(reportTemplateId){
        var params = {
            reportId: this.reportId,
            reportpackid: this.reportPackID,
            formname: this.formName,
            reportAliasName : this.reportAliasName,
            reportTemplateId : reportTemplateId
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.getFile('downloadReport', params)
                .toPromise()
                .then(
                    res => { // Success
                        this.downLoadFile(res)
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    /**
* Download the change audit report.
*/
    downloadChangeAuditReport() {
        var params = {
            reportId: this.reportId,
            reportPackId: this.reportPackID,
            formName: this.formName,
            reportAlias : this.reportAliasName,
            parentReportDisplayName : this.parentReportDisplayName,
            reportDisplayName: this.reportDisplayName
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.getFile('getchangeauditreport', params)
                .toPromise()
                .then(
                    res => { // Success
                        this.downLoadFile(res)
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    downLoadFile(data: any) {
        let blob = data.body;
        const contentHeader = data.headers.get('content-disposition');
        if (contentHeader !== null && blob !== null) {
            let fileName = data.headers.get('content-disposition').split(';')[1].trim().split('=')[1].replace(/"/g, '');
            saveAs(blob, fileName);
        }
    }

    /**
    * Saves all grid data to the database.
    */
    saveReportData(reportData: any[], reportAudit: any[]) {
        const data = {
            reportData: reportData, reportAudit: reportAudit, reportId: this.reportId, reportPackId: this.reportPackID,
            formname: this.reportAliasName, suitename: this.suitename
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.post('report/saveReportData', data, 'Changes saved successfully!')
                .toPromise()
                .then(
                    res => { // Success
                        console.log('Save call resolved : ' + JSON.stringify(res));
                        resolve(res);
                    },
                    msg => { // Error
                        console.log('Save call rejected: ' + JSON.stringify(msg));
                        reject(msg);
                    }
                );
        });
        return promise;

    }

    getBoxCodeAudit(boxCode: string) {
        const params = {
            reportPackId: this.reportPackID,
            reportId: this.reportId,
            formname: this.formName,
            reportAlias: this.reportAliasName,
            suitename: this.suitename,
            boxCode: boxCode
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('report/getBoxCodeAudit', params)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getAuditExceptionData(reportTemplateId: string) {
        const params = {
            reportPackId: this.reportPackID,
            reportId: this.reportId,
            formname: this.formName,
            reportAlias: this.reportAliasName,
            suitename: this.suitename,
            reportTemplateId: reportTemplateId
        };
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('report/getAuditExceptionData', params)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getAllSuiteReports() {
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('suite/getAllReports', '', '')
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getReportsByReportPack() {
        const params = {
            reportPackId: this.reportPackID
        };

        const promise = new Promise((resolve, reject) => {
            this.apiService.get('suite/getReportsByReportPack', params)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getIngestionErrorFile(loadId, filename) {
        window.location.href = this.baseURL + 'GetIngestionErrorFile?loadId=' + loadId + '&filename=' + filename + '&token=' + this.authService.getTokenHeader();
    }
    

    isReportAssigned(params) {
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('isReportAssigned/', params)
                .toPromise()
                .then(
                    res => {
                        resolve(res);
                    },
                    msg => {
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    hasPermission(params) {
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('hasPermission/', params)
                .toPromise()
                .then(
                    res => {
                        resolve(res);
                    },
                    msg => {
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getApproverComments(param) {
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('getApproverCommentHistory/', param)
                .toPromise()
                .then(
                    res => {
                        resolve(res);
                    },
                    msg => {
                        reject(msg);
                    }
                );
        });
        return promise;
    }
}
