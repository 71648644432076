<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
  <div class="col-12 col-md-6">
    <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
      [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
  </div>
  <div class="col-12 col-md-12 text-left text-md-right">
    <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button" (click)="addRPSequence()">
      <div class="icon-container"><i class="fas fa-play"></i></div><span>Add Report Pack Sequence</span>
    </button>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="report-pack-scroll">
      <p-table #dtrps [value]="reportPackSequenceList" [paginator]="true" [rows]="15" class="table-irr"
        [columns]="headerCols" [scrollable]="true" [scrollHeight]="'calc(100vh - 292px)'">
        <ng-template pTemplate="paginatorleft">
          <button class="btn btn-clear-filter hvr-shrink" type="button" (click)="dtrps.reset();clearFilters();">
            <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
          </button>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field"> {{col.header}}
              <p-sortIcon *ngIf="col.field !=''" [field]="col.field" ariaLabel="Activate to sort"
                ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon>
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns">
              <input *ngIf="col.field !='' && col.field != 'CreatedDate'" type="text" class="w-100"
                placeholder="Filter Text"
                [value]="dtrps.filters && dtrps.filters[col.field] ? dtrps.filters[col.field].value: ''"
                (input)="dtrps.filter($event.target.value, col.field, 'contains')">

              <p-calendar *ngIf="col.field =='CreatedDate'" [(ngModel)]="createdDateFilters" #rdFilter
                selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy" [readonlyInput]="true"
                placeholder="Select a range" appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="{{yearRange}}"
                (ngModelChange)="dtrps.filter(createdDateFilters, col.field, 'dateRangeFilter')"
                (onSelect)="closeDateRangeFilter(rdFilter, createdDateFilters)">
              </p-calendar>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
          <tr>
            <td>{{rowData['RPSequenceName']}}</td>
            <td>{{rowData['Description']}}</td>
            <td class="report-pack-link" (click)='openMappedReportPackModal(mappedReportPacksModal, rowData)'>Reports
              Packs</td>
            <td>{{rowData['CreatedBy']}}</td>
            <td>{{rowData['CreatedDate'] | date: 'dd/MM/yyyy'}}</td>
            <td>
              <button type="button" *ngIf="rowData.IsActive" class="btn-table-icon hvr-shrink" container="body"
                ngbTooltip="Edit Report Pack Sequence" id="editRPSequence" triggers="hover"
                (click)="editRPSequence(rowData)">
                <i class="fas fa-edit fa-lg"></i>
              </button>
              <button type="button" *ngIf="!rowData.IsActive" class="disable-edit-button btn-table-icon hvr-shrink"
                container="body" id="editRPSequence"
                ngbTooltip="This report pack sequence cannot be edited, as it is inactive." triggers="hover"
                tooltipClass="reporting-tooltip">
                <i class="fas fa-edit fa-lg"></i>
              </button>
              <button *ngIf="rowData.IsActive && !rowData.IsDefaultSequence" class="btn-table-icon hvr-shrink"
                type="button" (click)="openPromptModal(rowData)" ngbTooltip="Deactivate Report Pack Sequence"
                container="body" tooltipClass="reporting-tooltip">
                <i Class="fas fa-eye-slash"></i>
              </button>
              <button *ngIf="rowData.IsActive && rowData.IsDefaultSequence"
                class="disable-edit-button btn-table-icon hvr-shrink"
                ngbTooltip="This report pack sequence cannot be deactivated, as it is marked as default requence for one or more report packs."
                container="body" tooltipClass="reporting-tooltip">
                <i Class="fas fa-eye-slash"></i>
              </button>
              <button *ngIf="!rowData.IsActive" class="btn-table-icon hvr-shrink" type="button"
                (click)="openPromptModal(rowData)" ngbTooltip="Activate Report Pack Sequence" container="body"
                tooltipClass="reporting-tooltip">
                <i Class="fas fa-eye"></i>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<ng-template #mappedReportPacksModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title d-flex align-items-center m-0 w-100">Mapped Report Packs
      <i class="fas fa-times ml-auto cursor-pointer" aria-label="Close" (click)="closeMappedReportPackModal()">
      </i>
    </h5>
  </div>
  <div class="modal-body reports-modal-container" *ngIf="mappedReportPackList.length > 0">
    <p-table [value]="mappedReportPackList" [scrollable]="true" class="table-irr pack-admin"
      [tableStyle]="{ 'min-width': '45rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th>Report Pack ID</th>
          <th>Report Pack Title</th>
          <th>Description</th>
          <th>Report Date</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-reportPack>
        <tr>
          <td>{{ reportPack.ReportPackId }}</td>
          <td>{{ reportPack.ReportPackTitle }}</td>
          <td>{{ reportPack.ReportPackDescription }}</td>
          <td>{{ reportPack.ReportDate | date: 'dd/MM/yyyy'}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="modal-body reports-modal-container" *ngIf="mappedReportPackList.length == 0">
    {{'No ReportPack is mapped to this report sequence'}}
  </div>
</ng-template>