<nav class="navbar navbar-expand-lg navbar-light">
  <a class="navbar-brand">
    <img src="assets/images/EY-Logo-White.png" class="d-inline-block align-top ey-logo mr-3">
    <b>I</b>ntegrated <b>R</b>egulatory <b>R</b>eporting
  </a>
  <div class="mr-auto ml-sm-auto mr-sm-4 d-inline-block">
    <div class="clientEntityEnvironmentName">{{currentClientEntityEnvironmentName}}</div>
  </div>
  <div class="mr-auto ml-sm-auto mr-sm-0 d-inline-block">
    <div ngbDropdown class="d-inline-block float_right" placement="bottom-right" display="dynamic">
      <button class="dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle>Welcome
        {{ userNameOrEmail }} <i class="fas fa-chevron-down ml-2"></i> </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" placement="bottom-right">
        <div *ngIf="clientEntityEnvironments.length>0">
          <button ngbDropdownItem *ngFor="let env of clientEntityEnvironments"
            (click)="switchClientEntityEnvironment(env)">Switch to
            {{env.ClientEntityEnvironmentName}}</button>
        </div>
        <button ngbDropdownItem (click)="onSignOut()">{{ logOutButtonText }}</button>
      </div>
    </div>
    <div *ngIf="hasSupportAccess" class="float_right" placement="bottom-right" display="dynamic">
      <div class="dbname-font-weight" style="float: right;" placement="bottom-right">{{clientName}}</div>
    </div>
  </div>
</nav>