import { PromptModalComponent } from 'src/app/shared/prompt-modal/prompt-modal.component';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/core/services/alert.service';
import { ReportScheduleService } from 'src/app/core/services/reportSchedule.service';
import { Subscription } from 'rxjs';
import { FilterUtils } from 'primeng/utils';
import { CommonService } from 'src/app/core/services/common.service';
/**
 * Component
 */
@Component({
    selector: 'app-report-schedule-management',
    templateUrl: './report-schedule-management.component.html',
    styleUrls: ['./report-schedule-management.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ReportScheduleManagementComponent implements OnInit, OnDestroy {
    frequency: any[];
    reportScheduleList: any[];
    cols: any[];
    loading = false;
    action = '';
    selectedReports: any = '';
    selectedfrequency = '';
    selectedReport = '';
    selectedReportDisplayName = '';
    selectedSuite = '';
    CountryDependent = false;
    days: any;
    startDate: Date = new Date();
    promptResponse: Subscription;
    reportDateFilters: Date[];
    minDate: Date = new Date();
    private dateTime = new Date();
    private yearRange = "2000:" + this.dateTime.getFullYear().toString();
    private deleteFlag = false;
    constructor(private modalService: NgbModal,
        private reportScheduleService: ReportScheduleService,
        private alertService: AlertService,
        private commonService: CommonService) {
        this.minDate.setDate(new Date().getDate());
    }

    ngOnInit() {
        this.frequency = [
            { label: 'Yearly', value: 'Y' },
            { label: 'Monthly', value: 'M' },
            { label: 'Weekly', value: 'W' },
            { label: 'Daily', value: 'D' },
            { label: 'Other', value: 'O' }
        ];

        this.cols = [
            { field: 'SuiteName', header: 'Suite Name' },
            { field: 'ReportDisplayName', header: 'Report Name' },
            { field: 'Frequency', header: 'Frequency' },
            { field: 'Day', header: 'No Of Days' },
            { field: 'StartDate', header: 'Start Date' }
        ];

        FilterUtils['dateRangeFilter'] = (value, filter: [Date, Date]): boolean => {
            if (!filter || (!filter[0] && !filter[1]))
                return true;

            var val = new Date(value);
            var s = filter[0].getTime();
            var e;
            if (filter[1])
                e = filter[1].getTime() + (24 * 60 * 60 * 1000) - 1000;
            else e = s + (24 * 60 * 60 * 1000) - 1000;
            return val.getTime() >= s && val.getTime() <= e;
        }

        this.promptResponse = this.commonService.watchPrompt().subscribe(response => {
            this.modalService.dismissAll();
            if (this.deleteFlag == true && response.response === 'Yes' && response.callFor === 'deleteRS') {
                this.deleteFlag = false;
                this.triggerDelete();

            }
        });

        this.getReportSchedule();
    }

    ngOnDestroy(): void {
        this.modalService.dismissAll();
    }

    openSchedule(reportSchedule, rowData, action) {
        this.action = action;
        this.selectedReports = '';
        if (action === 'Edit') {
            var editFreq = this.frequency.find(x => x.label === rowData.Frequency);
            this.selectedfrequency = editFreq['value'];
            this.days = rowData.Day;
            this.startDate = new Date(rowData.StartDate);
            this.selectedReport = rowData.ReportShortName;
            this.selectedReportDisplayName = rowData.ReportDisplayName;
            this.selectedSuite = rowData.SuiteName;
            this.CountryDependent = rowData.CountryDependent;

            this.modalService.open(reportSchedule, {
                size: 'lg', windowClass: 'animated fadeInDown fast',
                scrollable: true, backdrop: 'static'
            });
        }
        else if (action === 'Add') {
            this.selectedfrequency = null;
            this.days = 0;
            this.startDate = new Date();
            this.modalService.open(reportSchedule, {
                size: 'lg', windowClass: 'animated fadeInDown fast',
                scrollable: true, backdrop: 'static'
            });
        }
    }

    getReportSchedule() {
        this.loading = true;
        this.reportScheduleService.getReportScheduleList()
            .then((reportSchedule: any) => {
                this.reportScheduleList = reportSchedule;
                this.loading = false;
            }).catch(() => { this.loading = false; });
    }

    submitReportSchedule() {

        if (this.selectedfrequency === null || this.selectedfrequency === '') {
            this.alertService.error('Please select the frequency'); return;
        }
        if (this.selectedfrequency === 'O' && (this.days === '0' || this.days === undefined || this.days < 0)) {
            this.alertService.error('No of days should be number and greater than 0'); return;
        }
        if (this.action != 'Edit' && (this.selectedReports === null || this.selectedReports == '')) {
            this.alertService.error('Please select report to schedule'); return;
        }
        if (this.selectedfrequency !== 'O') {
            this.days = 0;
        }
        
        var freq = this.frequency.find(x => x.value === this.selectedfrequency);
        this.loading = true;
        // Applied date format - Bug 927149
        const param = {
            reports: this.action == 'Add' ? this.selectedReports : this.selectedReport,
            Frequency: freq['label'],
            Day: this.days,
            IsActive: true,
            StartDate: (this.startDate.getMonth() + 1) + '/' + this.startDate.getDate() + '/' + this.startDate.getFullYear()
        }
        if (this.action === 'Add') {
            this.reportScheduleService.submitReportSchedule(param, 'Schedule created successfully').then((reportSchedule: any) => {
                this.modalService.dismissAll();
                this.getReportSchedule();
            }).catch(() => { this.loading = false; });
        } else if (this.action === 'Edit') {
            this.reportScheduleService.EditReportSchedule(param, 'Schedule edited successfully').then((reportSchedule: any) => {
                this.modalService.dismissAll();
                this.getReportSchedule();
            }).catch(() => { this.loading = false; });
        }
    }

    deleteReportSchedule(rowData) {
        this.selectedReport = rowData.ReportShortName;
        this.selectedSuite = rowData.SuiteName;
        this.selectedfrequency = rowData.Frequency;
        this.days = rowData.Day;
        this.startDate = new Date(rowData.StartDate);

        this.openPromptModal();
    }

    openPromptModal() {
        this.deleteFlag = true;
        var openModal = this.modalService.open(PromptModalComponent, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
        openModal.componentInstance.promptMessage = 'Are you sure, want to delete the selected report schedule?';
        openModal.componentInstance.promptCallFor = 'deleteRS';
    }

    triggerDelete() {

        this.loading = true;

        const param = {
            ReportShortName: this.selectedReport,
            SuiteName: this.selectedSuite,
            Frequency: this.selectedfrequency,
            Day: this.days,
            IsActive: false,
            StartDate: this.startDate
        }

        this.reportScheduleService.deleteReportSchedule(param).then((reportSchedule: any) => {
            this.getReportSchedule();
        }).catch(() => { this.loading = false; });
    }

    clearFilters() {
        this.reportDateFilters = null;
    }

    closeDateRangeFilter(dateComponent: any, filters: Date[]) {
        if (filters[1]) {
            dateComponent.hideOverlay();
        }
    }

    clearPopup() {
        this.selectedfrequency = '';
        this.days = 0;
        this.startDate = new Date();
        this.selectedReports = '';
    }
}
