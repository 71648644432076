<i class="fas fa-bars hamburger-menu d-block d-sm-block d-md-block d-lg-block d-xl-none cursor-pointer"
    (click)="showHamburgerSidenav()"></i>
<div class="side-navbar-left"
    [ngClass]="hamburgerSidenavMenu == true ? 'hamburger-sidenav animated slideInLeft' : 'd-none d-sm-none d-md-none d-lg-none d-xl-block'">
    <i class="fas fa-times fa-lg text-white cursor-pointer p-3 d-block d-sm-block d-md-block d-lg-block d-xl-none"
        (click)="closeHamburgerSidenav()"></i>
    <div class="sidenav-overflow" data-simplebar data-simplebar-auto-hide="false">
        <ngb-accordion [closeOthers]="accordionClose" [activeIds]="accordionActive">
            <ngb-panel id="menu-1" *appPermission="['V', null, null, 'reporting']">
                <ng-template ngbPanelTitle>
                    <div class="d-flex align-items-center">
                        <span class="dual-header"><b>R</b>eporting</span>
                        <i class="fas ml-auto custom-menu-icon"></i>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="row mx-0 animated fadeInDown fast">
                        <div class="col-6 pr-1 sidenav-item-parent">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/task-board']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-review-approval" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="Taskboard" container="body"></i>
                                    <span class="w-100 text-center mt-2"> Taskboard</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 pl-1 sidenav-item-parent">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/reportpack-dashboard']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-report-dashboard" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="Report Pack Dashboard" container="body"></i>
                                    <span class="w-100 text-center mt-2"> Report Pack Dashboard</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 pr-1 sidenav-item-parent">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/suite']" routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-suites" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="Suites" container="body"></i>
                                    <span class="w-100 text-center mt-2">Suites</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="menu-2" *appPermission="['I',null,null,'ingestion|report-bundling']">
                <ng-template ngbPanelTitle>
                    <div class="d-flex align-items-center">
                        <span class="dual-header"><b>D</b>ata <b>P</b>rocessing</span>
                        <i class="fas ml-auto custom-menu-icon"></i>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="row mx-0 animated fadeInDown fast">
                        <div class="col-6 pr-1 sidenav-item-parent" *appPermission="['I',null,null,'ingestion']">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/data-ingestion']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-data-ingestion" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="Data Ingestion" container="body"></i>
                                    <span class="w-100 text-center mt-2">Data Load</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 pl-1 sidenav-item-parent" *appPermission="['I',null,null,'ingestion']">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/bulk-report-run']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-data-admin" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="Bulk Report Run" container="body"></i>
                                    <span class="w-100 text-center mt-2">Bulk Report Run</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 pr-1 sidenav-item-parent" *appPermission="['B',null,null,'report-bundling']">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/report-bundling']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-report-bundling" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="Report Bundling" container="body"></i>
                                    <span class="w-100 text-center mt-2">Report Bundling</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="menu-3" *appPermission="['A',null,null,'administration']">
                <ng-template ngbPanelTitle>
                    <div class="d-flex align-items-center">
                        <span class="dual-header"><b>A</b>dministration </span>
                        <i class="fas ml-auto custom-menu-icon"></i>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="row mx-0 animated fadeInDown fast">
                        <div class="col-6 pr-1 sidenav-item-parent">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/user-management']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-user-mgmt" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="User Management" container="body"></i>
                                    <span class="w-100 text-center mt-2">User Management</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 pl-1 sidenav-item-parent">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/reportpack-management']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-report-pack" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="Report Pack Administration" container="body"></i>
                                    <span class="w-100 text-center mt-2">Report Pack Administration</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 pr-1 sidenav-item-parent">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/permission-management']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-approval-level-mgmt" [disableTooltip]="tooltipDisable"
                                        placement="right" ngbTooltip="Approval Level Management" container="body"></i>
                                    <span class="w-100 text-center mt-2">Approval Level Management</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 pl-1 sidenav-item-parent">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/role-management']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-role-mgmt" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="Role Management" container="body"></i>
                                    <span class="w-100 text-center mt-2">Role Management</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 pr-1 sidenav-item-parent">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/report-schedule-management']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-report-scheduler-mgmt" [disableTooltip]="tooltipDisable"
                                        placement="right" ngbTooltip="Report Schedule Management" container="body"></i>
                                    <span class="w-100 text-center mt-2">Report Schedule Management</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 pl-1 sidenav-item-parent">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/bundle-type-management']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-bundle-type-mgmt" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="Bundle Type Management" container="body"></i>
                                    <span class="w-100 text-center mt-2">Bundle Type Management</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 pr-1 sidenav-item-parent">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/report-pack-sequence-management']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-report-bundling" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="Report Pack Sequence Management" container="body"></i>
                                    <span class="w-100 text-center mt-2">Report Pack Sequence Management</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 pl-1 sidenav-item-parent">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/application-configuration']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-bundle-type-mgmt" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="Application Configuration" container="body"></i>
                                    <span class="w-100 text-center mt-2">Application Configuration</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="menu-4"
                *ngIf='displayAllAnalyticsTile || (menuPowerBiReports && menuPowerBiReports.length > 0)'>
                <ng-template ngbPanelTitle>
                    <div class="d-flex align-items-center">
                        <span class="dual-header"><b>A</b>nalytics </span>
                        <i class="fas ml-auto custom-menu-icon"></i>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="row mx-0 animated fadeInDown fast">
                        <div class="col-6 pr-1 sidenav-item-parent" *ngIf='displayAllAnalyticsTile'>
                            <div class="card sidenav-menu-item" [routerLink]="['/app/all-analytics']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-all-analytics" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="All Analytics" container="body"></i>
                                    <span class="w-100 text-center mt-2"> All Analytics</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="!i || i % 2 == 0 ? 'col-6 pl-1 sidenav-item-parent' : 'col-6 pr-1 sidenav-item-parent'"
                            *ngFor='let rep of menuPowerBiReports;let i = index;'>
                            <div class="card sidenav-menu-item"
                                [routerLink]="['/app/analytics-report-dashboard', rep.DisplayName]"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="{{rep.ReportLogoContent}}" [disableTooltip]="tooltipDisable"
                                        placement="right" ngbTooltip="{{rep.DisplayName}}" container="body"></i>
                                    <span class="w-100 text-center mt-2"> {{rep.DisplayName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="menu-5" *appPermission="['S',null,null,'support']">
                <ng-template ngbPanelTitle>
                    <div class="d-flex align-items-center">
                        <span class="dual-header"><b>S</b>upport </span>
                        <i class="fas ml-auto custom-menu-icon"></i>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="row mx-0 animated fadeInDown fast">
                        <div class="col-6 pr-1 sidenav-item-parent">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/client-management']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-user-mgmt" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="Client Management" container="body"></i>
                                    <span class="w-100 text-center mt-2">Client Management</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 pr-1 sidenav-item-parent">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/report-downloader']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-user-mgmt" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="Report Downloader" container="body"></i>
                                    <span class="w-100 text-center mt-2">Report Downloader</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 pr-1 sidenav-item-parent">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/query-engine']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-user-mgmt" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="Query Engine" container="body"></i>
                                    <span class="w-100 text-center mt-2">Query Engine</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 pr-1 sidenav-item-parent">
                            <div class="card sidenav-menu-item" [routerLink]="['/app/ref-data']"
                                routerLinkActive="active">
                                <div class="card-body d-flex align-items-center justify-content-center flex-column p-2">
                                    <i class="icon-user-mgmt" [disableTooltip]="tooltipDisable" placement="right"
                                        ngbTooltip="Ref Data" container="body"></i>
                                    <span class="w-100 text-center mt-2">Ref Data</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
</div>