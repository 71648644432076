import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { RoleManagementService } from 'src/app/core/security/role-management.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { CommonService } from 'src/app/core/services/common.service';
import { SupportService } from 'src/app/core/services/support.service';
import { PromptModalComponent } from 'src/app/shared/prompt-modal/prompt-modal.component';

@Component({
  selector: 'app-client-management.',
  templateUrl: './client-management.component.html',
  styleUrls: ['./client-management.component.css']
})
export class ClientManagementComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  promptResponse: Subscription;
  headerCols: any;
  treeList: any;
  gridTreeList: any;
  selectedClientEnvironment: any;

  selectedRowForEdit: any;

  constructor(private supportService: SupportService,
    private commonService: CommonService,
    private alertService: AlertService,
    private router: Router,
    private roleManagementService: RoleManagementService,
    private modalService: NgbModal,
    private authService: AuthenticationService) { }

  ngOnInit() {

    if (!this.roleManagementService.checkPermissionsForMenu('S', null, null, 'support')) {
      this.router.navigate(['/home'])
        .then(() => {
          window.location.reload();
        });
    }

    this.promptResponse = this.commonService.watchPrompt().subscribe(confirmation => {
      this.modalService.dismissAll();
      if (confirmation.callFor === 'switchClientEnvironment') {
        if (confirmation.response == 'Yes') {
          this.switchClientEnvironment();
        }
        else this.selectedClientEnvironment = null;
      }
    });

    this.headerCols = [
      { field: 'Name', header: 'Client/Client Entity/Environmet Name' }
    ];

    this.getClientList();
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  private getClientList() {
    this.loading = true;
    this.supportService.getClientTreeList().then((clientTreeList: any[]) => {
      this.treeList = JSON.parse(JSON.stringify(clientTreeList));
      this.gridTreeList = JSON.parse(JSON.stringify(clientTreeList));
      this.loading = false;
    }).catch(() => { this.loading = false; });
  }

  confirmClientSwitch(rowData) {
    this.selectedClientEnvironment = JSON.parse(JSON.stringify(rowData));
    var openModal = this.modalService.open(PromptModalComponent, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
    openModal.componentInstance.promptCallFrom = 'support';
    openModal.componentInstance.promptCallFor = 'switchClientEnvironment';
    openModal.componentInstance.promptMessage = 'Are you sure you want to switch to ' + rowData.Name + '? Please note that if \'Yes\' is selected, the application will reload automatically.';
  }

  switchClientEnvironment() {
    this.loading = true;
    this.supportService.switchClientEntityEnvironment(this.selectedClientEnvironment.Id).then(data => {
      window.location.href = "/";
    }).catch(() => { this.loading = false; });
  }

  openModifyNamePopUp(modifyNameModal, rowData) {
    this.selectedRowForEdit = JSON.parse(JSON.stringify(rowData));
    this.selectedRowForEdit.ChangedName = this.selectedRowForEdit.Name;
    this.modalService.open(modifyNameModal, { size: 'lg', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
  }

  clearChanges() {
    this.selectedRowForEdit.ChangedName = this.selectedRowForEdit.Name;
  }

  submitChanges() {
    if (this.selectedRowForEdit.ChangedName !== this.selectedRowForEdit.Name) {
      this.loading = true;
      this.supportService.updateClientDetails(this.selectedRowForEdit).then
        (() => {
          this.selectedRowForEdit = null;
          this.modalService.dismissAll();
          this.getClientList();
          this.loading = false;
        }).catch(() => { this.loading = false; });
    }
    else {
      this.alertService.info('No changes to save!');
    }
  }
}
