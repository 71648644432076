import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class AnalyticService {

    token: Object = [];
    pnpReportSelectionList: any;
    pnpFilterList: any;
    analyticsReports: any;

    /**
     * Creates an instance of ingestion service.
     */
    constructor(private apiService: ApiService) { }

    async getPowerBIToken(rname) {
        var param = {
            reportName: rname
        };

        const promise = new Promise((resolve, reject) => {
            this.apiService.get('reportAnalytics/getPowerBIReportEmbedInfo', param)
                .toPromise()
                .then(
                    res => { // Success
                        this.token = res;
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getReportSelectionForPnp() {
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('getPnpReportSelection', '', '')
                .toPromise()
                .then(
                    res => { // Success
                        this.pnpReportSelectionList = res;
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getFiltersForPnp(reportId: number, period1Id: number, period2Id: number) {
        const params = {
            reportId: reportId,
            period1Id: period1Id,
            period2Id: period2Id,
        };
      
        const promise = new Promise((resolve, reject) => {
            this.apiService.get('getPnpFilterList', params)
                .toPromise()
                .then(
                    res => { // Success
                        this.pnpFilterList = res;
                        this.pnpFilterList.reportId = reportId;
                        resolve(this.pnpFilterList);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getPnpReport(reportId: number, period1Id: number, period2Id: number, rowFilter: string, columnFilter: any) {
        const data = { reportId: reportId, period1Id: period1Id, period2Id: period2Id, rowFilter: rowFilter, columnFilter: columnFilter };

        const promise = new Promise((resolve, reject) => {
            this.apiService.post('getPnpReport', data, '')
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    GetPowerBiAnalyticsReport() {
            const promise = new Promise((resolve, reject) => {
            this.apiService.get('reportAnalytics/GetPowerBiAnalyticsReport', null)
                .toPromise()
                .then(
                    res => { // Success
                        this.analyticsReports = res;
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }
}