<div class="container-fluid landing-component h-100">
    <img src="assets/images/EY-Logo-White.png" class="ey-logo">
    <img src="assets/images/IRR-Logo3.svg" class="irr-logo">
    <div class="row h-100">
        <div
            class="col-lg-9 main-graphic d-flex align-items-center justify-content-center flex-column text-center order-2 order-sm-2 order-md-2 order-lg-1 order-xl-1">
            <h1 class="loading-app-name mb-5 mt-lg-5 mt-xl-5"> <b>I</b>ntegrated <b>R</b>egulatory <b>R</b>eporting</h1>
            <img src="assets/images/IRR-Landing-Graphic-New.svg" class="landing-img-main" />
        </div>
        <div
            class="col-lg-3 d-flex align-items-center justify-content-center side-content flex-column order-1 order-sm-1 order-md-1 order-lg-2 order-xl-2">
            <!-- Task - 958173  -->
            <p class="px-3 pb-2 mb-0 text-center" >
                Hello <b class="text-yellow">{{user === null ? "" :  user.username }} !</b>
            </p>
            <p class="px-3 text-center">
                Welcome to EY Integrated Regulatory Reporting.​
            </p>
            <p class="p-3 text-center">
                Please click <a class="custom-a" [routerLink]="['/privacy-notice']">here</a> to review the data privacy
                notice.
            </p>
            <button class="btn btn-secondary-ey btn-icon hvr-shrink" type="button" (click)="getStarted()"
                [disabled]="!user">
                <div class="icon-container"><i class="fas fa-arrow-right fa-lg"></i></div><span>Get Started</span>
            </button>
        </div>
    </div>
</div>