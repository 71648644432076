import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AnalyticService } from 'src/app/core/services/analytics.service';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
    selector: 'app-pnp-report-selection',
    templateUrl: './report-selection.component.html',
    styleUrls: ['./report-selection.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PnpReportSelectionComponent implements OnInit {
    @Input() suiteId: number;
    @Input() reportId: number;
    suiteList: any;
    reportList: any;
    @Output() suiteIdChange = new EventEmitter();
    @Output() reportIdChange = new EventEmitter();
    @Output() nextTab = new EventEmitter();
    suiteSelectList: any;
    reportSelectList: any;
    selectedSuite: any;
    selectedReport: any;
    loading: boolean = false;
    suiteCols: any;
    reportCols: any;
    refreshSuiteList: any;
    refreshReportList: any;

    constructor(private analyticsService: AnalyticService, private alertService: AlertService) { }

    ngOnInit() {

        this.suiteCols = [
            { field: 'reportSuiteAbbr', header: 'Suite Name' },
            { field: 'subHeader', header: 'Suite Description' }
        ];

        this.reportCols = [
            { field: 'ReportDisplayName', header: 'Report Name' },
            { field: 'reportName', header: 'Report Description' }
        ];


        this.getSelectionList();
    }

    getSelectionList() {
        if (this.analyticsService.pnpReportSelectionList) {
            var selectionList = this.analyticsService.pnpReportSelectionList;
            this.suiteList = JSON.parse(JSON.stringify(selectionList.SuiteList));
            this.reportList = JSON.parse(JSON.stringify(selectionList.ReportList));
            this.UpdateSelection();
        }
        else {
            this.loading = true;
            this.analyticsService.getReportSelectionForPnp().then((selectionList: any) => {
                this.suiteList = JSON.parse(JSON.stringify(selectionList.SuiteList));
                this.reportList = JSON.parse(JSON.stringify(selectionList.ReportList));
                this.UpdateSelection();
                this.loading = false;
            }).catch(() => { this.loading = false; });
        }
    }

    UpdateSelection() {
        this.suiteSelectList = JSON.parse(JSON.stringify(this.suiteList));
        this.reportSelectList = JSON.parse(JSON.stringify(this.reportList));
        if (this.suiteId) {
            var suite = this.suiteSelectList.filter(x => x.reportSuiteId == this.suiteId)[0];
            this.suiteSelect(suite);
        }
        if (this.reportId) {
            var report = this.reportSelectList.filter(x => x.reportId == this.reportId)[0];
            this.reportSelect(report);
        }
    }

    suiteSelect(suite: any) {
        if (suite.reportSuiteId) {
            if (!this.selectedSuite) {
                this.selectedSuite = suite;
                let index = this.suiteSelectList.indexOf(this.selectedSuite);
                this.suiteSelectList.splice(index, 1);
                this.refreshSuiteList = this.suiteSelectList;
                this.suiteId = this.selectedSuite.reportSuiteId;
                var filteredReportList = this.reportList.filter(x => x.suiteId == this.suiteId);
                this.reportSelectList = JSON.parse(JSON.stringify(filteredReportList));
                this.suiteIdChange.emit(this.suiteId);
            }
            else this.alertService.warn('Please remove the selected suite to add a new one!');
        }

        this.suiteSelectList = JSON.parse(JSON.stringify(this.refreshSuiteList));
    }

    suiteUnselect() {
        this.suiteSelectList = JSON.parse(JSON.stringify(this.suiteList));
        this.selectedSuite = null;
        this.suiteId = null;
        this.suiteIdChange.emit(this.suiteId);
        this.reportUnselect();
    }

    reportSelect(report: any) {
        if (report.reportId) {
            if (!this.selectedReport) {
                this.selectedReport = report;
                let index = this.reportSelectList.indexOf(this.selectedReport);
                this.reportSelectList.splice(index, 1);
                this.refreshReportList = this.reportSelectList;
                this.reportId = this.selectedReport.reportId;
                this.reportIdChange.emit(this.reportId);
            }
            else this.alertService.warn('Please remove the selected report to add a new one!');
        }

        this.reportSelectList = JSON.parse(JSON.stringify(this.refreshReportList));
    }

    reportUnselect() {
        this.reportSelectList = JSON.parse(JSON.stringify(this.reportList.filter(x => x.suiteId == this.suiteId)));
        this.selectedReport = null;
        this.reportId = null;
        this.reportIdChange.emit(this.reportId);
    }

    goToNextTab() {
        this.nextTab.emit(true);
    }
}
