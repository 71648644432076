<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
    <div class="col-12 col-md-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
    </div>
    <div class="col-12 col-md-6 text-left text-md-right">
        <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button"
            (click)="openSchedule(reportSchedule,null,'Add')">
            <div class="icon-container"><i class="far fa-calendar-alt fa-lg"></i></div><span>Create Report
                Schedule</span>
        </button>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="schedule-report-scroll">
            <p-table #rptSchList [value]="reportScheduleList" [paginator]="true" [rows]="12"
                class="table-irr table-report-schedule" [columns]="cols" [scrollable]="true"
                [scrollHeight]="'calc(100vh - 292px)'">
                <ng-template pTemplate="paginatorleft">
                    <button class="btn btn-clear-filter hvr-shrink" type="button"
                        (click)="rptSchList.reset();clearFilters();">
                        <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                    </button>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order">
                            </p-sortIcon>
                        </th>
                        <th>Actions</th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns">
                            <p-calendar *ngIf="col.field =='StartDate'" [(ngModel)]="reportDateFilters"
                                selectionMode="range" #rdFilter [showIcon]="true" dateFormat="dd/mm/yy"
                                [readonlyInput]="true" placeholder="Select a range" appendTo="body"
                                styleClass="table-calendar"
                                (ngModelChange)="rptSchList.filter(reportDateFilters, col.field, 'dateRangeFilter')"
                                [monthNavigator]="true" [yearNavigator]="true" yearRange="{{yearRange}}"
                                (onSelect)="closeDateRangeFilter(rdFilter, reportDateFilters)"></p-calendar>

                            <input *ngIf="col.field !='StartDate'" type="text" class="w-100" placeholder="Filter Text"
                                [value]="rptSchList.filters && rptSchList.filters[col.field] ? rptSchList.filters[col.field].value: ''"
                                (input)="rptSchList.filter($event.target.value, col.field, 'contains')">
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.SuiteName}}</td>
                        <td>{{rowData.ReportDisplayName}}</td>
                        <td>{{rowData.Frequency}}</td>
                        <td>{{rowData.Day}}</td>
                        <td>{{rowData.StartDate | date: 'dd/MM/yyyy'}}</td>
                        <td>
                            <button type="button" class="btn-table-icon hvr-shrink" container="body"
                                ngbTooltip="Edit Report Schedule" id="editReportSchedule"
                                (click)="openSchedule(reportSchedule,rowData,'Edit')" triggers="hover">
                                <i class="fas fa-edit fa-lg"></i>
                            </button>
                            <button type="button" class="btn-table-icon hvr-shrink" container="body"
                                id="deleteReportSchedule" ngbTooltip="Delete Report Schedule"
                                (click)='deleteReportSchedule(rowData)' triggers="hover">
                                <i class="fas fa-trash-alt fa-lg"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<ng-template #reportSchedule let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">
            {{action === 'Add'? 'Create Report Schedule' : 'Edit Report Schedule' }}<i
                class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="modal.dismiss('Cross click')"></i>
        </h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <form class="material-styling w-100 mt-3 d-lg-flex align-items-lg-center justify-content-lg-center">
                    <div class="group mt-5 mt-lg-3 w-50 mr-lg-5">
                        <p-dropdown [options]="frequency" placeholder="Select a Frequency"
                            [(ngModel)]="selectedfrequency" name="frequency" appendTo="body">
                        </p-dropdown>
                        <label class="non-input-label">Frequency</label>
                    </div>
                    <div class="group mt-4 w-50 mt-lg-3 mr-lg-5" *ngIf='selectedfrequency === "O"'>
                        <input type="text" required class="w-100" name="days" id="days" [(ngModel)]='days'
                            #noOfDays="ngModel">
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Days</label>

                        <div *ngIf="noOfDays.invalid && (noOfDays.dirty || noOfDays.touched)"
                            class="alert alert-danger">
                            <div *ngIf="noOfDays.errors.required">
                                Please enter the number of days.
                            </div>
                        </div>

                    </div>
                    <div class="group mt-4 w-50 mt-lg-4">
                        <p-calendar [showIcon]="true" name="sDate" #sDate="ngModel" id="sDate" [monthNavigator]='true'
                            [yearNavigator]='true' [(ngModel)]='startDate' yearRange="{{yearRange}}"
                            dateFormat="dd/mm/yy" appendTo="body" [minDate]="minDate">
                        </p-calendar>
                        <label class="non-input-label">Start Date</label>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-12" *ngIf='action === "Edit"'>
                <label class="non-input-label">Report Name: {{selectedReportDisplayName}}</label>
                <label class="non-input-label ml-3">Suite Name: {{selectedSuite}}</label>
            </div>
            <div class="col-12" *ngIf='action === "Add"'>
                <app-report-tree [(selectedReports)]="selectedReports"></app-report-tree>
            </div>
        </div>
    </div>
    <div class="modal-footer text-right">
        <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button" (click)="clearPopup()"
            id='clearCreateSchedule'>
            <span>Clear</span>
        </button>
        <button class="btn btn-modal-pos small-font hvr-shrink" type="button" (click)='submitReportSchedule()'
            id='createSchedule'>
            <span>Submit</span>
        </button>
    </div>
</ng-template>