import { CommonService } from 'src/app/core/services/common.service';
import { Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SuiteService } from 'src/app/core/services/suite.service';
import { AnalyticService } from 'src/app/core/services/analytics.service';
import { environment } from 'src/environments/environment';

/**
 * Sidebar Component
 */
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit, OnChanges, OnDestroy {
    sidebarServiceValue: boolean;
    accordionClose: any;
    accordionActive: any;
    tooltipDisable: any;
    accordionMenu1: boolean;
    accordionMenu2: boolean;
    accordionMenu3: boolean;
    accordionMenu4: boolean;
    showLoading: boolean = false;
    hamburgerSidenavMenu = false;
    analyticsPowerBiReports: any[];
    displayAllAnalyticsTile: boolean = false;
    menuPowerBiReports: any[];

    /**
     * Router Subscription
     */
    router_sub: Subscription;

    /**
     * Menu Url
     */
    menuUrl: string;

    /**
     * Suite url
     */
    suite: string;

    /**
     * Sub report url
     */
    subreports: string;

    constructor(
        private router: Router,
        private suiteService: SuiteService, private sidebarShared: CommonService,
        private analyticService: AnalyticService
    ) {
        this.menuUrl = router.url;
        this.router_sub = router.events.subscribe((val: NavigationEnd) => {
            if (val.url !== undefined) {
                this.menuUrl = val.url;
                const splitUrl = this.menuUrl.split('/');
                if (splitUrl.length && splitUrl.length > 2 && splitUrl[1] === 'suite') {
                    this.suite = this.suiteService.getCurrentSuite();
                }
                else {
                    this.suite = this.suiteService.getLatestSuite();
                }

                if (splitUrl.length && splitUrl.length === 5 && splitUrl[4] != 'subreports' && splitUrl[1] === 'suite') {
                    this.subreports = splitUrl[3].toString();
                }
                else {
                    this.subreports = "";
                }
            }
        });
    }


    ngOnInit() {
        this.accordionActive = "menu-1";
        this.tooltipDisable = true;
        this.accordionClose = true;

        this.sidebarShared.watchShowPanel().
            subscribe(
                data => {
                    //Denotes sidebar toggle is true (small width)
                    if (data == true) {
                        this.accordionClose = false;
                        this.accordionActive = "menu-1,menu-2,menu-3,menu-4";
                        this.tooltipDisable = false;
                    } else
                    //Denotes sidebar toggle is false (normal width)
                    {
                        this.accordionClose = true
                        this.accordionActive = "menu-1";
                        this.tooltipDisable = true;
                    }
                }
            );

        this.accordionActiveCheck();
        this.getPowerBiReports();
    }

    getPowerBiReports() {
        this.showLoading = true;
        this.analyticService.GetPowerBiAnalyticsReport().then(() => {
            // filtering the reports based on show in menu flag
            this.analyticsPowerBiReports = this.analyticService.analyticsReports;
            if (this.analyticsPowerBiReports.find(item => item.ShowInMenu == false)) {
                this.displayAllAnalyticsTile = true;
            }
            this.menuPowerBiReports = this.analyticsPowerBiReports.filter(item => item.ShowInMenu == true);
            this.showLoading = false;
        }, () => {
            this.showLoading = false;
        });
    }

    ngOnDestroy() {
        if (this.router_sub) {
            this.router_sub.unsubscribe();
        }
    }

    ngOnChanges() {
    }

    accordionActiveCheck() {
        if (this.router.url.includes('task-board') || this.router.url.includes('suite') || this.router.url.includes('reportpack-dashboard')) {
            this.accordionActive = "menu-1";
        } else if (this.router.url.includes('data-ingestion') || this.router.url.includes('bulk-report-run') || this.router.url.includes('report-bundling')) {
            this.accordionActive = "menu-2";
        } else if (this.router.url.includes('user-management') || this.router.url.includes('role-management') || this.router.url.includes('reportpack-management')
            || this.router.url.includes('report-schedule-management') || this.router.url.includes('permission-management')
            || this.router.url.includes('bundle-type-management') || this.router.url.includes('report-pack-sequence-management')
            || this.router.url.includes('application-configuration')) {
            this.accordionActive = "menu-3";
        }
        else if (this.router.url.includes('client-management') || this.router.url.includes('report-downloader') || this.router.url.includes('query-engine') || this.router.url.includes('ref-data')) {
            this.accordionActive = "menu-5";
        }
        else {
            this.accordionActive = "menu-4";
        }
    }

    showHamburgerSidenav() {
        this.hamburgerSidenavMenu = true;
    }

    closeHamburgerSidenav() {
        this.hamburgerSidenavMenu = false;
    }
}
