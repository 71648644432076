import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterUtils } from 'primeng/utils';
import { ReportPackSequenceService } from 'src/app/core/services/reportPackSequence.service';


@Component({
    selector: 'app-report-pack-sequence-filter',
    templateUrl: './report-pack-sequence-filter.component.html',
    styleUrls: ['./report-pack-sequence-filter.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class ReportPackSequenceFilterComponent implements OnInit, OnDestroy {

    @Input() selectedRpSequences: any;
    @Input() selectedRpSequenceIds: any;
    @Input() reportPackSequenceList: any;
    @Input() rpsIdsToIgnore: any = [];
    @Input() selectionMode: 'single' | 'multiple' = 'single'
    @Output() selectedRpSequencesChange = new EventEmitter();

    selectedRpSequenceRows: any;
    loading = false;
    headerCols: any[];
    showDescription: boolean;
    createdDateFilters: Date[];
    private dateTime = new Date();
    private yearRange = "2000:" + this.dateTime.getFullYear().toString();
    rpsForBannerDisplay: any;


    constructor(private modalService: NgbModal, private rpSequenceService: ReportPackSequenceService) {
    }

    ngOnInit() {
        this.headerCols = [
            { field: 'RPSequenceName', header: 'Report Pack Sequence Name' },
            { field: 'Description', header: 'Description' },
            { field: 'CreatedBy', header: 'Created By' },
            { field: 'CreatedDate', header: 'Created Date' }
        ];

        if (this.reportPackSequenceList) {
            this.prepareDisplayList();
        }
        else {
            this.loading = true;
            this.rpSequenceService.getActiveReportPackSequenceList()
                .then((data: any) => {
                    this.reportPackSequenceList = data
                    this.prepareDisplayList();
                    this.loading = false;
                }, () => {
                    this.loading = false;
                });
        }

        FilterUtils['dateRangeFilter'] = (value, filter: [Date, Date]): boolean => {
            if (!filter || (!filter[0] && !filter[1]))
                return true;

            var val = new Date(value);
            var s = filter[0].getTime();
            var e;
            if (filter[1])
                e = filter[1].getTime() + (24 * 60 * 60 * 1000) - 1000;
            else e = s + (24 * 60 * 60 * 1000) - 1000;
            return val.getTime() >= s && val.getTime() <= e;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.hasOwnProperty('reportPackSequenceList') && this.reportPackSequenceList && this.reportPackSequenceList.length > 0)
            || (changes.hasOwnProperty('selectedRpSequenceIds') && this.selectedRpSequenceIds)
            || (changes.hasOwnProperty('selectedRpSequences') && this.selectedRpSequences)) {
            this.prepareDisplayList();
        }
    }

    ngOnDestroy(): void {
        this.modalService.dismissAll();
    }

    prepareDisplayList() {
        if (this.rpsIdsToIgnore && this.rpsIdsToIgnore.length > 0) {
            this.reportPackSequenceList = this.reportPackSequenceList.filter(x => this.rpsIdsToIgnore.indexOf(x.RPSId) === -1);
        }

        if (this.selectedRpSequences) {
            this.selectedRpSequenceRows = this.selectedRpSequences;
        }
        else if (this.selectedRpSequenceIds && this.reportPackSequenceList.length > 0 && !Array.isArray(this.selectedRpSequenceIds)
            && this.reportPackSequenceList.filter(x => x.RPSId == this.selectedRpSequenceIds).length > 0) {
            this.selectedRpSequences = this.reportPackSequenceList.filter(x => x.RPSId == this.selectedRpSequenceIds);
            this.selectedRpSequenceRows = this.selectedRpSequences;
        }
        else if (this.selectedRpSequenceIds && this.reportPackSequenceList.length > 0 && Array.isArray(this.selectedRpSequenceIds)
            && this.reportPackSequenceList.filter(x => x.RPSId == this.selectedRpSequenceIds.indexOf(x.RPSId)).length > 0) {
            this.selectedRpSequences = this.reportPackSequenceList.filter(x => this.selectedRpSequenceIds.indexOf(x.RPSId));
            this.selectedRpSequenceRows = this.selectedRpSequences;
        }

        if (this.selectionMode == 'single' && this.selectedRpSequences)
            this.rpsForBannerDisplay = Array.isArray(this.selectedRpSequences) ? this.selectedRpSequences[0] : this.selectedRpSequences;
    }

    closeDateRangeFilter(dateComponent: any, filters: Date[]) {
        if (filters[1]) {
            dateComponent.hideOverlay();
        }
    }

    clearFilters() {
        this.createdDateFilters = null;
    }

    /**
      * Sets report pack id
      */
    async updateReportPackSequence() {
        this.closeModal();
        this.selectedRpSequences = this.selectedRpSequenceRows;
        if (this.selectionMode == 'single') this.rpsForBannerDisplay = this.selectedRpSequences;
        this.selectedRpSequencesChange.emit(this.selectedRpSequences);
    }

    open(reportPackSequenceFilter) {
        this.modalService.open(reportPackSequenceFilter, { size: 'xl', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
    }

    openModal(descriptionContent) {
        this.modalService.open(descriptionContent, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
    }

    closeModal() {
        this.clearFilters();
        this.modalService.dismissAll();
    }

    overflowToolCheck() {
        let ele = document.getElementById("overflowCheck");
        let osw = ele.offsetWidth;
        let sw = ele.scrollWidth;

        if (osw < sw) {
            this.showDescription = true;
        } else {
            this.showDescription = false;
        }
    }
}