<app-loader *ngIf="loading"></app-loader>
<div class="row">
    <div class="col-12 col-lg-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'" class="last-cap">
        </app-ng7-dynamic-breadcrumb>
    </div>
</div>
<div class="row" *ngIf="tableList">
    <div class="col-12">
        <div class="row header">Ref Data Table Selection Details</div>
        <form #tableForm="ngForm">
            <div class="row form-row">
                <div class="col-1"><label>Table </label></div>
                <div class="col-2">
                    <select id="tableList" name="tableList" [(ngModel)]="tableModel.tableId"
                        class="table-engine-form-control w-100 select-arrow">
                        <option *ngFor="let table of tableList" [selected]="table.tableId == tableModel.tableId"
                            value={{table.tableId}}>
                            {{table.displayName}}
                        </option>
                    </select>
                </div>
                <div class="col-1"></div>
                <div class="col-2 align-items-center">
                    <button class="btn btn-modal-pos small-font hvr-shrink" type="button"
                        (click)="getTableResult(true, false)">
                        <span>Get Table Result</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<app-dynamic-grid
    [gridData]="gridData" 
    [isCRUDEnabled]="true" 
    (modelChange)="updateTableModel($event)"
    (downloadResults)="downloadTableResult()"
    (downloadAudit)="downloadAuditReport()"
    (saveRefDataChanges)="saveRefDatachanges($event)">
</app-dynamic-grid>
