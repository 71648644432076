import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CoreModule } from 'src/app/core/core.module';
import { IngestionPanelComponent } from './ingestion-panel/ingestion-panel.component';
import { DataPreparationComponent } from './data-preparation-panel/datapreparation-panel.component';
import { BrowserModule } from '@angular/platform-browser';
import { IngestionFormComponent } from './ingestion-form/ingestion-form.component';
import { IngestionFileEditComponent } from './ingestion-file-edit/ingestion-file-edit.component';
import { NewIngestionFormComponent } from './new-ingestion-form/new-ingestion-form.component';
import { ReportBundlingComponent } from './report-bundling/report-bundling.component';
import { ReportBundlingFormComponent } from './report-bundling-form/report-bundling-form.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    IngestionPanelComponent,
    DataPreparationComponent,
    IngestionFormComponent,
    IngestionFileEditComponent,
    NewIngestionFormComponent,
    ReportBundlingComponent,
    ReportBundlingFormComponent
  ],
  exports: [
    IngestionPanelComponent,
    DataPreparationComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    BrowserModule,
    NgMultiSelectDropDownModule.forRoot() 
  ]
})

export class DataProcessingModule {

}