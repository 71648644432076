import { Component, Input, OnInit } from "@angular/core";
import { AlertService } from "src/app/core/services/alert.service";
import { TreeNode } from "primeng/api";
import { BundleTypeManagementService } from "src/app/core/services/bundleTypeManagementService";
import { Router } from "@angular/router";

@Component({
  selector: "app-bundle-type-form",
  templateUrl: "./bundle-type-form.component.html",
  styleUrls: ["./bundle-type-form.component.css"],
})
export class BundleTypeFormComponent implements OnInit {
  selectedReports: string;
  loading = false;
  bundleType: any;
  editMode: boolean;

  reportTreeHeaders: any;
  reportTreeList: any;
  selectedNodes: TreeNode[];

  maxCharsTextArea: any = 500;

  constructor(
    private bundleTypeManagementService: BundleTypeManagementService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit() {
    if (!this.bundleTypeManagementService.selectedBundleType) {
      this.backToBundleTypeManagement();
    } else {
      this.editMode = this.bundleTypeManagementService.editMode;
      this.bundleType = JSON.parse(
        JSON.stringify(this.bundleTypeManagementService.selectedBundleType)
      );

      this.reportTreeHeaders = [
        { field: "DisplayName", header: "Name" },
        { field: "Description", header: "Description" },
      ];

      this.getBundleTypeReportTreeData();
    }
  }

  backToBundleTypeManagement() {
    this.router.navigate(["/app/bundle-type-management"]);
  }

  getBundleTypeReportTreeData() {
    this.loading = true;
    this.bundleTypeManagementService
      .getBundleTypeReportTreeData()
      .then((treeList: any) => {
        this.reportTreeList = treeList;
        if (this.editMode) {
          this.prepareDataPrepNodeSelection();
          // this.populateSelectedNodes(this.reportTreeList);
          // this.countSelections();
        }
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  prepareDataPrepNodeSelection() {
    let selectedReportsArray = this.bundleType.reports.map(
      (node) => node.suiteId + "-" + node.reportId + "-" + node.reportingTypeId
    );
    this.selectedReports = selectedReportsArray.join("|");

    if (this.selectedReports != null && this.selectedReports != "") {
      selectedReportsArray = this.selectedReports.split("|");
      this.selectedNodes = [];
      this.reportTreeList.forEach((suiteNode) => {
        var selectedReportsCount = 0;
        suiteNode.children.forEach((reportNode) => {
          var selectedRtsCount = 0;
          var selectedChildrenNodes = [];
          reportNode.children.forEach((reportType) => {
            if (
              this.bundleType.reports.filter(
                (z) =>
                  reportType.data.Id == z.reportId + "-" + z.reportingTypeId
              ).length > 0
            ) {
              selectedChildrenNodes.push(reportType);
            }
          });

          if (selectedChildrenNodes.length == reportNode.children.length)
            this.selectedNodes.push(reportNode);
          this.selectedNodes.push(...selectedChildrenNodes);

          var selectedRts = this.selectedNodes.filter(
            (s) =>
              (s.parent && s.parent.data.Id == reportNode.data.Id) ||
              reportNode.children.filter((x) => x.data.Id == s.data.Id).length >
                0
          );
          selectedRtsCount = selectedRts ? selectedRts.length : 0;
          if (selectedRtsCount > 0) {
            selectedReportsCount = selectedReportsCount + 1;
          }
        });

        if (selectedReportsCount == suiteNode.children.length)
          this.selectedNodes.push(suiteNode);
      });
    } else {
      this.selectedNodes = [];
    }
    this.countSelectedReports();
  }

  selectAllReports(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    let selectedReportsArray = this.selectedNodes.map((node) => node.data.Name);
    this.selectedReports = selectedReportsArray.join("|");
    this.countSelectedReports();
  }

  nodeSelectChange(event: any) {
    if (event.node.data.isSourceReport == true) {
      event.preventDefault();
    } else {
      this.reportTreeList.forEach((suite) => {
        suite.children.forEach((report) => {
          if (
            report.data.isSourceReport == true &&
            this.selectedNodes.filter((x) => x.data.Id == report.data.Id)
              .length == 0
          ) {
            this.selectedNodes.push(report);
          }
        });
      });
    }

    let selectedReportsArray =
      this.selectedNodes !== null &&
      this.selectedNodes !== undefined &&
      this.selectedNodes.length > 0
        ? this.selectedNodes
            .filter((node) => node.parent)
            .map((x) => x.data.Name)
        : [];
    this.selectedReports =
      selectedReportsArray.length > 0 ? selectedReportsArray.join("|") : "";
    this.countSelectedReports();
    // this.selectedReportsChange.emit(this.selectedReports);
  }

  clearReportFilters() {
    this.getBundleTypeReportTreeData();
    this.selectedReports = "";
    this.prepareDataPrepNodeSelection();
  }

  clearChanges() {
    this.selectedNodes = [];
    if (!this.editMode) {
      this.bundleType = {
        bundleTypeId: 0,
        bundleTypeName: "",
        bundleDescription: "",
        reports: [],
      };
      // this.populateSelectedNodes(this.reportTreeList);
      // this.countSelections();
    }
  }

  countSelectedReports() {
    this.reportTreeList.map((suiteNode) => {
      var selectedReportsCount = 0;
      if (suiteNode.children) {
        suiteNode.children.forEach((reportNode) => {
          var selectedRtsCount = 0;
          var selectedReportNodes = this.selectedNodes.filter(
            (s) => s.data.Id == reportNode.data.Id
          );
          if (selectedReportNodes && selectedReportNodes.length > 0) {
            selectedRtsCount = reportNode.children.length;
          } else {
            var selectedRts = this.selectedNodes.filter(
              (s) =>
                (s.parent && s.parent.data.Id == reportNode.data.Id) ||
                reportNode.children.filter((x) => x.data.Id == s.data.Id)
                  .length > 0
            );
            selectedRtsCount = selectedRts ? selectedRts.length : 0;
          }
          if (selectedRtsCount > 0) {
            reportNode.data.selectedCount =
              "(Selected Reporting Types: " + selectedRtsCount + ")";
            selectedReportsCount = selectedReportsCount + 1;
          } else reportNode.data.selectedCount = "";
        });
        suiteNode.data.selectedCount =
          selectedReportsCount > 0
            ? "(Selected Reports: " + selectedReportsCount + ")"
            : "";
      }
    });
  }

  findParent(data: any, id: any) {
    var parent;
    for (let node of data) {
      if (node.children) {
        if (node.children.filter((x) => x.data.Id == id).length > 0) {
          parent = node;
          break;
        } else {
          parent = this.findParent(node.children, id);
          if (parent) break;
        }
      }
    }
    return parent;
  }

  submit() {
    if (
      this.bundleType.bundleName === null ||
      this.bundleType.bundleName === ""
    ) {
      this.alertService.error("Bundle type name is a mandatory field!");
      return;
    }

    var treeList = this.reportTreeList;
    var self = this;
    var reports = [];
    if (
      this.selectedNodes !== null &&
      this.selectedNodes !== undefined &&
      this.selectedNodes.length > 0
    ) {
      this.selectedNodes
      .filter((x) => !x.children)
      .forEach(function (node) {
        var parent = node.data;
        if (!parent) parent = self.findParent(treeList, node.data.Id);
        var nodeRefId =
          parent.data == undefined
            ? parent.Id
            : parent.data.Id;
        var rptId = nodeRefId.split("-");
        reports.push({
          reportId: rptId.length==2? rptId[0]:0,
          reportName: parent.data == undefined ? parent.Name : parent.data.Name,
          reportingTypeId: rptId.length==2? rptId[1]:0,
        });
      });
      this.bundleType.reports = reports;
    } else {
      this.alertService.error("Please select atleast one report!");
      return;
    }

    this.loading = true;
    if (!this.editMode) {
      this.bundleTypeManagementService
        .addBundleType(this.bundleType)
        .then((data: any) => {
          this.bundleTypeManagementService.selectedBundleType = null;
          this.loading = false;
          this.backToBundleTypeManagement();
        })
        .catch(() => {
          this.loading = false;
        });
    } else {
      this.bundleTypeManagementService
        .updateBundleType(this.bundleType)
        .then((data: any) => {
          this.bundleTypeManagementService.selectedBundleType = null;
          this.loading = false;
          this.backToBundleTypeManagement();
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
}
