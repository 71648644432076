<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
    <div class="col-12 col-sm-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
    </div>
</div>
<div class="row mt-3">
    <div class="col-6">
        <h6 class="text-white-50  font-italic font-13"><i class="fas fa-info-circle text-yellow mr-3"></i>
            The current environment is highlighted in yellow.</h6>
        <p-treeTable #dt [value]="gridTreeList" class="table-irr" [columns]="headerCols" [paginator]="false"
            [scrollable]="true" [scrollHeight]="'calc(100vh - 400px)'" sortField="Name" sortOrder="1">
            <ng-template pTemplate="paginatorleft">
                <button class="btn btn-clear-filter hvr-shrink" type="button" (click)="dt.reset();clearFilters();">
                    <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                </button>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [ttSortableColumn]="col.field"> {{col.header}}
                        <p-treeTableSortIcon [field]="col.field"></p-treeTableSortIcon>
                    </th>
                    <th>Actions</th>
                </tr>
                <tr>
                    <th *ngFor="let col of columns">
                        <input *ngIf="col.field !=''" type="text" class="w-100" placeholder="Filter Text"
                            [value]="dt.filters && dt.filters[col.field] ? dt.filters[col.field].value: ''"
                            (input)="dt.filter($event.target.value, col.field, 'contains')">
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                <tr>
                    <td [ngClass]="{'text-color-yellow': rowData.IsCurrentSelection === true}">
                        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                        {{rowData.Name}}
                    </td>
                    <td>
                        <button type="button" class="btn-table-icon hvr-shrink" container="body" ngbTooltip="Edit"
                            triggers="hover" (click)="openModifyNamePopUp(modifyNameModal,rowData)">
                            <i class="fas fa-edit fa-lg"></i>
                        </button>
                        <button *ngIf="rowData.RowType == 'Client Entity Environment' && rowData.IsCurrentSelection"
                            type="button" class="btn-table-icon hvr-shrink disable-delete-button" container="body"
                            ngbTooltip="Switch is disabled as you are currently aligned to this client."
                            triggers="hover">
                            <i class="fas fa-chevron-circle-right fa-lg"></i>
                        </button>
                        <button *ngIf="rowData.RowType == 'Client Entity Environment' && !rowData.IsCurrentSelection"
                            type="button" class="btn-table-icon hvr-shrink" container="body" ngbTooltip="Switch"
                            triggers="hover" (click)="confirmClientSwitch(rowData)">
                            <i class="fas fa-chevron-circle-right fa-lg"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
        </p-treeTable>
    </div>
</div>

<ng-template #modifyNameModal let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">{{selectedRowForEdit.RowType}}<i
                class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="modal.dismiss('Cross click')"></i></h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <form #clientForm="ngForm">
                    <div class="row form-row">
                        <div class="col-2"><label>{{selectedRowForEdit.RowType}}</label></div>
                        <div class="col-4"><input type="text" required minlength="3" maxlength="100"
                                [(ngModel)]="selectedRowForEdit.ChangedName" class="client-form-control" #clientname="ngModel"
                                id="clientName" name="clientName" pattern="^[A-Za-z0-9 _\-.]*$" trim-text />
                            <div *ngIf="clientname.invalid && (clientname.dirty || clientname.touched)"
                                class="alert alert-danger">
                                <div *ngIf="clientname.errors.required">
                                    {{selectedRowForEdit.RowType}} is a mandatory field.
                                </div>
                                <div *ngIf="clientname.errors.minlength">
                                    {{selectedRowForEdit.RowType}} must be at least 3 characters long.
                                </div>
                                <div *ngIf="clientname.errors.pattern">
                                    {{selectedRowForEdit.RowType}} is not valid, as it contains special characters.
                                </div>
                                <div *ngIf="clientname.errors.maxlength">
                                    {{selectedRowForEdit.RowType}} should not exceed 100 characters.
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer text-right">
        <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button" (click)='clearChanges()'>
            <span>Clear</span>
        </button>
        <button class="btn btn-modal-pos small-font hvr-shrink" type="button" (click)='submitChanges()'
            [disabled]="clientForm && clientForm.form ? !clientForm.form.valid : false">
            <span>Save</span>
        </button>
    </div>
</ng-template>