import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

/**
 * Injectable
 */
@Injectable()
export class ReportPackService {

  editMode: boolean;
  selectedReportPack: any;
  templateGroups: any;
  accountingFrameworks: any;
  reportingLevels: any;

  constructor(private apiService: ApiService) { }

  /**
   * Report Pack Metadata Extraction
   */
  getActiveReportPackList() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getActiveReportPacks/', '', '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getReportPackList() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getReportPacksList/', '', '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  saveActivationStatus(reportPackId: number, isActive: boolean) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.put('activateOrDeactivateReportPack/', reportPackId, isActive, '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getReportPack(reportPackId: number) {
    var params = { reportPackId: reportPackId };
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getReportPack/', params)
        .toPromise()
        .then(
          res => { // Success
            this.selectedReportPack = res;
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getDataForReportPackForm() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getReportPackFormData/', null, '')
        .toPromise()
        .then(
          (res: any) => { // Success
            this.templateGroups = res.IngestionTemplateGroups;
            this.accountingFrameworks = res.AccountingFrameworks;
            this.reportingLevels = res.ReportingLevels;
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  updateReportPack(updatedReportPack: any) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.put('updateReportPack/', null, updatedReportPack, '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}
