import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { Parameters } from 'src/app/shared/models/parameters.model';
import { DashboardService } from './dashboard.service';

/**
 * Injectable
 */
@Injectable()
export class SuiteService {

  baseURL: string = environment.apiUrl;

  reportSuiteList: Object = [];
  /**
   * Latest suite of suite service
   */
  latestSuite: string;

  /**
   * Creates an instance of suite service.
   */
  constructor(private router: Router, private http: HttpClient, private apiService: ApiService, private dashboardService: DashboardService) { }

  /**
   * Report Suite Metadata Extraction
   */
  getSuites() {
    var params = new Parameters();
    params.reportpackid = !this.dashboardService.reportPackID ? 0 : this.dashboardService.reportPackID;
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('suite/getSuites', params)
        .toPromise()
        .then(
          res => { // Success
            this.reportSuiteList = res;
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  /**
   * Gets current suite from url
   */
  getCurrentSuite() {
    const currentUrl = this.router.url.split('/');
    const suite = currentUrl && currentUrl.length > 3 && currentUrl[2] == 'suite' ? currentUrl[3] : null;
    if (suite) {
      this.latestSuite = suite;
    }
    return suite;
  }

  getLatestSuite() {
    return this.latestSuite;
  }

  setLatestSuite(suite: string) {
    this.latestSuite = suite;
  }

}
