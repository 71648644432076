<app-loader #pr *ngIf="loading"></app-loader>
<div class="row">
    <i class="fas fa-book-open summary-circle cursor-pointer" *ngIf="summary" ngbTooltip="View Summary" container="body"
        (click)="open(summaryPNP)"></i>
</div>
<div class="main mt-3">

    <div class="tabDv">
        <app-kendo-grid *ngIf="gridColumns" [columns]="gridColumns" [columnGroups]="gridColumnGroups"
            [blockedCells]="blockedCells" [pageable]="true" [gridData]="gridData" [showExcelExport]="true" [forIFForm]="false" [scrollable]="true"
            [excelReportFileName]="excelReportFileName" [editType]="'none'" [hasDynamicRows]="false"></app-kendo-grid>

    </div>
</div>
<div class="row mt-3">
    <div class="col-6">
        <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button" (click)="goToPreviousTab()">
            <div class="icon-container"><i class="fas fa-arrow-left fa-lg"></i></div><span>Prev</span>
        </button>
    </div>
</div>

<ng-template #summaryPNP let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">Summary<i
                class="fas fa-times ml-auto cursor-pointer" aria-label="Close" (click)="modal.close()"></i></h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="card card-drag-drop h-auto">
                    <div class="card-header text-center"><span class="badge-header-name">Suite</span></div>
                    <div class="card-body d-flex align-items-center justify-content-center text-center">
                        <div class="mt-3">
                            <div>{{summary.suiteName}}</div>
                            <div>{{summary.suiteDesc}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-drag-drop h-auto">
                    <div class="card-header text-center"><span class="badge-header-name">Report</span></div>
                    <div class="card-body d-flex align-items-center justify-content-center text-center">
                        <div class="mt-3">
                            <div>{{summary.reportName}}</div>
                            <div>{{summary.reportDesc}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-drag-drop h-auto">
                    <div class="card-header text-center"><span class="badge-header-name">Period 1</span></div>
                    <div class="card-body d-flex align-items-center justify-content-center text-center">
                        <div class="mt-3">
                            <div class="w-100 text-break">{{summary.period1Title}}</div>
                            <div>{{summary.period1Date | date: 'dd/MM/yyyy'}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card card-drag-drop h-auto">
                    <div class="card-header text-center"><span class="badge-header-name">Period 2</span></div>
                    <div class="card-body d-flex align-items-center justify-content-center text-center">
                        <div class="mt-3">
                            <div class="w-100 text-break">{{summary.period2Title}}</div>
                            <div>{{summary.period2Date | date: 'dd/MM/yyyy'}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>