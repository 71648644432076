import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QueryEngineService } from 'src/app/core/services/query-engine.service';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-query-engine',
    templateUrl: './query-engine.component.html',
    styleUrls: ['./query-engine.component.css'],
    encapsulation: ViewEncapsulation.None,
})

export class QueryEngineComponent implements OnInit, OnDestroy {
    public loading = false;
    queryList: any;
    selectedReportPack: any;
    queryModel: any;

    gridData: any;
    pageSize: number = 100;
    skip: number = 0;

    constructor(
        private alertService: AlertService,
        private queryEngineService: QueryEngineService,
        private router: Router,
        private authService: AuthenticationService,
        private modalService: NgbModal,
        private formBuilder: UntypedFormBuilder
    ) { }

    ngOnInit() {
        if (!this.authService.isPrivacyNoticeAcknowledged) {
            this.router.navigate(['/privacy-notice']);
        }

        this.queryModel = { queryId: '', queryDisplayName: '', reportPackId: 0, reportPackTitle: '', pageNumber: 1, pageSize: this.pageSize, isInitialLoad: true, sortList: [], filter: [] }
        this.getQueryList();
    }

    ngOnDestroy() {
        this.modalService.dismissAll();
    };

    private getQueryList() {
        this.loading = true;
        this.queryEngineService.getQueryList().then((queryList: any[]) => {
            this.queryList = queryList.map(x => ({ queryId: x.QueryId, displayName: x.QueryName }));
            this.queryModel.queryId = this.queryList[0].queryId;
            this.queryModel.queryDisplayName = this.queryList[0].displayName;
            this.loading = false;
        }).catch(() => { this.loading = false; });
    }

    onReportPackSelection() {
        if (this.selectedReportPack) {
            this.queryModel.reportPackId = this.selectedReportPack.ReportPackID;
            this.queryModel.reportPackTitle = this.selectedReportPack.Title;
        }
        else this.queryModel.reportPackId = 0;
    }

    getQueryResult(isInitialLoad: boolean, applySelection: boolean) {
        this.loading = true;
        this.queryModel.pageNumber = (this.skip / this.pageSize) + 1;
        this.queryModel.isInitialLoad = isInitialLoad;

        if (!applySelection) {
            this.queryModel.filter = [];
            this.queryModel.sortList = [];
            this.queryModel.pageNumber = 1;
        }

        if (this.queryModel.queryId === null) {
            this.alertService.error('Please select a query!');
            return;
        }
        else this.queryModel.queryDisplayName = this.queryList.filter(x => x.queryId == this.queryModel.queryId)[0].displayName;
        if (this.queryModel.reportPackId === null || this.queryModel.reportPackId == 0) {
            this.alertService.error('Please select a report pack!');
            return;
        }

        this.loading = true;
        this.queryEngineService.getQueryResult(this.queryModel).then((queryResult: any) => {
            this.gridData = {
                data: JSON.parse(JSON.stringify(queryResult.Data)),
                total: queryResult.TotalCount,
                columns: queryResult.Columns
            };
           
            this.loading = false;
        }).catch(() => { this.loading = false; });
    }

    pageChange(event: PageChangeEvent) {
        this.skip = event.skip;
        this.getQueryResult(false, true);
    }

    downloadQueryResult() {
        this.alertService.info('The query result will be downloaded in a while! Please note that the selected filter and sort criteria will not be applied in the downloaded file.');
        this.queryEngineService.downloadQueryResult(this.queryModel);
    }

    updateQueryModel(dynamicModel)
    {
        this.skip = dynamicModel.skip;
        this.queryModel.isInitialLoad = false;
        this.queryModel.sortList = dynamicModel.pageNumber;
        this.queryModel.sortList = dynamicModel.sortList;
        this.queryModel.filter = dynamicModel.filter;
        this.getQueryResult(false, true)
    }
}