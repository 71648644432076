import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
/**
 * Common Service
 */
@Injectable()
export class CommonService {
  /*showPanelSub is the variable*/
  showPanelSub = new Subject<boolean>();
  promptSub: any = new Subject<any>();
  promptResponse: string = null;

  constructor() { }

  /*Set method*/
  setShowPanel(value: boolean) {
    this.showPanelSub.next(value);
  }
  
  /*Get method*/
  watchShowPanel(): Observable<boolean> {
    return this.showPanelSub.asObservable();
  }

  watchPrompt(): Observable<any> {
    return this.promptSub.asObservable();
  }

  setPrompt(response: any) {
    this.promptResponse = response;
    this.promptSub.next(this.promptResponse);
  }
}
