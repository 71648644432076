<app-loader *ngIf="loading"></app-loader>
<div class="row">
    <div class="col-12 col-lg-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
    </div>
    <div class="col-12 col-lg-6 text-left text-lg-right">

    </div>
</div>
<app-report-pack-filter></app-report-pack-filter>
<app-no-report-pack *ngIf="!selectedRp"></app-no-report-pack>
<div class="col-12 scrollable-pack-dashboard pl-0 mt-3">
    <div class="row justify-content-center report-dashboard-status" *ngIf="reportPackSummary">
        <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2 d-flex align-items-center justify-content-center">
            <div [ngClass]="selectedStatusFilter === 'Not Started' ? 'card hvr-shrink notStarted active' : 'card hvr-shrink notStarted'"
                (click)="filterGridData('Not Started')">
                <div class="card-body">
                    <circle-progress [percent]="reportPackSummary.NotStarted*100/reportPackSummary.Total" [radius]="100"
                        [responsive]="true" [outerStrokeWidth]="7" [innerStrokeWidth]="5" [space]="10"
                        [showBackground]="false" [outerStrokeColor]="'#747480'" [innerStrokeColor]="'#000'"
                        [animation]="true" [animationDuration]="300" [showImage]="true" [imageHeight]="160"
                        [imageWidth]="170" [imageSrc]="'assets/images/report-pack-dashboard/not-started-dashboard.svg'">
                    </circle-progress>
                </div>
                <div class="card-footer">
                    Not Started - {{reportPackSummary.NotStarted}}
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2 d-flex align-items-center justify-content-center">
            <div [ngClass]="selectedStatusFilter === 'Developed' ? 'card hvr-shrink dev active' : 'card hvr-shrink dev'"
                (click)="filterGridData('Developed')">
                <div class="card-body">
                    <circle-progress [percent]="reportPackSummary.Developed*100/reportPackSummary.Total" [radius]="100"
                        [responsive]="true" [outerStrokeWidth]="7" [innerStrokeWidth]="5" [space]="10"
                        [showBackground]="false" [outerStrokeColor]="'#27acaa'" [innerStrokeColor]="'#000'"
                        [animation]="true" [animationDuration]="300" [showImage]="true" [imageHeight]="160"
                        [imageWidth]="170" [imageSrc]="'assets/images/report-pack-dashboard/developed-dashboard.svg'">
                    </circle-progress>
                </div>
                <div class="card-footer">
                    Developed - {{reportPackSummary.Developed}}
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2 d-flex align-items-center justify-content-center">
            <div [ngClass]="selectedStatusFilter === 'Prepared' ? 'card hvr-shrink prep active' : 'card hvr-shrink prep'"
                (click)="filterGridData('Prepared')">
                <div class="card-body">
                    <circle-progress [percent]="reportPackSummary.Prepared*100/reportPackSummary.Total" [radius]="100"
                        [responsive]="true" [outerStrokeWidth]="7" [innerStrokeWidth]="5" [space]="10"
                        [showBackground]="false" [outerStrokeColor]="'#4ebeeb'" [innerStrokeColor]="'#000'"
                        [animation]="true" [animationDuration]="300" [showImage]="true" [imageHeight]="160"
                        [imageWidth]="170" [imageSrc]="'assets/images/report-pack-dashboard/prepared-dashboard.svg'">
                    </circle-progress>
                </div>
                <div class="card-footer">
                    Prepared - {{reportPackSummary.Prepared}}
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2 d-flex align-items-center justify-content-center">
            <div [ngClass]="selectedStatusFilter === 'In Progress'? 'card hvr-shrink aip active' : 'card hvr-shrink aip'"
                (click)="filterGridData('In Progress')">
                <div class="card-body">
                    <circle-progress [percent]="reportPackSummary.InProgress*100/reportPackSummary.Total" [radius]="100"
                        [responsive]="true" [outerStrokeWidth]="7" [innerStrokeWidth]="5" [space]="10"
                        [showBackground]="false" [outerStrokeColor]="'#9c82d4'" [innerStrokeColor]="'#000'"
                        [animation]="true" [animationDuration]="300" [showImage]="true" [imageHeight]="160"
                        [imageWidth]="170" [imageSrc]="'assets/images/report-pack-dashboard/approval-dashboard.svg'">
                    </circle-progress>
                </div>
                <div class="card-footer">
                    Approval In Progress - {{reportPackSummary.InProgress}}
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2 d-flex align-items-center justify-content-center">
            <div [ngClass]="selectedStatusFilter === 'Completed' ? 'card hvr-shrink comp active' : 'card hvr-shrink comp'"
                (click)="filterGridData('Completed')">
                <div class="card-body">
                    <circle-progress [percent]="reportPackSummary.Completed*100/reportPackSummary.Total" [radius]="100"
                        [responsive]="true" [outerStrokeWidth]="7" [innerStrokeWidth]="5" [space]="10"
                        [showBackground]="false" [outerStrokeColor]="'#2db757'" [innerStrokeColor]="'#000'"
                        [animation]="true" [animationDuration]="300" [showImage]="true" [imageHeight]="160"
                        [imageWidth]="170" [imageSrc]="'assets/images/report-pack-dashboard/completed-dashboard.svg'">
                    </circle-progress>
                </div>
                <div class="card-footer">
                    Completed - {{reportPackSummary.Completed}}
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4" *ngIf="reportPackSummary">
        <div class="col-12">
            <div class="pack-dashboard-scroll">
                <p-table #rptPckDash [value]="gridList" [paginator]="true" [rows]="8" [(first)]="first"
                    class="table-irr report-packDashboard" [columns]="headerCols">
                    <ng-template pTemplate="paginatorleft">
                        <button class="btn btn-clear-filter hvr-shrink" type="button"
                            (click)="rptPckDash.reset();clearFilters();">
                            <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                        </button>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field"> {{col.header}}
                                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                            </th>
                        </tr>
                        <tr>
                            <th *ngFor="let col of columns">
                                <p-calendar *ngIf="col.field =='StartDate'" [(ngModel)]="startDateFilter" #sdFilter
                                    selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy" [readonlyInput]="true"
                                    placeholder="Select a range" appendTo="body" [monthNavigator]="true"
                                    [yearNavigator]="true" yearRange="{{yearRange}}"
                                    (ngModelChange)="rptPckDash.filter(startDateFilter, col.field, 'dateRangeFilter')"
                                    (onSelect)="closeDateRangeFilter(sdFilter, startDateFilter)">
                                </p-calendar>
                                <p-calendar *ngIf="col.field =='StatusChangedDate'" #scdFilter
                                    [(ngModel)]="statusChangedDateFilter" selectionMode="range" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [readonlyInput]="true" placeholder="Select a range"
                                    appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
                                    yearRange="{{yearRange}}"
                                    (ngModelChange)="rptPckDash.filter(statusChangedDateFilter, col.field, 'dateRangeFilter')"
                                    (onSelect)="closeDateRangeFilter(scdFilter, statusChangedDateFilter)">
                                </p-calendar>
                                <p-calendar *ngIf="col.field =='EndDate'" [(ngModel)]="endDateFilter" #edFilter
                                    selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy" [readonlyInput]="true"
                                    placeholder="Select a range" appendTo="body" [monthNavigator]="true"
                                    [yearNavigator]="true" yearRange="{{yearRange}}"
                                    (ngModelChange)="rptPckDash.filter(endDateFilter, col.field, 'dateRangeFilter')"
                                    (onSelect)="closeDateRangeFilter(edFilter, endDateFilter)">
                                </p-calendar>

                                <input
                                    *ngIf="col.field !='StartDate' && col.field !='StatusChangedDate' && col.field !='EndDate'"
                                    type="text" class="w-100" placeholder="Filter Text"
                                    [value]="rptPckDash.filters && rptPckDash.filters[col.field] ? rptPckDash.filters[col.field].value: ''"
                                    (input)="rptPckDash.filter($event.target.value, col.field, 'contains')">
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                            <td>{{rowData['SuiteName']}}</td>
                            <td>{{rowData['ReportDisplayName']}}</td>
                            <td>{{rowData['SubReportDisplayName']}}</td>
                            <td>{{rowData['StartDate'] | date: 'dd/MM/yyyy'}}</td>
                            <td>{{rowData['StatusChangedDate'] | date: 'dd/MM/yyyy'}}</td>
                            <td>{{rowData['EndDate'] | date: 'dd/MM/yyyy'}}</td>
                            <td>
                                <div class="progress-label">{{rowData['Status']}}</div>
                                <p-progressBar [showValue]="false" [value]="rowData['CompletionPercent']"
                                    [ngClass]="getClass(rowData['Status'])">
                                </p-progressBar>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>