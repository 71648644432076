<app-loader #rps *ngIf="loading"></app-loader>
<div class="row mb-3">
    <div class="col-10">
        <ul class="custom-irr-bread-crumb">
            <span>
                <li class="custom-irr-bread-crumb-li" (click)="updatePageByBreadCrumb(null)">
                    <span>
                        <a href="/home" style="color: rgb(196, 196, 205);">Home</a>
                    </span>
                </li>
            </span>
            <span *ngFor="let brc of populateBreadCrum">
                <span class="line"> / </span>
                <li class="custom-irr-bread-crumb-li" (click)="updatePageByBreadCrumb(brc)"
                    *ngIf="!checkLastBreadCrum(brc)">
                    <span style="color: rgb(196, 196, 205);">{{brc.GroupDisplayName}}
                    </span>
                </li>
                <li class="custom-irr-bread-crumb-li-last" (click)="updatePageByBreadCrumb(brc)"
                    *ngIf="checkLastBreadCrum(brc)">
                    <span style="color: rgba(196, 196, 205, 0.42);">{{brc.GroupDisplayName}}
                    </span>
                </li>
            </span>
        </ul>
    </div>
    <div class="col-6 text-right">
    </div>
    <div class="col-6 text-right">
        <div *ngIf='isDisplayChildReports' class="position-relative w-50 search-input-overall float-right">
            <input type="text" name="nameFilter" [(ngModel)]="searchReport" placeholder="Search Report" class="w-100"
                trim-text />
            <i class="fas fa-search"></i>
        </div>
    </div>
</div>
<div *ngIf='!isDisplayChildReports' class="row">
    <div class="col-6 col-sm-3 col-xl-2" *ngFor="let reportGroup of reportGroups">
        <div class="card card-analytics">
            <div class="card-header">
                <div class="analytics-name d-flex align-items-center w-100">
                    <div class="analytics-first-half w-100">
                        <div container="body" ngbTooltip="{{reportGroup.displayName}}">{{reportGroup.displayName}}</div>
                    </div>
                </div>
            </div>
            <div class="card-body d-flex align-items-center justify-content-center">
                <i class="{{reportGroup.reportLogoContent}}"></i>
            </div>
            <div class="card-footer d-flex align-items-center justify-content-center">
                <button *ngIf="!reportGroup.groupId" class="btn btn-icon-round mr-2 hvr-shrink" type="button"
                    (click)="navigateRouteToReport(reportGroup.displayName)" ngbTooltip="Go To Report" container="body"
                    tooltipClass="reporting-tooltip">
                    <i class="fas fa-eye"></i>
                </button>
                <button *ngIf="reportGroup.groupId" class="btn btn-icon-round mr-2 hvr-shrink" type="button"
                    (click)="showChildReports(reportGroup)" ngbTooltip="View Reports" container="body"
                    tooltipClass="reporting-tooltip">
                    <i class="fas fa-sitemap"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf='isDisplayChildReports'>
    <div class="col-6 col-sm-6 col-xl-3"
        *ngFor="let report of powerBiSubReports | searchReportFilter:'DisplayName':searchReport">
        <div class="card card-analytics">
            <div class="card-header">
                <div class="analytics-name d-flex align-items-center w-100">
                    <div class="analytics-first-half w-100">
                        <div container="body" ngbTooltip="{{report.DisplayName}}">{{report.DisplayName}}</div>
                    </div>
                </div>
            </div>
            <div *ngIf="report.ReportLogoContent" class="card-body d-flex align-items-center justify-content-center">
                <i class="{{report.ReportLogoContent}}"></i>
            </div>
            <div class="card-footer d-flex align-items-center justify-content-center">
                <button *ngIf="!report.ReportLogoContent" class="btn btn-icon-round mr-2 hvr-shrink" type="button"
                    ngbTooltip="Go To Report" (click)="navigateRouteToReport(report.DisplayName)" container="body"
                    tooltipClass="reporting-tooltip">
                    <i class="fas fa-eye"></i>
                </button>
                <button *ngIf="report.ReportLogoContent" class="btn btn-icon-round mr-2 hvr-shrink" type="button"
                    (click)="showChildReportsComp(report)" ngbTooltip="View Reports" container="body"
                    tooltipClass="reporting-tooltip">
                    <i class="fas fa-sitemap"></i>
                </button>
            </div>
        </div>
    </div>
</div>