<app-loader *ngIf="loading"></app-loader>
<div class="col-12">
    <div class="w-100 justify-content-center info-message" *ngIf="!selectDependencies">
        Please select reports from the below grid.
    </div>
    <div class="w-100 justify-content-center info-message" *ngIf="selectDependencies">
        <h6 class="text-white-50  font-italic small-font mt-3"><i class="fas fa-info-circle text-yellow mr-3"></i>Please
            note that the source dependencies of the selected reports will included by default (if not already
            prepared/selected) in the final list. </h6>
    </div>
    <p-treeTable #dtprepreports [value]="reportDataTree" selectionMode="checkbox" class="table-irr"
        [(selection)]="selectedNodes" [columns]="reportTreeHeaders" [scrollable]="true"
         (onNodeSelect)="nodeSelectChange($event)"
        (onNodeUnselect)="nodeSelectChange($event)" [scrollHeight]="'calc(100vh - 300px)'" [paginator]="true" [rows]="4"
        sortField="DisplayName" sortOrder="1">
        <ng-template pTemplate="paginatorleft">
            <button class="btn btn-clear-filter hvr-shrink" type="button"
                (click)="dtprepreports.reset();clearDataPrepFilters()">
                <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
            </button>
        </ng-template>
        <ng-template pTemplate="header" let-columns2>
            <tr>
                <th *ngFor="let col of columns2" [ttSortableColumn]="col.field">
                    <ng-container *ngIf="col.field === 'DisplayName'; else notNameColumn">
                      <p-treeTableHeaderCheckbox (click)="selectAllReports($event)"></p-treeTableHeaderCheckbox>
                    </ng-container>
                    {{col.header}}
                    <p-treeTableSortIcon [field]="col.field"></p-treeTableSortIcon>
                </th>
            </tr>
            <tr>
                <th *ngFor="let col of columns2">
                    <input type="text" class="w-100" placeholder="Filter Text"
                        [value]="dtprepreports.filters && dtprepreports.filters[col.field] ? dtprepreports.filters[col.field].value: ''"
                        (input)="dtprepreports.filter($event.target.value, col.field, 'contains')">
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
            <tr>
                <td
                    [title]="rowData.isSourceReport == true ? 'This report cannot be disabled as it has dependencies selected!': ''">
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    <p-treeTableCheckbox [value]="rowNode" ngDefaultControl
                        [ngClass]="rowData.isSourceReport == true ? 'disabled-checkbox':''">
                    </p-treeTableCheckbox>
                    {{rowData.DisplayName}} {{rowData.selectedReportsCount}}
                </td>
                <td>{{rowData.Description}}</td>
            </tr>
        </ng-template>
    </p-treeTable>
</div>