import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { UserModel } from '../../shared/models/user.model';
import { PermissionType, PermissionModel } from '../../shared/models/permission.model';
import { RoleModel } from '../../shared/models/role.model';

/**
 * Injectable
 */
@Injectable()
export class RoleManagementService {

  /**
   * Suite permissions
   */
  suitePermissions = {};
  /**
   * Suite permission subject
   */
  suitePermissionSub = new Subject<any>();
  /**
   * Approver permissions
   */
  approverPermissions = {};
  /**
   * Roles
   */
  roles: RoleModel[] = [];
  /**
   * Roles subject
   */
  rolesSub = new Subject<RoleModel[]>();

  reportFlag = false;
  ingestionFlag = false;
  adminFlag = false;
  analyticFlag = false;
  bundlingFlag = true;


  /**
   * Creates an instance of role management service.
   */
  constructor(
    private authService: AuthenticationService
  ) { }

  /**
   * Determines if specific user have permission
   */
  public checkPermissions(
    type: PermissionType,
    suite?: string,
    report?: string,
    resource?: string
  ): boolean {
    // const userPermissions = this.authService.getPermissions();
    // const user: UserModel = this.authService.getLoggedInUser();

    // const isAdmin = user.userRoleMappingDtos.filter(x => x.roleid === 1);
    // if (isAdmin.length >0) { return true; }
    // // const isApprover = user.userRoleMappingDtos.filter(x => x.roleid === 3 || x.roleid === 4 || x.roleid === 5);
    // // if (resource === 'approver-panel') { return isApprover.length >0; }
    // if (suite && !report) {
    //   for (const reportName in this.suitePermissions[suite]) {
    //     if (this.suitePermissions[suite][reportName] && userPermissions.includes(this.suitePermissions[suite][reportName][type])) {
    //       return true;
    //     }
    //   }
    // } else if (suite && report) {
    //   return this.suitePermissions[suite][report] &&
    //     this.suitePermissions[suite][report][type] &&
    //     userPermissions.includes(this.suitePermissions[suite][report][type]);
    // }
    return true;
  }

  /**
   * Gets permissions from API
   */
  setPermissions(data: PermissionModel[]) {
    const reportSection = 'Reports';
    const approverSection = 'Approver';
    data
      .filter(perm => perm['permissionsection'] === reportSection)
      .map(perm => {
        if (perm && perm['permissionsuite']) {
          perm['permissionsuite'] = perm['permissionsuite'].toLowerCase();
        }
        if (perm && perm['permissionreport']) {
          perm['permissionreport'] = perm['permissionreport'].toLowerCase();
        }
      });
    for (const permission of data) {
      if (permission['permissionsection'] === reportSection) {
        if (permission['permissionsuite'] in this.suitePermissions) {
          if (permission['permissionreport'] in this.suitePermissions[permission['permissionsuite']]) {
            this.suitePermissions[permission['permissionsuite']][permission['permissionreport']][permission['permissiontype']] =
              permission['permissionid'];
          } else {
            this.suitePermissions[permission['permissionsuite']][permission['permissionreport']] = {
              [permission['permissiontype']]: permission['permissionid']
            };
          }
        } else {
          this.suitePermissions[permission['permissionsuite']] = {
            [permission['permissionreport']]: {
              [permission['permissiontype']]: permission['permissionid']
            }
          };
        }
      } else if (permission['permissionsection'] === approverSection) {
        this.approverPermissions[permission['permissiontype']] = permission['permissionid'];
      }
    }
    this.suitePermissionSub.next(this.suitePermissions);
  }

  /**
   * Gets roles from API
   */
  setRoles(data: RoleModel[]) {
    this.roles = data;
    this.rolesSub.next(data);
  }

  /**
   * Gets roles
   */
  getRoles() {
    return this.roles;
  }

  /**
  * Watch the roles value
  */
  watchRoles(): Observable<any> {
    return this.rolesSub.asObservable();
  }

  /**
   * Gets suite permissions
   */
  getSuitePermissions() {
    return this.isEmpty(this.suitePermissions) ? null : this.suitePermissions;
  }

  /**
   * Watchs suite permissions
   */
  watchSuitePermissions(): Observable<any> {
    return this.suitePermissionSub.asObservable();
  }

  /**
   * Determines whether empty is
   */
  private isEmpty(obj: any) {
    return Object.keys(obj).length === 0 &&
      obj.constructor === Object;
  }
  public checkPermissionsForMenu(
    type: PermissionType,
    suite?: string,
    report?: string,
    resource?: string
  ): boolean {
    const userPermissions = this.authService.getPermissions();
    const user: UserModel = this.authService.getLoggedInUser();

    for (var i = 0; i < user.userRoleMappingDtos.length; i++) {
      const element = user.userRoleMappingDtos[i];
      if (resource == 'reporting') {
        var result = element.role.permissions.filter(x => x.permissionsection === 'Reports' || x.permissionsection === 'Approver');
        if (result.length > 0) {
          this.reportFlag = true;
          return true;
          break;
        }
      }
      else if (resource == 'approver-panel') {
        var result = element.role.permissions.filter(x => x.permissionsection === 'Approver');
        if (result.length > 0) {
          return true;
          break;
        }
      }
      else if (resource == 'ingestion') {
        var result = element.role.permissions.filter(x => x.permissionsection === 'Ingestion');
        if (result.length > 0) {
          this.ingestionFlag = true;
          return true;
          break;
        }
      }
      else if (resource == 'report-bundling') {
        var result = element.role.permissions.filter(x => x.permissionsection === 'Report Bundling');
        if (result.length > 0) {
          return true;
          break;
        }
      }
      else if (resource == 'ingestion|report-bundling') {
        var result = element.role.permissions.filter(x => x.permissionsection === 'Report Bundling' || x.permissionsection === 'Ingestion');
        if (result.length > 0) {
          return true;
          break;
        }
      }
      else if (resource == 'administration') {
        var result = element.role.permissions.filter(x => x.permissionsection === 'Admin');
        if (result.length > 0) {
          this.adminFlag = true;
          return true;
          break;
        }
      }
      else if (resource == 'support') {
        var result = element.role.permissions.filter(x => x.permissionsection === 'Support');
        if (result.length > 0) {
          return true;
          break;
        }
      }
      else if (resource == 'refdata admin') {
        var result = element.role.permissions.filter(x => x.permissionsection === 'RefData Admin');
        if (result.length > 0) {
          return true;
          break;
        }
      }
    }
    return false;
  }
}
