import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { TaskBoardComponent } from './taskboard/taskboard.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        TaskBoardComponent
    ],
    exports: [
        TaskBoardComponent
    ]
})
export class DashBoardModule {
}
