import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Mock } from '../../shared/models/mock.model';
import { AuthenticationService } from '../security/authentication.service';
import { AlertService } from './alert.service';
import { ErrorService } from './error.service';

/**
 * Injectable api service
 */
@Injectable()
export class ApiService {
  /**
   * Determines if will use mock data
   */
  private isMock = environment.mock === 'true' ? true : false;

  /**
   * Url of api service
   */
  private url: string = !this.isMock ? environment.apiUrl : '';

  /**
   * Creates an instance of api service.
   */
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private alertService: AlertService,
    private errorService: ErrorService
  ) { }

  /**
   * Gets cookie
   */
  private getCookie(name) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
      return parts
        .pop()
        .split(';')
        .shift();
    }
    return '';
  }

  /**
   * Create headers
   */
  private createHeaders(contentType: boolean | string = true, hasFileContent: boolean = false) {
    const headersObj = {
      'X-CSRFToken': this.getCookie('csrftoken'),
      [environment.tokenHeader]: this.authService.getTokenHeader()
    };
    if (contentType && !hasFileContent) {
      headersObj['Content-Type'] =
        typeof contentType === 'string' ? contentType : 'application/json';
    }

    return new HttpHeaders(headersObj);
  }

  /**
   * Gets query string
   */
  private getQueryString(params) {
    if (typeof params === 'undefined' || typeof params !== 'object') {
      params = {};
      return params;
    }

    let query = '?';
    let index = 0;

    // tslint:disable-next-line:forin
    for (const i in params) {
      index++;
      const param = i;
      const value = params[i];
      if (index === 1) {
        query += param + '=' + value;
      } else {
        query += '&' + param + '=' + value;
      }
    }
    return query;
  }

  /**
   * Checks if logged
   */
  private checkIfLogged(error, errorMessage) {
    if (this.authService.isloggedIn === true) {
      if (error.status === 401) {
        //Task - 958173 - removed
        //localStorage.setItem('x-id-token', '');

        this.alertService.error(errorMessage, false);

        this.authService.isloggedIn = false;
        //Task - 958173
        this.authService.refreshToken();
        //Task - 958173 - removed
        //this.router.navigate(['/']);
      }
    }
  }

  /**
   * Gets final url
   */
  private getFinalUrl(slug: string, id?: number): string {
    const finalSlug = id ? slug + id : slug;
    return this.isMock ?
      this.url + Mock[finalSlug] :
      this.url + finalSlug;
  }

  /**
   * GET method api service
   */
  public get(slug, paramsServer, contentType: boolean | string = true) {
    let finalUrl: string = slug;
    if (paramsServer !== '' && !this.isMock) {
      finalUrl = finalUrl + this.getQueryString(paramsServer);
    }
    if (slug !== 'login/' && !this.isMock) {
      if (this.authService.checkIdleTime()) {
        var updateLastActionDate = (slug == 'IsClientUnderMaintenance/' ? false : true);
        this.authService.isValidToken(updateLastActionDate);
      }
    }
    finalUrl = this.getFinalUrl(finalUrl);
    return this.http
      .get(finalUrl, {
        headers: this.createHeaders(contentType)
      })
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error, errorMessage);
          return this.handleError(error, errorMessage);
        })
      );
    }

    /**
   * GET File method api service
   */

  public getFile(slug, paramsServer, contentType: boolean | string = true) {
    let finalUrl: string = slug;
    if (paramsServer !== '' && !this.isMock) {
      finalUrl = finalUrl + this.getQueryString(paramsServer);
    }
    if (slug !== 'login/' && !this.isMock) {
      if (this.authService.checkIdleTime()) {
        this.authService.isValidToken(true);
      }
    }
    finalUrl = this.getFinalUrl(finalUrl);
    return this.http
      .get(finalUrl,
        {observe: 'response', responseType: 'blob', headers: this.createHeaders(contentType)
      })
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error, errorMessage);
          return this.handleError(error, errorMessage);
        })
      );
  }

  public getWithHttpParam(slug, params, contentType: boolean | string = true) {
    let finalUrl: string = slug;
    if (slug !== 'login/' && !this.isMock) {
      if (this.authService.checkIdleTime()) {
        this.authService.isValidToken(true);
      }
    }
    finalUrl = this.getFinalUrl(finalUrl);
    return this.http
      .get(finalUrl, {
        headers: this.createHeaders(contentType),
        params: params
      })
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error, errorMessage);
          return this.handleError(error, errorMessage);
        })
      );
  }


  /**
   * POST method api service
   */
  public post(slug, data, message, contentType: boolean | string = true, hasFileContent: boolean = false) {
    const finalUrl: string = this.getFinalUrl(slug);
    if (slug !== 'login' && !this.isMock) {
      if (this.authService.checkIdleTime()) {
        this.authService.isValidToken(true);
      }
    }
    return this.http
      .post(finalUrl, data, {
        headers: this.createHeaders(contentType, hasFileContent)
      })
      .pipe(
        map(res => {
          const obj = res;
          if (message) {
            this.alertService.success(message, false);
          }
          return obj;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error, errorMessage);
          return this.handleError(error, errorMessage);
        })
      );
  }

  /**
  * PUT method api service
  */
  public put(slug, id, data, message, contentType: boolean | string = true) {
    const finalUrl: string = this.getFinalUrl(slug, id);
    if (slug !== 'login' && !this.isMock) {
      if (this.authService.checkIdleTime()) {
        this.authService.isValidToken(true);
      }
    }

    return this.http
      .put(finalUrl, data, {
        headers: this.createHeaders(contentType)
      })
      .pipe(
        map(res => {
          const obj = res;
          if (message != '') {
            this.alertService.success(message, false);
          }
          return obj;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error, errorMessage);
          return this.handleError(error, errorMessage);
        })
      );
  }

  /**
   * PATCH method api service
   */
  public patch(slug, id, data, message, contentType: boolean | string = true) {
    const finalUrl: string = this.getFinalUrl(slug, id);
    if (slug !== 'login' && !this.isMock) {
      if (this.authService.checkIdleTime()) {
        this.authService.isValidToken(true);
      }
    }

    return this.http
      .patch(finalUrl, data, {
        headers: this.createHeaders(contentType),
      })
      .pipe(
        map(res => {
          const obj = res;
          this.alertService.success(message, false);
          return obj;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error, errorMessage);
          return this.handleError(error, errorMessage);
        })
      );
  }

  /**
   * DELETE method api service
   */
  public delete(slug, id, message, contentType: boolean | string = true) {
    const finalUrl: string = this.getFinalUrl(slug, id);
    if (slug !== 'login' && !this.isMock) {
      if (this.authService.checkIdleTime()) {
        this.authService.isValidToken(true);
      }
    }

    return this.http
      .delete(finalUrl, {
        headers: this.createHeaders(contentType)
      })
      .pipe(
        map(res => {
          const obj = res;

          if (message) {
            this.alertService.success(message, false);
          }

          return obj;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error, errorMessage);
          return this.handleError(error, errorMessage);
        })
      );
  }

  /**
   * Get error message from error response
   */
  private getErrorMessage(error: Response | any) {
    if (typeof error.error !== 'object') {
      return error.error;
    } else {
      let m = '';
      // tslint:disable-next-line:forin
      for (const key in error.error) {
        if (error.error['message'] !== undefined) {
          m = error.error['message'];
        }
        if (error.error['Message'] !== undefined) {
          m = error.error['Message'];
        }
      }
      if (m !== '') {
        return m;
      }
    }
    if (error.message) {
      return error.message;
    }
    const err = error.toString();
    return err ? err.trim() !== '' : 'Error executing the request';
  }

  /**
   * Handles errors
   */
  private handleError(error, errorMessage) {
    if (this.authService.isloggedIn === true) {
      if (error.error.ExceptionMessage != undefined) {
        if (error.status === 500) {
          errorMessage = "A problem occured while handling your request!";
        }
        else if (error.status === 400) {
          errorMessage = "Bad Request - Please make sure the data is valid!";
        }
        else if (error.status === 401) {
          errorMessage = "Unauthorized. You do not have access to perform this operation!";
        }
        else if (error.status === 403) {
          errorMessage = "Forbidden Request!";
        }
        else if (error.status === 404) {
          errorMessage = "Data not found!";
        }
        else if (error.status === 409) {
          errorMessage = "Data already exists.";
        }
        else if (error.status === 502) {
          errorMessage = "Bad Request!";
        }
        else if (error.status === 0) {
          errorMessage = "A problem occured while handling your request. Please try refreshing your page!";
        }
      }
    }

    if (error.status === 401) {
      errorMessage = "Unauthorized. You do not have access to perform this operation. Please try logging in again.";
    }
    else if (error.status === 0) {
      errorMessage = "A problem occured while handling your request. Please try refreshing your page!";
    }
    else if (error.status === 403) {
      errorMessage = "Forbidden Request!";
    }

    this.alertService.error(errorMessage, false);
    this.redirectToAccessDenied(error);
    return throwError(error);
  }

  // Redirect user to access denied page if they don't have access to application or module
  redirectToAccessDenied(error) {
    if (error.status === 401) {
      this.errorService.getUnAuthorizedUserEmailId(error);
      this.router.navigate(['/access-denied']);
    }
  }
}
