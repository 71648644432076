import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { PrivacyNoticeComponent } from './component/privacy-notice.component';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    PrivacyNoticeComponent
  ],
  exports: [
    PrivacyNoticeComponent,
  ],
  imports: [
    RouterModule,
    NgbTooltipModule,
    SharedModule
  ]
})
export class PrivacyNoticeModule { }
