<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
    <div class="col-12 col-lg-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
    </div>
</div>

<div class="row" *ngIf="ingestionModel">
    <div class="col-6">
        <div class="row header">Ingestion Details</div>
        <form #ingestionForm="ngForm">
            <div class="row form-row">
                <div class="col-4"><label>Template Type</label></div>
                <div class="col-8">
                    <select (change)="onTemplateGroupChange($event.target.value)" id="templateGroup"
                        name="templateGroup" [(ngModel)]="selectedTemplateGroupId"
                        class="ingestion-form-control w-100 select-arrow">
                        <option *ngFor="let group of templateGroups" [selected]="group.value == selectedTemplateGroupId"
                            value={{group.value}}>
                            {{group.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row form-row">
                <div class="col-4"><label>Template</label></div>
                <div class="col-8">
                    <select (change)="onTemplateChange($event.target.value)" id="templateType" name="templateType"
                        [(ngModel)]="selectedTemplateId" class="ingestion-form-control w-100 select-arrow">
                        <option *ngFor="let type of  templates" [selected]="type.value == selectedTemplateId"
                            value={{type.value}}>
                            {{type.name}}
                        </option>
                    </select>
                    <div *ngIf="!newReportPack && selectedReportPack != null && selectedTemplate != null && !selectedTemplate.showFileUpload && selectedReportPack.DataTypes.indexOf(selectedTemplate.tableId) > -1"
                        class="row alert alert-danger">
                        <div>
                            The selected report pack already contains data for {{selectedTemplate.name}}. Please edit
                            the
                            existing form for changing data.
                        </div>
                    </div>
                    <div *ngIf="!newReportPack && selectedReportPack != null && selectedTemplate != null && selectedTemplate.showFileUpload && selectedReportPack.DataTypes.indexOf(selectedTemplate.tableId) > -1"
                        class="row alert alert-danger">
                        <div>
                            The selected report pack already contains a load in draft status for
                            {{selectedTemplate.name}}. Please submit the existing draft load or abandon the previous
                            draft load using the 'Abandon Previous Draft Load' button below, before a new upload.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row">
                <div class="col-4"><label>Create New Report Pack ?</label></div>
                <div class="col-8">
                    <kendo-switch name="newReportPackSelection" [(ngModel)]="newReportPack" onLabel="Yes" offLabel="No"
                        (valueChange)="onNewReportPackSelectionChange($event)">
                    </kendo-switch>
                </div>
            </div>
            <div class="row form-row" *ngIf="newReportPack">
                <div class="col-4"><label>Report Pack Title</label></div>
                <div class="col-8">
                    <input type="text" required maxlength="50" minlength="4"
                        [(ngModel)]="ingestionModel.reportpacktitle" class="ingestion-form-control"
                        #reportpackttl="ngModel" id="reportpacktitle" name="reportpacktitle"
                        pattern="^[A-Za-z0-9 _\-.]*$" trim-text>
                    <div *ngIf="reportpackttl.invalid && (reportpackttl.dirty || reportpackttl.touched)"
                        class="alert alert-danger">
                        <div *ngIf="reportpackttl.errors.required">
                            Report pack title is a mandatory field.
                        </div>
                        <div *ngIf="reportpackttl.errors.pattern">
                            Report pack title is not valid, as it contains special characters.
                        </div>
                        <div *ngIf="reportpackttl.errors.minlength">
                            Report pack title must be at least 4 characters long.
                        </div>
                        <div *ngIf="reportpackttl.errors.maxlength">
                            Report pack title should not exceed 50 characters.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row" *ngIf="newReportPack">
                <div class="col-4"><label>Report Pack Description</label></div>
                <div class="col-8">
                    <textarea class="ingestion-form-control" [(ngModel)]="ingestionModel.reportpackdesc" maxlength="150"
                        name="reportpackDescription" #reportpackdsc="ngModel" pattern="[^^/:*|'<>\\{}\[\]]*"
                        id="reportpackdesc" trim-text></textarea>
                    <div *ngIf="reportpackdsc.invalid && (reportpackdsc.dirty || reportpackdsc.touched)"
                        class="alert alert-danger">
                        <div *ngIf="reportpackdsc.errors.pattern">
                            The report pack description is not valid, as it contains special
                            characters.
                        </div>
                        <div *ngIf="reportpackdsc.errors.maxlength">
                            Report pack description should not exceed 150 characters.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row" *ngIf="!newReportPack">
                <div class="col-4"><label>Report Pack </label></div>
                <div class="col-8">
                    <app-report-pack-filter [(selectedReportPacks)]="selectedReportPack"
                        [showOnlyValidReportPacks]="false" [selectedRpIds]="ingestionModel.reportpackid"
                        [updateServiceOnRpSelection]="false"
                        (selectedReportPacksChange)="onExistingReportPackSelection()">
                    </app-report-pack-filter>
                    <h6 *ngIf="dataPrepInProgress" class="text-white-50 font-italic small-font mt-3">
                        <i class="fas fa-exclamation-triangle hvr-shrink failed-icon mr-3"></i>Data preparation is in
                        progress for
                        the report pack. Please initiate ingestion after data preparation is complete for the report
                        pack!
                    </h6>
                </div>
            </div>
            <div class="row form-row"
                *ngIf="((newReportPack || ingestionModel.rpHasNullReportingDate) && selectedTemplate && selectedTemplate.reportDateMandatory)">
                <div class="col-4"><label>Reporting Date</label></div>
                <div class="col-8">
                    <p-calendar required [(ngModel)]="ingestionModel.reportingperiod" [showIcon]="true"
                        [autoZIndex]="true" id="reportingdatecalendar" name="reportingdatecalendar"
                        dateFormat="dd/mm/yy" [readonlyInput]="true" [monthNavigator]="true" yearRange="{{yearRange}}"
                        [yearNavigator]="true" appendTo="body">
                    </p-calendar>
                    <div *ngIf="newReportPack && selectedTemplate.reportDateMandatory && (!ingestionModel.reportingperiod || ingestionModel.reportingperiod === undefined)"
                        class="alert alert-danger">
                        Reporting date is a mandatory field.
                    </div>
                </div>
            </div>
            <div class="row form-row" *ngIf="newReportPack">
                <div class="col-4"><label>Accounting Framework</label></div>
                <div class="col-8">
                    <select id="accountingFramework" name="accountingFramework"
                        [(ngModel)]="ingestionModel.accountingFrameworkId"
                        class="ingestion-form-control w-100 select-arrow">
                        <option *ngFor="let af of  accountingFrameworks"
                            [selected]="af.AccountingFrameworkId == ingestionModel.accountingFrameworkId"
                            value={{af.AccountingFrameworkId}}>
                            {{af.AccountingFrameworkName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row form-row" *ngIf="newReportPack">
                <div class="col-4"><label>Reporting Level</label></div>
                <div class="col-8">
                    <select id="reportingLevel" name="reportingLevel" [(ngModel)]="ingestionModel.reportingLevelId"
                        class="ingestion-form-control w-100 select-arrow">
                        <option *ngFor="let rl of  reportingLevels"
                            [selected]="rl.ReportingLevelId == ingestionModel.reportingLevelId"
                            value={{rl.ReportingLevelId}}>
                            {{rl.ReportingLevelName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row form-row" *ngIf="newReportPack">
                <div class="col-4"><label>Create New Report Pack Sequence ?</label></div>
                <div class="col-8">
                    <kendo-switch name="newReportPackSequenceSelection" [(ngModel)]="newReportPackSequence"
                        onLabel="Yes" offLabel="No" (valueChange)="onRPSequenceSelectionChange($event)">
                    </kendo-switch>
                </div>
            </div>
            <div class="row form-row" *ngIf="newReportPack && newReportPackSequence">
                <div class="col-4"><label>Report Pack Sequence Name</label></div>
                <div class="col-8">
                    <input type="text" required maxlength="50" minlength="4"
                        [(ngModel)]="ingestionModel.reportPackSequenceName" class="ingestion-form-control"
                        #reportPackSequenceName="ngModel" id="reportPackSequenceName" name="reportPackSequenceName"
                        pattern="^[A-Za-z0-9 _\-.]*$" trim-text>
                    <div *ngIf="reportPackSequenceName.invalid && (reportPackSequenceName.dirty || reportPackSequenceName.touched)"
                        class="alert alert-danger">
                        <div *ngIf="reportPackSequenceName.errors.required">
                            Report pack sequence title is a mandatory field.
                        </div>
                        <div *ngIf="reportPackSequenceName.errors.pattern">
                            Report pack sequence title is not valid, as it contains special characters.
                        </div>
                        <div *ngIf="reportPackSequenceName.errors.minlength">
                            Report pack sequence title must be at least 4 characters long.
                        </div>
                        <div *ngIf="reportPackSequenceName.errors.maxlength">
                            Report pack sequence title should not exceed 50 characters.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row" *ngIf="newReportPack && newReportPackSequence">
                <div class="col-4"><label>Report Pack Sequence Description</label></div>
                <div class="col-8">
                    <textarea class="ingestion-form-control" [(ngModel)]="ingestionModel.reportPackSequenceDescription"
                        maxlength="150" name="reportPackSequenceDescription" #reportPackSequenceDescription="ngModel"
                        pattern="[^^/:*|'<>\\{}\[\]]*" id="reportPackSequenceDescription" trim-text></textarea>
                    <div *ngIf="reportPackSequenceDescription.invalid && (reportPackSequenceDescription.dirty || reportPackSequenceDescription.touched)"
                        class="alert alert-danger">
                        <div *ngIf="reportPackSequenceDescription.errors.pattern">
                            The report pack sequence description is not valid, as it contains special
                            characters.
                        </div>
                        <div *ngIf="reportPackSequenceDescription.errors.maxlength">
                            Report pack sequence description should not exceed 150 characters.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row" *ngIf="newReportPack && !newReportPackSequence">
                <div class="col-4"><label>Report Pack Sequence</label></div>
                <div class="col-8">
                    <app-report-pack-sequence-filter [(selectedRpSequences)]="selectedReportPackSequence"
                        [selectedRpSequenceIds]="ingestionModel.reportPackSequenceId"
                        (selectedRpSequencesChange)="onExistingRPSequenceSelection()">
                    </app-report-pack-sequence-filter>
                </div>
            </div>

            <div class="row form-row" *ngIf="!newReportPack">
                <div class="col-4"> <label>Change Reason</label></div>
                <div class="col-8">
                    <textarea class="ingestion-form-control" [(ngModel)]="ingestionModel.changereason" maxlength="150"
                        required name="changeReason" #rpChangeReason="ngModel" pattern="[^^/:*|'<>\\{}\[\]]*"
                        id="rpChangeReason" trim-text></textarea>
                    <div *ngIf="rpChangeReason.invalid && (rpChangeReason.dirty || rpChangeReason.touched)"
                        class="alert alert-danger">
                        <div *ngIf="rpChangeReason.errors.required">
                            Change reason is a mandatory field.
                        </div>
                        <div *ngIf="rpChangeReason.errors.pattern">
                            The change reason is not valid, as it contains special characters.
                        </div>
                        <div *ngIf="rpChangeReason.errors.maxlength">
                            Change Reason should not exceed 150 characters.
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="w-100 justify-content-center info-message" *ngIf="showFileUploadSection">
                Please upload a file of size upto 35 MB.
            </div> -->
            <div class="files mb-0 w-100" *ngIf="showFileUploadSection">
                <input type="file" class="ingestion-form-control" name="fileUpload"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    (change)="handleFileInput($event.target.files)">
                <p *ngIf="showFileDetail == false" class="file-upload-text">
                    Click in this area
                    to upload a file.
                </p>
                <div *ngIf="showFileDetail == true"
                    class="file-upload-text d-flex flex-column align-items-center justify-content-center">
                    <i class="far fa-file-excel text-success fa-2x mb-3"></i>
                    <h5 class="text-center text-break w-75">{{ingestionModel.fileName}}</h5>
                </div>
            </div>
        </form>
    </div>
    <div class="col-6">
        <div class="row header" style="margin-left:1px">Report Run</div>
        <app-report-tree [(selectedReports)]="selectedReports" selectDependencies="true"></app-report-tree>
    </div>
    <div class="alignButtons m-0 d-md-block mt-3">
        <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button" (click)='backToIngestion()'>
            <span>Back to Ingestion</span>
        </button>
        <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button" (click)='clear()'>
            <span>Clear</span>
        </button>
        <button class="btn btn-modal-pos small-font hvr-shrink" type="button" (click)="onAbandonPreviousLoad()"
            *ngIf="!newReportPack && selectedReportPack != null && selectedTemplate != null && selectedTemplate.showFileUpload && selectedReportPack.DataTypes.indexOf(selectedTemplate.tableId) > -1">
            <span>Abandon Previous Draft Load</span>
        </button>
        <button class="btn btn-modal-pos small-font hvr-shrink" type="button" (click)="onStartIngestion()"
            [disabled]="ingestionForm && ingestionForm.form ? !ingestionForm.form.valid : false">
            <span>Start Ingestion</span>
        </button>
    </div>
</div>