<app-loader #rf *ngIf="loading"></app-loader>
<div class="step2-pnp animated fadeIn fast">
    <div class="row mt-3">
        <div class="col-12">
            <h6 class="text-white-50  font-italic small-font mt-3">
                <i class="fas fa-info-circle text-yellow mr-3"></i>Please note that a maximum of 200 rows and 10
                columns can be selected at a time.
            </h6>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-6">
            <div class="d-flex align-items-center justify-content-center flex-column mb-3 text-center">
                <span class="badge-header-name mb-3">Row Filter</span>
                <p-dropdown *ngIf="filterType == 1" [options]="pageDropdownList" [(ngModel)]="selectedPage"
                            placeholder="Select a page" [showClear]="true" optionLabel="description" class="prime-dropdown"
                            (onChange)="onPageSelect()"></p-dropdown>
            </div>
            <div *ngIf="showFilters == false"
                 class="d-flex flex-column align-items-center justify-content-center drag-img">
                <img src="assets/images/drag-drop-notification.svg" class="mt-5">
                <h5 class="my-5 text-info"><i>Please select a page to enable row selection</i></h5>
            </div>
            <p-table *ngIf="showFilters" #rowCols [value]="rowFilter" [paginator]="false"
                     class="table-irr rowFilter-table" [columns]="rowFilterTableHeaders" [scrollable]="true"
                     scrollHeight="420px">
                <ng-template pTemplate="summary">
                    <button class="btn btn-clear-filter hvr-shrink" type="button"
                            (click)="rowCols.reset()">
                        <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                    </button>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                        ariaLabelDesc="Activate to sort in descending order"
                                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns">
                            <input *ngIf="col.field =='rowcode' || col.field =='description'" type="text" class="w-100"
                                   placeholder="Filter Text"
                                   [value]="rowCols.filters && rowCols.filters[col.field] ? rowCols.filters[col.field].value: ''"
                                   (input)="rowCols.filter($event.target.value, col.field, 'contains')">
                            <p-checkbox *ngIf="col.field !='rowcode' && col.field !='description'" binary=true
                                        [(ngModel)]="col.checked" (onChange)="onRowHeaderCheckBoxToggle($event.checked)"
                                        class="position-relative"></p-checkbox>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.rowcode}}</td>
                        <td>{{rowData.description}}</td>
                        <td>
                            <p-checkbox name="groupname" binary=true [(ngModel)]="rowData.checked"
                                        (onChange)="onRowSelectChange(rowData.rowcode)"></p-checkbox>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="col-6">
            <div class="d-flex align-items-center justify-content-center flex-column mb-3 text-center">
                <span class="badge-header-name mb-3">Column Filter</span>
                <p class="readOnlyPageValue mb-0 w-100" *ngIf="filterType == 1 && selectedPage">
                    {{selectedPage ? selectedPage.description : 'none'}}
                </p>
            </div>
            <div *ngIf="showFilters == false"
                 class="d-flex flex-column align-items-center justify-content-center drag-img mt-5">
                <img src="assets/images/drag-drop-notification.svg" class="mt-5">
                <h5 class="my-5 text-info"><i>Please select a page to enable column selection</i></h5>
            </div>
            <div class="row d-flex">
                <div [ngClass]="filterType == 2 ? 'col-3 p-0' : 'd-none'">
                    <p-table [value]="subReports" class="table-irr subReport-table" [scrollable]="true" #subReport
                             (onRowSelect)="onSubReportSelect()" scrollHeight="420px" selectionMode="single"
                             [(selection)]="selectedSubReport" dataKey="reportName">
                        <ng-template pTemplate="summary">
                            <button class="btn btn-clear-filter hvr-shrink" type="button"
                                    (click)="subReport.reset()">
                                <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="reportName">
                                    Sub-Reports <p-sortIcon field="reportName"
                                                            ariaLabel="Activate to sort"
                                                            ariaLabelDesc="Activate to sort in descending order"
                                                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    <input type="text" class="w-100" placeholder="Filter Text"
                                           [value]="subReport.filters && subReport.filters['reportName'] ? subReport.filters['reportName'].value: ''"
                                           (input)="subReport.filter($event.target.value, 'reportName', 'contains')">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-subReport>
                            <tr [pSelectableRow]="subReport">
                                <td>
                                    {{subReport.reportName}} <i *ngIf="subReport.isSelected == true"
                                                                class="fas fa-check-circle float-right text-success"></i>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div [ngClass]="filterType == 2 ? 'col-9' : 'col-12'">
                    <p-table *ngIf="showFilters && (filterType != 2 || (filterType==2 && selectedSubReport))" #colColumn
                             [value]="columnFilter" [paginator]="false" class="table-irr columnFilter-table"
                             [columns]="columnFilterTableHeaders" [scrollable]="true" scrollHeight="420px">
                        <ng-template pTemplate="summary">
                            <button class="btn btn-clear-filter hvr-shrink" type="button"
                                    (click)="colColumn.reset()">
                                <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col *ngFor="let col of columns" style="width:150px">
                            </colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                                ariaLabelDesc="Activate to sort in descending order"
                                                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                                </th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns">
                                    <input *ngIf="col.field =='columnName' || col.field =='columnDescription'"
                                           type="text" class="w-100" placeholder="Filter Text"
                                           [value]="colColumn.filters && colColumn.filters[col.field] ? colColumn.filters[col.field].value: ''"
                                           (input)="colColumn.filter($event.target.value, col.field, 'contains')">
                                    <p-checkbox *ngIf="col.field !='columnName' && col.field !='columnDescription'"
                                                #colHeader binary=true [(ngModel)]="col.checked"
                                                (onChange)="onColumnHeaderCheckBoxToggle(col.field, $event.checked, colHeader)"
                                                class="position-relative">
                                    </p-checkbox>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                                <td *ngFor="let c of columns">
                                    <p *ngIf="c.field == 'columnName' || c.field == 'columnDescription'" class="mb-0">
                                        {{rowData[c.field]}}
                                    </p>
                                    <p-checkbox *ngIf="c.field != 'columnName' && c.field != 'columnDescription'"
                                                #colCheckBox name="groupname" binary=true [(ngModel)]="rowData[c.field]"
                                                (onChange)="onColumnSelectChange(rowData.pageName, rowData.columnName, c.reportId, c.reportName ,rowData.fieldName, colCheckBox)">
                                    </p-checkbox>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-6">
            <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button" (click)="goToPreviousTab()">
                <div class="icon-container"><i class="fas fa-arrow-left fa-lg"></i></div><span>Prev</span>
            </button>
        </div>
        <div class="col-6 text-right">
            <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button" (click)="goToNextTab()">
                <div class="icon-container"><i class="fas fa-arrow-right fa-lg"></i></div><span>Next</span>
            </button>
        </div>
    </div>
</div>