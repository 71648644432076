import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

/**
 * Injectable
 */
@Injectable()
export class ApplicationConfigurationService {
  constructor(private apiService: ApiService) { }

  getApplicationConfiguration() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.get('getAppConfiguration/', '', '')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  } 

  updateApplicationConfiguration(appConfig: any) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.put('updateAppConfiguration/', appConfig.ConfigKey, appConfig, 'Application Configuration has been updated successfully!')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}
