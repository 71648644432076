import { NgModule } from '@angular/core';
import { LandingComponent } from './component/landing.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    LandingComponent,
    LandingPageComponent
  ],
  exports: [
    LandingComponent,
    LandingPageComponent
  ],
  imports: [
    RouterModule
  ]
})
export class LandingModule { }
