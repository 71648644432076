import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { Router } from '@angular/router';
import { ReportPackService } from 'src/app/core/services/reportPack.service';
import { ReportPacksModel } from 'src/app/shared/models/reportpacks.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { FilterUtils } from 'primeng/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromptModalComponent } from 'src/app/shared/prompt-modal/prompt-modal.component';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';


@Component({
    selector: 'app-reportpack-panel',
    templateUrl: './reportpack-panel.component.html',
    styleUrls: ['./reportpack-panel.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ReportPackComponent implements OnInit, OnDestroy {
    /**
     * loading
     */
    loading: boolean = false;
    /**
     * List of report packs
     */
    reportpacks: any[] = [];
    headerCols: any;
    reportDateFilters: Date[];
    ingestedDateFilters: Date[];
    promptResponse: Subscription;
    selectedReportPack: any;
    private dateTime = new Date();
    private yearRange = "2000:" + this.dateTime.getFullYear().toString();

    constructor(
        private reportPackService: ReportPackService,
        private router: Router,
        private authService: AuthenticationService,
        private alertService: AlertService,
        private modalService: NgbModal,
        private commonService: CommonService
    ) {
    }

    /**
     * on init
     */
    ngOnInit() {
        if (!this.authService.isPrivacyNoticeAcknowledged) {
            this.router.navigate(['/privacy-notice']);
        }

        this.promptResponse = this.commonService.watchPrompt().subscribe(response => {
            this.modalService.dismissAll();
            if (response.callFor === 'rpActivation') {
                if (response.response === 'Yes') {
                    this.activateordeactivateReportPack();
                }
                else this.selectedReportPack = null;
            }
        });

        this.getReportPackList();

        this.headerCols = [
            { field: 'ReportPackID', header: 'Report Pack ID' },
            { field: 'Title', header: 'Report Pack Title' },
            { field: 'RP_Description', header: 'Description' },
            { field: 'ReportDate', header: 'Report Date' },
            { field: 'IngestedBy', header: 'Created By' },
            { field: 'IngestedDate', header: 'Created Date' }
        ];

        FilterUtils['dateRangeFilter'] = (value, filter: [Date, Date]): boolean => {
            if (!filter || (!filter[0] && !filter[1]))
                return true;

            var val = new Date(value);
            var s = filter[0].getTime();
            var e;
            if (filter[1])
                e = filter[1].getTime() + (24 * 60 * 60 * 1000) - 1000;
            else e = s + (24 * 60 * 60 * 1000) - 1000;
            return val.getTime() >= s && val.getTime() <= e;
        }
    }

    ngOnDestroy(): void {
        this.modalService.dismissAll();
    }

    private getReportPackList() {
        this.loading = true;
        this.reportPackService.getReportPackList().then((reportPacks: ReportPacksModel[]) => {
            this.reportpacks = JSON.parse(JSON.stringify(reportPacks));
            this.loading = false;
        }).catch(() => { this.loading = false; });
    }

    closeDateRangeFilter(dateComponent: any, filters: Date[]) {
        if (filters[1]) {
            dateComponent.hideOverlay();
        }
    }

    clearFilters() {
        this.ingestedDateFilters = null;
        this.reportDateFilters = null;
    }

    viewOrEditReportPack(selectedRow: any, editMode: boolean) {
        this.reportPackService.editMode = editMode;
        this.loading = true;
        this.reportPackService.getReportPack(selectedRow.ReportPackID).then(() => {
            if (this.reportPackService.templateGroups) {
                this.loading = false;
                this.routeToReportPackForm();
            }
            else {
                this.reportPackService.getDataForReportPackForm().then(() => {
                    this.loading = false;
                    this.routeToReportPackForm();
                }).catch(() => { this.loading = false; });
            }
        }).catch(() => { this.loading = false; });
    }

    routeToReportPackForm() {
        this.router.navigate(['app/reportpack-management/report-pack-form']);
    }

    activateordeactivateReportPack() {
        this.loading = true;
        this.reportPackService.saveActivationStatus(this.selectedReportPack.ReportPackID, !this.selectedReportPack.isVisible).then(() => {
            let successMsg = this.selectedReportPack.isVisible ? "Report pack deactivated successfully!" : "Report pack activated successfully!";
            this.alertService.success(successMsg);
            this.selectedReportPack = null;
            this.getReportPackList();
        }).catch(() => { this.loading = false; });
    }

    openPromptModal(rowData) {
        this.selectedReportPack = rowData;
        var openModal = this.modalService.open(PromptModalComponent, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
        openModal.componentInstance.promptCallFor = 'rpActivation';
        openModal.componentInstance.promptMessage = this.selectedReportPack.isVisible ? 'Are you sure you want to deactivate this report pack?' : 'Are you sure you want to activate this report pack?';
    }
}
