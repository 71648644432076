import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClientManagementComponent } from './client-management/client-management.component';
import { ReportDownloaderComponent } from './report-downloader/report-downloader.component';
import {MultiSelectModule} from 'primeng/multiselect';
import { QueryEngineComponent } from './query-engine/query-engine.component';
import { RefDataComponent } from './ref-data/ref-data.component';

@NgModule({
  declarations: [
    ClientManagementComponent,
    ReportDownloaderComponent,
    QueryEngineComponent,
    RefDataComponent 
  ],
  imports: [
    CommonModule,
    SharedModule,
    MultiSelectModule
  ],
  exports: [
    ClientManagementComponent
  ]
})
export class SupportModule { }
