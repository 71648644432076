<div class="modal-body text-center" ngbAutofocus>
    {{promptMessage}}
</div>
<div class="modal-footer text-center">
    <div class="w-100">
        <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button"
            (click)="onYesClick();">
            <span>Yes</span>
        </button>
        <button class="btn btn-modal-pos small-font hvr-shrink" (click)="onNoClick();" type="button">
            <span>No</span>
        </button>
    </div>
</div>