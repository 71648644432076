<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
    <div class="col-12 col-lg-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
    </div>
    <div class="col-12 col-lg-6 text-left text-lg-right">
        <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button"
            (click)="open(addUser,'','Add')">
            <div class="icon-container"><i class="fas fa-user-plus fa-lg"></i></div><span>Add User</span>
        </button>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="user-mgmt-scroll">
            <p-table #userList [value]="users" [paginator]="true" [rows]="12" class="table-irr usr-mgmt-table"
                selectionMode="single" [(selection)]="selectedUser" [columns]="cols" [scrollable]="true"
                sortField="username" [scrollHeight]="'calc(100vh - 292px)'">
                <ng-template pTemplate="paginatorleft">
                    <button class="btn btn-clear-filter hvr-shrink" type="button" (click)="userList.reset()">
                        <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                    </button>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                        </th>
                        <th>Is Active ?</th>
                        <th>Actions</th>
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns">
                            <input type="text" class="w-100" placeholder="Filter Text"
                                [value]="userList.filters && userList.filters[col.field] ? userList.filters[col.field].value: ''"
                                (input)="userList.filter($event.target.value, col.field, 'contains')">
                        </th>
                        <th>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.username}}</td>
                        <td>{{rowData.email}}</td>
                        <td>{{rowData.isactive === true ?'Yes':'No'}}</td>
                        <td>
                            <button type="button" class="btn-table-icon hvr-shrink" container="body"
                                [disabled]="!rowData.isEditable" ngbTooltip="Edit User" triggers="hover"
                                (click)="open(addUser,rowData,'Edit')">
                                <i class="fas fa-edit fa-lg"></i>
                            </button>
                            <button type="button" class="btn-table-icon hvr-shrink" container="body"
                                ngbTooltip="View User Permissions" triggers="hover" [disabled]="!rowData.isEditable"
                                (click)="openModal(userRole,rowData)">
                                <i class="fas fa-file-alt fa-lg"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<ng-template #addUser let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">
            {{mode +' User'}}<i class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="modal.close()"></i>
        </h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 col-xl-4 d-flex align-items-center justify-content-center">
                <form class="material-styling w-100 mt-3" #userForm="ngForm">
                    <div class="group">
                        <input type="text" required minlength="4" maxlength="50" [(ngModel)]="username" #uname="ngModel"
                            name="username" id="username" pattern="^[A-Za-z0-9 _\-.]*$" trim-text />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Username</label>


                        <div *ngIf="uname.invalid && (uname.dirty || uname.touched)" class="alert alert-danger">
                            <div *ngIf="uname.errors.required">
                                Username is required.
                            </div>
                            <div *ngIf="uname.errors.minlength">
                                Username must be at least 4 characters long.
                            </div>

                            <div *ngIf="uname.errors.maxlength">
                                Username should not exceed 50 characters.
                            </div>

                            <div *ngIf="uname.errors.pattern">
                                Username not valid as it contains special characters.
                            </div>
                        </div>

                    </div>
                    <div class="group">
                        <input type="text" required email [(ngModel)]='email' [disabled]="mode === 'Edit'? true : false"
                            autocomplete="new-email" #mail="ngModel" name="email" id="email" />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label *ngIf="mode === 'Add'">Email</label>
                        <label class="non-input-label" *ngIf="mode === 'Edit'">Email</label>


                        <div *ngIf="mail.invalid && (mail.dirty || mail.touched)" class="alert alert-danger">
                            <div *ngIf="mail.errors.required">
                                Email is required.
                            </div>
                            <div *ngIf="mail.errors.email">
                                Email must be a valid email address.
                            </div>
                        </div>

                    </div>
                    <div class="group">
                        <p-checkbox name="active" value="val1" class="common-checkbox" [(ngModel)]='isactive'
                            [binary]="true" [disabled]="mode === 'Add' ? true : false">
                        </p-checkbox>
                        <label class="non-input-label">Is Active ?</label>
                    </div>
                    <div class="group" *appPermission="['S',null,null,'support']">
                        <p-checkbox name="displayUser" value="val1" class="common-checkbox" [(ngModel)]='displayUser'
                            [binary]="true" >
                        </p-checkbox>
                        <label class=" non-input-label">Display User ?</label>
                    </div>
                </form>
            </div>
            <div class="col-12 col-xl-8 d-flex flex-column justify-content-center">
                <p-table #addUserList [value]="roles" [paginator]="true" [rows]="5" selectionMode="checkbox"
                    [(selection)]="selectedRoles" class="table-irr data-ingestion" [columns]="userCols"
                    sortField="rolename">
                    <ng-template pTemplate="paginatorleft">
                        <button class="btn btn-clear-filter hvr-shrink" type="button" (click)="addUserList.reset()">
                            <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                        </button>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                <ng-container *ngIf="col.field === 'rolename'; else notNameColumn">
                                  <p-tableHeaderCheckbox (click)="handleCheckboxClick($event)"></p-tableHeaderCheckbox>
                                </ng-container>
                                {{col.header}}
                                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                            </th>
                        </tr>
                        <tr>
                            <th *ngFor="let col of columns">
                                <input type="text" class="w-100" placeholder="Filter Text"
                                    [value]="addUserList.filters && addUserList.filters[col.field] ? addUserList.filters[col.field].value: ''"
                                    (input)="addUserList.filter($event.target.value, col.field, 'contains')">
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr [pSelectableRow]="rowData">
                            <td>
                                <p-tableCheckbox [value]="rowData" class="mr-2"></p-tableCheckbox>{{rowData.rolename}}
                            </td>
                            <td>{{rowData.roledesc}}</td>
                        </tr>
                    </ng-template>
                </p-table>
                <span class="alert alert-danger" [hidden]='roleWarningMessage'>Please select the roles.</span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="float-right m-0">
            <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" (click)='clear(selectedUserRow,mode)'
                type="button">
                <span>Clear</span>
            </button>
            <button class="btn btn-modal-pos small-font hvr-shrink" (click)='submitUser(mode)' type="button"
                [disabled]="userForm && userForm.form ? !userForm.form.valid : false">
                <span>Submit</span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #userRole let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">
            User Roles<i class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="modal.dismiss('Cross click')"></i>
        </h5>
    </div>
    <div class="modal-body">
        <form class="material-styling w-100 mt-4">
            <div class="group">
                <input type="text" value="{{username}}" required disabled />
                <span class="highlight"></span>
                <span class="bar"></span>
                <label class="non-input-label">Username</label>
            </div>
            <div class="group mb-4">
                <input type="text" value="{{email}}" required disabled />
                <span class="highlight"></span>
                <span class="bar"></span>
                <label class="non-input-label">Email</label>
            </div>
        </form>
        <P class="color-teal">Roles</P>
        <ul>
            <li *ngFor="let role of selectedRoles">{{role.rolename}}</li>
        </ul>
    </div>
</ng-template>