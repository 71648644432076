<app-loader *ngIf="fileEditModel.isInitialLoad && loading"></app-loader>
<div class="row">
    <div class="col-12 col-lg-6">
        <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
            [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'" class="last-cap">
        </app-ng7-dynamic-breadcrumb>
    </div>
</div>
<div class="row no-load-data mt-3" *ngIf="!ingestionLoadData">
    <div class="col-12 d-flex flex-column align-items-center justify-content-center text-center">
        <i class="icon-error-report main"></i>
        <h4 class="w-75">Load information is not available. Please click on the view icon ( <i
                class="fas fa-file-alt hvr-shrink fa-lg cursor-pointer text-yellow"></i> ) in the 'Data Load' page to
            view the form.</h4>
    </div>
</div>
<div *ngIf="ingestionLoadData">
    <div class="row mb-3">
        <div class="col-12 d-flex align-items-center">
            <div class="text-center color-teal d-flex align-items-center ">
                <p class="mb-0 font-weight-bold">File Name : </p>
                <p class="mb-0 ml-2 form-data">{{ingestionLoadData.fileName}}</p>
            </div>
            <div class="text-center color-teal d-flex align-items-center ml-5">
                <p class="mb-0 font-weight-bold">Report Pack Name : </p>
                <p class="mb-0 ml-2 form-data">{{ingestionLoadData.reportpacktitle}}</p>
            </div>
            <div class="text-center color-teal d-flex align-items-center ml-5">
                <p class="mb-0 font-weight-bold">Reporting Period : </p>
                <p class="mb-0 ml-2 form-data">{{ingestionLoadData.reportingperiod | date: 'dd/MM/yyyy'}}</p>
            </div>
            <div class="text-center color-teal d-flex align-items-center ml-5">
                <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button"
                    (click)="openReportRunPopUp(reportRunPopUp)">
                    <div class="icon-container"><i class="fa fa-check-square fa-lg"></i></div><span>Report Run</span>
                </button>
            </div>
        </div>
    </div>

    <div class="row">
        <h6 *ngIf="dataPrepInProgress" class="text-white-50 font-italic small-font mt-3"><i
                class="fas fa-exclamation-triangle hvr-shrink failed-icon mr-3"></i>Data preparation is in progress for
            the report pack. Please save as draft and submit your changes after data preparation is complete for the
            report pack!
        </h6>
    </div>

    <div class="row" *ngIf="gridColumns">
        <kendo-grid [data]="gridData" scrollable="scrollable" style="width: 100%" [loading]="loading" [height]="450"
            (remove)="removeRow($event)" [resizable]="true" [pageSize]="pageSize" [skip]="skip" [pageable]="true"
            (pageChange)="pageChange($event)">
            <ng-template kendoGridToolbarTemplate>
                <button class="k-button float-right" (click)="openPopUp(summaryPopUp, 'm')">
                    <i class="fas fa-book-open fa-lg" ngbTooltip="Summary"></i>
                </button>
                <button class="k-button float-right" (click)="openPopUp(sortPopUp, 'lg')">
                    <i class="fas fa-sort fa-lg" ngbTooltip="Sort"></i>
                </button>
                <button class="k-button float-right" (click)="openPopUp(filterPopUp, 'xl')">
                    <i class="fas fa-filter fa-lg" ngbTooltip="Filter"></i>
                </button>
            </ng-template>
            <kendo-grid-command-column title="Actions" width="120px" [locked]="true">
                <ng-template kendoGridCellTemplate>
                    <button class="icon-button hvr-shrink icon-trash" kendoGridRemoveCommand><i
                            class="fas fa-trash-alt fa-sm my-1"></i></button>
                </ng-template>
            </kendo-grid-command-column>
            <kendo-grid-column field="status" title="Status" width="120" [locked]="true">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <i *ngIf="dataItem.status == 'Error'" class="fas fa-exclamation-circle fa-lg text-danger"></i>
                    <i *ngIf="dataItem.status == 'Success'" class="fas fa-check-circle fa-lg text-success"></i>
                    <i *ngIf="dataItem.status == 'Draft'" class="fas fa-pen-fancy fa-lg text-warning"></i>
                    <i *ngIf="dataItem.status == 'Valid'" class="fas fa-check-circle fa-lg text-warning"></i>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="rowRefId" title="IRR Internal ID" width="100" [locked]="true" [editable]="false">
            </kendo-grid-column>
            <kendo-grid-column *ngFor="let c of gridColumns" [id]="c.columnId" [field]="c.field" [editable]="c.editable"
                [title]="c.title" format="{0:n}" [locked]="c.pinned" [hidden]="c.hidden" width="120px"
                [class]="{'k-grid-align-left': c.leftalign, 'k-grid-align-right': c.rightalign, 'k-grid-align-center': c.centeralign}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="col-12 p-0 text-truncate"
                            [ngClass]="[errorCells.indexOf(dataItem.rowRefId +'_'+ dataItem.clientRefId + '_' + c.columnId) > -1 && localDraftCells.indexOf(dataItem.rowRefId +'_'+ dataItem.clientRefId + '_' + c.columnId) == -1 ? 'error-cell': '', 
                        draftCells.indexOf(dataItem.rowRefId +'_'+ dataItem.clientRefId + '_' + c.columnId) > -1 && localDraftCells.indexOf(dataItem.rowRefId +'_'+ dataItem.clientRefId + '_' + c.columnId) == -1 ? 'draft-cell': '',
                        localDraftCells.indexOf(dataItem.rowRefId +'_'+ dataItem.clientRefId + '_' + c.columnId) > -1 ? 'local-draft': '', c.editable ? 'editable': '']"
                            (dblclick)="showEditPopUp(cellPopOutModal, dataItem, c)"
                            title="{{errorCells.indexOf(dataItem.rowRefId +'_'+ dataItem.clientRefId + '_' + c.columnId) > -1 
                            && localDraftCells.indexOf(dataItem.rowRefId +'_'+ dataItem.clientRefId + '_' + c.columnId) == -1 
                            && cellErrorMessageJson[dataItem.rowRefId +'_'+ dataItem.clientRefId + '_' + c.columnId] ? cellErrorMessageJson[dataItem.rowRefId +'_'+ dataItem.clientRefId + '_' + c.columnId] : ''}}">
                            <div class="cell-data-padding">{{dataItem[c.field]}}</div>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>

        <div class="row float-right" [ngStyle]="{'padding-left': '15px'}">
            <button class="btn btn-primary-ey btn-icon mb-2 hvr-shrink small-font form-btn" type="button"
                (click)='backToIngestion()'>
                <div class="icon-container"><i class="fas fa-arrow-left fa-lg"></i></div><span>Back
                    to
                    Ingestion</span>
            </button>

            <button class="btn btn-primary-ey btn-icon mb-2 hvr-shrink small-font form-btn" type="button"
                (click)='downloadErrorAuditReport()'>
                <div class="icon-container hvr-shrink"><i class="fas fa-lg fa-download"></i></div>
                <span>Download Error Audit</span>
            </button>

            <button class="btn btn-primary-ey btn-icon mb-2 hvr-shrink small-font form-btn" type="button"
                (click)='downloadAuditReport()'>
                <div class="icon-container hvr-shrink"><i class="fas fa-lg fa-download"></i></div>
                <span>Download Audit</span>
            </button>

            <button class="btn btn-primary-ey btn-icon mb-2 hvr-shrink small-font form-btn" type="button"
                (click)="saveIngestionForm(true)">
                <div class="icon-container hvr-shrink"><i class="fas fa-lg fa-save"></i></div>
                <span>Save As Draft</span>
            </button>

            <button class="btn btn-primary-ey btn-icon mb-2 hvr-shrink small-font form-btn" type="button"
                (click)="saveIngestionForm(false)">
                <div class="icon-container hvr-shrink"><i class="fas fa-lg fa-thumbs-up"></i></div>
                <span>Submit Form</span>
            </button>
            <button class="btn btn-primary-ey btn-icon mb-2 hvr-shrink small-font form-btn" type="button"
                (click)="undoChanges()">
                <div class="icon-container hvr-shrink"><i class="fas fa-lg fa-undo"></i></div>
                <span>Undo All Changes</span>
            </button>
        </div>
    </div>
</div>
<ng-template #sortPopUp let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">Custom Sort<i
                class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="cancelSortChanges();modal.close()"></i></h5>
    </div>
    <div class="modal-body">
        <h6 class="text-white-50  font-italic small-font mt-3"><i class="fas fa-info-circle text-yellow mr-3"></i>Please
            click on the cell under columns 'Column' and 'Sort Order' to open the editor.</h6>
        <kendo-grid [data]="sortPopUpModel" scrollable="scrollable" [sortable]="false" [resizable]="true"
            [pageable]="false" (cellClick)="sortGridCellClickHandler($event)"
            (cellClose)="sortGridCellCloseHandler($event)" (remove)="removeSortLevel($event)"
            (add)="addSortLevel($event)">
            <ng-template kendoGridToolbarTemplate class="k-grid-toolbar">
                <button class="add-button hvr-shrink" kendoGridAddCommand>Add Level</button>
            </ng-template>
            <kendo-grid-column field="sortLevelText" title="" width="120" [editable]="false"></kendo-grid-column>
            <kendo-grid-column field="columnText" title="Column" width="140">
                <ng-template kendoGridEditTemplate let-formGroup>
                    <div>
                        <select [(ngModel)]="formGroup.value['columnId']">
                            <option *ngFor="let op of sortColumnList" [ngValue]="op.columnId">
                                {{op.title}}</option>
                        </select>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="sortOrderText" title="Sort Order" width="120">
                <ng-template kendoGridEditTemplate let-formGroup>
                    <div>
                        <select [(ngModel)]="formGroup.value['sortOrder']">
                            <option *ngFor="let op of sortOrderOptions" [ngValue]="op.sortOrder">
                                {{op.sortOrderText}}</option>
                        </select>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-command-column title="Actions" width="100">
                <ng-template kendoGridCellTemplate>
                    <button class="icon-button hvr-shrink icon-trash" kendoGridRemoveCommand><i
                            class="fas fa-trash-alt fa-sm my-1"></i></button>
                </ng-template>
            </kendo-grid-command-column>
        </kendo-grid>
    </div>
    <div class="modal-footer">
        <div class="float-right m-0">
            <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button"
                (click)='cancelSortChanges();modal.close()'>
                <span>Cancel</span>
            </button>
            <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button"
                (click)='clearSortSelection();modal.close()'>
                <span>Clear</span>
            </button>
            <button class="btn btn-modal-pos small-font hvr-shrink" type="button"
                (click)="confirmSortSelection();modal.close()">
                <span>Submit</span>
            </button>
        </div>
    </div>

</ng-template>
<ng-template #filterPopUp let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">Custom Filter<i
                class="fas fa-times ml-auto cursor-pointer" aria-label="Close"
                (click)="modal.close();cancelFilterChanges();"></i></h5>
    </div>
    <div class="modal-body">
        <div class="row">
            Show Only Error Rows :
            <kendo-switch [(ngModel)]="showOnlyErrorRows" onLabel="Yes" offLabel="No"></kendo-switch>
        </div>
        <div class="row">
            <kendo-grid [kendoGridBinding]="filterPopUpModel" scrollable="'scrollable'" [sortable]="true"
                [resizable]="true" style="width: 100%" [height]="670" [pageable]="false" [filterable]="true">
                <kendo-grid-column field="title" title="Column" width="120" editable="false"></kendo-grid-column>
                <kendo-grid-column field="showColumn" title="Show Column" width="120" filter="boolean">
                    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No"></kendo-grid-messages>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div>
                            <kendo-switch [(ngModel)]="dataItem['showColumn']" onLabel="Yes" offLabel="No"
                                [disabled]="dataItem.disableShowColumnChange">
                            </kendo-switch>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="enableFilter" title="Enable Filter" width="120" filter="boolean">
                    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No"></kendo-grid-messages>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div>
                            <kendo-switch [(ngModel)]="dataItem['enableFilter']" onLabel="Yes" offLabel="No"
                                (valueChange)="onFilterSwitchChange($event, dataItem)">
                            </kendo-switch>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="filterType" title="Filter Type" width="150px" [filterable]="false">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div *ngIf="dataItem['enableFilter']">
                            <select [(ngModel)]="dataItem['filterType']">
                                <option *ngFor="let op of dataItem.filterTypeOptions" [ngValue]="op">
                                    {{op}}</option>
                            </select>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="value" title="Filter Value" width="500px" [filterable]="false">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div *ngIf="dataItem['enableFilter']">
                            <kendo-numerictextbox *ngIf="dataItem.columnType == 'numeric'"
                                [(ngModel)]="dataItem.numberValue" [decimals]="5" [autoCorrect]="true"
                                [min]="-1000000000000000" [max]="1000000000000000" [format]="'n'">
                            </kendo-numerictextbox>
                            <input *ngIf="dataItem.columnType == 'text' && dataItem.filterType == 'Contains'"
                                [(ngModel)]="dataItem.textValue" maxlength="50" />
                            <kendo-multiselect
                                *ngIf="dataItem.columnType == 'text' && dataItem.filterType == 'Dictionary'"
                                [data]="dataItem.dictionary" [(ngModel)]="dataItem.dictionarySelection"
                                [textField]="'acceptableValue'" [valueField]="'mappingValue'"></kendo-multiselect>
                            <select *ngIf="dataItem.columnType == 'boolean'" [(ngModel)]="dataItem['booleanValue']">
                                <option *ngFor="let op of dataItem.dictionary" [ngValue]="op.value">
                                    {{op.title}}</option>
                            </select>
                            <input *ngIf="dataItem.columnType == 'date'" [style.display]="'none'" />
                            <p-calendar *ngIf="dataItem.columnType == 'date' && dataItem.filterType == 'Range'"
                                [(ngModel)]="dataItem.dateLowerLimit" [showIcon]="true" [autoZIndex]="true"
                                dateFormat="dd/mm/yy" [readonlyInput]="true" [monthNavigator]="true"
                                yearRange="{{yearRange}}" [yearNavigator]="true" appendTo="body">
                            </p-calendar>
                            <p-calendar *ngIf="dataItem.columnType == 'date' && dataItem.filterType == 'Range'"
                                [(ngModel)]="dataItem.dateUpperLimit" [showIcon]="true" [autoZIndex]="true"
                                dateFormat="dd/mm/yy" [readonlyInput]="true" [monthNavigator]="true"
                                yearRange="{{yearRange}}" [yearNavigator]="true" appendTo="body">
                            </p-calendar>
                            <p-calendar *ngIf="dataItem.columnType == 'date' && dataItem.filterType != 'Range'"
                                [(ngModel)]="dataItem.dateValue" [showIcon]="true" [autoZIndex]="true"
                                dateFormat="dd/mm/yy" [readonlyInput]="true" [monthNavigator]="true"
                                yearRange="{{yearRange}}" [yearNavigator]="true" appendTo="body">
                            </p-calendar>
                        </div>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>
    </div>
    <div class="modal-footer">
        <div class="float-right m-0">
            <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button"
                (click)='modal.close();cancelFilterChanges()'>
                <span>Cancel</span>
            </button>
            <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button"
                (click)='clearFilterSelection();'>
                <span>Reset Filters</span>
            </button>
            <button class="btn btn-modal-pos small-font hvr-shrink" type="button"
                (click)="modal.close();confirmFilterSelection();">
                <span>Submit</span>
            </button>
        </div>
    </div>

</ng-template>

<ng-template #cellPopOutModal let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">Update<i class="fas fa-times ml-auto cursor-pointer"
                aria-label="Close" (click)="cellPopOutClose()"></i></h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-8">
                <form class="material-styling w-100 mt-3" #cellPopOutForm="ngForm">
                    <div *ngIf="cellPopOutModel != null">
                        <div class="row">
                            <div class="col-3">
                                <div class="group">
                                    <input type="text" value="{{cellPopOutModel.clientRefId}}" required disabled />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label class="non-input-label">Reference Id</label>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="group">
                                    <input type="text" value="{{cellPopOutModel.columnTitle}}" class="text-truncate"
                                        title="{{cellPopOutModel.columnTitle}}" required disabled />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label class="non-input-label">Column</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="group">
                                    <input type="text" value="{{cellPopOutModel.oldValue}}" required disabled />
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label class="non-input-label">Cell Value</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="color-teal font-14 mb-2">
                                    New Value<sup><i class="fas fa-asterisk text-danger fa-xs ml-1"></i></sup>
                                </div>
                                <div>
                                    <kendo-numerictextbox
                                        *ngIf="cellPopOutModel.cellOptions.length == 0 && cellPopOutModel.editor == 'numeric'"
                                        required id="cellPopOutNewValue" name="cellPopOutNewValue"
                                        [(ngModel)]="cellPopOutModel.newValue" [decimals]="cellPopOutModel.decimals"
                                        [autoCorrect]="true" [min]="-1000000000000000" [max]="1000000000000000"
                                        [format]="'n'" class="w-100">
                                    </kendo-numerictextbox>
                                    <input type="text"
                                        *ngIf="cellPopOutModel.cellOptions.length == 0 && cellPopOutModel.editor == 'text'"
                                        id="cellPopOutNewValue" name="cellPopOutNewValue" required
                                        [(ngModel)]="cellPopOutModel.newValue" maxlength="50"
                                        pattern="^[A-Za-z0-9 _\-.]*$" trim-text class="w-100 cellPopOutInput" />
                                    <input
                                        *ngIf="cellPopOutModel.cellOptions.length == 0 && cellPopOutModel.editor == 'date'"
                                        [style.display]="'none'" />
                                    <p-calendar
                                        *ngIf="cellPopOutModel.cellOptions.length == 0 && cellPopOutModel.editor == 'date'"
                                        id="cellPopOutNewValue" name="cellPopOutNewValue" required
                                        [(ngModel)]="cellPopOutModel.newValue" [showIcon]="true" [autoZIndex]="true"
                                        dateFormat="dd/mm/yy" [readonlyInput]="true" [monthNavigator]="true"
                                        yearRange="{{yearRange}}" [yearNavigator]="true" appendTo="body">
                                    </p-calendar>
                                    <select *ngIf="cellPopOutModel.cellOptions.length > 0" class="w-100 cellPopOutInput"
                                        required [(ngModel)]="cellPopOutModel.newValue" id="cellPopOutNewValue"
                                        name="cellPopOutNewValue">
                                        <option *ngFor="let op of cellPopOutModel.cellOptions" [ngValue]="op.value">
                                            {{op.title}}</option>
                                    </select>
                                    <div *ngIf="cellPopOutModel.oldValue == cellPopOutModel.newValue"
                                        class="alert alert-danger">
                                        New value cannot be same as old value. Please enter a different value
                                    </div>
                                    <div *ngIf="!cellPopOutForm.valid && cellPopOutForm.controls.cellPopOutNewValue.errors"
                                        class="alert alert-danger">
                                        <div *ngIf="cellPopOutForm.controls.cellPopOutNewValue.errors.required">
                                            Please enter a value to save changes.
                                        </div>
                                        <div
                                            *ngIf="cellPopOutForm.controls.cellPopOutNewValue.errors.maxError || cellPopOutForm.controls.cellPopOutNewValue.errors.minError">
                                            The value should be between -1,000,000,000,000,000 and
                                            1,000,000,000,000,000.
                                        </div>
                                        <div *ngIf="cellPopOutForm.controls.cellPopOutNewValue.errors.pattern">
                                            The value contains invalid characters.
                                        </div>
                                        <div *ngIf="cellPopOutForm.controls.cellPopOutNewValue.errors.maxlength">
                                            The value cannot be greater than 50 characters.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="color-teal font-14 mb-2">
                                    Comment<sup><i class="fas fa-asterisk text-danger fa-xs ml-1"></i></sup>
                                </div>
                                <div>
                                    <textarea id="cellPopOutComment" name="cellPopOutComment" required maxlength="500"
                                        #cellPopOutComment="ngModel" rows="4" [(ngModel)]="cellPopOutModel.comment"
                                        pattern="^[A-Za-z0-9 _\-.]*$" trim-text placeholder="Enter Comments" class="w-100 cellPopOutInput"></textarea>
                                    <div *ngIf="!cellPopOutForm.valid && cellPopOutForm.controls.cellPopOutComment.errors"
                                        class="alert alert-danger">
                                        <div
                                            *ngIf="cellPopOutForm.controls.cellPopOutComment.errors.required && cellPopOutForm.controls.cellPopOutComment.touched">
                                            Please enter a comment to save changes.
                                        </div>
                                        <div *ngIf="cellPopOutForm.controls.cellPopOutComment.errors.pattern">
                                            The comment contains invalid characters.
                                        </div>
                                        <div *ngIf="cellPopOutForm.controls.cellPopOutComment.errors.maxlength">
                                            The comment cannot be greater than 500 characters.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-4 d-flex align-items-center justify-content-center text-center border-left-yellow"
                *ngIf="cellPopOutModel.latestAudit !=null">
                <div class="p-5">
                    <p>This cell was last modified by <span
                            class="text-yellow">{{cellPopOutModel.latestAudit.ChangedBy}}</span> on <span
                            class="text-yellow">{{cellPopOutModel.latestAudit.ChangedDate | date: 'dd-MM-yyyy
                            hh:mm:ss'}}</span>
                        and the value was changed
                        from <span class="text-yellow">{{cellPopOutModel.latestAudit.OldValue}}</span>
                        to
                        <span class="text-yellow">{{cellPopOutModel.latestAudit.NewValue | dataTypeFormat :
                            cellPopOutModel.editor}}</span>
                    </p>
                    <span class="cursor-pointer change-log" (click)="getFormCellAudit(cellPopOutModel)">View Change
                        Log</span>
                </div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="cellAudit && cellAudit.length > 0">
            <div class="col-12">
                <div class="">
                    <p-table #changeLog [paginator]="true" [rows]="12" class="table-irr change-log-table"
                        [value]="cellAudit" [columns]="auditTableCols" [scrollable]="true"
                        [scrollHeight]="'calc(100vh - 292px)'">
                        <ng-template pTemplate="paginatorleft">
                            <button class="btn btn-clear-filter hvr-shrink" type="button"
                                (click)="changeLog.reset();clearFilters();">
                                <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field"> {{col.header}}
                                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                        ariaLabelDesc="Activate to sort in descending order"
                                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                                </th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns">
                                    <p-calendar *ngIf="col.field =='ChangedDate'" [(ngModel)]="modifiedDateFilters"
                                        #clFilter selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy"
                                        [readonlyInput]="true" placeholder="Select a range" appendTo="body"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="{{yearRange}}"
                                        (ngModelChange)="changeLog.filter(modifiedDateFilters, col.field, 'dateRangeFilter')"
                                        (onSelect)="closeDateRangeFilter(clFilter, modifiedDateFilters)"></p-calendar>

                                    <input *ngIf="col.field !='ChangedDate'" type="text" class="w-100"
                                        placeholder="Filter Text"
                                        [value]="changeLog.filters && changeLog.filters[col.field] ? changeLog.filters[col.field].value: ''"
                                        (input)="changeLog.filter($event.target.value, col.field, 'contains')">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr [ngClass]="{'font-italic': rowData.isSaved == false }">
                                <td>{{rowData['oldValue']}}</td>
                                <td>{{rowData['newValue'] | dataTypeFormat : cellPopOutModel.editor}}</td>
                                <td>{{rowData['comment']}}</td>
                                <td>{{rowData['changedBy']}}</td>
                                <td>{{rowData['changedDate'] | date: 'dd-MM-yyyy hh:mm:ss'}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row w-100 m-0">
            <div class="col-6 pl-0">
                <h6 class="mb-0 font-13">
                    <sup><i class="fas fa-asterisk text-danger fa-xs mr-1"></i></sup>
                    Required fields
                </h6>
                <h6 class="mb-0 font-13" *ngIf="cellAudit && cellAudit.length > 0">
                    <span class="text-info">Note : </span>
                    Unsaved audit rows will be shown in italics.
                </h6>
            </div>
            <div class="col-6 text-right pr-0">
                <button class="btn btn-modal-neg small-font mr-2 hvr-shrink" type="button" (click)='clearEditPopUp()'>
                    <span>Clear</span>
                </button>
                <button class="btn btn-modal-pos small-font hvr-shrink" type="button" (click)="submitCellEdit()"
                    [disabled]="!cellPopOutModel.isNullable">
                    <span>Delete Cell Data</span>
                </button>
                <button class="btn btn-modal-pos small-font hvr-shrink" type="button"
                    [disabled]="(cellPopOutForm && !cellPopOutForm.form.valid) || !isValidForm()"
                    (click)="submitCellEdit()">
                    <span>Submit</span>
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #summaryPopUp let-modal>
    <div class=" modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">Summary<i
                class="fas fa-times ml-auto cursor-pointer" aria-label="Close" (click)="modal.close()"></i></h5>
    </div>
    <div class="modal-body">
        <div class="text-center color-teal d-flex align-items-center ml-5">
            <p class="mb-0 font-weight-bold">Total Row Count : </p>
            <p class="mb-0 ml-2 form-data">{{gridData.total}}</p>
        </div>
        <div class="text-center color-teal d-flex align-items-center ml-5">
            <p class="mb-0 font-weight-bold">Total Error Count : </p>
            <p class="mb-0 ml-2 form-data">{{gridData.totalErrorCount}}</p>
        </div>
        <div class="text-center color-teal d-flex align-items-center ml-5">
            <p class="mb-0 font-weight-bold">Unvalidated Row Count : </p>
            <p class="mb-0 ml-2 form-data">{{gridData.totalDraftRows}}</p>
        </div>
    </div>
</ng-template>
<ng-template #reportRunPopUp let-modal>
    <div class="modal-header">
        <h5 class="modal-title d-flex align-items-center m-0 w-100">Report Run
            <i class="fas fa-times ml-auto cursor-pointer" aria-label="Close" (click)="modal.close()"></i>
        </h5>
    </div>
    <div class='modal-body'>
        <app-report-tree [(selectedReports)]="selectedReports" selectDependencies="true"></app-report-tree>
        <div class="float-right">
            <button class="btn btn-modal-pos small-font hvr-shrink" (click)="clearReportSelection()" type="button">
                <span>Clear All</span>
            </button>
            <button class="btn btn-modal-pos small-font hvr-shrink" (click)="submitReportSelection();modal.close();"
                type="button">
                <span>Submit</span>
            </button>
        </div>
    </div>
</ng-template>