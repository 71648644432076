<app-loader *ngIf="loading"></app-loader>
<div class="row mb-3">
  <div class="col-12 col-md-6">
    <app-ng7-dynamic-breadcrumb [bgColor]="'transparent'" [fontSize]="'14px'"
      [lastLinkColor]="'rgba(196, 196, 205, 0.42)'" [fontColor]="'#c4c4cd'"></app-ng7-dynamic-breadcrumb>
  </div>
  <div class="col-12 col-md-12 text-left text-md-right">
    <button class="btn btn-primary-ey btn-icon small-font hvr-shrink" type="button" (click)="addBundleType()">
      <div class="icon-container"><i class="fas fa-play"></i></div><span>Add Bundle Type</span>
    </button>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="report-pack-scroll">
      <p-table #dting [value]="bundleTypesList" [paginator]="true" [rows]="15" class="table-irr"
        [columns]="headerCols" [scrollable]="true" [scrollHeight]="'calc(100vh - 292px)'">
        <ng-template pTemplate="paginatorleft">
          <button class="btn btn-clear-filter hvr-shrink" type="button" (click)="dting.reset();clearFilters();">
            <span><i class="fas fa-times mr-1"></i> Clear Filters</span>
          </button>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field"> {{col.header}}
              <p-sortIcon *ngIf="col.field !=''" [field]="col.field" ariaLabel="Activate to sort"
                ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon>
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns">
              <input *ngIf="col.field !='' && col.field != 'createdDate'" type="text" class="w-100"
                placeholder="Filter Text"
                [value]="dting.filters && dting.filters[col.field] ? dting.filters[col.field].value: ''"
                (input)="dting.filter($event.target.value, col.field, 'contains')">

              <p-calendar *ngIf="col.field =='createdDate'" [(ngModel)]="createdDateFilters" #rdFilter
                selectionMode="range" [showIcon]="true" dateFormat="dd/mm/yy" [readonlyInput]="true"
                placeholder="Select a range" appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="{{yearRange}}"
                (ngModelChange)="dting.filter(createdDateFilters, col.field, 'dateRangeFilter')"
                (onSelect)="closeDateRangeFilter(rdFilter, createdDateFilters)">
              </p-calendar>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr *ngIf="!rowData.deleted">
            <td>{{rowData['bundleTypeName']}}</td>
            <td>{{rowData['bundleTypeDescription']}}</td>
            <td class="report-link" (click)='openMappedReportsModal(mappedReportsModal, rowData)'>Reports</td>
            <td>{{rowData['createdBy']}}</td>
            <td>{{rowData['createdDate'] | date: 'dd/MM/yyyy'}}</td>
            <td>
              <button type="button" class="btn-table-icon hvr-shrink" container="body" ngbTooltip="Edit Bundle Type"
                *ngIf="!rowData['hasBundles']" id="editBundleType" (click)='editBundleType(rowData)' triggers="hover">
                <i class="fas fa-edit fa-lg"></i>
              </button>
              <button *ngIf="rowData['hasBundles']" type="button" class="disable-edit-button btn-table-icon hvr-shrink"
                container="body" id="editBundleType"
                ngbTooltip="This bundle type cannot be edited, as it is linked to report bundle(s)." triggers="hover"
                tooltipClass="reporting-tooltip">
                <i class="fas fa-edit fa-lg"></i>
              </button>
              <button type="button" class="btn-table-icon hvr-shrink" container="body" *ngIf="!rowData['hasBundles']"
                id="deleteBundleType" ngbTooltip="Delete Bundle Type" (click)='openPromptModal(rowData, true)'
                triggers="hover">
                <i class="fas fa-trash-alt fa-lg"></i>
              </button>
              <button *ngIf="rowData['hasBundles']" type="button"
                class="disable-delete-button btn-table-icon hvr-shrink" container="body" id="deleteBundleType"
                ngbTooltip="This bundle type cannot be deleted, as it is linked to report bundle(s)." triggers="hover"
                tooltipClass="reporting-tooltip">
                <i class="fas fa-trash-alt fa-lg"></i>
              </button>
              <button *ngIf="rowData.isActive" class="btn-table-icon hvr-shrink" type="button"
                (click)="openPromptModal(rowData, false)" ngbTooltip="Deactivate Bundle Type" container="body"
                tooltipClass="reporting-tooltip">
                <i class="fas fa-eye-slash"></i>
              </button>
              <button *ngIf="!rowData.isActive" class="btn-table-icon hvr-shrink" type="button"
                (click)="openPromptModal(rowData, false)" ngbTooltip="Activate Bundle Type" container="body"
                tooltipClass="reporting-tooltip">
                <i class="fas fa-eye"></i>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<ng-template #mappedReportsModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title d-flex align-items-center m-0 w-100">Mapped Reports
      <i class="fas fa-times ml-auto cursor-pointer" aria-label="Close" (click)="closeMappedReportsModal()">
      </i>
    </h5>
  </div>
  <div class="modal-body reports-modal-container">
    <div *ngFor="let report of mappedReportList">
      <ul>
        <li>{{ report.reportName }} - {{report.reportingTypeName}}</li>
      </ul>
    </div>
  </div>
</ng-template>