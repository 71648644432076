import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { PromptModalComponent } from 'src/app/shared/prompt-modal/prompt-modal.component';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { AlertService } from "src/app/core/services/alert.service";
import { FilterUtils } from "primeng/utils";
import { ReportPackSequenceService } from "src/app/core/services/reportPackSequence.service";

@Component({
  selector: 'app-report-pack-sequence-management',
  templateUrl: './report-pack-sequence-management.component.html',
  styleUrls: ['./report-pack-sequence-management.component.css']
})
export class ReportPackSequenceManagementComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  headerCols: any;
  reportPackSequenceList: any = [];
  mappedReportPackList: any = [];
  selectedRPSequence: any;
  promptResponse: Subscription;

  private dateTime = new Date();
  private yearRange = "2000:" + this.dateTime.getFullYear().toString();
  createdDateFilters: Date[];

  constructor(private rpSequenceService: ReportPackSequenceService,
    private modalService: NgbModal,
    private commonService: CommonService,
    private alertService: AlertService,
    private router: Router) { }

  ngOnInit() {
    this.getReportPackSequenceList();
    this.headerCols = [
      { field: 'RPSequenceName', header: 'Name' },
      { field: 'Description', header: 'Description' },
      { field: '', header: 'Report Packs' },
      { field: 'CreatedBy', header: 'Created By' },
      { field: 'CreatedDate', header: 'CreatedDate' },
      { field: '', header: 'Actions' },
    ];

    this.promptResponse = this.commonService.watchPrompt().subscribe(response => {
      this.modalService.dismissAll();
      if (response.callFor === 'rpSequenceActivation') {
        if (response.response === 'Yes') {
          this.changeReportPackSequenceStatus();
        }
        else this.selectedRPSequence = null;
      }
    });

    FilterUtils['dateRangeFilter'] = (value, filter: [Date, Date]): boolean => {
      if (!filter || (!filter[0] && !filter[1]))
        return true;
      var val = new Date(value);
      var convertDate = new Date(val.getFullYear() + '/' + (val.getMonth() + 1) + '/' + val.getDate());
      var s = filter[0].getTime();
      var e;
      if (filter[1])
        e = filter[1].getTime() + (24 * 60 * 60 * 1000) - 1000;
      else e = s + (24 * 60 * 60 * 1000) - 1000;
      return convertDate.getTime() >= s && convertDate.getTime() <= e;
    }
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  };


  private getReportPackSequenceList() {
    this.loading = true;
    this.rpSequenceService.getReportPackSequenceList()
      .then((reportPackSequences: any) => {
        this.reportPackSequenceList = reportPackSequences;
        this.loading = false;
      }).catch(() => { this.loading = false; });
  }

  openMappedReportPackModal(mappedReportPackModal, rowData) {
    this.mappedReportPackList = rowData.ReportPackSequenceMappings;
    this.modalService.open(mappedReportPackModal, { size: 'lg', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
  }

  closeMappedReportPackModal() {
    this.modalService.dismissAll();
    this.mappedReportPackList = null;
  }

  changeReportPackSequenceStatus() {
    this.loading = true;
    this.rpSequenceService.changeReportPackSequenceStatus(this.selectedRPSequence.RPSId, !this.selectedRPSequence.IsActive).then(() => {
      let successMsg = this.selectedRPSequence.isActive ? "Report Pack Sequence has been deactivated successfully!" : "Report Pack Sequence has been activated successfully!";
      this.alertService.success(successMsg);
      this.selectedRPSequence = null;
      this.getReportPackSequenceList();
    }).catch(() => { this.loading = false; });
  }

  openPromptModal(rowData: any) {
    this.selectedRPSequence = rowData;
    var openModal = this.modalService.open(PromptModalComponent, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
    openModal.componentInstance.promptCallFor = 'rpSequenceActivation';
    openModal.componentInstance.promptMessage = this.selectedRPSequence.isActive ? 'Are you sure you want to deactivate this report pack sequence?' : 'Are you sure you want to activate this report pack sequence?';
  }

  addRPSequence() {
    this.rpSequenceService.editMode = false;
    this.rpSequenceService.selectedRPSequence = { RPSId: 0, RPSequenceName: '', Description: '', ReportPackSequenceMappings: [] };
    this.router.navigate(['/app/report-pack-sequence-management/add-report-pack-sequence']);
  }

  editRPSequence(selectedRPSequence: any) {
    this.rpSequenceService.editMode = true;
    this.rpSequenceService.selectedRPSequence = selectedRPSequence;
    this.router.navigate(['/app/report-pack-sequence-management/edit-report-pack-sequence']);
  }

  clearFilters() {
    this.createdDateFilters = null;
  }

  closeDateRangeFilter(dateComponent: any, filters: Date[]) {
    if (filters[1]) {
      dateComponent.hideOverlay();
    }
  }
}